import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// images
import BaseballMockup from "../../assets/landing-page/pitch-tracking-animation.GIF";
import customizable from "../../assets/landing-page/widgetsMockup1.png";
import instantAnalytics from "../../assets/landing-page/landing-instant-analytics.png";
import multiDevice from "../../assets/landing-page/landing-track-anywhere.png";
import baseballPlayerImage from "../../assets/landing-page/baseball-player-image.jpeg";
import softballPlayerImage from "../../assets/landing-page/softball-player-image.png";
import lacrossePlayerImage from "../../assets/landing-page/lacrosse-player-image.jpg";
import volleyballPlayerImage from "../../assets/landing-page/volleyball-player-image.png";

// components
import LeftGalleryCard from "../../components/global/leftGalleryCard";
import RightGalleryCard from "../../components/global/rightGalleryCard";
import Testimonials from "../../components/global/testimonials";
import AttentionGrabberHeader from "../../components/global/attentionGrabberHeader";
import LandingPageBanner from "../../components/global/landingPageBanner";
import GetTappTodayFooter from "../../components/global/getTappTodayFooter";
import FadeInSection from "../../components/global/fadeInSection";

import { Row, Col } from "antd";

const SportCard = ({ image, sportName, linkPath }) => {
	const history = useHistory();

	return (
		<div
			onClick={() => history.push(linkPath)}
			className="relative w-full h-full max-h-60 overflow-hidden cursor-pointer transform hover:scale-105 transition duration-300 ease-in-out">
			<img src={image} alt="Avatar" className=" w-full h-full filter brightness-50 grayscale" />
			<div className="absolute w-full py-2.5 bottom-5 inset-x-0 text-white text-3xl text-center leading-4">
				<strong>{sportName}</strong>
			</div>
		</div>
	);
};

const Landing = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="mt-5">
			{/** Attention grabber row */}
			<AttentionGrabberHeader
				header={
					<span>
						Your clipboard, <br></br> supercharged
					</span>
				}
				description="Tagging metrics that matter to your team has never been this easy and
				straightforward"
				mockup={BaseballMockup}
				valueProp={[]}
			/>

			{/** Testimonials */}
			<Testimonials />

			{/** Charts for your team - banner */}
			<LandingPageBanner title="Charts for your team" />

			{/** Charts for your team - content */}
			<FadeInSection>
				<div className="site-layout-content">
					<Row gutter={[20, 20]}>
						<Col span={24} order={1} lg={{ span: 6, order: 1 }} sm={{ span: 12, order: 1 }}>
							<SportCard image={baseballPlayerImage} sportName="Baseball" linkPath="/baseball" />
						</Col>
						<Col span={24} order={2} lg={{ span: 6, order: 2 }} sm={{ span: 12, order: 2 }}>
							<SportCard image={softballPlayerImage} sportName="Softball" linkPath="/softball" />
						</Col>
						<Col span={24} order={4} lg={{ span: 6, order: 3 }} sm={{ span: 12, order: 3 }}>
							<SportCard image={lacrossePlayerImage} sportName="Lacrosse" linkPath="/lacrosse" />
						</Col>
						<Col span={24} order={3} lg={{ span: 6, order: 4 }} sm={{ span: 12, order: 4 }}>
							<SportCard image={volleyballPlayerImage} sportName="Volleyball" linkPath="/volleyball" />
						</Col>
					</Row>
				</div>
			</FadeInSection>

			{/** What is tapp - banner */}
			<LandingPageBanner title="What is tapp?" />

			{/** What is tapp - content */}

			<div className="site-layout-content">
				<LeftGalleryCard
					title={"Your custom charts"}
					content={"With tapp, you can customize your charts to track the metrics that matter."}
					image={customizable}
					className="mb-5"
					linkPath={"/product"}
				/>
				<RightGalleryCard
					title={"Track anywhere, anytime"}
					content={"Data tracked on the practice field is immediately available from all your devices."}
					image={multiDevice}
					linkPath={"/product"}
				/>
				<LeftGalleryCard
					title={"Instant analytics"}
					content={
						"Jumpstart the player development process with real time data insights and player reports."
					}
					image={instantAnalytics}
					linkPath={"/product"}
				/>
			</div>

			{/** Landing footer (not the real footer) */}
			<GetTappTodayFooter />
		</div>
	);
};

export default Landing;
