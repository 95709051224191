import React from "react";
import { ReloadOutlined, CheckOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

const StopwatchMini = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return value === undefined ? (
		<div className="w-full">
			<button className={"bg-white h-8 rounded shadow text-xl text-gray-400 w-full"}>
				<CaretRightOutlined className="mr-2 text-md" />
				0.00
			</button>
			<Row gutter={4}>
				<Col span={12}>
					<button
						className={
							"h-6 w-full rounded shadow text-md font-bold mt-1 float-left text-gray-500 bg-gray-300"
						}
						disabled={true}
						type="primary"
						ghost>
						<ReloadOutlined />
					</button>
				</Col>
				<Col span={12}>
					<button
						className={
							"h-6 w-full mt-1 float-right rounded shadow text-md font-bold text-gray-500 bg-gray-300"
						}
						disabled={true}>
						<CheckOutlined />
					</button>
				</Col>
			</Row>
		</div>
	) : (
		<div className="h-full text-center w-full">
			<button disabled className="h-full rounded text-xl bg-gray-200 text-blue w-full">
				<CaretRightOutlined className="mr-2 text-md" />
				{value === 0 || value === "edit" ? "0.00" : value}
			</button>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default StopwatchMini;
