import React from "react";

import { useAppState, useActions } from "../../../api";

import { WidgetTypes } from "../../../constants/widgetTypes";

export const CounterRecord = ({ label, widgetId, isGrouped }) => {
	//#region HANDLERS ------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();

	const activeGroup = state.stats.activeGroup;

	const onCounterClick = () => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.COUNTER,
		};
		if (isGrouped) {
			let newData = data;
			if (!activeGroup[label]) {
				newData = { ...data, value: 1 };
			}

			actions.stats.setActiveGroup(newData);
		} else {
			let newData = { ...data, value: 1 };

			actions.stats.create(newData);
		}
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	let colorChanger = `${
		activeGroup && activeGroup[label] && activeGroup[label].value === 1
			? ` text-white bg-${state.user.positiveColor}-500 `
			: ` text-${state.user.positiveColor}-500 bg-white `
	} `;

	return (
		<div className="h-full">
			<button
				id={widgetId}
				className={`w-full h-full rounded shadow text-3xl font-bold py-2
      ${colorChanger}`}
				onClick={() => onCounterClick()}>
				+
			</button>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default CounterRecord;
