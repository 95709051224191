import { WidgetTypes } from "../constants/widgetTypes";

import Counter from "../components/tools/counter/Mini";
import PosNeg from "../components/tools/posNeg/Mini";
import PosNegNeutral from "../components/tools/posNegNeutral/Mini";
import NumberInput from "../components/tools/numberInput/Mini";
import Stopwatch from "../components/tools/stopwatch/Mini";
import PitchLocation from "../components/tools/pitchLocation/Mini";
import ListSelect from "../components/tools/listSelect/Mini";
import Neg from "../components/tools/neg/Mini";
import PosNeutral from "../components/tools/posNeutral/Mini";
import PlayerListSelect from "../components/tools/playerListSelect/Mini";
import FieldLocation from "../components/tools/fieldLocation/Mini";
import TextInput from "../components/tools/textInput/Mini";
import XYCoordinateRecord from "../components/tools/xyCoordinate/Record";

export const getMiniTool = (type, value) => {
	if (type === WidgetTypes.COUNTER) {
		return <Counter value={value} />;
	} else if (type === WidgetTypes.POSNEG) {
		return <PosNeg value={value} />;
	} else if (type === WidgetTypes.POSNEGNEUTRAL) {
		return <PosNegNeutral value={value} />;
	} else if (type === WidgetTypes.NUMBERINPUT) {
		return <NumberInput value={value} />;
	} else if (type === WidgetTypes.STOPWATCH) {
		return <Stopwatch value={value} />;
	} else if (type === WidgetTypes.PITCHLOCATION) {
		return <PitchLocation value={value} />;
	} else if (type === WidgetTypes.LISTSELECT) {
		return <ListSelect value={value} />;
	} else if (type === WidgetTypes.NEGCOUNTER) {
		return <Neg value={value} />;
	} else if (type === WidgetTypes.POSNEUTRAL) {
		return <PosNeutral value={value} />;
	} else if (type === WidgetTypes.PLAYERLISTSELECT) {
		return <PlayerListSelect value={value} />;
	} else if (type === WidgetTypes.TEXTINPUT) {
		return <TextInput value={value} />;
	} else if (type === WidgetTypes.FIELDLOCATION) {
		return <FieldLocation value={value} />;
	} else if (type === WidgetTypes.XYCOORDINATE) {
		return <XYCoordinateRecord />;
	}
};

export default getMiniTool;
