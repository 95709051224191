import { useState, useEffect } from "react";
import { useRive, useStateMachineInput } from "rive-react";
import tappOutcome from "../../assets/rive-animations/tappOutcome.riv";

const OperationResult = () => {
	const { rive, RiveComponent } = useRive({
		src: tappOutcome,
		stateMachines: "state_machine",
		autoplay: true,
	});

	let [message, setMessage] = useState("");
	let [operationSuccess, setOperationSuccess] = useState(false);

	useEffect(() => {
		// Check to see if this is a redirect back from Checkout
		const query = new URLSearchParams(window.location.search);
		if (query) {
			if (query.get("success") === "true" && !query.get("canceled")) {
				setOperationSuccess(query.get("success"));
				setMessage("Your changes were processed successfully.");
				return <operationResult success={operationSuccess} message={message} />;
			} else if (query.get("success") !== "true") {
				setOperationSuccess(false);
				setMessage("We've encountered an error processing your payment. Please try again.");
			} else if (query.get("canceled") === "canceled") {
				setOperationSuccess(false);
				setMessage("Subscription canceled, payment not processed.");
			} else {
				setOperationSuccess(false);
				setMessage("An unknown error has occurred.");
			}
		}
	}, []);

	const success = useStateMachineInput(rive, "state_machine", "Success");
	const failure = useStateMachineInput(rive, "state_machine", "Failure");

	if (success && operationSuccess) success.value = true;
	if (failure && !operationSuccess) failure.value = true;

	return (
		<div>
			<div className="grid h-screen w-full bg-gray-100">
				<div className="py-5 px-10">
					<div className="items-center w-full">
						<RiveComponent className="justify-center mx-auto min-h-72 min-w-72 h-72 w-72" />
						<div className="text-5xl text-center">
							{operationSuccess ? "Success!" : "Subscription Failed :("}
						</div>
					</div>
					<div className="mt-12 text-lg text-center">
						{operationSuccess ? (
							message
						) : (
							<div className="block inline-block">
								<div>
									{`${message} If this issue persists, contact support at: `}
									<a
										href="mailto:viznstats@gmail.com?subject=Let's talk"
										className="cursor-pointer text-primary hover:font-semibold">
										viznstats@gmail.com
									</a>
								</div>
							</div>
						)}
						<div className="mt-12">You may now close this window.</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OperationResult;
