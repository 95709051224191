// TODO Some of the complex filters in the delete columns handler should be moved to actions eventually
import React, { useState, useEffect } from "react";
import { useTable, useSortBy, useFlexLayout, useRowSelect, useFilters } from "react-table";
import { convertColumnNameToHumanReadable } from "../../engines/tableEngine";
import { useAppState, useActions } from "../../api";
import { ArrowUpOutlined, ArrowDownOutlined, EyeInvisibleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message, Tooltip } from "antd";

export default function Table({ chartData, columns, columnActionsEnabled }) {
	const [selectedColumn, setSelectedColumn] = useState();
	const state = useAppState();
	const actions = useActions();
	const cellProps = (props, { cell }) => getStyles(props, cell.column.align);
	const getStyles = (props, align = "left") => [
		props,
		{
			style: {
				justifyContent: align === "right" ? "flex-end" : "flex-start",
				alignItems: "flex-start",
				display: "flex",
			},
		},
	];

	const defaultColumn = React.useMemo(
		() => ({
			// When using the useFlexLayout:
			minWidth: 30, // minWidth is only used as a limit for resizing
			width: 45, // width is used for both the flex-basis and flex-grow
			maxWidth: 200, // maxWidth is only used as a limit for resizing
		}),
		[]
	);

	const { headerGroups, footerGroups, rows, prepareRow, setHiddenColumns, setSortBy } = useTable(
		{
			columns,
			data: chartData,
			initialState: {
				hiddenColumns: state.charts.chartView.hiddenColumns,
				sortBy: state.charts.chartView.sort,
				filters: [{ id: "Player_Name", value: "Total" }], // by default, filter out the total row
			},
			defaultColumn,
			filterTypes: {
				noEqual: (rows, id, filterValue) => {
					return rows.filter((row) => {
						const rowValue = row.values[id];
						return rowValue !== undefined ? rowValue !== filterValue : true;
					});
				},
			},
		},
		useFilters,
		useSortBy,
		useFlexLayout,
		useRowSelect,
		(hooks) => {
			hooks.allColumns.push((columns) => [...columns]);
		}
	);

	useEffect(async () => {
		setHiddenColumns(await state.charts.chartView.hiddenColumns);
	}, [state.charts.chartView.hiddenColumns]);

	useEffect(async () => {
		setSortBy(await state.charts.chartView.sort);
	}, [state.charts.chartView.sort]);

	const sortColumn = (column, descending) => {
		// if header group, sort by it's first child - else if sub-group, sort by it's values
		const sortColumnId = !column.canSort && column.useFirstColDefaultSort ? column.columns[0].id : column.id;

		// hard-coding Total_POS for now, talk to Jayden about how this could be done differently
		actions.charts.setChartViewSort([
			{ id: sortColumnId, desc: descending },
			{ id: "Total_POS", desc: descending },
		]);
	};

	const hideColumn = (column) => {
		actions.charts.addChartViewHiddenColumn(column.id);
	};

	const columnActionsMenu = (
		<Menu theme={"light"}>
			<Menu.Item
				key="0"
				icon={<ArrowUpOutlined />}
				disabled={selectedColumn && !selectedColumn.canSort && !selectedColumn.useFirstColDefaultSort}
				onClick={() =>
					selectedColumn ? sortColumn(selectedColumn, false) : message.error("No column selected")
				}>
				Sort ascending
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item
				key="1"
				icon={<ArrowDownOutlined />}
				disabled={selectedColumn && !selectedColumn.canSort && !selectedColumn.useFirstColDefaultSort}
				onClick={() =>
					selectedColumn ? sortColumn(selectedColumn, true) : message.error("No column selected")
				}>
				Sort descending
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item
				key="2"
				icon={<EyeInvisibleOutlined />}
				onClick={() => (selectedColumn ? hideColumn(selectedColumn) : message.error("No column selected"))}>
				Hide column
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item key="3" icon={<CloseCircleOutlined />}>
				Close
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="print-container">
			<div className="print-body">
				{
					<div>
						{headerGroups.map((headerGroup) => (
							<div {...headerGroup.getHeaderGroupProps({})} className="tr cursor-pointer">
								{headerGroup.headers.map((column) => (
									<Dropdown
										overlay={columnActionsMenu}
										trigger={["click"]}
										disabled={!columnActionsEnabled || column.id === "Player_Name_placeholder_0"}>
										<Tooltip
											mouseEnterDelay={0.8}
											title={convertColumnNameToHumanReadable(column.id)}
											visible={column.id === "Player_Name_placeholder_0" ? false : undefined}>
											<div
												{...column.getHeaderProps()}
												className="th border border-gray-200 p-2 truncate hover:bg-gray-300 bg-gray-100"
												key={column.id}
												onClick={() => setSelectedColumn(column)}>
												{column.render("Header")}
												<span>
													{column.isSorted ? (
														column.isSortedDesc ? (
															<ArrowDownOutlined className="opacity-50 ml-1" />
														) : (
															<ArrowUpOutlined className="opacity-50 ml-1" />
														)
													) : (
														""
													)}
												</span>
											</div>
										</Tooltip>
									</Dropdown>
								))}
							</div>
						))}
					</div>
				}

				<div className="tbody">
					{rows.map((row) => {
						prepareRow(row);
						return (
							<div {...row.getRowProps()} className="tr">
								{row.cells.map((cell) => {
									return (
										<div
											{...cell.getCellProps(cellProps)}
											className="td p-2 border border-gray-100 truncate">
											{/* Conditional styling is done in the tableEngine */}
											{cell.render("Cell")}
										</div>
									);
								})}
							</div>
						);
					})}
				</div>

				<div>
					<div {...footerGroups[0].getFooterGroupProps()} className="tr">
						{footerGroups[0].headers.map((column) => (
							<div {...column.getFooterProps()} className="td p-2 border border-gray-200 bg-gray-100">
								{/* Conditional styling is done in the tableEngine */}
								{column.render("Footer")}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
