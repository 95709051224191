import React, { useEffect, useState } from "react";
import { useAppState, useActions } from "../../../api";
import { WidgetTypes } from "../../../constants/widgetTypes";
import { miniTools } from "../../../constants/tools";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
import { Modal, Input, Button, Select, Tooltip, Menu, Dropdown, Row, Col } from "antd";

const { Option } = Select;

export const EditWidgetsModal = ({ editing }) => {
	const state = useAppState();
	const actions = useActions();
	const [teamId, setTeamId] = useState();
	const [tagIds, setTagIds] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (state.widgets.new.type === WidgetTypes.PLAYERLISTSELECT) {
			potentialValueHandler([{ teamId, tagIds }]);
		}
	}, [teamId, tagIds]);

	useEffect(() => {
		setTeamId(state.widgets.new.potentialValues[0]?.teamId);
		setTagIds(state.widgets.new.potentialValues[0]?.tagIds);
	});

	useEffect(() => {
		potentialValueHandler([]);
	}, [state.widgets.new.type]);

	//#region HANDLERS ---------------------------------------------------------------------------------------------------------

	const potentialValueHandler = (value) => {
		actions.widgets.newWidgetSetPotentialValue(value);
	};

	const widgetAddHandler = async () => {
		setLoading(true);
		await actions.charts.addWidgetToChart();
		setTeamId("");
		if (editing) {
			await actions.charts.updateWidgets();
		}
		setLoading(false);
	};

	const cancelHandler = () => {
		actions.widgets.clearNewWidget();
		actions.widgets.setShowNewWidgetModal(false);
		actions.widgets.setEditingWidget(false);
	};

	const handleChoose = (widgetTypeId) => {
		actions.widgets.setNewWidgetTool(widgetTypeId);
		potentialValueHandler([]);
	};

	const getMini = (widgetType) => {
		let widgetObj = Object.values(miniTools).filter((miniTool) => {
			return widgetType === miniTool.id;
		});
		return widgetObj;
	};

	const widgetUpdateHandler = async () => {
		setLoading(true);
		await actions.widgets.updateWidget();
		await actions.widgets.clearNewWidget();
		await actions.widgets.setShowNewWidgetModal(false);
		await actions.widgets.setEditingWidget(false);
		setLoading(false);
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	const teamMenu = (
		<>
			{state.teams.allTeams.length > 0
				? state.teams.allTeams.map((team) => (
						<Option key={team.teamId} value={team.teamId} label={team.name}>
							{team.name}
						</Option>
				  ))
				: ""}
		</>
	);

	const miniToolsMenu = (
		<Menu className="w-64 h-96 overflow-y-auto">
			{Object.values(miniTools).map((miniTool) => {
				return (
					<Menu.Item className="border-b border-gray-200 pb-0">
						<Row className="mt-4 pb-4" type={miniTool.id} onClick={() => handleChoose(miniTool.id)}>
							<Col span={10}>{miniTool.tool}</Col>
							<Col span={14}>
								<p className="ml-2">{miniTool.title}</p>
							</Col>
						</Row>
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<Modal
			title={state.widgets.editingWidget ? "Edit Tool" : "Add Tool"}
			className="overflow-y-auto"
			visible={state.widgets.showNewWidgetModal}
			maskClosable={false}
			onOk={widgetAddHandler}
			onCancel={cancelHandler}
			footer={[
				<Button
					key="submit"
					loading={loading}
					disabled={
						!(state.widgets.new.name && state.widgets.new.type) ||
						(state.widgets.new.type === WidgetTypes.PLAYERLISTSELECT && !teamId) ||
						(state.widgets.new.type === WidgetTypes.LISTSELECT &&
							state.widgets.new.potentialValues.length < 1)
					}
					type="primary"
					onClick={state.widgets.editingWidget ? widgetUpdateHandler : widgetAddHandler}>
					Save
				</Button>,
			]}>
			<div className="mb-8">
				<Row className="mt-4">
					<Col span={12}>
						<Dropdown overlay={miniToolsMenu} trigger={["click"]} disabled={state.widgets.editingWidget}>
							<Button icon={<DownOutlined />} className="w-full">
								{getMini(state.widgets.new.type).length > 0
									? getMini(state.widgets.new.type)[0].title
									: "Tool"}
							</Button>
						</Dropdown>
					</Col>
					<Col span={12}>
						<Input
							placeholder="Title"
							disabled={state.widgets.editingWidget}
							value={state.widgets.new.name}
							onChange={(e) => actions.widgets.setNewWidgetLabel(e.target.value)}
						/>
					</Col>
				</Row>
			</div>
			{state.widgets.new.type === WidgetTypes.LISTSELECT ? (
				<>
					<p className="text-lg font-bold mt-12 mb-2">List Select Options</p>
					<Select
						mode="tags"
						style={{ width: "100%" }}
						placeholder="Options"
						onChange={potentialValueHandler}
						defaultValue={state.widgets.new.potentialValues}
					/>
				</>
			) : (
				""
			)}
			{state.widgets.new.type === WidgetTypes.PLAYERLISTSELECT ? (
				<>
					<div className="flex">
						<p className="text-lg font-bold mt-10 mb-2">Which players should be included?</p>
						<Tooltip
							className="ml-3 text-gray-500 mt-12 mb-2"
							title="The list select will be populated with players matching the team and tags you select.">
							<InfoCircleOutlined />
						</Tooltip>
					</div>
					<Select
						value={teamId}
						showSearch
						style={{ width: "100%" }}
						placeholder="Team"
						optionFilterProp="children"
						optionLabelProp="label"
						onChange={(value) => setTeamId(value)}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}>
						{teamMenu}
					</Select>
					<Select
						value={tagIds}
						mode="multiple"
						style={{ width: "100%", marginTop: "25px" }}
						placeholder="Tags"
						optionFilterProp="label"
						onChange={(value) => setTagIds(value)}>
						{state.tags.playerTags.map((tag) => (
							<Option key={tag.tagId} label={tag.label}>
								{tag.label}
							</Option>
						))}
					</Select>
				</>
			) : (
				""
			)}
		</Modal>
	);

	//#endregion JSX ----------------------------------------------------------------------------------------------------------
};

export default EditWidgetsModal;
