import React, { useState, useEffect } from "react";
import { useAppState, useActions } from "../../../api";
import { WidgetTypes } from "../../../constants/widgetTypes";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Input } from "antd";
const { TextArea } = Input;

export const NumberInputRecord = ({ label, widgetId, isGrouped }) => {
	//#region HOOKS -----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const activeGroup = state.stats.activeGroup;
	const [value, setValue] = useState(activeGroup && activeGroup[label] ? activeGroup[label].value : "");

	useEffect(() => {
		if (!activeGroup || !activeGroup[label]) {
			setValue("");
		} else {
			setValue(activeGroup[label].value);
		}
	}, [activeGroup && activeGroup[label] ? activeGroup[label].value : ""]);

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------

	const handleClick = () => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.TEXTINPUT,
		};
		if (isGrouped) {
			let newData = data;
			if (value !== "") {
				newData = { ...data, value: value };
			} else {
				setValue("");
			}
			actions.stats.setActiveGroup(newData);
		} else {
			let newData = { ...data, value: value };
			actions.stats.create(newData);
			setValue("");
		}
	};

	const handleInputChange = (e) => {
		setValue(e.target.value);
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<>
			<TextArea
				rows={isGrouped ? 8 : 6}
				className="h-full rounded shadow bg-white mr-1 w-full"
				id={widgetId}
				onBlur={(e) => {
					if (isGrouped) {
						handleClick(e);
					}
				}}
				onChange={(e) => {
					handleInputChange(e);
				}}
				value={value}
				placeholder="text"
				key={"editor " + label}
				icon={<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />}
			/>
			{isGrouped ? (
				""
			) : (
				<div className="text-right">
					<button
						onClick={(e) => handleClick(e)}
						id={widgetId}
						className={
							"mt-1 text-white font-bold py-1 text-xl rounded shadow w-full " +
							(value ? "bg-blue text-white" : "bg-gray-400")
						}>
						+
					</button>
				</div>
			)}
		</>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default NumberInputRecord;
