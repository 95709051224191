import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAppState, useActions } from "../../api";
import { useHistory } from "react-router-dom";
import { Menu, Modal } from "antd";
const { confirm } = Modal;

export default function DeleteChartMenuItem({ chartId }) {
	const history = useHistory();
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[chartId];

	const showConfirm = () => {
		const currentChart = state.charts.all[chartId];
		if (currentChart.isArchived) {
			confirm({
				title: "Are you sure you want to restore '" + currentChart.title + "'?",
				icon: <ExclamationCircleOutlined />,
				async onOk() {
					history.push("/");
					await actions.charts.unArchiveChart(chartId);
				},
				onCancel() {},
			});
		} else {
			confirm({
				title: "Are you sure you want to archive '" + currentChart.title + "'?",
				icon: <ExclamationCircleOutlined />,
				async onOk() {
					history.push("/");
					await actions.charts.archiveChart(chartId);
				},
				onCancel() {},
			});
		}
	};

	return <Menu.Item onClick={showConfirm}>{currentChart.isArchived ? "Restore chart" : "Archive Chart"}</Menu.Item>;
}
