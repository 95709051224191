export const chart = {
	chartId: "",
	creatorId: "",
	dateCreated: "",
	dateLastUsed: "",
	title: "",
	isGrouped: true,
	includesContext: false,
	subscribers: [],
	players: [],
	widgets: [],
};

export const widget = {
	widgetId: "",
	type: "",
	name: "",
	position: "",
	attributes: [],
	potentialValues: [],
};

export const player = {
	playerId: "",
	name: "",
	dateCreated: "",
	positions: [],
	number: "",
	team: "",
	gradYear: "",
};

const statData = {
	widgetId: "",
	widgetName: "",
	widgetType: "",
	value: "",
	attributes: [],
};

export const stat = {
	statId: "",
	chartId: "",
	creatorId: "",
	playerId: "",
	playerName: "",
	dateCreated: "",
	isGrouped: false,
	includesContext: false,
	statData: statData,
	gameContext: "",
};
