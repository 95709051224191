import moment from "moment";
import { TimeFrames } from "../constants/timeFrames";

/**
 * Create start and end date for the current time based on a time frame
 */
export const getCurrentDatesFromTimeFrame = (timeframe) => {
	let startDate = moment();
	let endDate = moment();

	if (Object.keys(TimeFrames).includes(timeframe)) {
		switch (timeframe) {
			case TimeFrames.WEEKLY:
				startDate = moment().startOf("week");
				break;
			case TimeFrames.MONTHLY:
				startDate = moment().startOf("month");
				break;
			case TimeFrames.SEASON:
				// first case is for first semester, second case is for second semester
				startDate =
					moment().month() > 8 ? moment(moment().year() + "-08-01 00:00:00") : moment().startOf("year");
				break;
			default:
				// no default necessary since this case along with 'today' is handled during variable initialization
				break;
		}
	}

	return {
		startDate,
		endDate,
	};
};
