import Widget from "../../models/widget";
import { WidgetTypes } from "../widgetTypes";

const exerciseWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Exercise",
	position: null,
	attributes: [],
	potentialValues: ["Bench", "Squat", "Deadlift"],
};
export const exerciseWidget = new Widget(exerciseWidgetInput);

const weightWidgetInput = {
	type: WidgetTypes.NUMBERINPUT,
	name: "Weight",
	position: null,
	attributes: [],
	potentialValues: [],
};
export const weightWidget = new Widget(weightWidgetInput);

const toFailureWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "To Failure",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const toFailureWidget = new Widget(toFailureWidgetInput);
