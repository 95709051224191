import React from "react";
import { CaretRightOutlined } from "@ant-design/icons";

const StopwatchDisabled = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<div className="h-full text-center">
			<button className={"bg-white h-full rounded shadow text-xl text-gray-400 w-full"}>
				<CaretRightOutlined className="mr-2 text-md" />
				{value === 0 || value === "edit" ? "0.00" : value}
			</button>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default StopwatchDisabled;
