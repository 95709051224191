import React from "react";
import { Modal, Button } from "antd";
import { wikiURL } from "../../constants/wiki";
import { useAppState, useActions } from "../../api";

const OnboardModal = () => {
	const state = useAppState();
	const actions = useActions();
	//#region Hooks ---------------------------------------------------------------------------------------------------------

	//#endregion Hooks ---------------------------------------------------------------------------------------------------------

	//#region HANDLERS ---------------------------------------------------------------------------------------------------------
	const handleCancel = async () => {
		await actions.organization.setChartModal(false);
	};

	const handleHelpPage = async () => {
		window.location.href = wikiURL.home;
		await actions.organization.setChartModal(false);
	};

	const handleOk = async () => {
		await actions.organization.setChartModal(false);
	};

	//#endregion HANDLERS ---------------------------------------------------------------------------------------------------------

	//#region JSX ---------------------------------------------------------------------------------------------------------
	return (
		<Modal
			title="Charts"
			visible={state.organization.organization.organizationConfig.showChartModal}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key="1" onClick={handleHelpPage}>
					Go to Help Page
				</Button>,
				<Button key="2" type="primary" onClick={handleOk}>
					Get Started!
				</Button>,
			]}>
			<p>📋 Here are the basics:</p>
			<div className="flex my-8">
				<p className="w-5/6">👆 Charts are made up of tagging tools and players</p>
			</div>
			<div className="flex my-8">
				<p className="w-5/6">
					📊 Data you input with the tagging tools is stored in the chart and you can analyze it, print it
					off, and share it however you like!
				</p>
			</div>
			<div className="flex my-8">
				<p className="w-5/6">
					📋 Data in a chart can be grouped together by rep (a pitching rep could group pitch type, velo, and
					strike/ball)
				</p>
			</div>
			<div className="flex my-8">
				<p className="w-5/6">🚩 When recording, you can flag stats to look at later</p>
			</div>
			<div className="my-8">
				<p className="mb-1">
					👊 To get started, add some tagging tools and players to your chart (bottom right) and start
					tracking!
				</p>
			</div>
			<div className="mt-8">
				<p className="mb-0 text-gray-500">
					👉 Questions? Email us at team@tappsports.com or visit the help page
				</p>
			</div>
		</Modal>
	);
	//#endregion JSX ---------------------------------------------------------------------------------------------------------
};

export default OnboardModal;
