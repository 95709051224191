import React, { useEffect, useState } from "react";
import { useAppState, useActions } from "../../api";
import RecordingToolsRows from "../widgets/recordingTools/RecordingToolsRows";
import { allCharts } from "../../constants/templates";
import { Divider, Modal, Tabs } from "antd";
const { TabPane } = Tabs;

export default function TemplatesModal() {
	const state = useAppState();
	const actions = useActions();
	const [confirmLoading, setConfirmLoading] = useState(false);

	useEffect(() => {
		// actions.charts.setNew(allCharts[0]);
	}, []);

	const handleOk = async () => {
		setConfirmLoading(true);
		for (const widgetIndex in state.charts.new.widgets) {
			const widget = state.charts.new.widgets[widgetIndex];
			await actions.widgets.setNewWidget(widget);
			await actions.charts.addWidgetToChart();
		}
		await actions.charts.updateWidgets();
		setConfirmLoading(false);
		actions.charts.updateIsGrouped(state.charts.new.isGrouped);
		actions.charts.setShowTemplatesModal(false);
		///update/chart
	};

	const handleCancel = () => {
		actions.charts.setShowTemplatesModal(false);
	};

	// const chartTemplates = ["Hitting", "Pitching", "Fielding", "Catching", "Weightlifting", "Tryout eval"];
	return (
		<Modal
			title="Chart Templates"
			visible={state.charts.showTemplatesModal}
			onOk={handleOk}
			onCancel={handleCancel}
			confirmLoading={confirmLoading}
			okText="Use This Template">
			<Tabs tabPosition={window.innerWidth < 500 ? "top" : "left"}>
				{allCharts.map((templateChart) => (
					<TabPane tab={templateChart.title} key={templateChart.title}>
						<div className="max-h-96 overflow-y-auto overflow-x-hidden p-2 bg-gray-100 rounded">
							<p>Chart grouped: {templateChart.isGrouped ? "Yes" : "No"}</p>
							<Divider />
							<RecordingToolsRows chart={templateChart} isGrouped={templateChart.isGrouped} />
						</div>
					</TabPane>
				))}
			</Tabs>
		</Modal>
	);
}
