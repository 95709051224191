import React, { useState } from "react";
import EditChartWidgets from "../../components/widgets/edit/EditChartWidgets";
import RecordingPlayerRows from "../../components/players/recording/RecordingPlayerRows";

import EditPlayersModal from "../../components/players/edit/EditPlayersModal";
import EditWidgetsModal from "../../components/widgets/edit/EditWidgetsModal";
import GroupedChartToggle from "../../components/charts/GroupedChartToggle.jsx";

import { useAppState, useActions } from "../../api";

import { Modal, Button } from "antd";
import TemplateSelect from "../../components/charts/TemplateSelect";
import TemplatesModal from "../../components/charts/TemplatesModal";

const Chart = () => {
	const state = useAppState();
	const actions = useActions();

	const currentChart = state.charts.all[state.charts.currentChartId];
	const [search, setSearch] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isPlayerModalVisible, setIsPlayerModalVisible] = useState(false);
	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const showEditPlayerModal = () => {
		setIsPlayerModalVisible(true);
	};

	const showNewWidgetModal = () => {
		actions.widgets.setShowNewWidgetModal(true);
	};

	return (
		<>
			<TemplatesModal />
			<Modal
				title="Basic Modal"
				visible={isDeleteModalVisible}
				onOk={() => setIsDeleteModalVisible(false)}
				onCancel={() => setIsDeleteModalVisible(false)}>
				<p>Are you sure you want to delete this chart?</p>
			</Modal>
			<EditPlayersModal
				isModalVisible={isPlayerModalVisible}
				setIsModalVisible={setIsPlayerModalVisible}
				editing={true}
			/>
			<EditWidgetsModal editing={true} />
			<Modal
				title="Please Select A player!"
				visible={isModalVisible}
				onCancel={handleCancel}
				centered
				footer={[
					<Button type="primary" key="ok" onClick={handleOk}>
						Ok
					</Button>,
				]}>
				<p>Please select which player this stat is supposed to be for.</p>
			</Modal>
			<div className="mt-2 h-full overflow-y-auto">
				<div className="mx-2">
					<div className="mr-2">
						<GroupedChartToggle />
					</div>
				</div>
				<div className="flex pb-0.5 font-montserrat pt-2 mt-2">
					<div className="w-3/4 font-montserrat pr-2">
						<div>
							<div
								onClick={showNewWidgetModal}
								className="h-14 w-full border border-coolPurple text-center mb-2 pt-4 text-coolPurple cursor-pointer">
								+ Tagging Tool
							</div>
							<EditChartWidgets editing />
						</div>

						{currentChart.widgets.length > 0 ? null : <TemplateSelect />}
					</div>
					<div className="w-1/4 pl-2 overflow-y-auto border-l border-gray-300">
						<div className="flex-1 h-12/12">
							<div
								onClick={showEditPlayerModal}
								className="h-14 w-full border border-coolPurple text-center mb-3 pt-4 text-coolPurple cursor-pointer">
								+ Player
							</div>
							<RecordingPlayerRows edit search={search} setSearch={setSearch} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chart;
