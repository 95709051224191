import React from "react";

export default function LandingPageButton({ children, onClick, ghost }) {
	const buttonStyles =
		"ml-2 md:ml-5 w-24 md:w-40 h-14 rounded text-lg font-bold hover:shadow-lg hover:font-extrabold transform hover:scale-105 transition duration-300 ease-in-out ";
	return (
		<button
			className={
				buttonStyles + (ghost ? "bg-coolPurple text-white border-2 border-white" : "bg-white text-coolPurple")
			}
			onClick={onClick}>
			{children}
		</button>
	);
}
