import React from "react";

import { useAppState } from "../../../api";
import { getSprayChartTotal } from "../../../engines/dataEngine";
import HomePlate from "../../../assets/home-plate.png";
import { FoulBalls, HomeRuns, InfieldValues } from "../../../constants/fieldLocation";

import { Row, Col, Tooltip } from "antd";

export const SprayChart = ({ data, dataPage }) => {
	const infieldColor = "darkYellow";
	const ballColor = "white";
	const homerunColor = "white";
	const outfieldColor = "green";
	let { total, max, min } = getSprayChartTotal(data);

	const state = useAppState();

	const getDataVal = (id) => {
		let dataVal;

		Object.keys(data).forEach((key) => {
			if (dataPage) {
				var regex = /\d+/g;
				var idNumberFromKey = key.match(regex);
				if (parseInt(idNumberFromKey) === id) {
					dataVal = data[key];
				}
			} else {
				// need to do this to get the tool's name
				let toolNameArr = key.split("_");
				//first value of the array is the name of the tool assuming the tool name doesn't contain an _
				let suffix = "";
				for (let i = 0; i < toolNameArr.length; i++) {
					if (i != 0) suffix = suffix + toolNameArr[i];
					if (i != 0 && i != toolNameArr.length - 1) suffix = suffix + "_";
				}

				if (suffix === id) {
					dataVal = data[key];
				}
			}
		});

		return dataVal;
	};

	const determineColor = (id, fracVal) => {
		const opacityVal = Math.ceil((fracVal * 100) / 5) * 5;
		let color;

		if (InfieldValues.some((val) => id.includes(val))) {
			if (fracVal === 0 || isNaN(fracVal)) {
				color = "bg-yellow-100 bg-opacity-50 text-gray-400";
			} else {
				color = `text-black bg-yellow-600 bg-opacity-${opacityVal} `;
			}
		} else if (FoulBalls.some((val) => id.includes(val))) {
			if (fracVal === 0 || isNaN(fracVal)) {
				color = "bg-red-100 bg-opacity-50 text-gray-400";
			} else {
				color = `text-black bg-red-600 bg-opacity-${opacityVal} `;
			}
		} else if (HomeRuns.some((val) => id.includes(val))) {
			if (fracVal === 0 || isNaN(fracVal)) {
				color = "bg-blue-100 bg-opacity-50 text-gray-400";
			} else {
				color = `text-black bg-blue-600 bg-opacity-${opacityVal} `;
			}
		} else {
			if (fracVal === 0 || isNaN(fracVal)) {
				color = "bg-green-100 bg-opacity-50 text-gray-400";
			} else {
				color = `text-black bg-green-600 bg-opacity-${opacityVal} `;
			}
		}

		return color;
	};

	//#region JSX -------------------------------------------------------------------------------------------------------------

	const LocationButton = ({ idNum, id }) => {
		let dataVal = 0;
		if (dataPage) {
			dataVal = getDataVal(idNum);
		} else {
			dataVal = getDataVal(id);
		}
		let fracVal = dataVal * (1 / (max - (min - 1)));
		let color = determineColor(id, fracVal);

		let percentage = (dataVal / total).toFixed(2);
		let fraction = dataVal ? dataVal + "/" + total : "0/" + total;

		let isHome = false;
		if (id === "IN_HOME") isHome = true;

		let textStyling = "";
		if (id === "IN_HOME") {
			textStyling = "mt-auto mb-auto ml-4";
		} else {
			textStyling = "mt-auto mb-auto";
		}

		return (
			<Tooltip
				placement="top"
				title={state.charts.chartView.sprayChartFraction ? percentage : fraction}
				trigger={["hover", "click"]}>
				<button
					className={"w-full h-full border border-black border-opacity-10 text-xs rounded " + color}
					key={id + " location button"}
					id={id}>
					<p className={"transform rotate-45 " + textStyling}>
						{dataVal !== 0 && dataVal
							? state.charts.chartView.sprayChartFraction
								? fraction
								: percentage
							: "-"}
					</p>
					{isHome ? (
						<div className={"mr-5 ml-auto mt-0 mb-auto transform rotate-45"}>
							<img className="w-4" src={HomePlate} alt="Mockup" />
						</div>
					) : (
						""
					)}
				</button>
			</Tooltip>
		);
	};

	const spraychartHeight = " h-44 ";

	return (
		<Row className={"mb-4 w-48 " + spraychartHeight} gutter={[4, 0]}>
			<Col span={4} className={spraychartHeight}>
				<Row className="h-8 mb-1 border-r-2 border-gray-800">
					<LocationButton textColor={ballColor} key={15} idNum={15} id={"FB_OUT_LEFT"} />
				</Row>
				<Row className="h-28 mb-1 border-r-2 border-gray-800">
					<LocationButton textColor={ballColor} key={16} idNum={16} id={"FB_IN_LEFT"} />
				</Row>
				<Row className="h-1/5" gutter={[0, 4]}>
					<LocationButton textColor={ballColor} key={17} idNum={17} id={"FB_BEHIND"} />
				</Row>
			</Col>
			<Col span={16} className={spraychartHeight}>
				<Row className="h-8 mb-1" gutter={[3, 0]}>
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={14} idNum={14} id={"HR_LEFT"} />
					</Col>
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={13} idNum={13} id={"HR_LEFT_CENTER"} />
					</Col>
				</Row>
				<Row className="h-1/5 mb-1" gutter={[3, 0]}>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={9} idNum={9} id={"OUT_LEFT"} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={8} idNum={8} id={"OUT_LEFT_CENTER"} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={7} idNum={7} id={"OUT_CENTER"} />
					</Col>
				</Row>
				<Row className="h-1/5 mb-1" gutter={[3, 0]}>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={4} idNum={4} id={"IN_THIRD"} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={3} idNum={3} id={"IN_SECOND"} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={6} idNum={6} id={"OUT_RIGHT_CENTER"} />
					</Col>
				</Row>
				<Row className="h-1/5 mb-1" gutter={[3, 0]}>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={1} idNum={1} id={"IN_HOME"} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={2} idNum={2} id={"IN_FIRST"} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={5} idNum={5} id={"OUT_RIGHT"} />
					</Col>
				</Row>
				<Row className="h-1/5 border-t-2 border-gray-800">
					<LocationButton textColor={ballColor} key={18} idNum={18} id={"FB_IN_RIGHT"} />
				</Row>
			</Col>
			<Col span={4} className={spraychartHeight}>
				<Row className="h-8 mb-1">
					<LocationButton textColor={homerunColor} key={12} idNum={12} id={"HR_CENTER"} />
				</Row>
				<Row className="h-14 mb-0.5">
					<LocationButton textColor={homerunColor} key={11} idNum={11} id={"HR_RIGHT_CENTER"} />
				</Row>
				<Row className="h-14 mb-1">
					<LocationButton textColor={homerunColor} key={10} idNum={10} id={"HR_RIGHT"} />
				</Row>
				<Row className="h-1/5 border-t-2 border-gray-800">
					<LocationButton textColor={ballColor} key={19} idNum={19} id={"FB_OUT_RIGHT"} />
				</Row>
			</Col>
		</Row>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default SprayChart;
