import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Divider, Form, Input, Row, Modal } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useActions, useAppState } from "../../api";

const WaitlistSignup = () => {
	const state = useAppState();
	const actions = useActions();
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	//All of this needs to be updated to store our new waitlist users
	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			await actions.user.createWaitlistSubmission(values);
			Modal.success({
				title: "Success!",
				content: "You will receive an email shortly from a team member to help set up your account. Thank you!",
				onOk: () => history.push("/"),
			});
		} catch (err) {
			Modal.error({
				title: "Error completing signup process.",
				content: (
					<>
						{`Reason: ${err.message}`}
						<br />
						<br />
						{"Please try again or contact team@tappsports.com for support."}
					</>
				),
			});
		}
		setLoading(false);
	};

	const formTailLayout = {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	};

	return (
		<div className="site-layout-content-narrow">
			<div className="relative z-1 bg-white rounded shadow-md">
				<Row gutter={[10, 10]}>
					<Col span={8} xs={0} sm={0} md={8}>
						<div className="w-full h-full p-2 bg-gray-200">
							<div className="mt-28 w-56 mx-auto">
								<p className="text-lg mb-6 font-semibold">With tapp, you can build</p>
								<p className="text-md mb-4 flex items-center">
									<CheckCircleFilled style={{ fontSize: 20, color: "#6163FF" }} /> &nbsp;&nbsp;
									Hitting charts
								</p>
								<p className="text-md mb-4 flex items-center">
									<CheckCircleFilled style={{ fontSize: 20, color: "#6163FF" }} /> &nbsp;&nbsp;
									Fielding charts
								</p>
								<p className="text-md mb-4 flex items-center">
									<CheckCircleFilled style={{ fontSize: 20, color: "#6163FF" }} /> &nbsp;&nbsp;
									Pitching charts
								</p>
								<p className="text-md mb-4 flex items-center">
									<CheckCircleFilled style={{ fontSize: 20, color: "#6163FF" }} /> &nbsp;&nbsp;
									Opposing hitter reports
								</p>
								<p className="text-md mb-4 flex items-center">
									<CheckCircleFilled style={{ fontSize: 20, color: "#6163FF" }} /> &nbsp;&nbsp;
									Synergy tagging charts
								</p>
							</div>
						</div>
					</Col>
					<Col span={16} xs={24} sm={24} md={16}>
						<div className="w-full p-10">
							<h1 className="mb-5 text-black text-3xl font-medium sm:text-right">Sign Up</h1>
							<Divider />
							<Form
								name="normal_signup"
								loading={loading}
								initialValues={{ remember: true }}
								labelCol={{ span: 8 }}
								wrapperCol={{ span: 16 }}
								size="large"
								onFinish={handleSubmit}>
								<Form.Item
									name="name"
									label="Name"
									rules={[{ required: true, message: "Please input your name. " }]}>
									<Input />
								</Form.Item>
								<Form.Item
									name="email"
									label="E-mail"
									initialValue={state.user.waitlistEmail}
									rules={[
										{
											type: "email",
											message: "The input is not a valid E-mail. ",
										},
										{
											required: true,
											message: "Please input your E-mail. ",
										},
									]}>
									<Input />
								</Form.Item>
								<Form.Item
									name="phone"
									label="Phone Number"
									initialValue={state.user.phone}
									rules={[
										{
											pattern: /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
											required: false,
											message: "Please input a valid phone number. ",
										},
									]}>
									<Input />
								</Form.Item>
								<Form.Item
									name="organization"
									label="Organization"
									rules={[{ required: true, message: "Please input your organization. " }]}>
									<Input />
								</Form.Item>

								<Form.Item wrapperCol={formTailLayout}>
									<button
										disabled={loading}
										type="submit"
										className="w-full p-2 rounded bg-coolPurple hover:bg-darkPurple text-white text-lg font-normal 
                hover:shadow-lg hover:font-extrabold transform hover:scale-105 disabled:bg-gray-600
                transition duration-300 ease-in-out">
										{loading ? "Loading..." : "Get Started"}
									</button>
								</Form.Item>
								<Form.Item wrapperCol={formTailLayout}>
									<p className="mt-4 mb-2 text-gray-800">
										Already have an account?{" "}
										<Link to="/login">
											<span className="text-coolPurple font-semibold hover:underline cursor-pointer">
												Login
											</span>
										</Link>
									</p>
								</Form.Item>
							</Form>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default WaitlistSignup;
