import React from "react";
import { useAppState, useActions } from "../../api";
import { Col, Row, Skeleton, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import EditableTable from "../../components/dataViews/EditableTable";
import Team from "../../models/team";

const TeamManagementPage = ({ hideTitle }) => {
	//#region Hooks ----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	//#endregion Hooks ----------------------------------------------------------------------------------------------------------

	//#region Constants ----------------------------------------------------------------------------------------------------------
	const KEY_PROPERTY = "teamId";
	const COLUMNS = [
		{
			title: "Team Name",
			dataIndex: "name",
			dataType: "link",
			link: "/roster",
			width: "40%",
			required: true,
			editable: true,
			defaultSortOrder: "ascend",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: (
				<div className="flex">
					Tags
					<Tooltip
						title="Tags are a great way to label and group teams. ex: varsity or conference"
						placement="top">
						<QuestionCircleOutlined className="mt-1 ml-auto" />
					</Tooltip>
				</div>
			),
			dataIndex: "teamTagIds",
			dataType: "tag",
			width: "30%",
			tags: state.tags.teamTags,
			required: false,
			editable: true,
		},
		{
			title: "Archived",
			dataIndex: "isArchived",
			dataType: "checkbox",
			width: "10%",
			required: true,
			editable: true,
			filterMultiple: false,
			defaultFilteredValue: [false],
			filters: [
				{ text: "Show Archived", value: true },
				{ text: "Hide Archived", value: false },
			],
			onFilter: (value, record) => (value ? true : !record.isArchived),
		},
	];
	//#endregion Constants -------------------------------------------------------------------------------------------------------------

	//#region Handlers ----------------------------------------------------------------------------------------------------------------
	const rowClickAction = async (row) => {
		await actions.teams.setSelectedTeamId(row[KEY_PROPERTY]);
	};

	const archiveSelectedTeams = async (rowKeys) => {
		await Promise.all(
			rowKeys.map(async (rowKey) => {
				const record = new Team(actions.teams.getTeamById(rowKey));
				record.isArchived = true;
				await updateTeam(record);
			})
		);
	};

	const deleteSelectedTeams = async (rowKeys) => {
		await Promise.all(
			rowKeys.map(async (rowKey) => {
				const record = new Team(actions.teams.getTeamById(rowKey));
				await deleteTeam(record);
			})
		);
	};

	const createTeam = async (row) => {
		await actions.teams.createTeam(row);
	};

	const updateTeam = async (row) => {
		await actions.teams.updateTeam(row);
	};

	const deleteTeam = async (row) => {
		await actions.teams.deleteTeam(row[KEY_PROPERTY]);
	};
	//#endregion JSX -------------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------
	return (
		<div className="font-montserrat" style={{ minWidth: 500 }}>
			{!state.global.loading ? (
				<div>
					<Col>
						<Row>
							<EditableTable
								hideTitle={hideTitle}
								data={state.teams.allTeams}
								keyProperty={KEY_PROPERTY}
								dataModel={Team}
								columns={COLUMNS}
								includeActions={true}
								addButtonTitle={"Create New Team"}
								title={"Teams"}
								rowClickAction={rowClickAction}
								archiveSelection={archiveSelectedTeams}
								deleteSelection={deleteSelectedTeams}
								createRecord={createTeam}
								updateRecord={updateTeam}
								deleteRecord={deleteTeam}
							/>
						</Row>
					</Col>
				</div>
			) : (
				<Skeleton />
			)}
		</div>
	);
	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default TeamManagementPage;
