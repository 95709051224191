import axios from "axios";
import { User } from "../../constants/api";

export const api = {
	async getUser() {
		try {
			const getUserRes = await axios.post(User.read);
			return getUserRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async getSubscriptionPortal() {
		const response = await axios.post(User.getSubscriptionLink);
		return response;
	},
	async createWaitlistSubmission(waitlistPayload) {
		const response = await axios.post(User.create.waitlist, waitlistPayload);
		return response;
	},
	async createUser(userPayload) {
		const createUserRes = await axios.post(User.create.user, userPayload);
		return createUserRes;
	},
	async createJoinUser(userPayload) {
		try {
			const getJoinUserRes = await axios.post(User.create.joinUser, userPayload);
			return getJoinUserRes;
		} catch (error) {
			throw new Error(error);
		}
	},
};
