export const WidgetSuffix = {
	HEADER: "",
	POS: "+",
	NEG: "-",
	NEUT: "•",
	PCT: "%",
	COUNTER: "+",
	MIN: "min",
	MAX: "max",
	AVG: "avg",
	NEG_COUNTER: "-",
};

export const WidgetSuffixEnum = {
	HEADER: "HEADER",
	POS: "POS",
	NEG: "NEG",
	NEUT: "NEUT",
	PCT: "PCT",
	COUNTER: "COUNTER",
	MIN: "MIN",
	MAX: "MAX",
	AVG: "AVG",
	NEG_COUNTER: "NEG_COUNTER",
};
