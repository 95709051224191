import React from "react";
import { useActions } from "../../../api";
import { getMiniTool } from "../../../util/getMiniTool";

import { Draggable } from "react-beautiful-dnd";
import { WidgetTypes } from "../../../constants/widgetTypes";
import { EllipsisOutlined } from "@ant-design/icons";
import { Row, Col, Dropdown, Menu } from "antd";

const EditWidgetRow = ({ widget, index, editing }) => {
	const actions = useActions();

	const editAllowed = [WidgetTypes.LISTSELECT, WidgetTypes.PLAYERLISTSELECT];

	//#region Handlers -----------------------------------------------------------------------------------------------------------
	const handleRemove = async () => {
		await actions.charts.removeWidgetFromChart(widget);
		if (editing) {
			actions.charts.updateWidgets();
		}
	};
	const handleEdit = () => {
		actions.widgets.setShowNewWidgetModal(true);
		actions.widgets.setEditingWidget(true);
		actions.widgets.setNewWidget(widget);
	};
	//#endregion Handlers --------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------
	const menu = (
		<Menu>
			<Menu.Item onClick={handleEdit} disabled={!editAllowed.includes(widget.type)}>
				Edit Tool
			</Menu.Item>
			<Menu.Item onClick={handleRemove} danger>
				Remove Tool
			</Menu.Item>
		</Menu>
	);

	return (
		<Draggable draggableId={widget.widgetId} index={index}>
			{(provided) => (
				<div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
					<Dropdown overlay={menu} trigger={["click"]}>
						<div className="mb-2 px-2 ant-radius border border-gray-300 bg-white">
							<Row gutter={4} className="py-2" justify="center" align="middle">
								<Col className="gutter-row" span={8}>
									<p className="text-xs break-words m-0 p-0">{widget.name}</p>
								</Col>
								<Col className="gutter-row text-center" span={14}>
									{getMiniTool(widget.type)}
								</Col>
								<Col className="gutter-row text-center" span={2}>
									<EllipsisOutlined />
								</Col>
							</Row>
						</div>
					</Dropdown>
				</div>
			)}
		</Draggable>
	);
	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default EditWidgetRow;
