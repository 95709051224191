import React from "react";

export const TextInputDisabled = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<>
			<input
				className={"h-full rounded shadow text-center text-lg bg-white mr-1 text-gray-400 w-full"}
				type="text"
				placeholder="abc"
				value={value === "edit" ? "text" : value}
				readOnly
			/>
		</>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default TextInputDisabled;
