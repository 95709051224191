import { WidgetTypes } from "../constants/widgetTypes";
import { WidgetSuffix, WidgetSuffixEnum } from "../constants/widgetSuffix";

const _generateTableCellValueAdditions = (cellValue, suffix, widgetType, isFooter) => {
	// if the value is empty, put a dash in the cell
	if (cellValue === undefined || cellValue === null || (isFooter && cellValue === "")) {
		return "-";
	}

	// if the value needs a unit, add it
	const unit = suffix === WidgetSuffixEnum.PCT ? "%" : "";
	return unit;
};

const _generateTableCellStyle = (cellValue, suffix, isFooter, positiveColor, negativeColor) => {
	let style = "";

	// add font-medium style to all footer columns
	style += isFooter ? "font-medium " : "";

	// if cell value is empty, color it gray
	if (!cellValue || (isFooter && cellValue === "")) {
		return "text-gray-400";

		// else, color the cell based on it's column type
	} else {
		switch (suffix) {
			case WidgetSuffixEnum.PCT:
			case WidgetSuffixEnum.AVG:
				style += isFooter ? "" : "font-medium ";
				break;
			case WidgetSuffixEnum.POS:
			case WidgetSuffixEnum.MAX:
				style += `text-${positiveColor}-500 `;
				break;
			case WidgetSuffixEnum.NEG:
			case WidgetSuffixEnum.NEG_COUNTER:
			case WidgetSuffixEnum.MIN:
				style += `text-${negativeColor}-500 `;
				break;
			default:
				break;
		}
	}
	return style;
};

const _formatCell = (cell, suffix, widgetType, isFooter, positiveColor, negativeColor) => {
	// pull the actual text value from the cell
	let cellValue = isFooter ? cell : cell.value;
	// try parse it as a float and round it
	const parsedFloatValue = parseFloat(cellValue);
	cellValue = isNaN(parsedFloatValue) ? cellValue : Math.round(parsedFloatValue * 100) / 100;
	// return JSX wrapped in styling - this cannot ever return undefined or the app will bomb
	return (
		<span className={_generateTableCellStyle(cellValue, suffix, isFooter, positiveColor, negativeColor)}>
			{cellValue}
			{_generateTableCellValueAdditions(cellValue, suffix, widgetType, isFooter)}
		</span>
	);
};

/**
 * Returns an array of visible column suffixes for the total column in the table.
 * @param {*} chart
 * @param {*} allWidgets
 */
const _getVisibleTotalColumns = (chart, allWidgets) => {
	// checks to see what total stuff should be included in the table
	let hasVal = {
		numPos: 0,
		numNeg: 0,
		numNeut: 0,
		numPct: 0,
	};

	chart.widgets.forEach((widgetId) => {
		let widget = allWidgets[widgetId];
		switch (widget.type) {
			case WidgetTypes.POSNEG:
				hasVal.numPos++;
				hasVal.numNeg++;
				hasVal.numPct++;
				break;
			case WidgetTypes.POSNEGNEUTRAL:
				hasVal.numPos++;
				hasVal.numNeg++;
				hasVal.numPct++;
				hasVal.numNeut++;
				break;
			case WidgetTypes.COUNTER:
				hasVal.numPos++;
				break;
			case WidgetTypes.NEGCOUNTER:
				hasVal.numNeg++;
				break;
			case WidgetTypes.POSNEUTRAL:
				hasVal.numPos++;
				hasVal.numNeut++;
				hasVal.numPct++;
				break;
			default:
				break;
		}
	});

	let totalSuffixes = [];
	let hasMultipleOfOne = false;
	Object.keys(hasVal).forEach((val) => {
		if (hasVal[val] > 1) hasMultipleOfOne = true;
	});

	if (hasVal.numPct > 0 && hasMultipleOfOne) totalSuffixes.push("PCT");
	if (hasVal.numPos > 0 && hasMultipleOfOne) totalSuffixes.push("POS");
	if (hasVal.numNeg > 0 && hasMultipleOfOne) totalSuffixes.push("NEG");
	if (hasVal.numNeut > 0 && hasMultipleOfOne) totalSuffixes.push("NEUT");

	return totalSuffixes;
};

/**
 * Generate a list of column & subcolumn objects based on a chart
 */
export const generateTableStructure = (chart, allWidgets, positiveColor, negativeColor) => {
	const totalRow = [...chart.chartData].find((dataRow) => dataRow.Player_Id === "Total"); // this finds the total row and sets it
	const tableColumns = [];

	// Push a player name column
	tableColumns.push(_createColumn("Player", "Name", "Name", "Name", totalRow, positiveColor, negativeColor));

	let totalSuffixes = _getVisibleTotalColumns(chart, allWidgets);

	if (totalSuffixes.length > 0) {
		// Push a total column
		tableColumns.push(
			_createColumnWithSubcolumns(
				"Total",
				WidgetTypes.POSNEG,
				true,
				totalSuffixes,
				totalRow,
				positiveColor,
				negativeColor
			)
		);
	}

	// Push all other columns based on widgets in chart
	if (chart && chart.widgets) {
		chart.widgets.forEach((widgetId) => {
			const widget = allWidgets[widgetId];
			const { suffixList, useFirstColDefaultSort } = _createSuffixListFromWidget(widget);
			if (suffixList.length)
				tableColumns.push(
					_createColumnWithSubcolumns(
						widget.name,
						widget.type,
						useFirstColDefaultSort,
						suffixList,
						totalRow,
						positiveColor,
						negativeColor
					)
				);
		});
	}
	return tableColumns;
};

/**
 * Convert a column name to a human readable string
 */
export const convertColumnNameToHumanReadable = (columnName) => {
	try {
		const { widgetName, suffix, knownSuffix } = splitColumnName(columnName);
		const readableSuffix = knownSuffix ? WidgetSuffix[suffix] : suffix;
		const readableColumnName = `${widgetName} ${readableSuffix}`;
		return readableColumnName;
	} catch {
		return columnName;
	}
};

/**
 * Split column name into widget name and type suffix
 */
export const splitColumnName = (columnName) => {
	try {
		const tokens = columnName.split("_");
		let suffix = tokens.pop();
		const secondToLastSuffix = tokens.length > 1 ? tokens.pop() : undefined;
		const widgetName = tokens.join("");
		let knownSuffix = WidgetSuffix[suffix] ? true : false;
		// react-table has an annoying habit of appending numbers to the columnId, this checks and removes that number
		if (secondToLastSuffix === WidgetSuffixEnum.HEADER) {
			suffix = WidgetSuffixEnum.HEADER;
			knownSuffix = true;
		}

		return {
			widgetName,
			suffix,
			knownSuffix,
		};
	} catch {
		return {
			widgetName: columnName,
			suffix: "",
			knownSuffix: false,
		};
	}
};

/**
 * Create a single column object for the table to interpret
 */
const _createColumn = (key, header, widgetType, suffix, totalRow, positiveColor, negativeColor) => {
	const accessor = suffix ? `${key}_${suffix}` : key;
	const columnObj = {
		Header: header,
		accessor: accessor,
		sortType: "basic",
		sortInverted: true,
		displayName: `${key} ${header}`,
		widgetType: widgetType,
		minWidth: widgetType === "Name" ? 80 : 40,
		maxWidth: widgetType === "Name" ? 200 : 150,
		Cell: (cell) => _formatCell(cell, suffix, widgetType, false, positiveColor, negativeColor),
		filter: widgetType === "Name" ? "noEqual" : undefined,
		Footer:
			totalRow && totalRow[accessor]
				? _formatCell(totalRow[accessor], suffix, widgetType, true, positiveColor, negativeColor)
				: "", // the footer CANNOT render undefined
	};
	return columnObj;
};

/**
 * Create table column groups with subcolumns underneath (based on data suffixes)
 *
 * Ex. a column with name COLNAME might have three subcolumns with suffixes COLNAME_A, COLNAME_B, COLNAME_C
 */
const _createColumnWithSubcolumns = (
	colHeader,
	widgetType,
	useFirstColDefaultSort,
	suffixList,
	totalRow,
	positiveColor,
	negativeColor
) => {
	const subcolumns = [];
	suffixList.forEach((suffix) => {
		// attempt to replace the suffix with a more human readable symbol
		const subColHeader = WidgetSuffix[suffix] ? WidgetSuffix[suffix] : suffix;
		subcolumns.push(
			_createColumn(colHeader, subColHeader, widgetType, suffix, totalRow, positiveColor, negativeColor)
		);
	});
	const columnObj = {
		Header: colHeader,
		accessor: `${colHeader}_${WidgetSuffixEnum.HEADER}`,
		columns: subcolumns,
		widgetType: widgetType,
		useFirstColDefaultSort: useFirstColDefaultSort,
		Footer: "",
	};
	return columnObj;
};

/**
 * Map a widget to a list of suffixes that the data aggregation engine sends back
 *
 * NOTE: the first item pushed to the list will be the defualt "sort-by" column
 */
const _createSuffixListFromWidget = (widget) => {
	const suffixList = [];
	let useFirstColDefaultSort = true; // this is the subcolumn that will default sorts
	switch (widget.type) {
		case WidgetTypes.POSNEG:
			suffixList.push("PCT", "POS", "NEG"); // will sort by PCT becuse it's first
			break;
		case WidgetTypes.POSNEGNEUTRAL:
			suffixList.push("PCT", "POS", "NEG", "NEUT");
			break;
		case WidgetTypes.COUNTER:
			suffixList.push("COUNTER");
			break;
		case WidgetTypes.NUMBERINPUT:
			suffixList.push("AVG", "MAX", "MIN");
			break;
		case WidgetTypes.LISTSELECT:
			suffixList.push(...widget.potentialValues); // does not have default sort
			useFirstColDefaultSort = false;
			break;
		case WidgetTypes.PITCHLOCATION: // hide pitch location from the chart
			useFirstColDefaultSort = false;
			break;
		case WidgetTypes.STOPWATCH:
			suffixList.push("AVG", "MAX", "MIN");
			break;
		case WidgetTypes.NEGCOUNTER:
			suffixList.push("NEG_COUNTER");
			break;
		case WidgetTypes.POSNEUTRAL:
			suffixList.push("PCT", "POS", "NEUT");
			break;
		default:
			break;
	}
	return { suffixList, useFirstColDefaultSort };
};
