import { auth } from "../firebase/firebase";

export async function signin(email, password) {
	return await auth().signInWithEmailAndPassword(email, password);
}

export async function logout() {
	localStorage.setItem("FBIdToken", "");
	await auth().signOut();
}
