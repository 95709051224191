import React from "react";

import { useAppState, useActions } from "../../../api";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { heatMapAndSprayChartWidgetIds } from "../../../engines/dataEngine";
import { Divider } from "antd";

const FilterHeapMaps = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	// heat map filters ------------------------------------------------------------------
	const hideHeatMap = (id) => {
		const newVisible = state.charts.chartView.visibleHeatMaps.filter((heatMapId) => heatMapId !== id);
		actions.charts.updateChartStateValue({ property: "chartView.visibleHeatMaps", newVal: newVisible });

		const newHidden = [...state.charts.chartView.hiddenHeatMaps, id];
		actions.charts.updateHiddenHeatMaps(newHidden);
	};

	const unHideHeatMap = (id) => {
		const newVisible = [...state.charts.chartView.visibleHeatMaps, id];
		actions.charts.updateChartStateValue({ property: "chartView.visibleHeatMaps", newVal: newVisible });

		const newHidden = state.charts.chartView.hiddenHeatMaps.filter((heatMapId) => heatMapId !== id);
		actions.charts.updateHiddenHeatMaps(newHidden);
	};

	const hideAllHeatMaps = () => {
		actions.charts.updateChartStateValue({ property: "chartView.visibleHeatMaps", newVal: [] });
		actions.charts.updateHiddenHeatMaps(heatMapAndSprayChartWidgetIds(state).heatMap);
	};

	const showAllHeatMaps = () => {
		actions.charts.updateChartStateValue({
			property: "chartView.visibleHeatMaps",
			newVal: heatMapAndSprayChartWidgetIds(state).heatMap,
		});
		actions.charts.updateHiddenHeatMaps([]);
	};

	// player filters ------------------------------------------------------------------
	const hideHeatMapPlayer = (id) => {
		const newVisible = state.charts.chartView.visibleHeatMapPlayers.filter((playerId) => playerId !== id);
		actions.charts.updateVisibleHeatMapPlayers(newVisible);

		const newHidden = [...state.charts.chartView.hiddenHeatMapPlayers, id];
		actions.charts.updateHiddenHeatMapPlayers(newHidden);
	};

	const unHideHeatMapPlayer = (id) => {
		const newVisible = [...state.charts.chartView.visibleHeatMapPlayers, id];
		actions.charts.updateVisibleHeatMapPlayers(newVisible);

		const newHidden = state.charts.chartView.hiddenHeatMapPlayers.filter((playerId) => playerId !== id);
		actions.charts.updateHiddenHeatMapPlayers(newHidden);
	};

	const hideAllPlayers = () => {
		actions.charts.updateVisibleHeatMapPlayers([]);
		let playerIds = [];
		currentChart.players.forEach((playerId) => {
			playerIds = [...playerIds, playerId];
		});
		actions.charts.updateHiddenHeatMapPlayers(playerIds);
	};

	const showAllPlayers = () => {
		let playerIds = [];
		currentChart.players.forEach((playerId) => {
			playerIds = [...playerIds, playerId];
		});
		actions.charts.updateVisibleHeatMapPlayers(playerIds);
		actions.charts.updateHiddenHeatMapPlayers([]);
	};

	// heat map lists ------------------------------------------------------------------
	const visibleHeatMaps = state.charts.chartView.visibleHeatMaps.map((heatMapId) => {
		return (
			<div className="flex w-full" key={"visible " + heatMapId}>
				<p className="mt-auto mb-auto">{state.widgets.all[heatMapId].name}</p>
				<EyeOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => hideHeatMap(heatMapId)}
				/>
			</div>
		);
	});

	const hiddenHeatMaps = state.charts.chartView.hiddenHeatMaps.map((heatMapId) => {
		return (
			<div className="flex w-full" key={"hidden " + heatMapId}>
				<p className="mt-auto mb-auto">{state.widgets.all[heatMapId].name}</p>
				<EyeInvisibleOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => unHideHeatMap(heatMapId)}
				/>
			</div>
		);
	});

	//player lists ------------------------------------------------------------------
	const visibleHeatMapPlayers = state.charts.chartView.visibleHeatMapPlayers.map((playerId) => {
		return (
			<div className="flex w-full" key={"visible heat " + playerId}>
				<p className="mt-auto mb-auto">{actions.players.getPlayerById(playerId).name}</p>
				<EyeOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => hideHeatMapPlayer(playerId)}
				/>
			</div>
		);
	});

	const hiddenHeatMapPlayers = state.charts.chartView.hiddenHeatMapPlayers.map((playerId) => {
		return (
			<div className="flex w-full" key={"hidden heat " + playerId}>
				<p className="mt-auto mb-auto">{actions.players.getPlayerById(playerId).name}</p>
				<EyeInvisibleOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => unHideHeatMapPlayer(playerId)}
				/>
			</div>
		);
	});

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat bg-white shadow p-2 w-80">
			{state.charts.chartView.visibleHeatMaps.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Visible heatmaps</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={hideAllHeatMaps}>
							Hide all
						</p>
					</div>
					{visibleHeatMaps}
				</div>
			) : (
				""
			)}
			{state.charts.chartView.hiddenHeatMaps.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Hidden heatmaps</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={showAllHeatMaps}>
							Show all
						</p>
					</div>
					{hiddenHeatMaps}
				</div>
			) : (
				""
			)}

			<Divider />

			{state.charts.chartView.visibleHeatMapPlayers.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Visible players</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={hideAllPlayers}>
							Hide all
						</p>
					</div>
					{visibleHeatMapPlayers}
				</div>
			) : (
				""
			)}
			{state.charts.chartView.hiddenHeatMapPlayers.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Hidden players</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={showAllPlayers}>
							Show all
						</p>
					</div>
					{hiddenHeatMapPlayers}
				</div>
			) : (
				""
			)}
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default FilterHeapMaps;
