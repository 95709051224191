import React from "react";

import { useActions } from "../../../api";
import { Switch } from "antd";

const HeatMapProperties = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const actions = useActions();

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------
	const onChange = (val) => {
		actions.charts.setHeatMapFraction(val);
	};

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat bg-white shadow p-2">
			<div className="flex mb-0 ml-2 mr-2 mt-2">
				<p className="mr-10">Fraction</p>
				<Switch onChange={onChange} />
			</div>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default HeatMapProperties;
