import React from "react";
import LightWordmark from "../../assets/wordmark-light.png";
import { Link, useHistory } from "react-router-dom";
import { useActions, useAppState } from "../../api";
import { Button, Col, Dropdown, Menu, message, Modal, Row } from "antd";
import {
	UserOutlined,
	LogoutOutlined,
	LeftOutlined,
	PlusOutlined,
	DeleteOutlined,
	CheckOutlined,
	EllipsisOutlined,
	ExclamationCircleOutlined,
	EditOutlined,
	BarChartOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import { logout } from "../../helpers/auth";
const { confirm } = Modal;

/**
 * TODO this mobile navbar file is way too big, it can definitely be broken up eventually
 */
const MobileHeader = () => {
	const history = useHistory();
	const state = useAppState();
	const actions = useActions();

	/**
	 * Handler for the profile menu
	 */
	function handleProfileMenuClick(e) {
		switch (e.key) {
			case "logout":
				logout();
				history.push("/");
				break;
			default:
				break;
		}
	}

	/**
	 * Handler for navbar buttons
	 */
	const handleGoBack = async () => {
		if (state.global.context === "/new") {
			confirm({
				title: "Your chart has not been saved!",
				icon: <ExclamationCircleOutlined />,
				okText: "Leave without saving",
				okType: "danger",
				async onOk() {
					history.push("/dashboard");
					await actions.charts.deleteChart(state.charts.currentChartId);
					actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: false });
				},
				onCancel() {},
			});
		} else {
			history.push("/dashboard");
			actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: false });
		}
	};
	const handleNewChart = async () => {
		await actions.charts.createNew();
		history.push("/new");
	};
	const handleTrackData = async () => {
		actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: false });
		history.push("/take");
	};
	const handleViewData = async () => {
		history.push("/view");
	};
	const handleEditChart = async () => {
		await actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: true });
		history.push("/edit");
	};
	const handleSaveChart = async () => {
		if (state.charts.all[state.charts.currentChartId].title === "") {
			message.error("You must give your chart a title");
		} else {
			await actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: false });
			history.push("/take");
		}
	};
	const handleDeleteChart = () => {
		const currentChart = state.charts.all[state.charts.currentChartId];
		confirm({
			title: "Are you sure you want to delete '" + currentChart.title + "'?",
			icon: <ExclamationCircleOutlined />,
			content: "This action cannot be reversed",
			okText: "Delete",
			okType: "danger",
			onOk() {
				history.push("/");
				actions.charts.deleteChart(currentChart.chartId);
			},
			onCancel() {},
		});
	};
	const handleTitleChange = async (e) => {
		await actions.charts.updateTitle(e.target.value);
	};

	/**
	 * Make the JSX for the inner-content on a navbar item
	 */
	const makeHeaderInner = (title, Icon) => {
		return (
			<div className="h-8 flex items-center">
				<Icon style={{ fontSize: 18 }} />
				<span className="ml-2 text-md font-normal">{title}</span>
			</div>
		);
	};

	/**
	 * Make the JSX for a navbar link (in-app redirects only)
	 */
	const makeHeaderLink = (title, headerIcon, link) => {
		const linkClassName = `${state.global.context === link ? "header-menu-item-select" : "header-menu-item"}`;
		return (
			<Link to={link} className={linkClassName}>
				{makeHeaderInner(title, headerIcon)}
			</Link>
		);
	};

	/**
	 * This component is copied in MobileDashboardChart :(
	 */
	const chartOptionsMenu = (includeView, includeTrack) => (
		<Menu onClick={handleProfileMenuClick}>
			<Menu.Item
				className={includeView ? "inline-block" : "hidden"}
				icon={<BarChartOutlined />}
				onClick={handleViewData}>
				View Data
			</Menu.Item>
			<Menu.Divider className={includeView ? "block" : "hidden"} />
			<Menu.Item
				className={includeTrack ? "inline-block" : "hidden"}
				icon={<PlusCircleOutlined />}
				onClick={handleTrackData}>
				Track Stats
			</Menu.Item>
			<Menu.Divider className={includeTrack ? "block" : "hidden"} />
			<Menu.Item icon={<EditOutlined />} onClick={handleEditChart}>
				Edit Chart
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item danger icon={<DeleteOutlined />} onClick={handleDeleteChart}>
				Delete Chart
			</Menu.Item>
		</Menu>
	);

	const profileMenu = (
		<Menu theme="dark" onClick={handleProfileMenuClick}>
			<Menu.Item className="bg-darkGray" key="profile">
				{makeHeaderLink("View Profile", UserOutlined, "/profile")}
			</Menu.Item>
			<Menu.Item className="bg-darkGray" key="logout">
				{makeHeaderInner("Logout", LogoutOutlined)}
			</Menu.Item>
		</Menu>
	);

	const ChartOptionsDropdownButton = (includeView, includeTrack) => {
		return (
			<Dropdown overlay={chartOptionsMenu(includeView, includeTrack)} placement="bottomRight" trigger={["click"]}>
				{getControlButton("OPTIONS")}
			</Dropdown>
		);
	};

	const ProfileDropdownButton = () => {
		return (
			<Dropdown overlay={profileMenu} placement="bottomLeft" trigger={["click"]}>
				<Button size="medium" ghost shape="circle" icon={<UserOutlined style={{ color: "#f9f9f9" }} />} />
			</Dropdown>
		);
	};

	const BackButton = () => {
		return (
			<button className="h-8 w-1/2 flex items-center justify-start" onClick={handleGoBack}>
				<LeftOutlined style={{ fontSize: 25, color: "#f9f9f9" }} />
			</button>
		);
	};

	const getControlButton = (type, onClick) => {
		let ControlButton = <></>;
		switch (type) {
			case "CREATE":
				ControlButton = (
					<Button
						type="primary"
						size="medium"
						shape="circle"
						icon={<PlusOutlined style={{ paddingTop: 5 }} />}
						onClick={onClick}
					/>
				);
				break;
			case "DELETE":
				ControlButton = (
					<Button
						type="danger"
						ghost
						size="medium"
						shape="circle"
						icon={<DeleteOutlined />}
						onClick={onClick}
					/>
				);
				break;
			case "SAVE":
				ControlButton = (
					<Button
						type="primary"
						size="medium"
						shape="circle"
						icon={<CheckOutlined style={{ paddingTop: 5 }} />}
						onClick={onClick}
					/>
				);
				break;
			case "OPTIONS":
				ControlButton = (
					<Button
						type="secondary"
						ghost
						size="medium"
						shape="circle"
						icon={<EllipsisOutlined style={{ fontSize: 18, paddingTop: 4 }} />}
						onClick={onClick}
					/>
				);
				break;
			default:
				ControlButton = <></>;
				break;
		}
		return ControlButton;
	};

	const ControlButtons = () => {
		let ControlButtons = <></>;
		switch (state.global.context) {
			case "/dashboard":
				ControlButtons = getControlButton("CREATE", handleNewChart);
				break;
			case "/take":
				ControlButtons = ChartOptionsDropdownButton(true, false);
				break;
			case "/view":
				ControlButtons = ChartOptionsDropdownButton(false, true);
				break;
			case "/new":
			case "/edit":
				ControlButtons = (
					<>
						{getControlButton("DELETE", handleDeleteChart)}
						{getControlButton("SAVE", handleSaveChart)}
					</>
				);
				break;
			default:
				break;
		}
		return ControlButtons;
	};

	const CenterComponent = () => {
		const titleClassName = "m-0 w-36 text-center truncate text-offWhite font-medium";
		let CenterComponent = (
			<img
				className="w-24"
				src={LightWordmark}
				alt="tapp"
				style={{ minWidth: 50 }}
				onClick={() => window.location.reload()}
			/>
		);
		if (state.charts.currentChartId) {
			switch (state.global.context) {
				case "/profile":
					CenterComponent = <p className={titleClassName}>My Profile</p>;
					break;
				case "/take":
				case "/view":
					CenterComponent = (
						<p className={titleClassName}>{state.charts.all[state.charts.currentChartId].title}</p>
					);
					break;
				case "/new":
				case "/edit":
					CenterComponent = (
						<input
							className={`bg-darkGray border border-gray-400 text-gray-400 rounded focus:border-coolPurple focus:text-white focus:outline-none w-full p-1 ${
								state.charts.all[state.charts.currentChartId].title === ""
									? "animate-pulse"
									: "animate-none"
							}`}
							placeholder={"Enter chart title"}
							onChange={handleTitleChange}
							value={state.charts.all[state.charts.currentChartId].title}
						/>
					);
					break;
				default:
					break;
			}
		}
		return CenterComponent;
	};

	return (
		<div className="mobile-header w-full bg-darkGray font-montserrat shadow">
			<div className="w-full my-auto mx-auto" style={{ height: "35px" }}>
				<Row justify="space-between" wrap={false} align="bottom" gutter={[10, 10]}>
					<Col>
						<div
							className="flex flex-shrink-0 items-center justify-start"
							style={
								state.global.context === "/new" || state.global.context === "/edit"
									? {}
									: { width: "70px" }
							}>
							{state.global.context === "/dashboard" ? <ProfileDropdownButton /> : <BackButton />}
						</div>
					</Col>
					<Col flex="auto">
						<div className="w-full flex items-center justify-center gap-2">{CenterComponent()}</div>
					</Col>
					<Col>
						<div className="flex flex-shrink-0 items-center justify-end gap-2" style={{ width: "70px" }}>
							<ControlButtons />
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default MobileHeader;
