export default class Stat {
	constructor(
		statId,
		chartId,
		creatorId,
		playerId,
		playerName,
		dateCreated,
		isGrouped = false,
		includesContext = false,
		statData,
		gameContext = "",
		isFlagged = false
	) {
		this.statId = statId;
		this.chartId = chartId;
		this.creatorId = creatorId;
		this.playerId = playerId;
		this.playerName = playerName;
		this.dateCreated = dateCreated;
		this.isGrouped = isGrouped;
		this.includesContext = includesContext;
		this.statData = statData;
		this.gameContext = gameContext;
		this.isFlagged = isFlagged;
	}

	setIsFlagged(isFlagged) {
		this.isFlagged = isFlagged;
	}
}
