import { splitTagsByType } from "../../engines/tagEngine";
import { handleError } from "../../util/errorUtil";
import { message } from "antd";
import { tagType } from "../../constants/tagType";
import Tag from "../../models/tag";

export const createTag = async ({ state, actions, effects }, tag) => {
	try {
		const receipt = await effects.tags.api.createTag(tag);
		const newTag = new Tag({ tagType: receipt.updatedTagDocJson.tagType, ...receipt.newTag });

		// TODO probably move this into a helper action, I'm guessing it will be re-used on update and delete
		switch (tag.tagType) {
			case tagType.team_tag:
				state.tags.teamTags = [...state.tags.teamTags, newTag];
				break;
			case tagType.player_tag:
				state.tags.playerTags = [...state.tags.playerTags, newTag];
				break;
			case tagType.chart_tag:
				state.tags.chartTags = [...state.tags.chartTags, newTag];
				break;
			case tagType.widget_tag:
				state.tags.widgetTags = [...state.tags.widgetTags, newTag];
				break;
			case tagType.stat_tag:
				state.tags.statTags = [...state.tags.statTags, newTag];
				break;
		}
		state.tags.allTags = [...state.tags.allTags, newTag];
		return receipt.newTag.tagId;
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const getAllTags = async ({ state, actions, effects }) => {
	try {
		const tagDocumentList = await effects.tags.api.getAllTags();
		const tagLists = splitTagsByType(tagDocumentList);
		state.tags.allTags = tagLists.allTags;
		state.tags.teamTags = tagLists.teamTags;
		state.tags.playerTags = tagLists.playerTags;
		state.tags.chartTags = tagLists.chartTags;
		state.tags.widgetTags = tagLists.widgetTags;
		state.tags.statTags = tagLists.statTags;
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

// todo finish
export const updateTag = async ({ state, actions, effects }, tag) => {
	try {
		await effects.tags.api.update(tag);
		// TODO update state
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

// todo finish
export const deleteTag = async ({ state, actions, effects }, tag) => {
	try {
		await effects.tags.api.delete(tag);
		// TODO update state
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const getTagLabel = ({ state }, tagId) => {
	try {
		return state.tags.allTags.filter((tag) => tag.tagId === tagId)[0].label;
	} catch (error) {
		return undefined;
	}
};
