import Widget from "../../models/widget";
import { WidgetTypes } from "../widgetTypes";

const pointWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Point",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const pointWidget = new Widget(pointWidgetInput);

const killsWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Kills",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const killsWidget = new Widget(killsWidgetInput);

const errorWidgetInput = {
	type: WidgetTypes.NEGCOUNTER,
	name: "Error",
	position: null,
	attributes: [],
	potentialValues: [-1],
};
export const errorWidget = new Widget(errorWidgetInput);

const attemptWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Attempt",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const attemptWidget = new Widget(attemptWidgetInput);

const volleyballAssistWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Assist",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const volleyballAssistWidget = new Widget(volleyballAssistWidgetInput);

const serviceAceWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Service Ace",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const serviceAceWidget = new Widget(serviceAceWidgetInput);

const serviceErrorWidgetInput = {
	type: WidgetTypes.NEGCOUNTER,
	name: "Service Error",
	position: null,
	attributes: [],
	potentialValues: [-1],
};
export const serviceErrorWidget = new Widget(serviceErrorWidgetInput);

const digWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Dig",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const digWidget = new Widget(digWidgetInput);

const recieveErrorWidgetInput = {
	type: WidgetTypes.NEGCOUNTER,
	name: "Receive Error",
	position: null,
	attributes: [],
	potentialValues: [-1],
};
export const recieveErrorWidget = new Widget(recieveErrorWidgetInput);

const blockWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Block",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const blockWidget = new Widget(blockWidgetInput);

const blockAssistWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Block Assist",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const blockAssistWidget = new Widget(blockAssistWidgetInput);

const blockSoloWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Block Solo",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const blockSoloWidget = new Widget(blockSoloWidgetInput);

const blockErrorWidgetInput = {
	type: WidgetTypes.NEGCOUNTER,
	name: "Block Error",
	position: null,
	attributes: [],
	potentialValues: [-1],
};
export const blockErrorWidget = new Widget(blockErrorWidgetInput);

const zoneServeWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Zone Serve",
	position: null,
	attributes: [],
	potentialValues: ["1", "2", "3", "4", "5", "6"],
};
export const zoneServeWidget = new Widget(zoneServeWidgetInput);

const zoneHitWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Zone Hit",
	position: null,
	attributes: [],
	potentialValues: ["1", "2", "3", "4", "5", "6"],
};
export const zoneHitWidget = new Widget(zoneHitWidgetInput);

const positionSetWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Position Set",
	position: null,
	attributes: [],
	potentialValues: ["OH", "MH", "RS", "BIC", "D"],
};
export const positionSetWidget = new Widget(positionSetWidgetInput);

const setTempoWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Set Tempo",
	position: null,
	attributes: [],
	potentialValues: ["Quick", "High"],
};
export const setTempoWidget = new Widget(setTempoWidgetInput);

const passGradeWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Pass Grade",
	position: null,
	attributes: [],
	potentialValues: ["1", "2", "3"],
};
export const passGradeWidget = new Widget(passGradeWidgetInput);
