import React from "react";
import { useActions, useAppState } from "../../../api";
import { Menu, Dropdown, message, Row, Col } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { Draggable } from "react-beautiful-dnd";

const RecordingPlayer = ({ playerId, name, index }) => {
	const actions = useActions();
	const state = useAppState();
	const currentChart = state.charts.all[state.charts.currentChartId];

	const handleEdit = () => {
		message.info("Editing players is not available at the moment!");
	};

	const handleRemove = async () => {
		await actions.charts.removePlayerFromChart({ playerId: playerId });
		actions.charts.updatePlayers(currentChart.players);
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={handleEdit}>Edit Player</Menu.Item>
			<Menu.Item onClick={handleRemove} danger>
				Remove Player
			</Menu.Item>
		</Menu>
	);

	//#region JSX -------------------------------------------------------------------------------------------------------------
	return (
		<Draggable draggableId={playerId} index={index} isDragDisabled={state.players.recordingPlayerSearch.length > 0}>
			{(provided) => (
				<div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} id={playerId}>
					<Dropdown
						overlay={menu}
						trigger={["click"]}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						ref={provided.innerRef}>
						<div className="mb-2 px-2 ant-radius border border-gray-300 bg-white ">
							<Row gutter={4} className="py-2 bg-white" justify="center" align="middle">
								<Col className="gutter-row" span={18}>
									<h1 className="text-xs m-0" id={playerId}>
										{name}
									</h1>
								</Col>
								<Col className="gutter-row text-center" span={4}>
									<EllipsisOutlined />
								</Col>
							</Row>
						</div>
					</Dropdown>
				</div>
			)}
		</Draggable>
	);
	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default RecordingPlayer;
