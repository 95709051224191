import React from "react";
import { Input, InputNumber, Form, Select, Checkbox, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";

const EditableCell = ({
	editing,
	dataIndex,
	title,
	dataType,
	required,
	listOptions,
	listOptionsJSX,
	tags,
	link,
	children,
	...restProps
}) => {
	let inputNode = <></>;
	switch (dataType) {
		case "link":
			inputNode = editing ? (
				<Input placeholder={title} />
			) : (
				<Link className="text-coolPurple hover:underline" to={link}>
					{children[1]}
				</Link>
			);
			break;
		case "string":
			inputNode = editing ? (
				<Input placeholder={title} />
			) : (
				<Tooltip title="Click 'edit' to change this value" mouseEnterDelay={0.25}>
					<span>{children[1]}</span>
				</Tooltip>
			);
			break;
		case "number":
			inputNode = editing ? (
				<InputNumber placeholder={title} />
			) : (
				<Tooltip title="Click 'edit' to change this value" mouseEnterDelay={0.25}>
					<span>{children[1]}</span>
				</Tooltip>
			);
			break;
		case "select":
			inputNode = editing ? (
				<Select placeholder={`Select ${title}`}>{listOptionsJSX}</Select>
			) : (
				<Tooltip title="Click 'edit' to change this value" mouseEnterDelay={0.25}>
					<Tag>{children[1]}</Tag>
				</Tooltip>
			);
			break;
		case "multiselect":
			inputNode = editing ? (
				<Select
					mode="multiple"
					placeholder={`Select ${title}`}
					optionLabelProp="label"
					optionFilterProp="label">
					{listOptionsJSX}
				</Select>
			) : (
				<Tooltip title="Click 'edit' to add/remove options" mouseEnterDelay={0.25}>
					{children[1].map((tagId) =>
						listOptions.some((tag) => tag.key === tagId) ? (
							<Tag key={tagId}>{listOptions.filter((tag) => tag.key === tagId)[0].label}</Tag>
						) : (
							""
						)
					)}
				</Tooltip>
			);
			break;
		case "tag":
			inputNode = editing ? (
				<Select mode="tags" placeholder={`Select ${title}`} optionLabelProp="label" optionFilterProp="label">
					{listOptionsJSX}
				</Select>
			) : (
				<Tooltip title="Click 'edit' to add/remove tags" mouseEnterDelay={0.25}>
					{children[1].map((tagId) =>
						tags.some((tag) => tag.tagId === tagId) ? (
							<Tag key={tagId}>{tags.filter((tag) => tag.tagId === tagId)[0].label}</Tag>
						) : (
							""
						)
					)}
				</Tooltip>
			);
			break;
		case "checkbox":
			inputNode = editing ? (
				<Checkbox />
			) : (
				<Tooltip title="Click 'edit' to change this value" mouseEnterDelay={0.25}>
					<Checkbox disabled={true} checked={children[1]} />
				</Tooltip>
			);
			break;
		default:
			inputNode = children;
			break;
	}
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					style={{
						margin: 0,
					}}
					rules={[
						{
							required,
							message: `${title} is required`,
						},
					]}
					valuePropName={dataType === "checkbox" ? "checked" : "value"}>
					{inputNode}
				</Form.Item>
			) : (
				inputNode
			)}
		</td>
	);
};

export default EditableCell;
