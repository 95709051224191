export const WidgetTypes = {
	COUNTER: "COUNTER",
	POSNEG: "POS_NEG",
	POSNEGNEUTRAL: "POS_NEG_NEUT",
	STOPWATCH: "STOPWATCH",
	PITCHLOCATION: "PITCH_LOCATION",
	NUMBERINPUT: "NUMERIC",
	LISTSELECT: "LIST_SELECT",
	NEGCOUNTER: "NEG_COUNTER",
	POSNEUTRAL: "POS_NEUT",
	PLAYERLISTSELECT: "PLAYER_LIST_SELECT",
	TEXTINPUT: "NOTE",
	FIELDLOCATION: "FIELD_LOCATION",
	XYCOORDINATE: "XY_COORDINATE",
};
