import React from "react";
import { Row, Col } from "antd";
import { RightOutlined } from "@ant-design/icons";
import FadeInSection from "./fadeInSection";

const CenterGalleryCard = ({ title, content, image, useCaseClicked }) => {
	const onClick = () => {
		useCaseClicked(title);
	};
	return (
		<FadeInSection>
			<div
				className="w-full h-full rounded-sm bg-white transform hover:scale-105 hover:shadow-lg transition duration-300 ease-in-out"
				onClick={onClick}>
				<Row align="middle" justify="space-between" className="w-full h-full mt-2 mb-2">
					<Col span={24} className="px-4 pt-12 md:pt-0 my-8">
						<Row className="w-full h-fit">
							<div className="w-full text-5xl md:text-4xl lg:text-5xl text-center"> {title}</div>
						</Row>
						<Row className="w-full mt-5">
							<div className="w-full h-fit mb-5 sm:h-12 md:h-24 lg:h-16 px-2 sm:px-16 md:px-5 lg:px-16 xl:px-24 text-lg lg:text-xl text-center text-gray-700">
								{content}
							</div>
						</Row>
						{image ? (
							<Row className="w-full mt-5">
								<div
									className="w-full text-center mt-2 text-coolPurple cursor-pointer hover:underline"
									onClick={onClick}>
									Try it out
									<RightOutlined className="ml-2" />
								</div>
							</Row>
						) : (
							""
						)}
					</Col>
					{image ? (
						<Col span={24}>
							<img className="w-full h-auto" src={image} alt="Mockup" />
						</Col>
					) : (
						""
					)}
				</Row>
			</div>
		</FadeInSection>
	);
};

export default CenterGalleryCard;
