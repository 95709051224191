import React from "react";
import { useHistory } from "react-router-dom";
import ChooseTool from "../tools/ChooseTool";
import { wikiURL } from "../../constants/wiki";
import { useAppState, useActions } from "../../api";
import { Modal, Button } from "antd";

const OnboardModal = () => {
	//#region Hooks ---------------------------------------------------------------------------------------------------------
	const history = useHistory();
	const state = useAppState();
	const actions = useActions();

	//#endregion Hooks ---------------------------------------------------------------------------------------------------------

	//#region HANDLERS ---------------------------------------------------------------------------------------------------------
	const handleCancel = async () => {
		await actions.organization.setGettingStartedModal(false);
	};

	const handleHelpPage = async () => {
		window.location.href = wikiURL.home;
		await actions.organization.setGettingStartedModal(false);
	};

	const handleOk = async () => {
		await actions.organization.setGettingStartedModal(false);
	};

	const handleTeamNav = async () => {
		history.push("/teams");
		await actions.organization.setGettingStartedModal(false);
	};
	//#endregion HANDLERS ---------------------------------------------------------------------------------------------------------

	//#region JSX ---------------------------------------------------------------------------------------------------------
	const ChartOptionCard = ({ content }) => {
		return <div className="border border-gray-300 text-center px-4 py-2 inline-block">{content}</div>;
	};

	return (
		<Modal
			title="Getting Started"
			visible={state.organization.organization.organizationConfig.showGettingStartedModal}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key="1" onClick={handleHelpPage}>
					Go to Help Page
				</Button>,
			]}>
			<p className="text-lg my-0">👋 Welcome to tapp!</p>
			<p>Here are the basics:</p>
			<div className="flex my-8">
				<p className="w-5/6">🛠 You can use tagging tools:</p>
				<ChooseTool display />
			</div>
			<div className="my-8">
				<p className="mb-1">📋 To build charts & track data for anything that matters to you</p>
				<ChartOptionCard content="Hitting" />
				<ChartOptionCard content="Pitching" />
				<ChartOptionCard content="Fielding" />
				<ChartOptionCard content="Prospect Camps" />
				<ChartOptionCard content="Tryouts" />
				<ChartOptionCard content="Catching" />
				<ChartOptionCard content="Baserunning" />
				<ChartOptionCard content="Weightlifting" />
				<ChartOptionCard content="And more" />
				<p className="mt-2 text-gray-600">
					To better understand your players, scout your opponents, manage your tryouts and more!
				</p>
			</div>
			<div className="my-8">
				<p className="mb-1">👊 To get started, add a team and some players to track!</p>
				<Button onClick={handleTeamNav} type="primary">
					Add a Team
				</Button>
			</div>
			<div className="mt-8">
				<p className="mb-0 text-gray-500">
					👉 Questions? Email us at team@tappsports.com or visit the help page
				</p>
			</div>
		</Modal>
	);
	//#endregion JSX ---------------------------------------------------------------------------------------------------------
};

export default OnboardModal;
