export const state = {
	loading: false,
	requestChartIds: ["nwsl99dPq9DxZo0lVdnY"],
	requestPlayerIds: ["*"],
	// requestPlayerIds: ["ZTXfggT0rSNmTArogP0j"],
	requestWidgetIds: ["*"],
	requestTimeDelta: "year",
	requestNumDeltas: 2,
	requestTotalTimeFrame: true,
	requestTotalPlayerRow: true,
	dashboards: {},
	activeDashboardString: undefined,
	directFlat: {}, // TODO replace this with long term solution,
};
