import axios from "axios";
import { Widget } from "../../constants/api";
import WidgetModel from "../../models/widget";

export const api = {
	async getAll() {
		try {
			const getAllRes = await axios.post(Widget.read.all);
			const validatedWidgets = getAllRes.data.widgets.map((widget) => new WidgetModel(widget));
			return validatedWidgets;
		} catch (error) {
			//review: add error state
		}
	},
	async create(payload) {
		try {
			const createRes = await axios.post(Widget.create, payload.toJson());
			return createRes.data.receipt.created.widgetId;
		} catch (error) {
			throw new Error(error);
		}
	},
	async delete(payload) {
		try {
			const deleteRes = await axios.post(Widget.delete, payload);
			return deleteRes.data.newWidgetId;
		} catch (error) {
			throw new Error(error);
		}
	},
	async update(payload) {
		try {
			const updateRes = await axios.post(Widget.update, payload);
			return updateRes.data.receipt.updated;
		} catch (error) {
			throw new Error(error);
		}
	},
	async compare(payload) {
		try {
			const compareRes = await axios.post(Widget.compare, payload);
			return compareRes.data.response;
		} catch (error) {
			throw new Error(error);
		}
	},
};
