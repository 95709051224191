import React from "react";
import LightWordmark from "../../assets/wordmark-light.png";
import { Link, useHistory } from "react-router-dom";
import { useAppState } from "../../api";
import { Col, Dropdown, Menu, message, Row } from "antd";
import { typeformId } from "../../constants/typeform";
import {
	UserOutlined,
	TeamOutlined,
	QuestionCircleOutlined,
	AppstoreOutlined,
	BulbOutlined,
	MenuOutlined,
	LogoutOutlined,
	ReloadOutlined,
	LoadingOutlined,
} from "@ant-design/icons";
import { PopupButton } from "@typeform/embed-react";
import { logout } from "../../helpers/auth";
import { wikiURL } from "../../constants/wiki";

const AppHeader = () => {
	const history = useHistory();
	const state = useAppState();

	/**
	 * Handler for the profile menu
	 */
	function handleProfileMenuClick(e) {
		switch (e.key) {
			case "logout":
				logout();
				history.push("/");
				break;
			default:
				break;
		}
	}

	/**
	 * Make the JSX for the inner-content on a navbar item
	 */
	const makeHeaderInner = (title, Icon) => {
		return (
			<div className="h-8 flex items-center">
				<Icon style={{ fontSize: 18 }} />
				<span className="ml-2 text-md font-normal">{title}</span>
			</div>
		);
	};

	/**
	 * Make the JSX for a navbar link (in-app redirects only)
	 */
	const makeHeaderLink = (title, headerIcon, link) => {
		const linkClassName = `${state.global.context === link ? "header-menu-item-select" : "header-menu-item"}`;
		return (
			<Link to={link} className={linkClassName}>
				{makeHeaderInner(title, headerIcon)}
			</Link>
		);
	};

	/**
	 * To add a new element to the nav-bar, add it to this list.
	 * Elements must be valid JSX. If you are adding a simple link within the app,
	 * use the makeHeaderLink helper function to ensure consistency.
	 */
	const navElements = [
		// dashboard link
		makeHeaderLink("Dashboard", AppstoreOutlined, "/dashboard"),
		// teams link
		makeHeaderLink("Teams", TeamOutlined, "/teams"),
		// feedback typeform popup button
		<PopupButton
			className="header-menu-item"
			id={typeformId.feedback}
			hidden={{
				name: state.user.name,
				email: state.user.email,
				"organization-id": state.organization.organizationId,
			}}
			onSubmit={() => message.success("Thank you, we received your feedback")}>
			{makeHeaderInner("Submit Feedback", BulbOutlined)}
		</PopupButton>,
		// notion wiki link
		<a className="header-menu-item" href={wikiURL.home} target="_blank" rel="noreferrer noopener">
			{makeHeaderInner("Help", QuestionCircleOutlined)}
		</a>,
	];

	const profileMenu = (
		<Menu theme="dark" onClick={handleProfileMenuClick}>
			<Menu.Item className="bg-darkGray" key="profile">
				{makeHeaderLink("View Profile", UserOutlined, "/profile")}
			</Menu.Item>
			<Menu.Item className="bg-darkGray" key="logout">
				{makeHeaderInner("Logout", LogoutOutlined)}
			</Menu.Item>
		</Menu>
	);

	const navMenu = (
		<Menu theme="dark">
			{navElements.map((navElement, index) => (
				<Menu.Item className="bg-darkGray" key={index} style={{ color: "black" }}>
					{navElement}
				</Menu.Item>
			))}
		</Menu>
	);

	return (
		<div className="w-full bg-darkGray font-montserrat pt-5">
			<div className="mx-auto px-7 py-3 flex flex-row flex-nowrap gap-4">
				<div className="flex items-center">
					<Link to="/dashboard">
						<img className="w-40 float-left" src={LightWordmark} alt="Logo" style={{ minWidth: 130 }} />
					</Link>
				</div>
				<div className="w-full ml-3 my-auto">
					<Row className="bg-darkGray" gutter={[10, 10]}>
						<Col className="h-full hidden md:inline">
							<Row gutter={[25, 10]}>
								{navElements.map((navElement, index) => (
									<Col className="h-full py-2" key={index}>
										{navElement}
									</Col>
								))}
							</Row>
						</Col>
						<Col flex="auto" />
						<Col className="h-full inline md:hidden py-2">
							<Dropdown overlay={navMenu} placement="bottomRight" trigger={["click"]}>
								<button className="h-8 w-8 header-menu-item flex items-center justify-center">
									<MenuOutlined style={{ fontSize: 18 }} />
								</button>
							</Dropdown>
						</Col>
						<Col className="h-full py-2">
							<button
								className="text-xl h-8 w-8 header-menu-item flex items-center justify-center"
								onClick={() => window.location.reload()}>
								{state.global.loading ? <LoadingOutlined /> : <ReloadOutlined />}
							</button>
						</Col>
						<Col className="h-full py-2">
							<Dropdown overlay={profileMenu} placement="bottomRight" trigger={["click"]}>
								<button className="rounded-full h-8 w-8 header-menu-item border flex items-center justify-center">
									<UserOutlined style={{ fontSize: 18 }} />
								</button>
							</Dropdown>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

export default AppHeader;
