import React from "react";

import { DownOutlined } from "@ant-design/icons";

export const ListSelectDisabled = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<div className="flex w-full h-full pb-2 rounded shadow bg-white cursor-pointer text-center pt-2">
			<h1 className={`w-10/12 text-gray-400 truncate text-center pl-1 mt-auto mb-auto`}>
				{value === "edit" ? "Options" : value}
			</h1>
			<div className="w-4/12">
				<DownOutlined />
			</div>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default ListSelectDisabled;
