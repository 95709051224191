import React from "react";
import Nebraska from "../../assets/landing-page/Nebraska.jpg";
import PiusX from "../../assets/landing-page/PiusX.jpg";
import DavisElkins from "../../assets/landing-page/DavisElkins.png";
import FadeInSection from "./fadeInSection";
import { Row, Col, Carousel } from "antd";

const Testimonials = () => {
	const TestimonialCard = (imgSrc, name, title, emphasisColor, quoteEmphasisList) => {
		return (
			<div className="w-full h-full p-5 bg-white">
				<Row className="h-full max-w-screen-lg mx-auto my-5" align="middle">
					<Col span={0} md={4}>
						<img className="h-full max-h-32 float-left" src={imgSrc} alt="Logo" />
					</Col>
					<Col span={20}>
						<p className="text-gray-600 font-semibold sm:font-bold text-lg md:text-xl mb-3">
							"
							{quoteEmphasisList.map((quoteFragment) => {
								return quoteFragment.emphasize ? (
									<span className={emphasisColor}>{quoteFragment.text} </span>
								) : (
									<span>{quoteFragment.text} </span>
								);
							})}
							"
						</p>
						<div className="text-gray-400">{name}</div>
						<div className="text-gray-400">{title}</div>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<div className="site-layout-content">
			<FadeInSection>
				<Row className="w-full flex items-center justify-center px-5 py-2">
					<span className="w-full text-4xl sm:text-5xl font-bold text-center">Trusted by coaches</span>
				</Row>
				<Row>
					<div className="w-full my-5 shadow-lg">
						<Carousel autoplay dots autoplaySpeed={6000} className="bg-white">
							{TestimonialCard(
								Nebraska,
								"Danny Marcuzzo",
								"Husker Baseball Assistant Coach",
								"text-red-600",
								[
									{ emphasize: false, text: "Compared to last year, this app has" },
									{ emphasize: true, text: "saved me hundreds of hours" },
									{
										emphasize: false,
										text: "staring at excel sheets and our players have become",
									},
									{ emphasize: true, text: "much more competitive" },
									{ emphasize: false, text: "during practice" },
								]
							)}

							{TestimonialCard(
								DavisElkins,
								"Tim Miller",
								"Davis & Elkins College Head Baseball Coach",
								"text-red-700",
								[
									{ emphasize: false, text: "Our players have" },
									{ emphasize: true, text: "gravitated toward the information" },
									{
										emphasize: false,
										text: "that we have been able to track, and we are finding new ways to use the program on an almost daily basis.",
									},
								]
							)}

							{TestimonialCard(
								PiusX,
								"Fletcher Zornes",
								"Lincoln Pius X Assistant Baseball Coach",
								"text-green-700",
								[
									{
										emphasize: false,
										text: "With tapp, we can collaborate with the players, and",
									},
									{ emphasize: true, text: "they are more bought into the process" },
									{ emphasize: false, text: " when they see the numbers." },
								]
							)}
						</Carousel>
					</div>
				</Row>
			</FadeInSection>
		</div>
	);
};

export default Testimonials;
