import React from "react";
import DeleteChartMenuItem from "./DeleteChartMenuItem";
import { EllipsisOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button } from "antd";

export default function ChartOptions({ chartId }) {
	const menu = (
		<Menu>
			<DeleteChartMenuItem chartId={chartId} />
		</Menu>
	);
	return (
		<Dropdown overlay={menu} trigger={["click"]}>
			<Button icon={<EllipsisOutlined />} />
		</Dropdown>
	);
}
