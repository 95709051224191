import Organization from "../../models/organization";

export const getOrganization = async ({ state, effects }) => {
	const getOrganizationRes = await effects.organization.api.getOrganization();
	const orgModel = new Organization(getOrganizationRes.data.organization);
	state.organization.organization = orgModel;
};

export const createOrganization = async ({ effects }, organizationFormInput) => {
	try {
		const createOrganizationRes = await effects.organization.api.createOrganization(organizationFormInput);
		return createOrganizationRes;
	} catch (error) {
		return new Error(error);
	}
};

export const updateOrganization = async ({ effects }, updateInput) => {
	try {
		/**
		 * Old - this is no longer up to date with the API
		 */
		const updateOrganizationPayload = {
			title: updateInput.title,
			adminName: updateInput.adminName,
			adminEmail: updateInput.adminEmail,
			timezone: updateInput.timezone,
			organizationConfig: updateInput.organizationConfig,
		};
		const updateOrganizationRes = await effects.organization.api.updateOrganization(updateOrganizationPayload);
		return updateOrganizationRes;
	} catch (error) {
		return new Error(error);
	}
};

export const setChartModal = async ({ state, actions }, value) => {
	state.organization.organization.organizationConfig.showChartModal = value;
	await actions.organization.updateOrganization({
		organizationConfig: state.organization.organization.organizationConfig,
	});
};

export const setGettingStartedModal = async ({ state, actions }, value) => {
	state.organization.organization.organizationConfig.showGettingStartedModal = value;
	await actions.organization.updateOrganization({
		organizationConfig: state.organization.organization.organizationConfig,
	});
};

export const setCreateChartBanner = async ({ state, actions }, value) => {
	state.organization.organization.organizationConfig.showCreateChartBanner = value;
	await actions.organization.updateOrganization({
		organizationConfig: state.organization.organization.organizationConfig,
	});
};

export const setCreateTeamBanner = async ({ state, actions }, value) => {
	state.organization.organization.organizationConfig.showCreateTeamBanner = value;
	await actions.organization.updateOrganization({
		organizationConfig: state.organization.organization.organizationConfig,
	});
};
