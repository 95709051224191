import React from "react";
import { useAppState, useActions } from "../../api";
import { Switch, Modal, Row, Col, Button } from "antd";
import PosNegMini from "../tools/posNeg/Mini";
import CounterMini from "../tools/counter/Mini";

export default function GroupedChartToggle() {
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];

	const handleGroupedChange = async (newVal) => {
		await actions.charts.updateIsGrouped(newVal);
		actions.stats.clearActiveGroup();
	};

	const handleGroupedExplanation = () => {
		actions.charts.setShowGroupedExpModal(true);
	};

	const handleGroupedExpModalOK = () => {
		actions.charts.setShowGroupedExpModal(false);
	};

	const GroupedExplanationModal = () => {
		return (
			<Modal
				title="Grouped Chart"
				visible={state.charts.showGroupedExpModal}
				onOk={handleGroupedExpModalOK}
				onCancel={handleGroupedExpModalOK}>
				<p>📋 Here are the basics:</p>
				<Row gutter={16}>
					<Col span={12}>
						<h1 className="mb-0">Grouped</h1>
						<p className="text-gray-600">Groups all your tagging tools</p>
						<p>✅ Stats go together</p>
						<p>✅ More input when recording</p>
						<p>✅ Analysis across tools (ex: strike % of pitch type)</p>
						<p>✅ Stats save on 'Save Group'</p>
						<div className="px-2 border border-gray-300 rounded mb-1">
							<div className="mt-1">
								<PosNegMini value={1} />
							</div>
							<div className="my-2">
								<PosNegMini value={-1} />
							</div>
							<div className="mb-1">
								<CounterMini value={1} />
							</div>
						</div>
						<Button className="w-full mb-2">Save Group</Button>
						<p>👉 Good for pitching and hitting charts</p>
					</Col>
					<Col span={12}>
						<h1 className="mb-0">Ungrouped</h1>
						<p className="text-gray-600">Keeps everything separate</p>
						<p>✅ Stats are independent</p>
						<p>✅ Quicker recording</p>
						<p>✅ Simpler analysis</p>
						<p>✅ Stats save immediately</p>
						<div className="px-1 border border-gray-300 rounded mb-1">
							<div className="my-2">
								<PosNegMini />
							</div>
						</div>
						<div className="px-1 border border-gray-300 rounded mb-1">
							<div className="my-2">
								<PosNegMini />
							</div>
						</div>
						<div className="px-1 border border-gray-300 rounded mb-2">
							<div className="my-2">
								<CounterMini />
							</div>
						</div>
						<p>👉 Good for fielding and player eval charts</p>
					</Col>
				</Row>
			</Modal>
		);
	};
	return (
		<>
			<GroupedExplanationModal />
			<Switch className="inline" onChange={handleGroupedChange} checked={currentChart.isGrouped} />
			<p className="inline px-2">Grouped Chart</p>
			<p className="inline text-xs text-coolPurple underline cursor-pointer" onClick={handleGroupedExplanation}>
				What does grouped mean?
			</p>
		</>
	);
}
