import React from "react";

import { useAppState, useActions } from "../../../api";
import { WidgetTypes } from "../../../constants/widgetTypes";
import { Row, Col } from "antd";

export const PosNegRecord = ({ widgetId, label, isGrouped }) => {
	const state = useAppState();
	const actions = useActions();

	const activeGroup = state.stats.activeGroup;

	//#region HANDLERS ------------------------------------------------------------------------------------------------------

	const onPosNegClick = (val) => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.POSNEG,
		};

		if (isGrouped) {
			let newData = data;
			if (activeGroup[label] ? activeGroup[label].value !== val : true) {
				newData = { ...data, value: val };
			}

			actions.stats.setActiveGroup(newData);
		} else {
			let newData = { ...data, value: val };

			actions.stats.create(newData);
		}
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	let colorChangerPos = `${
		activeGroup && activeGroup[label] && activeGroup[label].value === 1
			? `bg-${state.user.positiveColor}-500 text-white`
			: ` text-${state.user.positiveColor}-500 bg-white `
	} `;

	let colorChangerNeg = `${
		activeGroup && activeGroup[label] && activeGroup[label].value === -1
			? `bg-${state.user.negativeColor}-500 text-white`
			: ` text-${state.user.negativeColor}-500 bg-white `
	} `;

	return (
		<div className="h-full text-center w-full">
			<Row gutter={[8, 0]} className="h-full">
				<Col span={12}>
					<button
						id={widgetId}
						className={"w-full h-full mr-1 rounded shadow text-3xl font-bold " + colorChangerPos}
						onClick={() => onPosNegClick(1)}>
						+
					</button>
				</Col>
				<Col span={12}>
					<button
						id={widgetId}
						className={"w-full h-full rounded shadow text-3xl font-bold " + colorChangerNeg}
						onClick={() => onPosNegClick(-1)}>
						-
					</button>
				</Col>
			</Row>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PosNegRecord;
