/**
 * To update terms, it may be easiest to edit the word documents and run them through a converter.
 * Converter: https://word2cleanhtml.com/
 */

/**
 *
 * --- PRIVACY POLICY ------------------------------------------------------------------------
 *
 */

import Document from "../models/document";

// The date updated MUST be updated alongside any wording changes in the JSX
const privacyPolicyDateLastUpdated = "July 14, 2022";
const privacyPolicyStyles = {
	p_p1: {
		margin: "0.0px 0.0px 0.0px 0.0px",
		textAlign: "center",
		lineHeight: "22.8px",
		font: "21.3px 'Times New Roman'",
		color: "#000000",
		WebkitTextStroke: "#000000",
	},
	p_p2: {
		margin: "0.0px 0.0px 0.0px 0.0px",
		lineHeight: "17.1px",
		font: "16.0px 'Times New Roman'",
		color: "#000000",
		WebkitTextStroke: "#000000",
	},
	p_p3: {
		margin: "0.0px 0.0px 0.0px 48.0px",
		textAlign: "justify",
		textIndent: "-24.0px",
		lineHeight: "17.1px",
		font: "16.0px 'Times New Roman'",
		color: "#000000",
		WebkitTextStroke: "#000000",
	},
	p_p4: {
		margin: "0.0px 0.0px 0.0px 0.0px",
		textAlign: "justify",
		lineHeight: "17.1px",
		font: "16.0px 'Times New Roman'",
		color: "#000000",
		WebkitTextStroke: "#000000",
	},
	p_p5: {
		margin: "0.0px 0.0px 0.0px 48.0px",
		textIndent: "-24.0px",
		lineHeight: "17.1px",
		font: "16.0px 'Times New Roman'",
		color: "#000000",
		WebkitTextStroke: "#000000",
	},
	p_p6: {
		margin: "0.0px 0.0px 0.0px 48.0px",
		textAlign: "justify",
		lineHeight: "17.1px",
		font: "16.0px 'Times New Roman'",
		color: "#000000",
		WebkitTextStroke: "#000000",
	},
	span_s1: { fontKerning: "none" },
	span_s2: { font: "9.3px 'Times New Roman'", fontKerning: "none" },
	span_s3: { textDecoration: "underline", fontKerning: "none" },
	span_s4: {
		font: "16.0px 'Times New Roman'",
		textDecoration: "underline",
		fontKerning: "none",
		color: "#0b4cb4",
		WebkitTextStroke: "0px #0b4cb4",
	},
};
const privacyPolicyJSX = (
	<>
		<p className="p1">
			<span className="s1">
				<b>VIZN STATS LLC PRIVACY POLICY</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p2">
			<span className="s1">
				Effective as of <b>{privacyPolicyDateLastUpdated}.</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1">
				<b> </b>
			</span>
		</p>
		<p className="p3">
			<span className="s1">
				<b>1.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>Introduction</b>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">This privacy policy (the “</span>
			<span className="s3">Privacy Policy</span>
			<span className="s1">
				”) applies to the web-based<b> </b>application (the “
			</span>
			<span className="s3">Web App</span>
			<span className="s1">”), owned and operated by VIZN Stats LLC (“</span>
			<span className="s3">VIZN Stats</span>
			<span className="s1">,” “</span>
			<span className="s3">we</span>
			<span className="s1">,” “</span>
			<span className="s3">us</span>
			<span className="s1">,” or “</span>
			<span className="s3">our</span>
			<span className="s1">
				”). This Privacy Policy describes the types of information we may collect or receive when you use the
				Web App and our practices for collecting, using, maintaining, protecting, and disclosing that
				information. The Privacy Policy applies to information we collect in the Web App, and in email, text,
				and other electronic communications sent through or in connection with the Web App.
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				This Privacy Policy does not address the privacy practices of any third parties that we do not own,
				control, or are affiliated with. Capitalized terms not defined in this Privacy Policy will have the
				meaning set forth in our Terms of Use.  
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				Please read this Privacy Policy carefully to understand our policies and practices regarding your
				information and how we will treat it. If you do not agree with our policies and practices, do not use
				the Web App. By using the Web App, you are agreeing to the terms of this Privacy Policy, the
				accompanying Terms of Use, and the applicable Services Agreement.
				<b>
					<span className="Apple-converted-space"> </span>
				</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p5">
			<span className="s1">
				<b>2.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>Children Under the Age of 13</b>
			</span>
		</p>
		<p className="p4">
			<span className="s1">
				<b> </b>
			</span>
		</p>
		<p className="p4">
			<span className="s1">
				The Web App is not directed to children under the age of 13. We adhere to the Children’s Online Privacy
				Protection Act (COPPA) and will not knowingly collect personal information from any child under the age
				of 13. We ask that minors (under the age of 13) not use the Web App. If we learn that we have collected
				or received personal information from or about a child under 13, we will delete that information from
				our records. If you believe we might have any personal information from or about a child under 13,
				please contact us at <b>team@tappsports.com</b>.
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p5">
			<span className="s1">
				<b>3.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>Information We Collect or Receive</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				In the course of operating the Web App, we will collect or receive the following types of information.
				You authorize us to collect or receive such information.
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">(a)</span>
			<span className="s2">   </span>
			<span className="s3">Information You Provide to Us</span>
			<span className="s1">
				.  We collect or receive information when you: (i) subscribe to the Web App; (ii) create a Team account;
				(iii) input Team Data,<b> </b>including<b> </b>Player Information<b> </b>and performance statistics;
				(iv) create Team Charts; (v) contact us; (vi) provide feedback; or (vii) otherwise communicate
				information to us.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p6">
			<span className="s1">
				Some of the information we may receive is information by which you may be personally identified, such as
				your first and last name, postal address, email address, or telephone number (“
			</span>
			<span className="s3">Personal Information</span>
			<span className="s1">
				”). If you create a Team account or contact us, you will be required to provide us with Personal
				Information. When you otherwise use the Web App and record Team Data, we will only receive or collect
				Personal Information if you provide us with such Personal Information voluntarily. We do not require,
				encourage, or prompt you to input Personal Information about yourself or anyone else when creating Team
				Charts, recording Team Data, or submitting Player Information. We do not generally control the manner in
				which Teams input, use, display, or share the Team Data stored on the Web App.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p6">
			<span className="s1"> </span>
		</p>
		<p className="p6">
			<span className="s1">
				Because the Web App provides a subscription service, a Team will be required to provide payment
				information when it creates an account. This payment information will be collected and processed by our
				third-party payment vendor pursuant to the terms and conditions of their privacy policy and terms of
				use. The only payment information that we may obtain access to in connection with the Team’s account is
				the last four digits of the credit or debit card, the cardholder’s name, and the brand of the credit or
				debit card.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">(b)</span>
			<span className="s2">  </span>
			<span className="s3">Automatic Information Collection</span>
			<span className="s1">
				.<b>  </b>Any automatic collection of information regarding you and your use of the Web App, your
				interactions with us, and your computer and mobile devices used to access the Web App is done by Google.
				Google’s Firebase platform hosts the Web App, and VIZN Stats does not control Google’s automatic
				information collection. To learn more about Google’s automatic information collection, visit Google’s
				privacy policy at{" "}
				<a href="https://policies.google.com/privacy">
					<span className="s4">https://policies.google.com/privacy</span>
				</a>
				. If you do not agree to the terms of Google’s privacy policy regarding the automatic collection of
				information, do not use the Web App.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">(c)</span>
			<span className="s2">   </span>
			<span className="s3">Third-Party Information Collection</span>
			<span className="s1">
				.  In addition to Google, certain third parties may use automatic information collection technologies to
				collect information about your or your device when you use the Web App. These third parties may include
				your device manufacturer and your mobile service provider. These third parties may use tracking
				technologies to collect information about you when you use the Web App. The information they collect may
				be associated with your Personal Information or they may collect information, including Personal
				Information, about your online activities over time and across different websites, apps, and other
				online services websites. They may use this information to provide you with interest-based (behavioral)
				advertising or other targeted content. We do not control these third parties’ tracking technologies or
				how they may be used. If you have any questions about an advertisement or other targeted content, you
				should contact the responsible provider directly.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p5">
			<span className="s1">
				<b>4.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>How We Use Your Information</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				We use the information that we collect or receive from you, including the Team Data and any Personal
				Information, to:<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(a)</span>
			<span className="s2">   </span>
			<span className="s1">
				Provide you with the Web App and its contents, and any other information, products, or services that you
				request from us.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(b)</span>
			<span className="s2">  </span>
			<span className="s1">Fulfill any other purpose for which you provide it.</span>
		</p>
		<p className="p3">
			<span className="s1">(c)</span>
			<span className="s2">   </span>
			<span className="s1">
				Give you notices about your account and subscription, including expiration notices and communications
				related to your usage of the Web App.
			</span>
		</p>
		<p className="p3">
			<span className="s1">(d)</span>
			<span className="s2">  </span>
			<span className="s1">
				Carry out our obligations and enforce our rights arising from any contracts entered into between you and
				us, including for billing and collection.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(e)</span>
			<span className="s2">   </span>
			<span className="s1">
				Notify you when Web App updates are available, and of changes to any products or services we offer or
				provide through it.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(f)</span>
			<span className="s2">   </span>
			<span className="s1">
				Improve the Web App and deliver a better and more personalized experience.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(g)</span>
			<span className="s2">  </span>
			<span className="s1">Solicit feedback from Teams regarding their use of the Web App.</span>
		</p>
		<p className="p3">
			<span className="s1">(h)</span>
			<span className="s2">  </span>
			<span className="s1">
				Identify usage patterns, search for correlations in performance, and execute other machine learning
				functions.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p6">
			<span className="s3">
				<b> </b>
			</span>
		</p>
		<p className="p5">
			<span className="s1">
				<b>5.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>
					Disclosure of Your Information<span className="Apple-converted-space"> </span>
				</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				We may disclose aggregated and anonymized Team Data without restriction.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				In addition, we may disclose Team Data, including any Personal Information:
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(a)</span>
			<span className="s2">   </span>
			<span className="s1">
				To third-party service providers that we engage to support our business, including, but not limited to,
				data analytics companies. These service providers will have access to Team Data only as necessary to
				perform their functions and to the extent permitted by law.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(b)</span>
			<span className="s2">  </span>
			<span className="s1">
				To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
				dissolution, or other sale or transfer of some or all of VIZN Stats’s assets, whether as a going concern
				or as part of bankruptcy, liquidation, or similar proceeding, in which Team Data held by VIZN Stats in
				our Web App users is among the assets transferred.
			</span>
		</p>
		<p className="p3">
			<span className="s1">(c)</span>
			<span className="s2">   </span>
			<span className="s1">
				To comply with any court order, law, or legal process, including to respond to any governmental or
				regulatory request.
			</span>
		</p>
		<p className="p3">
			<span className="s1">(d)</span>
			<span className="s2">  </span>
			<span className="s1">
				To enforce our rights arising from any contracts entered into between you and us, including the
				applicable Services Agreement, and for billing and collection.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p3">
			<span className="s1">(e)</span>
			<span className="s2">   </span>
			<span className="s1">
				If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of VIZN
				Stats, our customers or others, to prevent harm or loss, or in connection with an investigation or
				suspected or actual unlawful activity.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p5">
			<span className="s1">(f)</span>
			<span className="s2">   </span>
			<span className="s1">
				As may otherwise be disclosed at the time of collection.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p5">
			<span className="s1">(g)</span>
			<span className="s2">  </span>
			<span className="s1">With your consent.</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p5">
			<span className="s1">
				<b>6.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>Your Choices About Our Collection, Use, and Disclosure of Your Information</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				We strive to provide you with choices regarding the information you provide to us. This section
				describes mechanisms for you to control certain uses and disclosures of your information.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">(a)</span>
			<span className="s2">   </span>
			<span className="s3">Tracking Technologies</span>
			<span className="s1">
				.  You can set your browser to refuse all or some browser cookies, or to alert you when cookies are
				being sent.
			</span>
		</p>
		<p className="p6">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">(b)</span>
			<span className="s2">  </span>
			<span className="s3">Do Not Track Signals</span>
			<span className="s1">
				.  VIZN Stats will not track you over time and across third party websites to provide targeted
				advertising; therefore, we do not respond to Do Not Track signals. However, Google’s Firebase platform
				hosts the Web App, and VIZN Stats does not control Google’s response to Do Not Track signals.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p6">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">(c)</span>
			<span className="s2">   </span>
			<span className="s3">Location Information</span>
			<span className="s1">
				.  You can choose whether or not to allow the Web App to collect and use information about your device’s
				location through the device’s privacy settings.
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">(d)</span>
			<span className="s2">  </span>
			<span className="s3">Promotion by the Company</span>
			<span className="s1">
				.  You may manage your receipt of marketing and non-transactional communications by disabling the
				transmission of such communications in the Web App or by sending us an email stating your request to
				<b> team@tappsports.com</b>.<b> </b>We will use commercially reasonable efforts to process such requests
				in a timely manner. You cannot opt out of receiving transactional emails related to the Web App (
				<i>e.g</i>., requests for support or subscription notices).
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				We do not control third parties’ collection or use of your information to serve interest-based
				advertising. However, these third parties may provide you with ways to choose not to have your
				information collected or used in this way. You can opt out of receiving targeted ads from members of the
				Network Advertising Initiative (“NAI”) on the NAI’s{" "}
				<a href="https://optout.networkadvertising.org/?c=1">
					<span className="s4">website</span>
				</a>
				.
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">
				<b>7.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>Accessing and Modifying Your Personal Information</b>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				You can review and change the Personal Information solicited by VIZN Stats to create a Team account—that
				is, your name and email address—by contacting us at<b> team@tappsports.com</b>.
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				You acknowledge that if you, or any Authorized User, choose to record Team Data that contains Personal
				Information, you have granted us a license to download, copy, and use such Personal Information in
				accordance with our Terms of Use. Even if you delete such Personal Information from your Team Charts, we
				may retain copies of the Personal Information on our computers. You may request that we delete such Team
				Data containing Personal Information from our records, and in such case, we will use commercially
				reasonable efforts to delete such Team Data containing Personal Information from our databases.
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p5">
			<span className="s1">
				<b>8.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>
					Data Security<span className="Apple-converted-space"> </span>
				</b>
			</span>
		</p>
		<p className="p2">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				We have implemented measures designed to secure your information from accidental loss and from
				unauthorized access, use, disclosure, alteration, or destruction.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				The safety and security of your information also depends on you. Each Team is fully responsible for
				maintaining the confidentiality of its password and account information and is fully responsible for any
				and all activities that occur under the Team’s password or account. If a Team elects to provide access
				to its account to Authorized Users by sharing its password, it does so at its own risk. We will not be
				liable for any loss or damage arising from a Team’s failure to comply with this Section.
				<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				Unfortunately, the transmission of information via the internet is not completely secure. Although we do
				our best to protect your Personal Information and Team Data, we cannot guarantee the security of your
				information transmitted through our Web App. Any transmission of Personal Information is at your own
				risk. We are not responsible for circumvention of any privacy settings or security measures we provide.
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">
				<b>9.</b>
			</span>
			<span className="s2">     </span>
			<span className="s3">
				<b>Changes to Our Privacy Policy</b>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				We may update this Privacy Policy from time to time. If we make material changes to how we treat our
				users’ Personal Information, we will post the new privacy policy on this page with a notice that the
				Privacy Policy has been updated and notify you by email to the primary email address specified in the
				Team account.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				The date the Privacy Policy was last revised is identified at the top of the page. You are responsible
				for ensuring we have an up-to-date active and deliverable email address for you and for periodically
				visiting this Privacy Policy to check for any changes.<span className="Apple-converted-space"> </span>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p3">
			<span className="s1">
				<b>10.</b>
			</span>
			<span className="s2">  </span>
			<span className="s3">
				<b>
					Contact Information<span className="Apple-converted-space"> </span>
				</b>
			</span>
		</p>
		<p className="p4">
			<span className="s1"> </span>
		</p>
		<p className="p4">
			<span className="s1">
				If you have questions about this Privacy Policy, please email us at <b>team@tappsports.com</b> with
				“Privacy Policy” in the subject line.<span className="Apple-converted-space"> </span>
			</span>
		</p>
	</>
);

/**
 *
 * --- TERMS OF USE ------------------------------------------------------------------------
 *
 */

// The date updated MUST be updated alongside any wording changes in the JSX
const termsDateLastUpdated = "July 14, 2022";
const termsStyles = { p: { margin: "0.0px 0.0px 10.0px 0.0px" } };
const termsJSX = (
	<>
		<div>
			<p className="mb-4">
				<strong>VIZN STATS LLC TERMS OF USE</strong>
			</p>
			<p className="mb-2">
				Effective as of <strong>{termsDateLastUpdated}</strong>
			</p>
			<p className="mb-2">
				<strong>1. </strong>
				<strong>
					<u>Acceptance of these Terms of Use</u>
				</strong>
			</p>
			<p className="mb-2">
				These terms of use are entered into by and between you and VIZN Stats LLC (“<u>VIZN Stats</u>,” “
				<u>we</u>,” “<u>us</u>,” or “<u>our</u>”). The following terms and conditions, together with any
				documents they expressly incorporate by reference (collectively, these “ <u>Terms of Use</u>”), govern
				your access to and use of our web-based application, including any content, functionality, and services
				offered on or through the web-based application (collectively, the “ <u>Web App</u>”).
			</p>
			<p className="mb-2">
				Please read these Terms of Use carefully before you start to use the Web App.
				<strong>
					By using the Web App or by clicking to accept or agree to these Terms of Use when this option is
					made available to you, you accept and agree to be bound and abide by these Terms of Use and our
					Privacy Policy, found at&nbsp;
					<a href="https://vizn-stats.web.app/terms">https://vizn-stats.web.app/terms</a>
					&nbsp;and&nbsp;
					<a href="https://vizn-stats.web.app/privacy-policy">https://vizn-stats.web.app/privacy-policy</a>
					&nbsp;, respectively, incorporated herein by reference
				</strong>
				. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use
				the Web App.
			</p>
			<p className="mb-2">
				The Web App is offered and available to users who are 18 years of age or older (or the age of majority
				if higher in your place of residence) and reside in the United States or any of its territories or
				possessions. By using the Web App, you represent and warrant that you are of legal age to form a binding
				contract with VIZN Stats and meet the foregoing eligibility requirements. If you do not meet these
				requirements, you must not access or use the Web App.
			</p>
			<p className="mb-2">
				<strong>2. </strong>
				<strong>
					<u>Changes to these Terms of Use</u>
				</strong>
			</p>
			<p className="mb-2">
				We reserve the right, at our sole discretion, to change or modify portions of these Terms of Use at any
				time. All changes are effective immediately when we post them and apply to all access to and use of the
				Web App thereafter. Your continued use of the Web App following the posting of the revised Terms of Use
				constitutes your acceptance of such changes. You are expected to check this page from time to time so
				you are aware of any changes, as they are binding on you. If you do not wish to accept the new Terms of
				Use, you may discontinue your use of the Web App.
			</p>
			<p className="mb-2">
				<strong>3. </strong>
				<strong>
					<u>Description and Use of the Web App</u>
				</strong>
			</p>
			<p className="mb-2">
				<strong> </strong>
			</p>
			<p className="mb-2">
				The Web App provides sports teams (each, a “<u>Team</u>”) with a cloud-based service for tracking and
				analyzing players’ performance and progress using customizable charts and metrics. A Team must purchase
				an annual subscription to create an account and use the Web App. The Team may provide access to the
				account only to authorized coaches, managers, or other individuals associated with the Team (“
				<u>Authorized Users</u>
				”).
			</p>
			<p className="mb-2">
				Authorized Users track players’ performance by creating custom charts (“<u>Team Charts</u>”) using the
				Web App’s customizable tracking tools. Team Charts may track any metric or measure of performance
				desired by the Team. To record statistics using Team Charts, Authorized Users must input information
				about the players (“<u>Player Information</u>”), which may include the player’s first name, jersey
				number, field position, or any other appropriate identifier. VIZN Stats does not require, encourage, or
				prompt Authorized Users to input any personal information about the players.
			</p>
			<p className="mb-2">
				All content, data, text, images, video, or other materials that Authorized Users input, upload, record,
				or submit through the Web App, including, but not limited to, performance statistics and Player
				Information, is “<u>Team Data</u>.” Using the Team Charts and Team Data, the Web App creates aggregated
				tables and other readily comprehensible displays of the recorded statistics. Authorized Users may
				download, reproduce, distribute, and display these tables and visuals for any non-commercial purpose,
				including training, coaching, and recruiting purposes.
			</p>
			<p className="mb-2">
				<strong>4. </strong>
				<strong>
					<u>Accessing the Web App</u>
				</strong>
			</p>
			<p className="mb-2">
				We reserve the right to update and amend the Web App from time to time, in our sole discretion and
				without notice. We will not be liable if, for any reason, all or any part of the Web App is unavailable
				at any time or for any period. From time to time, we may restrict access to some parts or the entirety
				of the Web App.
			</p>
			<p className="mb-2">
				It is a condition of your use of the Web App that all the information you provide on the Web App is
				correct, current, and complete. You agree that all information you provide to create an account, as well
				as all information you provide while using the Web App, is governed by our Privacy Policy (
				<strong>
					<a href="https://vizn-stats.web.app/privacy-policy">https://vizn-stats.web.app/privacy-policy</a>
				</strong>
				), and you consent to all actions we take with respect to your information consistent with our Privacy
				Policy.
			</p>
			<p className="mb-2">
				<strong>5. </strong>
				<strong>
					<u>Account Security</u>
				</strong>
			</p>
			<p className="mb-2">
				A Team is required to create an account to use the Web App. In creating an account, the Team must create
				a password. The Team is fully responsible for maintaining the confidentiality of the password and
				account information and is fully responsible for any and all activities that occur under the Team’s
				password or account. If the Team elects to provide access to its account to Authorized Users by sharing
				its password, it does so at its own risk. VIZN Stats will not be liable for any loss or damage arising
				from a Team’s failure to comply with this Section.
			</p>
			<p className="mb-2">
				You agree to notify us immediately of any unauthorized access to or use of your account or password or
				any other breach of security. You also agree to ensure that you exit from your account at the end of
				each session. You should use particular caution when accessing your account from a public or shared
				computer so that others are not able to view or record your password or other personal information.
			</p>
			<p className="mb-2">
				<strong>6. </strong>
				<strong>
					<u>Intellectual Property Rights</u>
				</strong>
			</p>
			<p className="mb-2">
				The Web App and its entire contents, features, and functionality, including but not limited to all
				information, software, text, displays, and images, and the design, selection, and arrangement thereof
				(the “ <u>Company Content</u>”), are owned by VIZN Stats, its licensors, or other providers of such
				material and are protected by United States and international copyright and other intellectual property
				or proprietary rights laws.
			</p>
			<p className="mb-2">
				These Terms of Use permit a Team to use the Web App for its <strong> </strong>non-commercial use only.
				Though a Team’s use of the Web App may yield economic benefits, a Team may not sell any Company Content,
				Team Charts, or other information downloaded from or created by the Web App. A Team may not generate
				revenue directly from its use of the Web App.
			</p>
			<p className="mb-2">
				You may reproduce, distribute, display, download, store, or transmit any of the Team Data, Team Charts,
				aggregated tables, and other visuals of the Team Data on our Web App, in whole or in part. A Team’s
				rights to access and use any and all information in the Web App expire upon the expiration or
				termination of the Team’s account.
			</p>
			<p className="mb-2">
				No right, title, or interest in or to the Web App is transferred to you, and all rights not expressly
				granted are reserved by VIZN Stats. Any use of the Web App not expressly permitted by these Terms of Use
				is a breach of these Terms of Use and may violate intellectual property laws.
			</p>
			<p className="mb-2">
				If you wish to make any use of material on the Web App other than that set out in this section, please
				address your request to: <strong>team@tappsports.com</strong>.
			</p>
			<p className="mb-2">
				The name VIZN Stats, and all related names, logos, product and service names, designs and slogans are
				the property of VIZN Stats or its affiliates or licensors. You must not use such marks without the prior
				written permission of VIZN Stats.
			</p>
			<p className="mb-2">
				<strong>7. </strong>
				<strong>
					<u>Team Data</u>
				</strong>
			</p>
			<p className="mb-2">
				With respect to the Team Data you input, upload, record, or submit through the Web App, you represent
				and warrant that you own all right, title, and interest in and to, or otherwise have all necessary
				rights and consents to fully exploit, such Team Data, including, without limitation, as it concerns all
				rights of publicity or privacy related thereto. All Team Data must comply with the Content Standards set
				out in these Terms of Use.
			</p>
			<p className="mb-2">
				If any information capable of identifying an individual appears in your Team Data, you represent and
				warrant that you have obtained consent from such person(s) to input, record, or display their
				information on the Web App.
				<strong>
					You may not input, record, or display Team Data that includes the personal information of a child
					under the age of 13. If we become aware that your Team Data contains the personal information of a
					child under the age of 13, we will delete such information, and we may suspend or terminate your
					Team’s account.
				</strong>
			</p>
			<p className="mb-2">
				You understand and acknowledge that you are responsible for any Team Data you input or record, and you,
				not VIZN Stats, have full responsibility for such content, including its legality, reliability, accuracy
				and appropriateness. VIZN Stats is not responsible for Team Data. We do not warrant the legality,
				reliability, or accuracy of Team Data and will not be liable for any errors, omissions, or
				misrepresentations in any Team Data.
			</p>
			<p className="mb-2">
				All Team Data is and will be considered non-confidential and non-proprietary. By providing Team Data to
				the Web App and creating Team Charts, you hereby grant to VIZN Stats a non-exclusive, perpetual,
				irrevocable, royalty-free, worldwide license to reproduce, distribute, modify, and otherwise use and
				display the Team Data and Team Charts and perform all acts with respect to the Team Data and Team Charts
				as may be necessary or convenient for us in operating our business, including to provide the Web App to
				you and others, improve the Web App, and solicit feedback from you; <em>provided</em>, that any
				distribution, display, or disclosure of Team Data or Team Charts to the public shall not include any
				personally identifiable information for any individuals affiliated with the Team.
			</p>
			<p className="mb-2">
				As described in our Privacy Policy, we do not control the manner in which Teams input, use, or display
				Team Data or Team Charts stored on our Web App.
			</p>
			<p className="mb-2">
				<strong>8. </strong>
				<strong>
					<u>Content Standards</u>
				</strong>
			</p>
			<p className="mb-2">
				These content standards apply to any and all Team Data. Team Data must in its entirety comply with all
				applicable federal, state, local, and international laws and regulations. Without limiting the
				foregoing, Team Data must not:
			</p>
			<p className="mb-2">
				(a) Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent,
				hateful, inflammatory, or otherwise objectionable;
			</p>
			<p className="mb-2">
				(b) Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex,
				religion, nationality, disability, sexual orientation, or age;
			</p>
			<p className="mb-2">
				(c) Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other
				rights of any other person;
			</p>
			<p className="mb-2">
				(d) Violate the legal rights (including the rights of publicity and privacy) of others or contain any
				material that could give rise to any civil or criminal liability under applicable laws or regulations or
				that otherwise may be in conflict with these Terms of Use and our Privacy Policy (
				<strong>
					<a href="https://vizn-stats.web.app/privacy-policy">https://vizn-stats.web.app/privacy-policy</a>
				</strong>
				);
			</p>
			<p className="mb-2">(e) Be likely to deceive any person;</p>
			<p className="mb-2">(f) Promote any illegal activity, or advocate, promote, or assist any unlawful act;</p>
			<p className="mb-2">
				(g) Impersonate any person, or misrepresent your identity or affiliation with any person or
				organization; or
			</p>
			<p className="mb-2">
				(h) Give the impression that they emanate from or are endorsed by us or any other person or entity, if
				this is not the case.
			</p>
			<p className="mb-2">
				<strong>9. </strong>
				<strong>
					<u>Prohibited Uses</u>
				</strong>
			</p>
			<p className="mb-2">
				You may use the Web App only for lawful purposes and in accordance with these Terms of Use. You agree
				not to use the Web App:
			</p>
			<p className="mb-2">
				(a) In any way that violates any applicable federal, state, local, or international law or regulation
				(including, without limitation, any laws regarding the export of data or software to and from the United
				States or other countries);
			</p>
			<p className="mb-2">(b) In any way that poses or creates a privacy or security risk to any person;</p>
			<p className="mb-2">
				(c) To upload, download, use, or re-use any material that does not comply with the Content Standards set
				out in these Terms of Use;
			</p>
			<p className="mb-2">
				(d) To impersonate or attempt to impersonate VIZN Stats, a VIZN Stats employee, another user, or any
				other person or entity (including, without limitation, by using email addresses associated with any of
				the foregoing); or
			</p>
			<p className="mb-2">
				(e) In the sole judgment of VIZN Stats, is objectionable or which restricts or inhibits any other person
				from using or enjoying the Web App, or which may expose VIZN Stats or its users to any harm or liability
				of any type.
			</p>
			<p className="mb-2">Additionally, you agree not to:</p>
			<p className="mb-2">
				(a) Use the Web App in any manner that could disable, overburden, damage, or impair the Web App or
				interfere with any other party’s use of the Web App;
			</p>
			<p className="mb-2">
				(b) Use any robot, spider, or other automatic device, process, or means to access the Web App for any
				purpose, including monitoring or copying any of the material on the Web App;
			</p>
			<p className="mb-2">
				(c) Use any manual process to monitor or copy any of the material on the Web App, or for any other
				purpose not expressly authorized in these Terms of Use, without our prior written consent;
			</p>
			<p className="mb-2">
				(d) Use any device, software, or routine that interferes with the proper working of the Web App;
			</p>
			<p className="mb-2">
				(e) Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or
				technologically harmful;
			</p>
			<p className="mb-2">
				(f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any part of the Web App,
				the server on which the Web App is stored, or any server, computer, or database connected to the Web
				App;
			</p>
			<p className="mb-2">
				(g) Attack the Web App via a denial-of-service attack or a distributed denial-of-service attack; or
			</p>
			<p className="mb-2">(h) Otherwise attempt to interfere with the proper working of the Web App.</p>
			<p className="mb-2">
				<strong>10. </strong>
				<strong>
					<u>Monitoring and Enforcement; Termination </u>
				</strong>
			</p>
			<p className="mb-2">We have the right to:</p>
			<p className="mb-2">
				(a) Take any action with respect to any Team Data that we deem necessary or appropriate in our sole
				discretion, including if we believe that such Team Data violates these Terms of Use, including the
				Content Standards, infringes any intellectual property right or other right of any person or entity,
				threatens the personal safety of users of the Web App or the public, or could create liability for VIZN
				Stats;
			</p>
			<p className="mb-2">
				(b) Take appropriate legal action, including without limitation, referral to law enforcement, for any
				illegal or unauthorized use of the Web App;
			</p>
			<p className="mb-2">
				(c) Suspend or terminate the account of any Team that violates the Content Standards or engages in any
				of the Prohibited Uses set out in these Terms of Use; and
			</p>
			<p className="mb-2">
				(d) Suspend or terminate the account of any Team that inputs, records, or displays Team Data that
				contains the personal information of a child under the age of 13.
			</p>
			<p className="mb-2">
				<strong>11. </strong>
				<strong>
					<u>Reliance on the Web App’s Charts and Displays</u>
				</strong>
			</p>
			<p className="mb-2">
				<strong>
					<u> </u>
				</strong>
			</p>
			<p className="mb-2">
				The Team Charts, aggregated tables, and other readily comprehensible displays of Team Data generated by
				the Web App are made available solely for general information purposes. We do not warrant the accuracy,
				completeness, or usefulness of these charts and displays. Any reliance you place on such materials is
				strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed
				on such materials by the Team or any Authorized User, or by anyone who may be informed of any of their
				contents.
			</p>
			<p className="mb-2">
				<strong>12. </strong>
				<strong>
					<u>Changes to the Web App</u>
				</strong>
			</p>
			<p className="mb-2">
				We may update, enhance, or modify the content on the Web App, or introduce new services from time to
				time, but the content is not necessarily complete or up-to-date. Any of the material on the Web App may
				be out of date at any given time, and we are under no obligation to update such material. You agree that
				VIZN Stats shall not be liable to a Team, Authorized User, or any third party for any modification of
				the Web App.
			</p>
			<p className="mb-2">
				<strong>13. </strong>
				<strong>
					<u>Subscriptions </u>
				</strong>
			</p>
			<p className="mb-2">
				A Team must purchase an annual subscription to create an account and use the Web App. A Team’s
				<strong> </strong>purchase of a subscription is governed by the applicable Services Agreement, executed
				by and between VIZN Stats and the Team, which is hereby incorporated into these Terms of Use.
			</p>
			<p className="mb-2">
				<strong>14. </strong>
				<strong>
					<u>Geographic Restrictions</u>
				</strong>
			</p>
			<p className="mb-2">
				The owner of the Web App is based in the State of Nebraska in the United States. We provide the Web App
				for use only by persons located in the United States. We make no claims that the Web App or any of the
				Web App’s content is accessible or appropriate outside of the United States. Access to the Web App may
				not be legal by certain persons or in certain countries. If you access the Web App from outside the
				United States, you do so on your own initiative and are responsible for compliance with local laws.
			</p>
			<p className="mb-2">
				<strong>15. </strong>
				<strong>
					<u>Disclaimer of Warranties</u>
				</strong>
			</p>
			<p className="mb-2">
				You understand that we cannot and do not guarantee or warrant that files available for downloading from
				the internet or the Web App will be free of viruses or other destructive code. You are responsible for
				implementing sufficient procedures and checkpoints to satisfy your particular requirements for
				anti-virus protection and accuracy of data input and output, and for maintaining a means external to our
				Web App for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE
				LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
				TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR
				OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEB APP OR TO YOUR DOWNLOADING OF ANY MATERIAL FROM
				THE WEB APP.
			</p>
			<p className="mb-2">
				YOUR USE OF THE WEB APP, ITS CONTENT, AND ANY MATERIALS OBTAINED THROUGH THE WEB APP IS AT YOUR OWN
				RISK. THE WEB APP, ITS CONTENT, AND ANY MATERIALS OBTAINED THROUGH THE WEB APP ARE PROVIDED ON AN “AS
				IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
				VIZN STATS NOR ANY PERSON ASSOCIATED WITH VIZN STATS MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT
				TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEB APP. WITHOUT
				LIMITING THE FOREGOING, NEITHER VIZN STATS NOR ANYONE ASSOCIATED WITH VIZN STATS REPRESENTS OR WARRANTS
				THAT THE WEB APP, ITS CONTENT, OR ANY MATERIALS OBTAINED THROUGH THE WEB APP WILL BE ACCURATE, RELIABLE,
				ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEB APP OR THE SERVER THAT MAKES
				IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEB APP OR MATERIALS OBTAINED
				THROUGH THE WEB APP WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
			</p>
			<p className="mb-2">
				TO THE FULLEST EXTENT PROVIDED BY LAW, VIZN STATS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
				EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
				MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
			</p>
			<p className="mb-2">
				THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
			</p>
			<p className="mb-2">
				<strong>16. </strong>
				<strong>
					<u>Limitation of Liability</u>
				</strong>
			</p>
			<p className="mb-2">
				IN NO EVENT WILL VIZN STATS BE LIABLE UNDER OR IN CONNECTION WITH THESE TERMS OF USE UNDER ANY LEGAL OR
				EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR
				OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE
				DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS;
				(c) LOSS OF GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR RECOVERY OF
				ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH
				CASE REGARDLESS OF WHETHER VIZN STATS WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH
				LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE.
			</p>
			<p className="mb-2">
				TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL VIZN STATS’S AGGREGATE LIABILITY ARISING OUT OF
				OR RELATED TO THESE TERMS OF USE UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
				(INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE EXCEED THE TOTAL AMOUNTS PAID TO VIZN STATS IN
				THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
			</p>
			<p className="mb-2">
				The limitation of liability set out above does not apply to liability resulting from our gross
				negligence or willful misconduct.
			</p>
			<p className="mb-2">
				THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
			</p>
			<p className="mb-2">
				<strong>17. </strong>
				<strong>
					<u>Indemnification </u>
				</strong>
			</p>
			<p className="mb-2">
				You agree to defend, indemnify, and hold harmless VIZN Stats, its affiliates, licensors, and service
				providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
				suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
				losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to
				your violation of these Terms of Use or your use of the Web App, including, but not limited to, your
				Team Data and any use of the Company Content other than as expressly authorized in these Terms of Use,
				or your use of any information obtained from the Web App.
			</p>
			<p className="mb-2">
				<strong>18. </strong>
				<strong>
					<u>Governing Law and Jurisdiction</u>
				</strong>
			</p>
			<p className="mb-2">
				All matters relating to the Web App and these Terms of Use, and any dispute or claim arising therefrom
				or related thereto (in each case, including non-contractual disputes or claims), shall be governed by
				and construed in accordance with the internal laws of the State of Nebraska without giving effect to any
				choice or conflict of law provision or rule (whether of the State of Nebraska or any other
				jurisdiction).
			</p>
			<p className="mb-2">
				Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Web App
				shall be instituted exclusively in the federal courts of the United States or the courts of the State of
				Nebraska, in each case located in the City of Lincoln and County of Lancaster. You waive any and all
				objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
			</p>
			<p className="mb-2">
				<strong>19. </strong>
				<strong>
					<u>Arbitration</u>
				</strong>
			</p>
			<p className="mb-2">
				At VIZN Stats’s sole discretion, it may require you to submit any disputes arising from these Terms of
				Use or use of the Web App, including disputes arising from or concerning their interpretation,
				violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules
				of Arbitration of the American Arbitration Association applying Nebraska law.
			</p>
			<p className="mb-2">
				<strong>20. </strong>
				<strong>
					<u>Limitation on Time to File Claims</u>
				</strong>
			</p>
			<p className="mb-2">
				ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEB
				APP MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
				ACTION OR CLAIM IS PERMANENTLY BARRED.
			</p>
			<p className="mb-2">
				<strong>21. </strong>
				<strong>
					<u>Waiver and Severability</u>
				</strong>
			</p>
			<p className="mb-2">
				<strong> </strong>
			</p>
			<p className="mb-2">
				No waiver by VIZN Stats of any term or condition set out in these Terms of Use shall be deemed a further
				or continuing waiver of such term or condition or a waiver of any other term or condition, and any
				failure of VIZN Stats to assert a right or provision under these Terms of Use shall not constitute a
				waiver of such right or provision.
			</p>
			<p className="mb-2">
				If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to
				be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to
				the minimum extent such that the remaining provisions of these Terms of Use will continue in full force
				and effect.
			</p>
			<p className="mb-2">
				<strong>22. </strong>
				<strong>
					<u>Entire Agreement</u>
				</strong>
			</p>
			<p className="mb-2">
				These Terms of Use, the Privacy Policy, and the applicable Services Agreement constitute the sole and
				entire agreement between you and VIZN Stats regarding the Web App and supersede all prior and
				contemporaneous understandings, agreements, representations, and warranties, both written and oral,
				regarding the Web App.
			</p>
			<p className="mb-2">
				<strong>23. </strong>
				<strong>
					<u>Your Comments and Concerns</u>
				</strong>
			</p>
			<p className="mb-2">
				All feedback, comments, requests for technical support and other communications relating to the Web App
				should be directed to: <strong>team@tappsports.com</strong>.
			</p>
		</div>
	</>
);

export const PrivacyPolicy = new Document(privacyPolicyStyles, privacyPolicyJSX, privacyPolicyDateLastUpdated);
export const TermsOfUse = new Document(termsStyles, termsJSX, termsDateLastUpdated);
