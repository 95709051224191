import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAppState, useActions } from "../../api";
import SingleSnapshot from "../../components/snapshots/singleSnapshot";

import { Card, Typography } from "antd";
const { Paragraph } = Typography;

export default function Snapshots({ noAuth }) {
	const state = useAppState();
	const actions = useActions();
	const [showAll, setShowAll] = useState(0);

	useEffect(() => {
		if (noAuth) {
			if (document.getElementById("sider")) {
				document.getElementById("sider").hidden = true;
			}
		}

		if (!noAuth) {
			actions.snapshots.getAll();
		}

		return () => {
			if (document.getElementById("sider")) {
				document.getElementById("sider").hidden = false;
			}
			document.title = "VIZN Stats";
		};
	}, []);

	return (
		<div className="m-4">
			<div className="mb-4">
				<div>
					{Object.values(state.snapshots.all)[0] && Object.values(state.snapshots.all)[0].accessLog ? (
						<div>
							<p className="mb-0 inline">Seen today by:</p>
							{Object.values(state.snapshots.all)[0] &&
							Object.values(state.snapshots.all)[0].accessLog &&
							Object.values(state.snapshots.all).length > 0
								? [
										...new Map(
											Object.values(state.snapshots.all)[0].accessLog.map((item) => [
												item["name"],
												item,
											])
										).values(),
								  ]
										.filter((accessEvent) => moment(accessEvent.date).isSame(new Date(), "day"))
										.map((obj) => <p className="mb-0 ml-1 inline">{obj.name},</p>)
								: null}
						</div>
					) : null}
				</div>
				<div className="mt-4">
					{!noAuth ? (
						<Card>
							<h1 className="uppercase">Shareable Link</h1>
							<Paragraph className="text-lg" copyable>
								{`https://vizn-stats-dev.web.app/snaps?mapId=${state.snapshots.organizationMapId}`}
							</Paragraph>
						</Card>
					) : null}
				</div>
			</div>
			{noAuth
				? Object.values(state.snapshots.all)
						.sort((a, b) => moment(moment(b.startDate)).diff(moment(a.startDate)))
						.map((snapshot, index) => {
							return <SingleSnapshot key={index} snapshot={snapshot} noApi={true} />;
						})
				: Object.values(state.snapshots.all)
						.sort((a, b) => moment(moment(b.startDate)).diff(moment(a.startDate)))
						.map((snapshot, index) => {
							if (index < showAll + 1) {
								return <SingleSnapshot key={index} snapshot={snapshot} noApi={false} />;
							} else {
								return null;
							}
						})}
			<Card className="text-center" hoverable onClick={() => setShowAll(showAll + 1)}>
				<h1 className="text-lg">Show One More</h1>
			</Card>
		</div>
	);
}
