import StatHistoryRow from "../components/stats/StatHistoryRow";
import StatHistoryRowGrouped from "../components/stats/StatHistoryRowGrouped";
import { useActions } from "../api";
import moment from "moment";

export const createStatHistoryComponent = (stat, currentChart) => {
	const actions = useActions();
	let widgetType = stat.statList[0].statData[0].widgetType;
	let widgetName = stat.statList[0].statData[0].widgetName;
	let playerName = actions.players.getPlayerById(stat.statList[0].playerId)?.name;
	let dateCreated = moment(stat.statList[0].dateCreated).format("hh:mm A");
	let value = stat.statList[0].statData[0].value;
	let values = stat.statList[0].statData;
	let isFlagged = stat.isFlagged;

	let component = undefined;
	if (stat.statId) {
		if (currentChart.isGrouped) {
			component = (
				<StatHistoryRowGrouped
					playerName={playerName}
					statId={stat.statId}
					dateCreated={dateCreated}
					values={values}
					isFlagged={isFlagged}
				/>
			);
		} else {
			component = (
				<StatHistoryRow
					playerName={playerName}
					widgetName={widgetName}
					widgetType={widgetType}
					value={value}
					statId={stat.statId}
					isFlagged={isFlagged}
				/>
			);
		}
	} else {
		component = (
			<StatHistoryRow
				playerName={`Loading...`}
				widgetName={widgetName}
				widgetType={widgetType}
				value={value}
				statId={stat.statId}
				isFlagged={isFlagged}
			/>
		);
	}

	return component;
};
