import React, { useEffect } from "react";
import { Row, Col, Divider } from "antd";
import SubscriptionCard from "../../components/global/subscriptionCard";
import FadeInSection from "../../components/global/fadeInSection";
import { SubscriptionType } from "../../constants/subscriptionType";
import { wikiURL } from "../../constants/wiki";

const Pricing = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const buttonText = "Start Free Trial";

	//team subscription details
	const teamSubscription = {
		title: "Team Subscription",
		pricingDescription: (
			<div className="text-2xl">
				<strong>Free</strong> for 7 days, then $19.99/month
			</div>
		),
		features: [
			"Unlimited charts",
			"Unlimited stats",
			"Unlimited teams & players",
			"Template charts",
			"Powerful data displays & analytics",
			"Data import from supported tools",
			"Data export to CSV, XLSX, and PDF",
			"24/7 support",
		],
	};

	//player subscription details
	const playerSubscription = {
		title: "Player/Parent Account",
		pricingDescription: (
			<div>
				<strong className="text-2xl">Free</strong> <br />{" "}
				<span className="text-md">*Must belong to a team that has a subscription</span>
			</div>
		),
		features: [
			"Powerful data displays & analytics",
			"Data recording capabilities",
			"Data export to CSV, XLSX, and PDF",
			"24/7 support",
		],
	};

	//individual subscription details
	const individualSubscription = {
		title: "Individual Plan",
		pricingDescription: (
			<div className="text-2xl">
				<strong>Free</strong> for 7 days, then $4.99/month
			</div>
		),
		features: [
			"Unlimited charts",
			"Unlimited stats",
			"One User",
			"Template charts",
			"Powerful data displays & analytics",
			"Data import from supported tools",
			"Data export to CSV, XLSX, and PDF",
			"24/7 support",
		],
	};

	return (
		<div className="site-layout-content">
			<div className="mt-5">
				<FadeInSection>
					<Row wrap={true} gutter={[25, 100]}>
						<Col span={16} xs={24} sm={24} md={25} lg={24} xl={24} xxl={16}>
							<div className=" h-full">
								<Divider orientation="center" style={{ border: "rgb(209 213 219)" }}>
									<span className="text-gray-300 text-3xl font-medium">Teams</span>
								</Divider>
								<Row wrap={true} gutter={[25, 20]} className="h-full">
									<Col span={12} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
										<SubscriptionCard
											subscriptionType={SubscriptionType.TeamTier2022_1}
											title={teamSubscription.title}
											pricingDescription={teamSubscription.pricingDescription}
											features={teamSubscription.features}
											buttonText={buttonText}
										/>
									</Col>
									<Col span={12} xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
										<SubscriptionCard
											subscriptionType={SubscriptionType.PlayerTier2022_1}
											title={playerSubscription.title}
											pricingDescription={playerSubscription.pricingDescription}
											features={playerSubscription.features}
											buttonText={"Set up account"}
										/>
									</Col>
								</Row>
							</div>
						</Col>
						<Col span={8} xs={24} sm={24} md={24} lg={24} xl={24} xxl={8}>
							<div className="h-full">
								<Divider orientation="center" style={{ border: "rgb(209 213 219)" }}>
									<span className="text-gray-300 text-3xl font-medium">Individual</span>
								</Divider>

								<SubscriptionCard
									subscriptionType={SubscriptionType.IndividualTier2022_1}
									title={individualSubscription.title}
									pricingDescription={individualSubscription.pricingDescription}
									features={individualSubscription.features}
									buttonText={buttonText}
								/>
							</div>
						</Col>
					</Row>
				</FadeInSection>
				<Row className="h-32" />

				<Row className="mb-5">
					<p className="text-4xl font-semibold text-black mb-1">How it works</p>
					<Divider className="mb-2" />
					<p className="text-xl font-normal text-black mb-8">
						We offer a <span className="font-semibold">7 day free trial</span> period for you to use the
						application. During this time, we want you to track metrics in practice and analyze your team's
						performance data. After your trial period is over, you will need to pay the subscription fee to
						continue using tapp.
					</p>
					<p className="text-xl font-normal text-black mb-8">
						Payment is collected through credit card. If this is inconvenient and you would like to work out
						an alternate payment form, please contact team@tappsports.com. We are confident that you will
						love using tapp, so{" "}
						<span className="font-semibold">we are prepared to offer your money back</span> if you do not
						get value from the application during your first season using it.
					</p>
				</Row>
				<a className="underline text-lg" href={wikiURL.faq} target="_blank" rel="noreferrer noopener">
					Commonly asked questions
				</a>
			</div>
		</div>
	);
};

export default Pricing;
