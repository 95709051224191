import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useAppState, useActions } from "../../../api";
import EditWidgetsColumn from "./EditWidgetsColumn";
import EditWidgetsModal from "./EditWidgetsModal";

const EditChartWidgets = ({ editing }) => {
	//#region HOOKS -----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];
	const [column, setColumn] = useState({
		id: "column-1",
		taskIds: currentChart.widgets,
	});

	useEffect(() => {
		setColumn({
			id: "column-1",
			taskIds: currentChart.widgets,
		});
	}, [currentChart ? currentChart.widgets : currentChart]); //Review

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------

	const dragEndHandler = (result) => {
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		const newTaskIds = Array.from(column.taskIds);
		newTaskIds.splice(source.index, 1);
		newTaskIds.splice(destination.index, 0, draggableId);

		setColumn({
			id: "column-1",
			taskIds: newTaskIds,
		});

		actions.charts.setWidgets(newTaskIds); //the newTaskIds are the widgetIds in the new order.
	};

	const recordRows =
		currentChart && currentChart.widgets ? (
			<DragDropContext onDragEnd={dragEndHandler}>
				{["column-1"].map(() => {
					const tasks = column.taskIds.map((taskId) => state.widgets.all[taskId]);

					return <EditWidgetsColumn editing={editing} key={column.id} column={column} tasks={tasks} />;
				})}
			</DragDropContext>
		) : (
			<div className="bg-white rounded h-2/5">
				<p className="">No Categories :(</p>
			</div>
		);

	//#endregion HANDLERS --------------------------------------------------------------------------------------------------------

	//#region JSX -----------------------------------------------------------------------------------------------------------

	return (
		<>
			<EditWidgetsModal editing={editing} />
			<div className="font-montserrat overflow-y-auto">{recordRows}</div>
		</>
	);

	//#endregion JSX -----------------------------------------------------------------------------------------------------------
};

export default EditChartWidgets;
