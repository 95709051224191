export const Colors = {};

Colors.names = [
	"#1984c5",
	"#22a7f0",
	"#63bff0",
	"#a7d5ed",
	"#808080",
	"#e1a692",
	"#de6e56",
	"#e14b31",
	"#c23728",
	"#7c1158",
	"#4421af",
	"#1a53ff",
	"#0d88e6",
	"#00b7c7",
	"#5ad45a",
	"#8be04e",
	"#ebdc78",
	"#fd7f6f",
	"#7eb0d5",
	"#b2e061",
	"#bd7ebe",
	"#ffb55a",
	"#ffee65",
	"#beb9db",
	"#fdcce5",
	"#8bd3c7",
];

Colors.get = function (numColors) {
	const colors = Colors.names.slice(0, numColors);
	return colors;
};
