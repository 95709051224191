import axios from "axios";
import { Tag } from "../../constants/api";

export const api = {
	async createTag(payload) {
		const res = await axios.post(Tag.create, payload);
		return res.data.receipt;
	},
	async getAllTags() {
		const res = await axios.post(Tag.read.all, {});
		return res.data;
	},
	async updateTag(payload) {
		const res = await axios.post(Tag.update, payload);
		return res.data.receipt;
	},
	async deleteTag(payload) {
		const res = await axios.post(Tag.delete, payload);
		return res.data.receipt;
	},
};
