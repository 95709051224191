import { Card, Button } from "antd";
import { useHistory } from "react-router-dom";

const GlobalErrorBoundary = () => {
	const history = useHistory();

	return (
		<div className="font-montserrat">
			<Card className="w-100% cursor-pointer">
				<h1 className="text-2xl font-bold">We're sorry, something went wrong.</h1>
				<p className="text-lg">
					If this persists, please contact us at: <b>team@tappsports.com</b> and we will fix the issue as soon
					as possible.
				</p>
				{/** TODO error boundary may cause user to get stuck on unauthenticated pages due to the reload */}
				<Button
					className="m-3"
					type="primary"
					onClick={() => {
						history.push("/");
						window.location.reload();
					}}>
					Refresh
				</Button>
			</Card>
		</div>
	);
};

export default GlobalErrorBoundary;
