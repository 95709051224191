import axios from "axios";
import { Import } from "../../constants/api";

export const api = {
	async createCSV(payload) {
		try {
			const createCSVRes = await axios.post(Import.create.chart, payload);
			return createCSVRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async createStatsCSV(payload) {
		try {
			const createStatslRes = await axios.post(Import.create.stat, payload);
			return createStatslRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async createRoster(payload) {
		try {
			const createRosterRes = await axios.post(Import.create.player, payload);
			return createRosterRes;
		} catch (error) {
			throw new Error(error);
		}
	},
};
