import { signin } from "../../helpers/auth";
import axios from "axios";

const _setAxiosAuthHeader = (authToken) => {
	let userSignedIn = false;
	if (authToken !== "") {
		axios.defaults.headers.common["Authorization"] = authToken;
		userSignedIn = true;
	} else {
		axios.defaults.headers.common["Authorization"] = null;
	}
	return userSignedIn;
};

export const getUser = async ({ state, effects }) => {
	const getUserRes = await effects.user.api.getUser();
	state.user.user = getUserRes.data.user;
};

export const getSubscriptionPortal = async ({ effects }) => {
	const getSubscriptionPortalRes = await effects.user.api.getSubscriptionPortal();
	return getSubscriptionPortalRes.data.url;
};

// eslint-disable-next-line no-unused-vars
export const loginUser = async ({ state, effects }, loginPayload) => {
	try {
		const res = await signin(loginPayload.email, loginPayload.password);
		const FBIdToken = `Bearer ${await res.user.getIdToken()}`;
		localStorage.setItem("FBIdToken", FBIdToken);
		_setAxiosAuthHeader(FBIdToken);
	} catch (err) {
		throw new Error(err);
	}
};

/**
 * Depending on where this is called from, the waitlist submission could be a string with an email or an object with more details.
 */
export const createWaitlistSubmission = async ({ state, effects }, waitlistFormInput) => {
	const waitlistPayload = waitlistFormInput
		? {
				name: waitlistFormInput.name,
				email: waitlistFormInput.email,
				phone: waitlistFormInput.phone,
				organization: waitlistFormInput.organization,
		  }
		: {
				email: state.user.waitlistEmail,
		  };

	try {
		await effects.user.api.createWaitlistSubmission(waitlistPayload);
	} catch (err) {
		throw Error(err);
	}
};

export const createUser = async ({ effects }, userFormInput) => {
	let userRes;
	try {
		const userPayload = {
			email: userFormInput.email,
			password: userFormInput.password,
			name: userFormInput.name,
			userContext: userFormInput.userContext,
		};
		userRes = await effects.user.api.createUser(userPayload);
		return userRes.data.userToken;
	} catch (err) {
		if (err && err.response && err.response.data && err.response.data.error) {
			// need to make sure we are sending an error message
			throw new Error(err.response.data.error);
		} else {
			throw new Error(err);
		}
	}
};

export const createAdminUser = async ({ effects }, orgKey) => {
	let userRes;
	try {
		const userPayload = {
			email: `${orgKey}@viznstats.com`,
			password: `${orgKey}-admin`,
			name: "Admin",
			userType: "ADMIN",
			organizationKey: orgKey,
			populateWithDefaults: false, // review, on the backend we should just get rid of this
		};
		userRes = await effects.user.api.createUser(userPayload);
		return { userPayload, userRes };
	} catch (err) {
		if (err && err.response && err.response.data && err.response.data.error) {
			// need to make sure we are sending an error message
			throw new Error(err.response.data.error);
		} else {
			throw new Error(err);
		}
	}
};

export const setWaitlistEmail = ({ state }, newValue) => {
	state.user.waitlistEmail = newValue;
};

export const setSidebarCollapse = ({ state }, newValue) => {
	state.user.sidebarCollapse = newValue;
};

export const setAppLoading = ({ state }, newValue) => {
	state.user.appLoading = newValue;
};

export const setAuthenticated = ({ state }, newValue) => {
	state.user.authenticated = newValue;
};

export const createJoinUser = async ({ state, effects }, joinUserModel) => {
	try {
		const createJoinUserRes = await effects.user.api.createJoinUser(joinUserModel);
		const createJoinUserData = createJoinUserRes.data.receipt;
		state.user.joinOrg = createJoinUserData;
	} catch (err) {
		throw new Error(err);
	}
};

export const setShowChoosePlayerModal = ({ state }, newValue) => {
	state.user.showChoosePlayerModal = newValue;
};

export const setUserInputSignupInfo = ({ state }, newValue) => {
	state.user.userInputSignupInfo = newValue;
};

export const setSignupInfo = ({ state }, newVal) => {
	state.user.signupInfo = newVal;
};

export const toggleColors = ({ state }) => {
	state.user.positiveColorIsBlue = !state.user.positiveColorIsBlue;
};
