import React from "react";

import { useAppState, useActions } from "../../../api";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { heatMapAndSprayChartWidgetIds } from "../../../engines/dataEngine";
import { Divider } from "antd";

const FilterSprayCharts = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	// heat map filters ------------------------------------------------------------------
	const hideSprayChart = (id) => {
		const newVisible = state.charts.chartView.visibleSprayCharts.filter((sprayChartId) => sprayChartId !== id);
		actions.charts.updateVisibleSprayCharts(newVisible);

		const newHidden = [...state.charts.chartView.hiddenSprayCharts, id];
		actions.charts.updateHiddenSprayCharts(newHidden);
	};

	const unHideSprayChart = (id) => {
		const newVisible = [...state.charts.chartView.visibleSprayCharts, id];
		actions.charts.updateVisibleSprayCharts(newVisible);

		const newHidden = state.charts.chartView.hiddenSprayCharts.filter((sprayChartId) => sprayChartId !== id);
		actions.charts.updateHiddenSprayCharts(newHidden);
	};

	const hideAllSprayCharts = () => {
		actions.charts.updateVisibleSprayCharts([]);
		actions.charts.updateHiddenSprayCharts(heatMapAndSprayChartWidgetIds(state).sprayChart);
	};

	const showAllSprayCharts = () => {
		actions.charts.updateVisibleSprayCharts(heatMapAndSprayChartWidgetIds(state).sprayChart);
		actions.charts.updateHiddenSprayCharts([]);
	};

	// player filters ------------------------------------------------------------------
	const hideSprayChartPlayer = (id) => {
		const newVisible = state.charts.chartView.visibleSprayChartPlayers.filter((playerId) => playerId !== id);
		actions.charts.updateVisibleSprayChartPlayers(newVisible);

		const newHidden = [...state.charts.chartView.hiddenSprayChartPlayers, id];
		actions.charts.updateHiddenSprayChartPlayers(newHidden);
	};

	const unHideSprayChartPlayer = (id) => {
		const newVisible = [...state.charts.chartView.visibleSprayChartPlayers, id];
		actions.charts.updateVisibleSprayChartPlayers(newVisible);

		const newHidden = state.charts.chartView.hiddenSprayChartPlayers.filter((playerId) => playerId !== id);
		actions.charts.updateHiddenSprayChartPlayers(newHidden);
	};

	const hideAllPlayers = () => {
		actions.charts.updateVisibleSprayChartPlayers([]);
		let playerIds = [];
		currentChart.players.forEach((playerId) => {
			playerIds = [...playerIds, playerId];
		});
		actions.charts.updateHiddenSprayChartPlayers(playerIds);
	};

	const showAllPlayers = () => {
		let playerIds = [];
		currentChart.players.forEach((playerId) => {
			playerIds = [...playerIds, playerId];
		});
		actions.charts.updateVisibleSprayChartPlayers(playerIds);
		actions.charts.updateHiddenSprayChartPlayers([]);
	};

	// spray chart lists ------------------------------------------------------------------
	const visibleSprayCharts = state.charts.chartView.visibleSprayCharts.map((sprayChartId) => {
		return (
			<div className="flex w-full" key={"visible " + sprayChartId}>
				<p className="mt-auto mb-auto">{state.widgets.all[sprayChartId].name}</p>
				<EyeOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => hideSprayChart(sprayChartId)}
				/>
			</div>
		);
	});

	const hiddenSprayCharts = state.charts.chartView.hiddenSprayCharts.map((sprayChartId) => {
		return (
			<div className="flex w-full" key={"hidden " + sprayChartId}>
				<p className="mt-auto mb-auto">{state.widgets.all[sprayChartId].name}</p>
				<EyeInvisibleOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => unHideSprayChart(sprayChartId)}
				/>
			</div>
		);
	});

	//player lists ------------------------------------------------------------------
	const visibleSprayChartPlayers = state.charts.chartView.visibleSprayChartPlayers.map((playerId) => {
		return (
			<div className="flex w-full" key={"visible spray " + playerId}>
				<p className="mt-auto mb-auto">{actions.players.getPlayerById(playerId).name}</p>
				<EyeOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => hideSprayChartPlayer(playerId)}
				/>
			</div>
		);
	});

	const hiddenSprayChartPlayers = state.charts.chartView.hiddenSprayChartPlayers.map((playerId) => {
		return (
			<div className="flex w-full" key={"hidden spray " + playerId}>
				<p className="mt-auto mb-auto">{actions.players.getPlayerById(playerId).name}</p>
				<EyeInvisibleOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => unHideSprayChartPlayer(playerId)}
				/>
			</div>
		);
	});

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat bg-white shadow p-2 w-80">
			{state.charts.chartView.visibleSprayCharts.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Visible spray charts</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={hideAllSprayCharts}>
							Hide all
						</p>
					</div>
					{visibleSprayCharts}
				</div>
			) : (
				""
			)}
			{state.charts.chartView.hiddenSprayCharts.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Hidden spray charts</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={showAllSprayCharts}>
							Show all
						</p>
					</div>
					{hiddenSprayCharts}
				</div>
			) : (
				""
			)}

			<Divider />

			{state.charts.chartView.visibleSprayChartPlayers.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Visible players</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={hideAllPlayers}>
							Hide all
						</p>
					</div>
					{visibleSprayChartPlayers}
				</div>
			) : (
				""
			)}
			{state.charts.chartView.hiddenSprayChartPlayers.length > 0 ? (
				<div>
					<div className="flex">
						<p className="text-gray-400 mb-0">Hidden players</p>
						<p
							className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
							onClick={showAllPlayers}>
							Show all
						</p>
					</div>
					{hiddenSprayChartPlayers}
				</div>
			) : (
				""
			)}
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default FilterSprayCharts;
