import React from "react";

import { useAppState } from "../../../api";
import { getHeatMapTotal, getHeatMapTotalStrikes } from "../../../engines/dataEngine";
import { heatMapAndSprayChartWidgetIds } from "../../../engines/dataEngine";
import HeatMap from "./HeatMap";

import { Col } from "antd";

const PlayerHeatMap = ({ playerData }) => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const state = useAppState();

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return heatMapAndSprayChartWidgetIds(state).heatMap.map((heatMapId) => {
		if (state.charts.chartView.visibleHeatMaps.includes(heatMapId)) {
			let data = {};
			//TODO change the heatmaps to id based naming
			Object.keys(playerData).forEach((stat) => {
				// need to do this to get the tool's name
				let toolNameArr = stat.split("_");

				// remove first of three words from key
				toolNameArr.pop();
				//rejoins the remaining elements by an underscore if they previously had an underscore
				let toolName = toolNameArr.join("_");

				if (toolName === state.widgets.all[heatMapId].name) {
					data[stat] = playerData[stat];
				}
			});
			const { total } = getHeatMapTotal(data);
			let heatMapTotal = total;
			let heatMapTotalStrikes = getHeatMapTotalStrikes(data);
			let heatMapTotalBalls = heatMapTotal - heatMapTotalStrikes;
			let strikePct = Math.round((heatMapTotalStrikes / heatMapTotal) * 100);
			if (heatMapTotal !== 0) {
				return (
					<Col key={playerData.Player_Id + heatMapId}>
						<div className="flex justify-center items-center w-full">
							<div>
								<p className="text-gray-400 text-md mb-0 ml-5">{state.widgets.all[heatMapId].name}</p>
								<HeatMap data={data} dataPage={true} />
								<p className="ml-5">
									<span className={`text-${state.user.positiveColor}-500`}>
										{heatMapTotalStrikes}
									</span>{" "}
									|{" "}
									<span className={`text-${state.user.negativeColor}-500`}>{heatMapTotalBalls}</span>{" "}
									| <span className="font-semibold">{strikePct}%</span> ({heatMapTotalStrikes} /{" "}
									{heatMapTotal})
								</p>
							</div>
						</div>
					</Col>
				);
			} else {
				return "";
			}
		} else {
			return "";
		}
	});

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PlayerHeatMap;
