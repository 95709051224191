import React from "react";
import Moment from "react-moment";
import { useActions, useAppState } from "../../api";
import { Card, Switch, Typography } from "antd";
const { Paragraph } = Typography;
import Record from "../../components/tools/posNeg/Record";

const Profile = () => {
	const state = useAppState();
	const actions = useActions();

	let isPlayer = false;
	let isOutdated = true;
	try {
		isPlayer = state.user.user.userContext[0].userRoles[0] === "PLAYER";
		isOutdated = false;
	} catch (error) {
		console.log("issue with identifying role");
	}

	const manageSubscriptionClick = async () => {
		const link = await actions.user.getSubscriptionPortal();
		window.open(link);
	};

	const handleColorToggle = () => {
		actions.user.toggleColors();
	};

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat" style={{ minWidth: 500 }}>
			<Card
				title={<h1 className="flex-auto text-2xl lg:text-3xl text-left h-full my-auto">My Account</h1>}
				headStyle={{ height: 75 }}
				style={{ maxWidth: 600, margin: "auto" }}>
				<div className="font-montserrat mt-5">
					<div className="bg-offWhite p-6">
						<p className="uppercase  my-0">Team Code</p>
						<div className="mb-2 mt-1 mx-auto text-xl font-semibold">
							<Paragraph style={{ marginBottom: "2px" }} copyable>
								{state.organization.organization.organizationId.slice(0, 7)}
							</Paragraph>
						</div>
						<p className="mx-auto my-0 text-xs text-gray-500">
							Use this code to add coaches and players to your organization
						</p>
					</div>
					<div className="mt-5 bg-offWhite p-6">
						<p className="uppercase my-0">Preferences</p>
						<div className="mt-1 py-2">
							<div className="mb-4">
								<Switch
									className="inline"
									onChange={handleColorToggle}
									defaultChecked={!state.user.positiveColorIsBlue}
								/>
								<p className="ml-2 text-xs text-gray-500 inline">Red is positive mode. Ex:</p>
							</div>
							<Record />
						</div>
					</div>
					<div className="mt-5 bg-offWhite p-6">
						<p className="uppercase my-0">Details</p>
						<div className="mt-1 border-b border-gray-200 py-2">
							<p className="text-lg mt-0 border-gray-300 mb-0 mx-auto">{state.user.user.name}</p>
							<p className="my-0 text-xs text-gray-500">Name</p>
						</div>
						<div className="mt-1 border-b border-gray-200 py-2">
							<p className="text-lg mt-0 border-gray-300 mb-0 mx-auto">{state.user.user.email}</p>
							<p className="my-0 text-xs text-gray-500">Email</p>
						</div>
						<div className="mt-1 border-gray-200 py-2">
							<p className="text-lg mt-0 border-gray-300 mb-0 mx-auto">
								<Moment fromNow ago>
									{state.user.user.dateCreated}
								</Moment>
							</p>
							<p className="my-0 text-xs text-gray-500">
								Time on platform. Here's to making your team better for years to come!
							</p>
						</div>
					</div>
					{!isOutdated && !isPlayer ? (
						<div className="mt-5 bg-offWhite p-6">
							<p className="uppercase my-0">Subscription</p>
							<div className="mt-1 border-gray-200 py-2">
								<button
									className="bg-coolPurple text-white py-2 px-12 md:px-12 rounded-lg hover:shadow-lg transform hover:scale-105 
                transition duration-300 ease-in-out"
									onClick={manageSubscriptionClick}>
									Manage Subscription
								</button>
							</div>
						</div>
					) : undefined}
				</div>
			</Card>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default Profile;
