import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{value !== 0 ? value : ""}
		</text>
	);
};

export const PieChartComponent = ({ data, COLORS }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		// <ResponsiveContainer width="100%" height="100%">

		<PieChart width={400} height={300}>
			<Pie
				data={data}
				cx="50%"
				cy="50%"
				labelLine={false}
				label={renderCustomizedLabel}
				outerRadius={100}
				fill="#8884d8"
				dataKey="value">
				{data.map((entry, index) => (
					<Cell key={`cell-${index + entry}`} fill={COLORS[index % COLORS.length]} />
				))}
			</Pie>
			<Tooltip />
			<Legend />
		</PieChart>

		// </ResponsiveContainer>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PieChartComponent;
