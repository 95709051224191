import React from "react";

import { DownOutlined } from "@ant-design/icons";
import { useActions } from "../../../api";

export const PlayerListSelectMini = ({ value }) => {
	const actions = useActions();

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return value === undefined ? (
		<p className="w-full mb-0 rounded text-center font-bold bg-gray-100 flex text-center items-center justify-center">
			Players <DownOutlined className="ml-2" />
		</p>
	) : (
		<div className="flex w-full h-full  pb-2 rounded bg-gray-100 cursor-pointer text-center pt-2">
			<h1 className={`w-10/12 text-gray-400 truncate text-center pl-1 mt-auto mb-auto`}>
				{actions.players.getPlayerById(value) ? actions.players.getPlayerById(value).name : "Players"}
			</h1>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PlayerListSelectMini;
