import React, { useEffect } from "react";
import ChartData from "../../components/charts/ChartData";
import moment from "moment";
import { TimeFrames, TimeFramesReadable } from "../../constants/timeFrames";
import { useAppState, useActions } from "../../api";
import ChartInfoModal from "../../components/onboarding/ChartInfoModal";
import { DatePicker, message, Modal, Select, Typography, Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import ChartOptions from "../../components/charts/ChartOptions";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;

const ChartActionsPage = () => {
	const state = useAppState();
	const actions = useActions();

	useEffect(() => {
		if (!(state.charts.chartView.timeFrame === TimeFrames.CUSTOM)) {
			setTimeFrame(state.charts.chartView.timeFrame);
		}
	}, []);
	//#region HANDLERS -------------------------------------------------------------------------------------------------------

	const onChange = (dateStrings) => {
		try {
			let startDate = dateStrings ? moment(dateStrings[0]) : moment();
			let endDate = dateStrings ? moment(dateStrings[1]) : moment();
			actions.charts.setCustomChartViewDates({ startDate, endDate });
		} catch (error) {
			Modal.error({
				title: "Error",
				content: "Error setting date range. Please try again or contact team@tappsports.com",
			});
		}
	};

	const setTimeFrame = (timeFrame) => {
		actions.charts.setChartViewTimeFrame(timeFrame);
		if (timeFrame === TimeFrames.CUSTOM) {
			message.info("Use the calendar to select a custom date range");
		}
	};

	const handleTitleEdit = (value) => {
		actions.charts.updateTitle(value);
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	const timeFrameMenu = (
		<Select
			value={state.charts.chartView.timeFrame}
			style={{ width: 140 }}
			onChange={setTimeFrame}
			disabled={state.charts.chartDataLoading}>
			{Object.keys(TimeFramesReadable).map((timeframe) => {
				return <Option value={timeframe}>{TimeFramesReadable[timeframe]}</Option>;
			})}
		</Select>
	);

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat" style={{ minWidth: 700 }}>
			<ChartInfoModal />
			<div className="flex row flex-nowrap mb-4">
				<div className="flex-auto">
					<div className="text-2xl text-left">
						<Paragraph style={{ margin: 0 }} editable={{ onChange: handleTitleEdit }}>
							{state.charts.all[state.charts.currentChartId].title}
						</Paragraph>
					</div>
				</div>
				{state.charts.all[state.charts.currentChartId].widgets.length < 1 ? null : (
					<>
						{state.charts.showDateRangePicker ? (
							<>
								{state.charts.noNewData ? (
									<Tooltip title="No new data loaded. Please select a different date range to get new data.">
										<WarningOutlined
											style={{ color: "#eed202" }}
											className="mt-2 mr-2 cursor-pointer"
										/>
									</Tooltip>
								) : (
									""
								)}
								<div>{timeFrameMenu}</div>
								<div>
									<RangePicker
										value={[state.charts.chartView.startDate, state.charts.chartView.endDate]}
										onChange={onChange}
									/>
								</div>
								<ChartOptions chartId={state.charts.all[state.charts.currentChartId].chartId} />
							</>
						) : (
							""
						)}
					</>
				)}
			</div>
			<ChartData />
		</div>
	);

	//#endregion JSX -----------------------------------------------------------------------------------------------------------
};

export default ChartActionsPage;
