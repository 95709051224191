import React, { useEffect, useState, useRef } from "react";
import { volleyballCharts } from "../../../constants/templates";

// components
import FadeInSection from "../../../components/global/fadeInSection";
import Testimonials from "../../../components/global/testimonials";
import AttentionGrabberHeader from "../../../components/global/attentionGrabberHeader";
import LandingPageBanner from "../../../components/global/landingPageBanner";
import GetTappTodayFooter from "../../../components/global/getTappTodayFooter";

// mockups
import LandingPageTemplates from "../../../components/global/landingPageTemplates";
import CenterGalleryCard from "../../../components/global/centerGalleryCard";
import volleyballMockup from "../../../assets/landing-page/volleyball-mockup.png";

import { Row, Col } from "antd";

const Volleyball = () => {
	const templateRef = useRef(null);
	const [activeTemplate, setActiveTemplate] = useState("Volleyball Defense");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const useCaseClicked = (title) => {
		switch (title) {
			case "Defense evaluations":
				setActiveTemplate("Volleyball Defense");
				break;
			case "Offense evaluations":
				setActiveTemplate("Volleyball Offense");
				break;
			case "Blocks evaluations":
				setActiveTemplate("Volleyball Blocks");
				break;
			case "Game evaluations":
				setActiveTemplate("Volleyball Game");
				break;
		}

		templateRef.current.scrollIntoView();
	};

	const valueProp = [
		"Customizable templates",
		"Unlimited Players",
		"Unlimited Charts",
		"Evaluate anything, anywhere, anytime",
		"Instant reports",
	];

	return (
		<div>
			<div className="mt-5 site-layout-content">
				<FadeInSection>
					<AttentionGrabberHeader
						header={<span>Volleyball evaluations anywhere, anytime</span>}
						description="Never worry about uploading stats from sheet to computer again."
						mockup={volleyballMockup}
						valueProp={valueProp}
					/>
					<Testimonials />
				</FadeInSection>
			</div>

			<LandingPageBanner title="Use Cases" />
			<div className="site-layout-content">
				<Row gutter={[20, 20]}>
					<Col span={24} order={1} md={{ span: 12, order: 1 }}>
						<CenterGalleryCard
							title={"Defense evaluations"}
							content={
								"Teams that track defensive stats create more competitive practices and smarter players."
							}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={2} md={{ span: 12, order: 2 }}>
						<CenterGalleryCard
							title={"Offense evaluations"}
							content={"Take your offense to the next level by tracking everything important to you."}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={3} md={{ span: 12, order: 3 }}>
						<CenterGalleryCard
							title={"Blocks evaluations"}
							content={"Seamlessly run blocking drills, never missing a rep on any player!"}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={4} md={{ span: 12, order: 4 }}>
						<CenterGalleryCard
							title={"Game evaluations"}
							content={"Evaluate your players live during the game, offense and defense."}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
				</Row>
			</div>

			{/** Templates */}
			<LandingPageBanner title="Volleyball Templates" />
			<div ref={templateRef} className="site-layout-content text-center w-full md:w-1/2">
				<p className="text-lg lg:text-xl">
					Our templates are a great way to get started. Each one is completely custimizable so you can measure
					the metrics that matter to you.
				</p>
			</div>
			<LandingPageTemplates
				charts={volleyballCharts}
				activeIndex={activeTemplate}
				setActiveIndex={setActiveTemplate}
			/>

			{/** Landing footer (not the real footer) */}
			<GetTappTodayFooter />
		</div>
	);
};

export default Volleyball;
