import { WidgetTypes } from "../constants/widgetTypes";
import { Link } from "react-router-dom";
import Counter from "../components/tools/counter/Record";
import PosNeg from "../components/tools/posNeg/Record";
import PosNegNeutral from "../components/tools/posNegNeutral/Record";
import NumberInput from "../components/tools/numberInput/Record";
import Stopwatch from "../components/tools/stopwatch/Record";
import PitchLocation from "../components/tools/pitchLocation/Record";
import ListSelect from "../components/tools/listSelect/Record";
import Neg from "../components/tools/neg/Record";
import PosNeutral from "../components/tools/posNeutral/Record";
import PlayerListSelect from "../components/tools/playerListSelect/Record";
import TextInput from "../components/tools/textInput/Record";
import FieldLocation from "../components/tools/fieldLocation/Record";

export const getRecordingTool = (type, name, widgetId, isGrouped = false, potentialValues = "", isEditing = false) => {
	if (type === WidgetTypes.COUNTER) {
		return <Counter label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.POSNEG) {
		return <PosNeg label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.POSNEGNEUTRAL) {
		return <PosNegNeutral label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.NUMBERINPUT) {
		return <NumberInput label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.STOPWATCH) {
		if (isEditing) {
			return <NumberInput label={name} widgetId={widgetId} isGrouped={isGrouped} />;
		} else {
			return <Stopwatch label={name} widgetId={widgetId} isGrouped={isGrouped} />;
		}
	} else if (type === WidgetTypes.PITCHLOCATION) {
		return <PitchLocation label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.LISTSELECT) {
		return <ListSelect label={name} widgetId={widgetId} potentialValues={potentialValues} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.NEGCOUNTER) {
		return <Neg label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.POSNEUTRAL) {
		return <PosNeutral label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.PLAYERLISTSELECT) {
		return (
			<PlayerListSelect
				label={name}
				widgetId={widgetId}
				potentialValues={potentialValues}
				isGrouped={isGrouped}
			/>
		);
	} else if (type === WidgetTypes.TEXTINPUT) {
		return <TextInput label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.FIELDLOCATION) {
		return <FieldLocation label={name} widgetId={widgetId} isGrouped={isGrouped} />;
	} else if (type === WidgetTypes.XYCOORDINATE) {
		return (
			<div className="bg-white p-2 rounded-md mb-2">
				To use this tool you must use the
				<Link
					to={{
						pathname: "https://apps.apple.com/us/app/tapp-sports/id1636570218",
					}}
					target="_blank">
					{" "}
					mobile app
				</Link>
				.
			</div>
		);
	}
};

export default getRecordingTool;
