import React, { useEffect } from "react";

import { useAppState } from "../../../api";
import { hasCoordinateHeatMapData } from "../../../engines/dataEngine";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import NoDataCard from "../../stats/NoDataCard";

import { Col, Row, Dropdown, Button, Divider } from "antd";
import { DownOutlined } from "@ant-design/icons";

import PlayerCoordinateHeatMapCard from "./PlayerCoordinateHeatMapCard";
import { useState } from "react";
import { WidgetTypes } from "../../../constants/widgetTypes";

const CoordinateHeatMapCard = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------

	const state = useAppState();
	const stateChart = state.charts.all[state.charts.currentChartId];

	const [directFlatChart, setDirectFlatChart] = useState(state.dashboard.directFlat[state.charts.currentChartId]);
	const [players, setPlayers] = useState([
		...new Set(
			directFlatChart?.map((datum) => {
				return datum.player;
			})
		),
	]);
	const [visiblePlayers, setVisiblePlayers] = useState(players);
	const [hiddenPlayers, setHiddenPlayers] = useState([]);
	const [xyWidgets, setXYWidgets] = useState(
		stateChart.widgets.filter((id) => {
			return state.widgets.all[id].type === WidgetTypes.XYCOORDINATE;
		})
	);
	const [visibleXYWidgets, setVisibleXYWidgets] = useState(xyWidgets);
	const [hiddenXYWidgets, setHiddenXYWidgets] = useState([]);

	useEffect(() => {
		setDirectFlatChart(state.dashboard.directFlat[state.charts.currentChartId]);
	}, [state.dashboard.directFlat[state.charts.currentChartId]]);

	useEffect(() => {
		setPlayers([
			...new Set(
				directFlatChart?.map((datum) => {
					return datum.player;
				})
			),
		]);
		setXYWidgets(
			stateChart.widgets.filter((id) => {
				return state.widgets.all[id].type === WidgetTypes.XYCOORDINATE;
			})
		);
	}, [directFlatChart]);

	useEffect(() => {
		setVisiblePlayers(players);
	}, [players]);

	useEffect(() => {
		setHiddenPlayers(
			players.filter((player) => {
				return !visiblePlayers.includes(player);
			})
		);
	}, [visiblePlayers]);

	useEffect(() => {
		setVisibleXYWidgets(xyWidgets);
	}, [xyWidgets]);

	useEffect(() => {
		setHiddenXYWidgets(
			xyWidgets.filter((widget) => {
				return !visibleXYWidgets.includes(widget);
			})
		);
	}, [visibleXYWidgets]);

	const hidePlayer = (playerId) => {
		const newVisiblePlayers = visiblePlayers.filter((player) => {
			return player != playerId;
		});
		setVisiblePlayers(newVisiblePlayers);
	};

	const hideAllPlayers = () => {
		setVisiblePlayers([]);
	};

	const showAllPlayers = () => {
		setVisiblePlayers(players);
	};
	const showPlayer = (playerId) => {
		const newVisiblePlayers = [...visiblePlayers];
		newVisiblePlayers.push(playerId);
		setVisiblePlayers(newVisiblePlayers);
	};

	const hideWidget = (widgetId) => {
		const newVisibleXYWidgets = visibleXYWidgets.filter((widget) => {
			return widget != widgetId;
		});
		setVisibleXYWidgets(newVisibleXYWidgets);
	};

	const hideAllWidgets = () => {
		setVisibleXYWidgets([]);
	};

	const showWidget = (widgetId) => {
		const newVisibleXYWidgets = [...visibleXYWidgets];
		newVisibleXYWidgets.push(widgetId);
		setVisibleXYWidgets(newVisibleXYWidgets);
	};

	const showAllWidgets = () => {
		setVisibleXYWidgets(xyWidgets);
	};
	const visiblePlayerRows = visiblePlayers.map((player) => {
		const statePlayer = state.players.allPlayers.find((p) => {
			return p.playerId === player;
		});

		return (
			<div className="flex w-full" key={"visible " + player}>
				<p className="mt-auto mb-auto">{statePlayer.name}</p>
				<EyeOutlined className="ml-auto mr-2 mt-auto mb-auto hover:shadow" onClick={() => hidePlayer(player)} />
			</div>
		);
	});

	const hiddenPlayerRows = hiddenPlayers.map((player) => {
		const statePlayer = state.players.allPlayers.find((p) => {
			return p.playerId === player;
		});
		return (
			<div className="flex w-full" key={"hidden " + player}>
				<p className="mt-auto mb-auto">{statePlayer.name}</p>
				<EyeInvisibleOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => showPlayer(player)}
				/>
			</div>
		);
	});

	const visibleWidgetRows = visibleXYWidgets.map((widget) => {
		const stateWidget = state.widgets.all[widget];
		return (
			<div className="flex w-full" key={"hidden " + widget}>
				<p className="mt-auto mb-auto">{stateWidget.name}</p>
				<EyeOutlined className="ml-auto mr-2 mt-auto mb-auto hover:shadow" onClick={() => hideWidget(widget)} />
			</div>
		);
	});

	const hiddenWidgetRows = hiddenXYWidgets.map((widget) => {
		const stateWidget = state.widgets.all[widget];
		return (
			<div className="flex w-full" key={"hidden " + widget}>
				<p className="mt-auto mb-auto">{stateWidget.name}</p>
				<EyeInvisibleOutlined
					className="ml-auto mr-2 mt-auto mb-auto hover:shadow"
					onClick={() => showWidget(widget)}
				/>
			</div>
		);
	});

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------------

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	const FilterCoordinates = () => {
		return (
			<div className="font-montserrat bg-white shadow p-2 w-80">
				{visibleXYWidgets.length > 0 ? (
					<div>
						<div className="flex">
							<p className="text-gray-400 mb-0">Visible coordinate heatmaps</p>
							<p
								className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
								onClick={hideAllWidgets}>
								Hide all
							</p>
						</div>
						{visibleWidgetRows}
					</div>
				) : (
					""
				)}
				{hiddenXYWidgets.length > 0 ? (
					<div>
						<div className="flex">
							<p className="text-gray-400 mb-0">Hidden coordinate heatmaps</p>
							<p
								className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
								onClick={showAllWidgets}>
								Show all
							</p>
						</div>
						{hiddenWidgetRows}
					</div>
				) : (
					""
				)}

				<Divider />

				{visiblePlayers.length > 0 ? (
					<div>
						<div className="flex">
							<p className="text-gray-400 mb-0">Visible players</p>
							<p
								className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
								onClick={hideAllPlayers}>
								Hide all
							</p>
						</div>
						{visiblePlayerRows}
					</div>
				) : (
					""
				)}
				{hiddenPlayers.length > 0 ? (
					<div>
						<div className="flex">
							<p className="text-gray-400 mb-0">Hidden players</p>
							<p
								className={`ml-auto mr-2 text-${state.user.positiveColor}-500 cursor-pointer hover:text-${state.user.positiveColor}-500 mb-0`}
								onClick={showAllPlayers}>
								Show all
							</p>
						</div>
						{hiddenPlayerRows}
					</div>
				) : (
					""
				)}
			</div>
		);
	};
	return (
		<div className="font-montserrat group">
			<Row>
				<Col flex="auto">
					<p className="text-lg font-semibold mr-4">Heatmaps (Coordinates)</p>
				</Col>

				<Col>
					<Dropdown overlay={<FilterCoordinates />} trigger={["click"]} placement="bottomCenter">
						<Button className="mr-1">
							Filter <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
			</Row>
			<div>
				{state.charts.chartDataLoading || !hasCoordinateHeatMapData(directFlatChart, state) ? (
					<NoDataCard />
				) : (
					<div className="flex flex-wrap">
						<PlayerCoordinateHeatMapCard
							data={directFlatChart}
							visiblePlayers={visiblePlayers}
							visibleWidgets={visibleXYWidgets}
						/>
					</div>
				)}
			</div>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default CoordinateHeatMapCard;
