import moment from "moment";
import { message } from "antd";
import { rawStatsToCSV, directStatsToCSV } from "../../engines/dataEngine";

import * as analyticsUtil from "../../util/analyticsUtil";
export const create = async ({ actions, effects, state }, stat) => {
	try {
		let currentChart = state.charts.all[state.charts.currentChartId];
		let recordingPlayer = state.players.allPlayers.filter(
			(player) => player.playerId === state.stats.recordingPlayerId
		)[0];
		let dateCreated = state.charts.chartDataRecordingDate !== "" ? state.charts.chartDataRecordingDate : moment();

		if (recordingPlayer) {
			let payload = {
				statList: [
					{
						chartId: currentChart.chartId,
						playerId: recordingPlayer.playerId,
						isGrouped: currentChart.isGrouped,
						dateCreated: dateCreated,
						statData: [
							{
								widgetId: stat.widgetId,
								widgetName: stat.widgetName,
								widgetType: stat.widgetType,
								value: stat.value,
							},
						],
					},
				],
			};
			setCurrentStats(state, currentChart, payload);
			analyticsUtil.statAddedEvent(
				stat.widgetType,
				state.charts.currentChartId,
				state.organization.organization.organizationId,
				state.organization.organization.title
			);
			await effects.stats.api
				.create(payload)
				.then((createRes) => {
					const statId = createRes;

					payload = {
						...payload,
						statId: statId,
						isFlagged: false,
					};

					let index = state.stats.currentStats[currentChart.chartId].findIndex(
						(obj) => obj.statList[0].dateCreated.toISOString() === dateCreated.toISOString()
					);
					state.stats.currentStats[currentChart.chartId].splice(index, 1);

					setCurrentStats(state, currentChart, payload);
				})
				.catch(() => {
					message.error("Error adding stat. Please try again.");
					let index = state.stats.currentStats[currentChart.chartId].findIndex(
						(obj) => obj.statList[0].dateCreated.toISOString() === dateCreated.toISOString()
					);
					state.stats.currentStats[currentChart.chartId].splice(index, 1);
				});
			actions.stats.setNonLoadedStats(state.stats.nonLoadedStats + 1);
		} else {
			message.error("Stat not recorded. Please select a player.");
		}
	} catch (error) {
		throw new Error(error);
	}
};

export const deleteStat = async ({ effects, state }, stat) => {
	try {
		analyticsUtil.statDeletedEvent(
			state.organization.organization.organizationId,
			"Record Stats",
			state.organization.organization.title
		);

		await effects.stats.api.delete({ statId: stat.statId });
	} catch (error) {
		throw new Error(error);
	}
};

export const removeStatFromHistoryRow = async ({ state }, statId) => {
	let currentChart = state.charts.all[state.charts.currentChartId];
	let index = await state.stats.currentStats[currentChart.chartId].findIndex((obj) => {
		return obj.statId.statId == statId;
	});

	await state.stats.currentStats[currentChart.chartId].splice(index, 1);
};

const setCurrentStats = (state, currentChart, payload) => {
	if (state.stats.currentStats[currentChart.chartId]) {
		state.stats.currentStats[currentChart.chartId].unshift(payload);
	} else {
		state.stats.currentStats[currentChart.chartId] = [payload];
	}
};

export const setRecordingPlayerId = ({ state }, newValue) => {
	state.stats.recordingPlayerId = newValue;
};

export const setActiveGroup = ({ state }, value) => {
	if (value.value !== undefined) {
		state.stats.activeGroup[value.widgetName] = value;
	} else {
		delete state.stats.activeGroup[value.widgetName];
	}
};

export const deleteFromActiveGroup = ({ state }, widget) => {
	delete state.stats.activeGroup[widget];
};

export const clearActiveGroup = ({ state }) => {
	state.stats.activeGroup = {};
};

export const addGroupedStats = async ({ actions, effects, state }) => {
	try {
		let currentChart = state.charts.all[state.charts.currentChartId];
		let recordingPlayer = state.players.allPlayers.filter(
			(player) => player.playerId === state.stats.recordingPlayerId
		)[0];
		let dateCreated = state.charts.chartDataRecordingDate !== "" ? state.charts.chartDataRecordingDate : moment();
		let widgetTypes = [];
		let allStats = [];
		Object.keys(state.stats.activeGroup).forEach((key) => {
			widgetTypes.push(state.stats.activeGroup[key].widgetType);
			allStats.push(state.stats.activeGroup[key]);
		});

		let payload = {
			statList: [
				{
					chartId: currentChart.chartId,
					playerId: recordingPlayer.playerId,
					isGrouped: currentChart.isGrouped,
					dateCreated: dateCreated,
					statData: allStats,
				},
			],
		};

		setCurrentStats(state, currentChart, payload);
		analyticsUtil.statAddedEvent(
			widgetTypes,
			state.charts.currentChartId,
			state.organization.organization.organizationId,
			state.organization.organization.title
		);
		await effects.stats.api
			.create(payload)
			.then((createRes) => {
				const statId = createRes;

				payload = {
					...payload,
					statId: statId,
				};
				let index = state.stats.currentStats[currentChart.chartId].findIndex(
					(obj) => obj.statList[0].dateCreated.toISOString() === dateCreated.toISOString()
				);
				state.stats.currentStats[currentChart.chartId].splice(index, 1);

				setCurrentStats(state, currentChart, payload);

				// reset the active group
				state.stats.activeGroup = {};
			})
			.catch(() => {
				message.error("Error adding stat. Please try again.");
				let index = state.stats.currentStats[currentChart.chartId].findIndex(
					(obj) => obj.statList[0].dateCreated.toISOString() === dateCreated.toISOString()
				);
				state.stats.currentStats[currentChart.chartId].splice(index, 1);
			});
		actions.stats.setNonLoadedStats(state.stats.nonLoadedStats + 1);
		return true;
	} catch (error) {
		throw new Error(error);
	}
};

export const updateStatFlagged = async ({ state, effects }, payload) => {
	try {
		if (payload.isFlagged) {
			const isGrouped = state.charts.all[state.charts.currentChartId].isGrouped;
			analyticsUtil.statFlaggedEvent(
				state.organization.organization.organizationId,
				isGrouped,
				state.organization.organization.title
			);
		}

		await effects.stats.api.update(payload);

		return true;
	} catch (error) {
		throw new Error(error);
	}
};

export const updateCurrentStatsFlagged = async ({ state }, payload) => {
	try {
		state.stats.currentStats[state.charts.currentChartId].filter(
			(stat) => stat.statId === payload.statId
		)[0].isFlagged = payload.isFlagged;
		return true;
	} catch (error) {
		throw new Error(error);
	}
};

export const setFlaggedFilter = async ({ state }, isFlagged) => {
	state.stats.flaggedFilter = isFlagged;
};

export const clearRawStatsPageState = ({ state }) => {
	state.stats.rawStatsPageStats = {};
	state.stats.rawStatsPageDates = [];
	state.stats.rawStatsCsvString = "";
};

// TODO this will be outdated when we move to the new dataViews
export const setRawStatsCsvString = ({ state, actions }, newVal) => {
	state.stats.rawStatsCsvString = rawStatsToCSV(state, actions, newVal);
};

export const setDirectStatsCsvString = ({ state, actions }, newVal) => {
	state.stats.directStatsCsvString = directStatsToCSV(state, actions, newVal.obj, newVal.chartId);
};

export const setSprayChartSearch = ({ state }, newVal) => {
	state.stats.sprayChartSearch = newVal;
};

export const setHeatMapSearch = ({ state }, newVal) => {
	state.stats.heatMapSearch = newVal;
};

export const setCoordinateHeatMapSearch = ({ state }, newVal) => {
	state.stats.coordinateHeatMapSearch = newVal;
};

export const setNonLoadedStats = ({ state }, newVal) => {
	state.stats.nonLoadedStats = newVal;
};

export const setPlayerFilter = ({ state }, newVal) => {
	state.stats.playerFilter = newVal;
};

export const setEditingStatModal = ({ state }, newVal) => {
	state.stats.editingStatModal = newVal;
};

export const setStatToEditId = ({ state }, newVal) => {
	state.stats.statToEditId = newVal;
};

export const editStat = async ({ state, effects }, statToEdit) => {
	try {
		// await effects.stats.api.update(payload);
		let updatedStatData = Object.values(state.stats.activeGroup);

		await effects.stats.api.update({
			statId: statToEdit.statId,
			statData: updatedStatData,
			dateCreated: statToEdit.dateCreated,
		});

		return true;
	} catch (error) {
		throw new Error(error);
	}
};
