export const TimeFrames = {
	DAILY: "DAILY",
	WEEKLY: "WEEKLY",
	MONTHLY: "MONTHLY",
	SEASON: "SEASON",
	CUSTOM: "CUSTOM",
};

export const TimeFramesReadable = {
	DAILY: "Today",
	WEEKLY: "This week",
	MONTHLY: "This month",
	SEASON: "This season",
	CUSTOM: "Custom",
};
