import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { IoAddCircle, IoClose } from "react-icons/io5";
import { useAppState, useActions } from "../../../api";

const RecordingPlayer = ({ player, sug, index }) => {
	const { playerId, name, number } = player;
	const active = "bg-blue rounded text-white";
	const state = useAppState();
	const actions = useActions();
	const btnClass = state.stats.recordingPlayerId === playerId ? active : "";

	//#region Actions -------------------------------------------------------------------------------------------------------------
	const handlePlayerClick = async (e) => {
		actions.stats.setRecordingPlayerId(e.target.id);
		actions.players.setRecordingPlayerSearch("");
	};
	//#endregion Actions -------------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------
	return (
		<Draggable draggableId={playerId} index={index} isDragDisabled={state.players.recordingPlayerSearch.length > 0}>
			{(provided) => (
				<div
					className={
						"mb-2 pl-1 rounded w-full cursor-pointer bg-white h-8 shadow flex items-center" +
						" " +
						btnClass +
						" " +
						(sug === "Add" ? "bg-green-100 shadow" : sug === "Delete" ? "bg-red-100 shadow" : null)
					}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
					onClick={handlePlayerClick}
					id={playerId}>
					{sug === "Add" ? (
						<IoAddCircle className="inline text-green-500 w-2/12 mr-1 text-xl" />
					) : sug === "Delete" ? (
						<IoClose className="inline text-red-500 w-2/12 mr-1 text-xl" />
					) : null}
					<div
						className={
							"w-3/12 truncate overflow-hidden " +
							(state.stats.recordingPlayerId === playerId
								? "border-r border-white"
								: "border-r border-black")
						}>
						<h1
							className={`text-center text-xs truncate m-0 ${btnClass}`}
							onClick={handlePlayerClick}
							id={playerId}>
							{number ? number : "-"}
						</h1>
					</div>
					<div className="w-9/12 pr-1">
						<h1
							className={`text-left text-xs truncate m-0 ml-1 ${btnClass}`}
							onClick={handlePlayerClick}
							id={playerId}>
							{name}
						</h1>
					</div>
				</div>
			)}
		</Draggable>
	);
	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default RecordingPlayer;
