import React, { useEffect, useState } from "react";
import { analytics } from "../../firebase/firebase";
import RecordingToolsRows from "../../components/widgets/recordingTools/RecordingToolsRows.jsx";
import RecordingPlayerRows from "../../components/players/recording/RecordingPlayerRows";
import StatHistoryContainer from "../../components/stats/StatHistoryContainer";
import moment from "moment";
import EditPlayersModal from "../../components/players/edit/EditPlayersModal";
import EditWidgetsModal from "../../components/widgets/edit/EditWidgetsModal";
import { useAppState, useActions } from "../../api";
import { CheckCircleOutlined, FileExclamationOutlined, DownOutlined } from "@ant-design/icons";
import { Modal, Button, Row, Col, Alert, Dropdown, Menu, Input } from "antd";

const Chart = () => {
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isPlayerModalVisible, setIsPlayerModalVisible] = useState(false);

	useEffect(() => {
		analytics.logEvent("taking_stats");
		actions.stats.setRecordingPlayerId("");
		actions.charts.setChartDataRecordingDate("");
		actions.charts.setChartDateLastUsed(moment());
	}, [actions.charts, actions.stats]);

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const searchOnChange = (e) => {
		actions.players.setRecordingPlayerSearch(e.target.value);
	};

	const setSort = (val) => {
		actions.players.setPlayerSortVal(val.key);
	};

	const menu = (
		<Menu>
			<Menu.Item onClick={setSort} key="name">
				a-z
			</Menu.Item>
			<Menu.Item onClick={setSort} key="number">
				1-100
			</Menu.Item>
			<Menu.Item onClick={setSort} key="none">
				Remove sort
			</Menu.Item>
		</Menu>
	);

	return (
		<>
			<EditPlayersModal
				isModalVisible={isPlayerModalVisible}
				setIsModalVisible={setIsPlayerModalVisible}
				editing={true}
			/>
			<EditWidgetsModal editing={true} />
			<Modal
				title="Please Select A player!"
				visible={isModalVisible}
				onCancel={handleCancel}
				centered
				footer={[
					<Button type="primary" key="ok" onClick={handleOk}>
						Ok
					</Button>,
				]}>
				<p>Please select which player this stat is supposed to be for.</p>
			</Modal>
			<Row className="mt-1 border-b border-gray-300">
				<Col xs={24} md={9}>
					<StatHistoryContainer />
					{state.stats.recordingPlayerId === "" ? (
						<Alert message="Select a player to record data for them" closable type="warning" />
					) : null}
				</Col>
				<Col xs={17} md={10} className="pl-1 border-r border-gray-300 pb-1">
					<div className="h-100 overflow-y-auto pt-2">
						<div className="flex-1 h-12/12 pr-2 pl-1">
							{currentChart.widgets.length > 0 ? (
								<RecordingToolsRows chart={currentChart} isGrouped={currentChart.isGrouped} />
							) : (
								<>
									<div className="text-center">
										<FileExclamationOutlined className="text-3xl" />
										<p className="mb-0">No tagging tools. Edit the chart to add tools.</p>
									</div>
								</>
							)}
						</div>
					</div>
					{currentChart.isGrouped ? (
						<div className="mx-1 mr-2 shadow">
							<Button
								disabled={
									(state.stats.activeGroup && // 👈 null and undefined check
										Object.keys(state.stats.activeGroup).length === 0 &&
										state.stats.activeGroup.constructor === Object) ||
									state.stats.recordingPlayerId === ""
								}
								type="primary"
								icon={<CheckCircleOutlined />}
								style={{ width: "100%" }}
								onClick={() => actions.stats.addGroupedStats()}>
								Save Group
							</Button>
						</div>
					) : (
						""
					)}
				</Col>
				<Col xs={7} md={5} className="pl-1">
					<div className="h-100 overflow-y-auto">
						<div className="flex-1 h-12/12">
							<div className="border-b border-gray-400 text-center">
								<Input allowClear onChange={searchOnChange} placeholder="Search" />
								<div className="my-2">
									<Dropdown overlay={menu} placement="bottom" trigger={["click"]} onChange={setSort}>
										<a onClick={(e) => e.preventDefault()}>
											Sort
											<DownOutlined />
										</a>
									</Dropdown>
								</div>
							</div>
							{currentChart.players.length > 0 ? (
								<RecordingPlayerRows />
							) : (
								<div className="text-center mt-2">
									<FileExclamationOutlined className="text-3xl" />
									<p>No players. Edit the chart to add players</p>
								</div>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default Chart;
