import Chart from "../models/chart";
import {
	strikeWidget,
	pitchLocationWidget,
	pitchTypeWidget,
	hitResultWidget,
	fieldLocationWidget,
	veloWidget,
	pitcherWidget,
	frontHandWidget,
	backHandWidget,
	goodThrowWidget,
	popTimeWidget,
	goodReceiveWidget,
	homeToFirstWidget,
	stolenBaseWidget,
} from "./baseball/baseballWidgets";
import { exerciseWidget, weightWidget, toFailureWidget } from "./exercise/exerciseWidgets";
import {
	goalWidget,
	shotOnGoalWidget,
	assistWidget,
	takeawayWidget,
	gbWidget,
	turnoverWidget,
	goalieSaveWidget,
	faceoffWidget,
	drawWidget,
} from "./lacrosse/lacrosseWidgets";
import {
	pointWidget,
	killsWidget,
	errorWidget,
	attemptWidget,
	volleyballAssistWidget,
	serviceAceWidget,
	serviceErrorWidget,
	digWidget,
	recieveErrorWidget,
	blockWidget,
	blockAssistWidget,
	blockSoloWidget,
	blockErrorWidget,
	zoneServeWidget,
	zoneHitWidget,
	positionSetWidget,
	setTempoWidget,
	passGradeWidget,
} from "./volleyball/volleyballWidgets";

const menLacrosseChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Men's Lacrosse Stats",
	isGrouped: false,
	includesContext: false,
	widgets: [
		goalWidget,
		shotOnGoalWidget,
		assistWidget,
		takeawayWidget,
		gbWidget,
		turnoverWidget,
		goalieSaveWidget,
		faceoffWidget,
	],
};
export const menLacrosseChart = new Chart(menLacrosseChartInput);

const womenLacrosseChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Women's Lacrosse Stats",
	isGrouped: false,
	includesContext: false,
	widgets: [
		goalWidget,
		shotOnGoalWidget,
		assistWidget,
		takeawayWidget,
		gbWidget,
		turnoverWidget,
		goalieSaveWidget,
		drawWidget,
	],
};
export const womenLacrosseChart = new Chart(womenLacrosseChartInput);

const pitchingChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Pitching",
	isGrouped: true,
	includesContext: false,
	widgets: [pitchTypeWidget, strikeWidget, pitchLocationWidget, veloWidget, hitResultWidget, fieldLocationWidget],
};
export const pitchingChart = new Chart(pitchingChartInput);

const hittingChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Hitting",
	isGrouped: true,
	includesContext: false,
	widgets: [
		pitcherWidget,
		pitchTypeWidget,
		strikeWidget,
		pitchLocationWidget,
		veloWidget,
		hitResultWidget,
		fieldLocationWidget,
	],
};
export const hittingChart = new Chart(hittingChartInput);

const fieldingChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Fielding",
	isGrouped: false,
	includesContext: false,
	widgets: [frontHandWidget, backHandWidget, goodThrowWidget],
};
export const fieldingChart = new Chart(fieldingChartInput);

const catchingChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Catching",
	isGrouped: true,
	includesContext: false,
	widgets: [pitchLocationWidget, goodReceiveWidget, popTimeWidget],
};
export const catchingChart = new Chart(catchingChartInput);

const baserunningChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Baserunning",
	isGrouped: false,
	includesContext: false,
	widgets: [homeToFirstWidget, stolenBaseWidget],
};
export const baserunningChart = new Chart(baserunningChartInput);

const weightLiftingChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Weight Lifting",
	isGrouped: true,
	includesContext: false,
	widgets: [exerciseWidget, weightWidget, toFailureWidget],
};
export const weightLiftingChart = new Chart(weightLiftingChartInput);

const volleyballGameChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Volleyball Game",
	isGrouped: false,
	includesContext: false,
	widgets: [
		pointWidget,
		killsWidget,
		errorWidget,
		attemptWidget,
		volleyballAssistWidget,
		serviceAceWidget,
		serviceErrorWidget,
		digWidget,
		recieveErrorWidget,
		blockWidget,
		blockAssistWidget,
		blockSoloWidget,
		blockErrorWidget,
		zoneServeWidget,
		zoneHitWidget,
		positionSetWidget,
		setTempoWidget,
		passGradeWidget,
	],
};
export const volleyballGameChart = new Chart(volleyballGameChartInput);

const volleyballBlocksChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Volleyball Blocks",
	isGrouped: false,
	includesContext: false,
	widgets: [blockWidget, blockAssistWidget, blockSoloWidget, blockErrorWidget],
};
export const volleyballBlocksChart = new Chart(volleyballBlocksChartInput);

const volleyballOffenseChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Volleyball Offense",
	isGrouped: false,
	includesContext: false,
	widgets: [
		pointWidget,
		killsWidget,
		errorWidget,
		attemptWidget,
		volleyballAssistWidget,
		serviceAceWidget,
		serviceErrorWidget,
		zoneServeWidget,
		zoneHitWidget,
		positionSetWidget,
		setTempoWidget,
		passGradeWidget,
	],
};
export const volleyballOffenseChart = new Chart(volleyballOffenseChartInput);

const volleyballDefenseChartInput = {
	creatorId: "YourFriendsAtTapp",
	title: "Volleyball Defense",
	isGrouped: false,
	includesContext: false,
	widgets: [digWidget, recieveErrorWidget, blockWidget, blockAssistWidget, blockSoloWidget, blockErrorWidget],
};
export const volleyballDefenseChart = new Chart(volleyballDefenseChartInput);

export const allCharts = [
	pitchingChart,
	hittingChart,
	fieldingChart,
	catchingChart,
	baserunningChart,
	weightLiftingChart,
	menLacrosseChart,
	womenLacrosseChartInput,
	volleyballDefenseChart,
	volleyballBlocksChart,
	volleyballOffenseChart,
	volleyballGameChart,
];

export const baseballCharts = [
	pitchingChart,
	hittingChart,
	fieldingChart,
	catchingChart,
	baserunningChart,
	weightLiftingChart,
];

export const softballCharts = [
	pitchingChart,
	hittingChart,
	fieldingChart,
	catchingChart,
	baserunningChart,
	weightLiftingChart,
];

export const lacrosseCharts = [menLacrosseChart, womenLacrosseChartInput, weightLiftingChart];

export const volleyballCharts = [
	volleyballDefenseChart,
	volleyballBlocksChart,
	volleyballOffenseChart,
	volleyballGameChart,
	weightLiftingChart,
];
