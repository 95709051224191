import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTable, useSortBy, useFlexLayout, useRowSelect } from "react-table";
import { generateTableStructure } from "../../engines/tableEngine";
import { useAppState, useActions } from "../../api";
import { LoadingOutlined } from "@ant-design/icons";

export default function SnapTable({ tableChart, allWidgets, noApi }) {
	const state = useAppState();
	const actions = useActions();
	const [data, setData] = useState([]);
	const [loadingData, setLoadingData] = useState(false);
	const [localColumns, setLocalColumns] = useState([]);
	const [chartUsing, setChartUsing] = useState(tableChart);

	const getChartData = async () => {
		setLoadingData(true);
		setLocalColumns(
			generateTableStructure(
				state.charts.all[state.charts.currentChartId],
				allWidgets,
				state.user.positiveColor,
				state.user.negativeColor
			)
		);
		if (!noApi) {
			await actions.charts.getChartData([moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]);
			setData(state.charts.all[state.charts.currentChartId].chartData);
		}
		setLoadingData(false);
	};

	useEffect(() => {
		setLoadingData(true);
		setLocalColumns(
			generateTableStructure(chartUsing, allWidgets, state.user.positiveColor, state.user.negativeColor)
		);
		if (chartUsing && chartUsing.chartData.length > 0) {
			setData(chartUsing.chartData);
		} else {
			setChartUsing(state.charts.all[state.charts.currentChartId]);
			getChartData();
		}

		setLoadingData(false);
	}, []); //review

	const cellProps = (props, { cell }) => getStyles(props, cell.column.align);
	const getStyles = (props, align = "left") => [
		props,
		{
			style: {
				justifyContent: align === "right" ? "flex-end" : "flex-start",
				alignItems: "flex-start",
				display: "flex",
			},
		},
	];

	const defaultColumn = React.useMemo(
		() => ({
			// When using the useFlexLayout:
			minWidth: 30, // minWidth is only used as a limit for resizing
			width: 45, // width is used for both the flex-basis and flex-grow
			maxWidth: 200, // maxWidth is only used as a limit for resizing
		}),
		[]
	);

	const { headerGroups, rows, prepareRow } = useTable(
		{
			columns: localColumns ? localColumns : [],
			data,
			defaultColumn,
			initialState: {
				sortBy: [
					{
						id: "Total_PCT",
						desc: false,
					},
				],
			},
			disableSortBy: true, //this disables click header to sort
		},
		useSortBy,
		useFlexLayout,
		useRowSelect,
		(hooks) => {
			hooks.allColumns.push((columns) => [...columns]);
		}
	);

	return (
		<div className="rounded print-container bg-white w-full overflow-auto">
			{loadingData ? (
				<div className="m-4 text-center">
					<LoadingOutlined className="text-5xl my-2" />
					<h1>Loading Data...</h1>
				</div>
			) : data.length > 0 ? (
				<>
					<div>
						{headerGroups.map((headerGroup) => (
							<div {...headerGroup.getHeaderGroupProps({})} className="tr">
								{headerGroup.headers.map((column) => (
									<div
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className="th border border-gray-300 py-2 text-center w-14 truncate bg-gray-100">
										{column.render("Header")}
									</div>
								))}
							</div>
						))}
					</div>
					<div className="tbody">
						{rows.map((row, rowIndex) => {
							prepareRow(row);
							return (
								<div {...row.getRowProps()} className="tr">
									{row.cells.map((cell) => {
										return (
											<div
												{...cell.getCellProps(cellProps)}
												className={
													"td p-2 border border-gray-300 truncate " +
													(rowIndex === 0
														? "bg-yellow-200"
														: rowIndex % 2 === 0
														? "bg-white"
														: "bg-white")
												}>
												<span>{cell.render("Cell")}</span>
											</div>
										);
									})}
								</div>
							);
						})}
					</div>
				</>
			) : (
				<div className="m-4 text-center">
					<LoadingOutlined className="text-5xl my-2" />
					<h1>Cracking military-grade encryption...</h1>
				</div>
			)}
		</div>
	);
}
