import React, { useState } from "react";
import { useAppState, useActions } from "../../api";
import { Modal, Button, message, Alert, Input, Radio, Space } from "antd";

const ChoosePlayerModal = () => {
	const state = useAppState();
	const actions = useActions();
	const [selectedPlayerId, setSelectedPlayerId] = useState(0);
	const [searchVal, setSearchVal] = useState("");
	const [loading, setLoading] = useState(false);

	const handleRadioChange = (e) => {
		setSelectedPlayerId(e.target.value);
	};

	const handleJoinSubmit = async () => {
		try {
			setLoading(true);
			await actions.user.createUser({
				name: state.user.userInputSignupInfo.name,
				email: state.user.userInputSignupInfo.email,
				password: state.user.userInputSignupInfo.password,
				userContext: [
					{
						organizationId: state.user.joinOrg.organizationId,
						userRoles: ["PLAYER"],
						playerId: selectedPlayerId,
					},
				],
			});
			await actions.user.loginUser({
				email: state.user.userInputSignupInfo.email,
				password: state.user.userInputSignupInfo.password,
			});

			history.push("/dashboard");

			actions.user.setShowChoosePlayerModal(false);
		} catch (error) {
			message.error(error.message);
			setLoading(false);
		}
	};

	const handleCancel = () => {
		actions.user.setShowChoosePlayerModal(false);
	};

	const handleSearchChange = (e) => {
		setSearchVal(e.target.value);
	};

	return (
		<Modal
			title="Choose your name"
			visible={state.user.showChoosePlayerModal}
			onCancel={handleCancel}
			footer={[
				<Button onClick={handleCancel}>Cancel</Button>,
				<Button disabled={!selectedPlayerId} type="primary" onClick={handleJoinSubmit} loading={loading}>
					Create account
				</Button>,
			]}>
			{state.user.joinOrg.players ? (
				<>
					<Input placeholder="Search names" onChange={handleSearchChange} allowClear />
					<div className="my-6">
						<Radio.Group onChange={handleRadioChange} value={selectedPlayerId}>
							<Space direction="vertical">
								{state.user.joinOrg.players
									.filter((player) => player.name.toLowerCase().includes(searchVal.toLowerCase()))
									.map((player) => (
										<Radio value={player.playerId}>{player.name}</Radio>
									))}
							</Space>
						</Radio.Group>
					</div>
					<p className="text-xs">
						Name not on the list? Have your coach add you to the team and check back again!
					</p>
				</>
			) : (
				<Alert message="Your team does not have any players yet. Have your coach add players to the team and check back again!" />
			)}
		</Modal>
	);
};

export default ChoosePlayerModal;
