import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useActions, useAppState } from "../../api";
import {
	EllipsisOutlined,
	EditOutlined,
	UserOutlined,
	LineOutlined,
	MenuOutlined,
	ToolOutlined,
	BarChartOutlined,
	PlusCircleOutlined,
} from "@ant-design/icons";
import DeleteChartMenuItem from "./DeleteChartMenuItem";
import { Row, Col, Card, Button, Menu, Dropdown } from "antd";

const MobileDashboardChart = ({ chartId, chartName, lastUsed, isGrouped, widgets, players }) => {
	// ^review to pass in chart instead of fields
	const history = useHistory();
	const actions = useActions();
	const state = useAppState();

	//#region HANDLERS ------------------------------------------------------------------------------------------------------

	const navigateToTake = async () => {
		await actions.charts.setCurrentChartId(chartId);
		await actions.charts.updateChartStateValue({ property: state.charts.setMobileEditing, newVal: false });
		history.push("/take");
	};

	const navigateToView = async () => {
		await actions.charts.setCurrentChartId(chartId);
		history.push("/view");
	};

	const navigateToEdit = async () => {
		await actions.charts.setCurrentChartId(chartId);
		await actions.charts.updateChartStateValue({ property: state.charts.setMobileEditing, newVal: true });
		history.push("/edit");
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<Card bodyStyle={{ padding: "0" }} className="shadow cursor-pointer w-full">
			<div className="m-3">
				<Row className="h-full" type="flex" align="middle" style={{ padding: "0", height: "100%" }}>
					<Col span={22} onClick={navigateToTake}>
						<div className="text-xs">
							{isGrouped ? (
								<span>
									<MenuOutlined className="inline" style={{ color: "#6163FF" }} />
									<p className="ml-1 inline text-coolPurple">Grouped</p>
								</span>
							) : (
								<span>
									<LineOutlined className="inline" style={{ color: "#F58A07" }} />
									<p className="ml-1 inline text-funkyGreen">Ungrouped</p>
								</span>
							)}
							<span className="ml-2 font-medium text-gray-400">
								<UserOutlined className="inline" style={{ color: "#9ca3af" }} />
								<p className="ml-1 inline">{players.length} players</p>
							</span>
							<span className="ml-2 font-medium text-gray-400">
								<ToolOutlined className="inline" style={{ color: "#9ca3af" }} />
								<p className="ml-1 inline">{widgets.length} widgets</p>
							</span>
						</div>
						<h1 className="font-semibold my-1">{chartName}</h1>
						<p className="mb-0 text-gray-400 text-xs">
							{lastUsed ? moment(lastUsed).fromNow() : moment().fromNow()}
						</p>
					</Col>
					<Col span={2} style={{ textAlign: "right" }}>
						<Dropdown
							overlay={
								<Menu>
									<Menu.Item icon={<BarChartOutlined />} onClick={navigateToView}>
										View Data
									</Menu.Item>
									<Menu.Divider />
									<Menu.Item icon={<PlusCircleOutlined />} onClick={navigateToTake}>
										Track Stats
									</Menu.Item>
									<Menu.Divider />
									<Menu.Item icon={<EditOutlined />} onClick={navigateToEdit}>
										Edit Chart
									</Menu.Item>
									<Menu.Divider />
									<DeleteChartMenuItem chartId={chartId} />
								</Menu>
							}
							trigger={["click"]}>
							<Button shape="circle" icon={<EllipsisOutlined />} />
						</Dropdown>
					</Col>
				</Row>
			</div>
		</Card>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default MobileDashboardChart;
