import React from "react";
import axios from "axios";
import { auth } from "./firebase/firebase";
import { useActions } from "./api";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import AppRouter from "./views/router/Router";
import httpStatusCodes from "./constants/httpStatusCodes";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
import { message } from "antd";
import { logout } from "./helpers/auth";
const history = createBrowserHistory({ forceRefresh: true }); //need the force refesh to actually change the view
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
	const actions = useActions();

	const getData = async () => {
		await getAuthToken();
		try {
			await actions.global.setLoading(true);
			await actions.tags.getAllTags();
			await actions.teams.getAllTeams();
			await actions.charts.getAll();
			await actions.widgets.getAll();
			await actions.players.getAllPlayers();
			await actions.charts.clearCurrentChart();
			await actions.charts.clearNewChart();
			await actions.user.getUser();
			await actions.organization.getOrganization();
			await actions.global.setLoading(false);
			await actions.onboarding.setShowInfoModal(true);
		} catch (err) {
			await actions.global.setLoading(false);
			await actions.global.setError(err);
		}
	};

	const getAuthToken = async () => {
		if (auth().currentUser) {
			try {
				const resId = await auth().currentUser.getIdToken(true);
				axios.defaults.headers.common["Authorization"] = `Bearer ${resId}`;
			} catch (e) {
				console.error("error auth", e);
			}
		}
	};

	//interceptor for post requests
	axios.interceptors.request.use(
		async (req) => {
			await actions.user.setAppLoading(true); // not sure if anyone is using this state
			//TODO if it does bomb, it should delete and re-add the stat
			await getAuthToken();
			return req;
		},
		(err) => {
			return Promise.reject(err);
		}
	);

	axios.interceptors.response.use(
		async (res) => {
			// spinning hide
			// UPDATE: Add this code to hide global loading indicator
			await actions.user.setAppLoading(false); // not sure if anyone is using this state

			return res;
		},
		async function (err) {
			const statusCode = err.response.status;
			if (statusCode === httpStatusCodes.UNAUTHORIZED) {
				if (
					err.response.data.error ===
					"BAD SUBSCRIPTION STATUS! This organization does not have permission to perform this action."
				) {
					if (window.location.pathname !== "/expired") {
						await logout();
						history.push("/expired");
					}
				} else {
					message.error("Sorry, you are not allowed to perform this action.");
				}
				return Promise.resolve();
			}
			return Promise.reject(err);
		}
	);

	/**
	 * Review, I don't think this works
	 */
	const getTokenAvoidSignout = async () => {
		await actions.global.setAuthLoading(true);
		const res = await auth().currentUser.getIdToken();
		const FBIdToken = `Bearer ${res}`;
		localStorage.setItem("FBIdToken", FBIdToken);
		axios.defaults.headers.common["Authorization"] = FBIdToken;
		await actions.global.setAuthLoading(false);
	};

	/**
	 * Review, changing the authenticated state can cause the app to "bounce" between auth and unauth views
	 */
	auth().onAuthStateChanged(async (user) => {
		if (user) {
			await getTokenAvoidSignout(); // TODO I don't think this actually works
			await actions.user.setAuthenticated(true);
			await getData();
		} else {
			await actions.user.setAuthenticated(false);
			await actions.global.setAuthLoading(false);
		}
	});

	return (
		<Router history={history}>
			<AppRouter history={history} />
		</Router>
	);
};

export default App;
