import React, { useRef } from "react";
import moment from "moment";
import CustomTable from "./Table";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { CSVLink } from "react-csv";
import { generateTableStructure, convertColumnNameToHumanReadable } from "../../engines/tableEngine";
import { useAppState, useActions } from "../../api";
import {
	ReloadOutlined,
	LoadingOutlined,
	EyeOutlined,
	EyeInvisibleOutlined,
	PrinterOutlined,
	CameraOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import { Col, Dropdown, Button, message, Menu, Row, Tooltip, Badge, Skeleton } from "antd";
import TemplateSelect from "./TemplateSelect";
import TemplatesModal from "./TemplatesModal";

const ChartDataTable = () => {
	const state = useAppState();
	const actions = useActions();
	const csvDownloadRef = useRef(null);
	const currentChart = state.charts.all[state.charts.currentChartId];

	const removeHiddenColumn = (column) => {
		actions.charts.removeChartViewHiddenColumn(column);
	};

	const clearHiddenColumns = () => {
		actions.charts.clearChartViewHiddenColumns();
	};

	// TODO need to figure out scrolling and dynamic sizing still
	const handlePrint = async () => {
		message.loading({ content: "Saving table image to downloads folder", key: "printLoadingMsg", duration: 5 });
		await actions.global.setIsPrinting(true);
		html2canvas(document.getElementById(`print-area`), { scrollY: -window.scrollY })
			.then((canvas) => {
				const imgData = canvas.toDataURL("image/png");
				const pdf = new jsPDF({
					orientation: "landscape",
				});
				const imgProps = pdf.getImageProperties(imgData);
				const pdfWidth = pdf.internal.pageSize.getWidth();
				const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
				pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
				pdf.save(
					`${currentChart.title}_table_${moment(state.charts.chartView.startDate).format(
						"YYYY-MM-DD"
					)}_${moment(state.charts.chartView.endDate).format("YYYY-MM-DD")}`
				);
			})
			.then(() => {
				message.destroy("printLoadingMsg");
				message.success("Saving complete.");
				actions.global.setIsPrinting(false);
			});
	};

	/**
	 * Be wary of calling this frequently, it shouldn't need to be called more than once per load
	 */
	const fetchChartData = async () => {
		await actions.charts.getChartData(state.charts.chartView);
	};

	const handleCsvDownload = async (event, done) => {
		message.loading({ content: "Saving data to downloads folder", key: "printLoadingMsg", duration: 3 });
		await actions.charts.updateChartStateValue({ property: "setTableCsvString", newVal: currentChart.chartData });
		done();
	};

	// review, need to restore snapshots
	const handleSnapshot = () => {
		// const mapId = await actions.snapshots.saveSnapshot({
		//   data: JSON.stringify({ currentChart: currentChart, widgets: state.widgets.all }),
		//   startDate: moment(state.charts.chartDataStartDate).format('YYYY-MM-DD HH:mm'),
		//   endDate: moment(state.charts.startDataEndDate).format('YYYY-MM-DD HH:mm'),
		//   authRequired: false
		// });
		// setMapIdUrl(`https://vizn-stats-dev.web.app/snaps?mapId=${mapId.organizationMapId}`);
		message.warning("Snapshot not currently available");
	};

	const handleRefreshDataClick = async () => {
		actions.stats.setNonLoadedStats(0);
		await fetchChartData();
	};

	const hiddenColumnMenu = (
		<Menu>
			{state.charts.chartView.hiddenColumns.map((hiddenColumn) => {
				return (
					<Menu.Item
						key={hiddenColumn}
						icon={<EyeOutlined />}
						onClick={() => removeHiddenColumn(hiddenColumn)}>
						{convertColumnNameToHumanReadable(hiddenColumn)}
					</Menu.Item>
				);
			})}
			<Menu.Divider />
			<Menu.Item key={"showAllColumns"} icon={<EyeOutlined />} onClick={() => clearHiddenColumns()}>
				Show all columns
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="m-0">
			<TemplatesModal />
			{/* <Card> */}
			{currentChart.widgets.length < 1 ? (
				<div>
					<h1 className="mb-0">Click "Edit Chart" to add categories and start recording data!</h1>
					<TemplateSelect />
				</div>
			) : (
				<>
					<Col className="group m-0 mt-2">
						<Row className="group" gutter={[10, 10]}>
							<Col flex="auto">
								<p className="text-lg font-semibold mb-0 inline p-0">Data table</p>
							</Col>
							<Col>
								<Dropdown
									overlay={hiddenColumnMenu}
									trigger={["click"]}
									disabled={
										state.charts.chartDataLoading || !state.charts.chartView.hiddenColumns.length
									}>
									<Tooltip
										trigger={!state.charts.chartView.hiddenColumns.length ? "hover" : "none"}
										title="No columns currently hidden. To hide a column, click on the header row and select hide from the menu.">
										<Button
											disabled={
												state.charts.chartDataLoading ||
												!state.charts.chartView.hiddenColumns.length
											}>
											Hidden Columns <EyeInvisibleOutlined />
										</Button>
									</Tooltip>
								</Dropdown>
							</Col>
							<Col>
								<Button
									id="snapshot"
									disabled={
										state.charts.chartDataLoading ||
										!currentChart.chartData ||
										!currentChart.chartData.length
									}
									icon={<CameraOutlined />}
									onClick={handleSnapshot}
								/>
							</Col>
							<Col>
								<Button
									id="print"
									disabled={
										state.charts.chartDataLoading ||
										!currentChart.chartData ||
										!currentChart.chartData.length
									}
									icon={<PrinterOutlined />}
									onClick={handlePrint}
								/>
							</Col>
							<Col>
								<CSVLink
									data={state.charts.tableCsvString}
									ref={csvDownloadRef}
									asyncOnClick={true}
									onClick={handleCsvDownload}
									filename={`${currentChart.title}_table_${moment(
										state.charts.chartView.startDate
									).format("YYYY-MM-DD")}_${moment(state.charts.chartView.endDate).format(
										"YYYY-MM-DD"
									)}.csv`}>
									<Button
										id="download"
										disabled={
											state.charts.chartDataLoading ||
											!currentChart.chartData ||
											!currentChart.chartData.length
										}
										icon={<DownloadOutlined />}
									/>
								</CSVLink>
							</Col>
							<Col>
								<Badge count={state.stats.nonLoadedStats} offset={[-30, 0]}>
									<Button
										id="reload"
										disabled={state.charts.chartDataLoading}
										icon={state.charts.chartDataLoading ? <LoadingOutlined /> : <ReloadOutlined />}
										onClick={handleRefreshDataClick}
									/>
								</Badge>
							</Col>
						</Row>
						{/* <Divider orientation="left" /> */}
						{/* Data table */}
						<Row>
							<div className="w-full overflow-x-auto my-2">
								{state.charts.chartDataLoading ? (
									<Skeleton />
								) : (
									<CustomTable
										chartData={currentChart.chartData}
										columns={generateTableStructure(
											currentChart,
											state.widgets.all,
											state.user.positiveColor,
											state.user.negativeColor
										)}
										columnActionsEnabled={true}
									/>
								)}
							</div>
						</Row>
					</Col>
				</>
			)}

			{/* </Card> */}
		</div>
	);
};

export default ChartDataTable;
