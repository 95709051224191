import React, { useEffect } from "react";
import { useAppState, useActions } from "../../api";
import moment from "moment";
import { heatMapAndSprayChartWidgetIds } from "../../engines/dataEngine";
import ChartDataTable from "./ChartDataTable";
import Edit from "../../pages/mobile/editChart";
import Take from "../../pages/mobile/take";
import HeatMapCard from "../dataViews/pitchLocation/HeatMapCard";
import SprayChartCard from "../dataViews/fieldLocation/SprayChartCard";
import DirectFlatTable from "../dataViews/DirectFlatTable";
import { Link } from "react-router-dom";
import { Col, message, Row, Button, Alert, Divider, Tabs } from "antd";
import TakeStats from "../../pages/desktop/takeStats";
import CustomQabTable from "./CustomQabTable";
import CoordinateHeatMapCard from "../dataViews/pitchLocation/CoordinateHeatMapCard";

const { TabPane } = Tabs;

const ChartData = () => {
	//#region HOOKS ---------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];

	const huskerQualityAtBatChartId = "kO5IIKaHF3VnZbakLB5W";
	const developmentQabChartId = "jK0evvLLxyBiIinlD7OR";

	useEffect(() => {
		getData();
	}, [state.charts.chartView.startDate, state.charts.chartView.endDate]);

	//#endregion HOOKS ------------------------------------------------------------------------------------------------------

	//#region DISPATCH - HANDLERS --------------------------------------------------------------------------------------------

	const getData = async () => {
		try {
			// clear raw stats page stats immediately to prevent delayed flicker of stats from previous stats
			actions.stats.clearRawStatsPageState();
			await actions.charts.getChartData(state.charts.chartView);
			await actions.dashboard.getDirectFlatData(false);
			// await actions.dashboard.getDirectNestedData();
			// TODO change functionality to automatically match data with the time range when you can view multiple players when backend implements it
			actions.widgets.clearComparisonStates();
		} catch (error) {
			message.error("Error getting stats");
		}
	};

	const handleEditClick = () => {
		actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: !state.charts.mobileEditing });
	};

	const handleHideClick = () => {
		actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: false });
		actions.charts.setShowRecord(false);
	};
	const handlePlayerClick = (playerId) => {
		actions.dashboard.setRequestChartIds([state.charts.currentChartId]);
		actions.dashboard.setRequestPlayersIds([playerId]);
	};

	const onChange = async (tab) => {
		if (tab === "playByPlay") {
			actions.charts.setShowDateRangePicker(true);
			await actions.dashboard.getDirectFlatData(false);
			await actions.dashboard.getDirectNestedData();
		} else if (tab === "aggregateData") {
			actions.charts.setShowDateRangePicker(true);
		} else if (tab === "recordStats") {
			actions.charts.setShowDateRangePicker(false);
		} else if (tab === "editChart") {
			actions.charts.setShowDateRangePicker(false);
		} else if (tab === "customQabChart") {
			actions.charts.setShowDateRangePicker(true);
		}
	};

	//#endregion DISPATCH ----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	const PlayerSelectors = (
		<Col span={24}>
			<p className="text-lg font-semibold">See Data Page For:</p>
			<Row>
				{currentChart.players.map((playerId) => {
					return (
						<Col className="mb-2">
							<Link
								className="bg-gray-100 mr-2 px-2 text-blue cursor-pointer hover:bg-gray-200 hover-shadow border border-gray-300"
								onClick={() => handlePlayerClick(playerId)}
								to={"/data"}>
								{actions.players.getPlayerById(playerId)?.name}
							</Link>
						</Col>
					);
				})}
			</Row>
			<Divider />
		</Col>
	);

	return (
		<div className="font-montserrat mb-5">
			<div className="fixed bottom-4 right-4 z-50">
				{state.charts.showRecord ? (
					<div className="rounded border border-coolPurple bg-gray-100 shadow-xl w-210 h-150 overflow-y-auto ">
						<div className="h-112 m-0 p-3">
							<div className="inline">
								<p className="inline font-bold mr-2">
									{state.charts.mobileEditing ? "Edit Chart" : currentChart.title}
								</p>
							</div>
							<div className="inline float-right mb-0">
								<div className="inline mr-2">
									{state.charts.mobileEditing ? (
										<Button size="small" onClick={handleEditClick}>
											Save
										</Button>
									) : (
										<p className="inline text-coolPurple cursor-pointer" onClick={handleEditClick}>
											Edit
										</p>
									)}
								</div>
								<p className="inline mb-0 text-coolPurple cursor-pointer" onClick={handleHideClick}>
									Hide
								</p>
							</div>
						</div>
						<div className="h-110 p-4">{state.charts.mobileEditing ? <Edit /> : <Take />}</div>
					</div>
				) : (
					""
				)}
			</div>
			{!moment(state.charts.chartView.endDate.format("YYYY-MM-DD")).isSameOrAfter(
				moment(new Date()).format("YYYY-MM-DD"),
				"day"
			) ? (
				<div className="lg:w-1/2 lg:m-auto lg:mb-2 mb-2">
					<Alert
						message="Heads up! You're not viewing data for the current day"
						type="warning"
						showIcon
						closable
					/>
				</div>
			) : null}
			{/* {currentChart.chartTagIds?.includes("d7ebb7fe-eb77-44ee-a4bb-0b607cd4d14d") ? ( // development opposing hitters chart */}
			{currentChart.chartTagIds?.includes("c322a1a8-9ac0-42dd-a6b0-df0d17da60bb") ? ( // PIUS opposing hitters tag id
				PlayerSelectors
			) : (
				<Col span={24}>
					<Tabs onChange={onChange} type="card">
						<TabPane tab="All Data" key="aggregateData">
							<Row className="mb-5">
								<Col span={24}>
									<ChartDataTable />
								</Col>
							</Row>
							{heatMapAndSprayChartWidgetIds(state).heatMap.length > 0 ? (
								<Row className="mb-5">
									<Col span={24}>
										<HeatMapCard />
									</Col>
								</Row>
							) : (
								""
							)}
							{heatMapAndSprayChartWidgetIds(state).sprayChart.length > 0 ? (
								<Row className="mb-5">
									<Col span={24}>
										<SprayChartCard />
									</Col>
								</Row>
							) : (
								""
							)}
							{heatMapAndSprayChartWidgetIds(state).xyPitchLoc.length > 0 ? (
								<Row className="mb-5">
									<Col span={24}>
										<CoordinateHeatMapCard />
									</Col>
								</Row>
							) : (
								""
							)}
						</TabPane>
						<TabPane tab="Play by Play" key="playByPlay">
							<Row className="h-full" gutter={[20, 20]}>
								<DirectFlatTable />
							</Row>
						</TabPane>
						<TabPane tab="Record" key="recordStats">
							<TakeStats />
						</TabPane>
						<TabPane tab="Edit Chart" key="editChart">
							<div className="w-5/6 m-auto">
								<Edit />
							</div>
						</TabPane>
						{currentChart.chartId == huskerQualityAtBatChartId ||
						currentChart.chartId == developmentQabChartId ? (
							<TabPane tab="Custom QAB Chart" key="customQabChart">
								<div className="w-5/6 m-auto">
									<CustomQabTable />
								</div>
							</TabPane>
						) : (
							<></>
						)}
					</Tabs>
				</Col>
			)}
		</div>
	);

	//#endregion JSX -----------------------------------------------------------------------------------------------------------
};

export default ChartData;
