import React from "react";

export const PosNegNeutralMini = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	let colorChangerPos =
		value !== undefined
			? `${value === 1 ? "bg-gray-400 text-white" : " text-gray-400 bg-gray-100 "} `
			: "bg-gray-100";
	let colorChangerNeut =
		value !== undefined
			? `${value === 0 ? "bg-gray-400 text-white" : " text-gray-400 bg-gray-100 "} `
			: "bg-gray-100";
	let colorChangerNeg =
		value !== undefined
			? `${value === -1 ? "bg-gray-400 text-white" : " text-gray-400 bg-gray-100 "} `
			: "bg-gray-100";

	return (
		<div className="flex w-full">
			<p
				className={
					"w-1/2 mb-0 rounded text-center font-bold flex text-center items-center justify-center " +
					colorChangerPos
				}>
				+
			</p>
			<p
				className={
					"w-1/2 mb-0 ml-1 rounded text-center font-bold flex text-center items-center justify-center " +
					colorChangerNeut
				}>
				•
			</p>
			<p
				className={
					"w-1/2 mb-0 ml-1 rounded text-center font-bold flex text-center items-center justify-center " +
					colorChangerNeg
				}>
				—
			</p>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PosNegNeutralMini;
