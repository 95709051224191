import Widget from "../../models/widget";
import { WidgetTypes } from "../widgetTypes";

const goalWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Goal",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const goalWidget = new Widget(goalWidgetInput);

const shotOnGoalWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Shot on goal",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const shotOnGoalWidget = new Widget(shotOnGoalWidgetInput);

const assistWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Assist",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const assistWidget = new Widget(assistWidgetInput);

const takeawayWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Takeaway",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const takeawayWidget = new Widget(takeawayWidgetInput);

const gbWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "GB",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const gbWidget = new Widget(gbWidgetInput);

const turnoverWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Turnover",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const turnoverWidget = new Widget(turnoverWidgetInput);

const goalieSaveWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Goalie save",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const goalieSaveWidget = new Widget(goalieSaveWidgetInput);

const faceoffWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Face-off won/lost",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const faceoffWidget = new Widget(faceoffWidgetInput);

const drawWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Draw won/lost",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const drawWidget = new Widget(drawWidgetInput);
