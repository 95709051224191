import React from "react";
import { useHistory } from "react-router-dom";
import { useAppState } from "../../api";
import { logout } from "../../helpers/auth";
import Moment from "react-moment";
import { RightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
const { Paragraph } = Typography;

export default function MobileProfile() {
	const history = useHistory();
	const state = useAppState();

	const handleLogout = async () => {
		history.push("/");
		await logout();
	};

	return (
		<div className="h-screen mt-2">
			<div className="mx-4 md:mx-40 font-montserrat">
				<div className="sm:mx-4 lg:mx-40 mb-4">
					<div className="bg-white p-6">
						<p className="uppercase  my-0">Team Code</p>
						<div className="mb-2 mt-1 mx-auto text-xl font-semibold">
							<Paragraph style={{ marginBottom: "2px" }} copyable>
								{state.organization.organization.organizationKey}
							</Paragraph>
						</div>
						<p className="mx-auto my-0 text-xs text-gray-500">
							Use this code to add coaches and players to your organization
						</p>
					</div>
					<div className="mt-8 bg-white p-6">
						<p className="uppercase my-0">Details</p>
						<div className="mt-1 border-b border-gray-200 py-2">
							<p className="text-lg mt-0 border-gray-300 mb-0 mx-auto">{state.user.user.name}</p>
							<p className="my-0 text-xs text-gray-500">Name</p>
						</div>
						<div className="mt-1 border-b border-gray-200 py-2">
							<p className="text-lg mt-0 border-gray-300 mb-0 mx-auto">{state.user.user.email}</p>
							<p className="my-0 text-xs text-gray-500">Email</p>
						</div>
						<div className="mt-1 border-gray-200 py-2">
							<p className="text-lg mt-0 border-gray-300 mb-0 mx-auto">
								<Moment fromNow ago>
									{state.user.user.dateCreated}
								</Moment>
							</p>
							<p className="my-0 text-xs text-gray-500">
								Time on platform. Here's to making your team better for years to come!
							</p>
						</div>
					</div>
				</div>
				<div className="bg-white p-6 shadow cursor-pointer my-4" onClick={handleLogout}>
					<p className="uppercase my-0 inline">Logout</p>
					<div className="float-right inline">
						<RightOutlined />
					</div>
				</div>
				<div className="my-4">
					<p>
						If you have any questions or concerns, please call us at{" "}
						<a href="tel:402-367-2113" className="text-blue">
							(402) 367-2113
						</a>{" "}
						or email us at{" "}
						<a href="mailto:team@tappsports.com" className="text-blue">
							team@tappsports.com
						</a>
					</p>
				</div>
			</div>
		</div>
	);
}
