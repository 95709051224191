import { message } from "antd";
import { NEW_ROW_KEY } from "../../constants/editableTableConstant";
import { tagType } from "../../constants/tagType";
import Team from "../../models/team";
import { handleError } from "../../util/errorUtil";

export const createTeam = async ({ state, actions, effects }, team) => {
	try {
		if (team.teamId === NEW_ROW_KEY) {
			state.teams.allTeams = [team, ...state.teams.allTeams];
		} else {
			team.teamTagIds = await actions.teams.tryCreateNewTeamTags(team);
			const receipt = await effects.teams.api.createTeam(team);
			team.teamId = receipt.created.teamId;
			state.teams.allTeams = [team, ...state.teams.allTeams].filter((team) => team.teamId !== NEW_ROW_KEY);
		}
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
		state.teams.allTeams = state.teams.allTeams.filter((team) => team.teamId !== NEW_ROW_KEY);
	}
};

export const getAllTeams = async ({ state, actions, effects }) => {
	try {
		const teamList = await effects.teams.api.getAllTeams();
		state.teams.allTeams = teamList.map((team) => new Team(team));
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const updateTeam = async ({ state, actions, effects }, team) => {
	try {
		team.teamTagIds = await actions.teams.tryCreateNewTeamTags(team);
		await effects.teams.api.updateTeam(team);
		state.teams.allTeams = state.teams.allTeams.map((teamObj) => (teamObj.teamId === team.teamId ? team : teamObj));
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const deleteTeam = async ({ state, actions, effects }, teamId) => {
	try {
		if (actions.players.getPlayersInTeam(teamId).length) {
			message.error("This team contains players. Please remove them before deleting the team.", 7);
		} else {
			if (teamId !== NEW_ROW_KEY) {
				await effects.teams.api.deleteTeam({ teamId });
			}
			state.teams.allTeams = state.teams.allTeams.filter((team) => team.teamId !== teamId);
		}
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const tryCreateNewTeamTags = async ({ state, actions }, team) => {
	try {
		const existingTagIds = team.teamTagIds.filter((tagId) =>
			state.tags.teamTags.some((tag) => tag.tagId === tagId)
		);
		const newTags = team.teamTagIds.filter((tagId) => !state.tags.teamTags.some((tag) => tag.tagId === tagId));
		const newTagIds = await Promise.all(
			newTags.map(
				async (newTag) =>
					await actions.tags.createTag({ label: newTag, tagType: tagType.team_tag, isHidden: false })
			)
		);
		return [...existingTagIds, ...newTagIds];
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const setSelectedTeamId = async ({ state }, teamId) => {
	state.teams.selectedTeamId = teamId;
};

export const getTeamById = ({ state }, teamId) => {
	try {
		return state.teams.allTeams.filter((team) => team.teamId === teamId)[0];
	} catch (error) {
		return undefined;
	}
};
