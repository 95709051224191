export default class DashboardRequest {
	constructor(
		requestChartIds,
		requestPlayerIds,
		requestWidgetIds,
		requestStartDate,
		requestEndDate,
		requestTotalTimeFrame,
		requestTotalPlayerRow,
		requestQueries,
		format
	) {
		this.chartIds = requestChartIds.sort((a, b) => a.localeCompare(b));
		this.playerIds = requestPlayerIds.sort((a, b) => a.localeCompare(b));
		this.widgetIds = requestWidgetIds.sort((a, b) => a.localeCompare(b));
		this.startDate = requestStartDate;
		this.endDate = requestEndDate;
		// this.timeDelta = requestTimeDelta;
		this.includeTotalTimeFrame = requestTotalTimeFrame;
		this.includeTotalRow = requestTotalPlayerRow;
		// this.format = "infoFlat";
		// this.format = "directFlat";
		this.format = format;
		this.queries = requestQueries ?? [];
		this.params = {
			usePlayerNames: false, //specifies if the csv should use player names or player ids in the player field
			useWidgetNames: false, //specifies whether headers for widgets should be names or ids
		};
	}

	getQueryRequest() {
		const overheadInfo = { ...this };
		delete overheadInfo.queries;
		return {
			overheadInfo: overheadInfo,
			queries: this.queries,
		};
	}
}
