import moment from "moment";
import { WidgetTypes } from "../constants/widgetTypes";
import SingleNumberCard from "../components/dataViews/SingleNumberCard";
import DoubleNumberCard from "../components/dataViews/DoubleNumberCard";
import TripleNumberCard from "../components/dataViews/TripleNumberCard";
import PieChartCard from "../components/dataViews/pieChart/PieChartCard";
import { Card, Col, Table } from "antd";
import DataPageHeatMapCard from "../components/dataViews/pitchLocation/DataPageHeatMapCard";
import DataPageSprayChartCard from "../components/dataViews/fieldLocation/DataPageSprayChartsCard";
import SimpleTableCard from "../components/dataViews/SimpleTableCard";
import BarGraphComponent from "../components/dataViews/barGraph/BarGraphComponent";
import BarGraphCard from "../components/dataViews/barGraph/BarGraphCard";
import DataPageMultipleHeatMap from "../components/dataViews/pitchLocation/DataPageMultipleHeatMaps";
import DataPageMultipleSprayCharts from "../components/dataViews/fieldLocation/DataPageMultipleSprayCharts";
import TwoVizualizationsOneCard from "../components/dataViews/TwoVisualizationsOneCard";
import LineChartComponent from "../components/dataViews/lineChart/LineChartComponent";
import HeatmapTable from "../components/dataViews/HeatmapTable";
import NoDataCard from "../components/stats/NoDataCard";

export const getDataViewJSX = (state /*actions*/) => {
	const activeDashboard = state.dashboard.dashboards["pius data"];

	return temporaryOpposingHitters(activeDashboard.dataList);

	//return activeDashboard.dataList.map((row) => getSinglePlayerSingleDateSliceJSX(state, row));

	// let numPlayers = activeDashboard.playerIds.length;
	// let numDateSlices = activeDashboard.timeSlices.length;
	// if (numPlayers === 1 && numDateSlices === 1) {
	// 	return activeDashboard.dataList.map((row) => getSinglePlayerSingleDateSliceJSX(state, row));
	// } else if (numPlayers > 1 && numDateSlices === 1) {
	// 	return activeDashboard.widgetIds.map((widgetId) => {
	// 		return getMultiplePlayersSingleDateSliceJSX(
	// 			state,
	// 			actions,
	// 			activeDashboard.dataList.filter((stat) => stat.widgetId === widgetId),
	// 			widgetId
	// 		);
	// 	});
	// } else if (numPlayers === 1 && numDateSlices > 1) {

	// 	// return activeDashboard.dataList.map((row) => getSinglePlayerMultipleDateSlicesJSX(state, actions, row));
	// 	return activeDashboard.widgetIds.map((widgetId) => {
	// 		return getSinglePlayerMultipleDateSlicesJSX(
	// 			state,
	// 			actions,
	// 			activeDashboard.dataList.filter((stat) => stat.widgetId === widgetId),
	// 			widgetId
	// 		);
	// 	});
	// }
};

export const temporaryOpposingHitters = (dataList) => {
	// FOR OUR DEV ACCOUNT
	// const fieldLocationId = "acv5UuldLNCA4wwMb4Vk";
	// const hitResultId = "IUlAQuNdI2vvDFA9293J";
	// const pitchCountId = "R65SG8oixpZ5eEYSNRI5";
	// const pitchTypeId = "aKcYnINOO1KDzHkN0mb7";

	// FOR PIUS X
	const fieldLocationId = "2QIrbWHwV24KLmTwzYyU";
	const hitResultId = "TuGJl3pVBskM36Xdn85D";
	const pitchCountId = "Dv0nv4h0Tcu9EfNH3q8h";
	const pitchTypeId = "OHrWSAmL1dEHVgxDSoWt";
	let JSX = [];

	// Stuff for cross widget between pitch type and hit result
	let pitchTypeResultData = dataList
		.filter((row) => row.timestamp === "Entire Range")
		.filter((row) => row.widgetId === pitchTypeId);

	if (pitchTypeResultData[0]) {
		JSX.push(
			<Col span={24}>
				<HeatmapTable
					title="Pitch Type vs Hit Result"
					data={pitchTypeResultData[0].data.crosstab_dd[hitResultId]}
				/>
			</Col>
		);
	} else {
		JSX.push(
			<Col span={24}>
				<TwoVizualizationsOneCard title={"Pitch Type vs Hit Result"} vizualization1={<NoDataCard />} />
			</Col>
		);
	}

	//Stuff for result breakdown
	let resultData = dataList
		.filter((row) => row.timestamp === "Entire Range")
		.filter((row) => row.widgetId === hitResultId);
	if (resultData[0]) {
		JSX.push(
			<Col span={12}>
				<PieChartCard title={"Hit Results"} value={resultData[0].data} />
			</Col>
		);
	} else {
		JSX.push(
			<Col span={12}>
				<TwoVizualizationsOneCard title={"Hit Results"} vizualization1={<NoDataCard />} />
			</Col>
		);
	}

	// Stuff for overall field location
	let fieldLocationData = dataList
		.filter((row) => row.timestamp === "Entire Range")
		.filter((row) => row.widgetId === fieldLocationId);

	if (fieldLocationData[0]) {
		JSX.push(
			<Col span={12}>
				<DataPageSprayChartCard title={"Overall Field Location"} data={fieldLocationData[0].data.count} />
			</Col>
		);
	} else {
		JSX.push(
			<Col span={12}>
				<TwoVizualizationsOneCard title={"Overall Field Location"} vizualization1={<NoDataCard />} />
			</Col>
		);
	}

	// Stuff for cross widget between count and hit result
	let countResultData = dataList
		.filter((row) => row.timestamp === "Entire Range")
		.filter((row) => row.widgetId === pitchCountId);

	if (countResultData[0]) {
		JSX.push(
			<Col span={24}>
				<HeatmapTable title="Hit Result vs Count" data={countResultData[0].data.crosstab_dd[hitResultId]} />
			</Col>
		);
	} else {
		JSX.push(
			<Col span={24}>
				<TwoVizualizationsOneCard title={"Hit Result vs Count"} vizualization1={<NoDataCard />} />
			</Col>
		);
	}

	// Stuff for cross widget between field location and hit result
	let fieldLocationResultData = dataList
		.filter((row) => row.timestamp === "Entire Range")
		.filter((row) => row.widgetId === hitResultId);

	if (fieldLocationResultData[0]) {
		JSX.push(
			<Col span={24}>
				<DataPageMultipleSprayCharts
					title={"Field Location vs Hit Result"}
					data={fieldLocationResultData[0].data.crosstab_dd[fieldLocationId]}
					crossWidget={true}
				/>
			</Col>
		);
	} else {
		JSX.push(
			<Col span={24}>
				<TwoVizualizationsOneCard title={"Field Location vs Hit Result"} vizualization1={<NoDataCard />} />
			</Col>
		);
	}

	return JSX;
};

export const getSinglePlayerSingleDateSliceJSX = (state, row) => {
	const widget = state.widgets.all[row.widgetId];
	let order = 0;
	let JSX = <></>;
	switch (widget.type) {
		case WidgetTypes.COUNTER:
			JSX = <SingleNumberCard title={widget.name} value={row.data.count["1"]} type={widget.type} />;
			break;
		case WidgetTypes.POSNEG:
			JSX = <DoubleNumberCard title={widget.name} value={row.data.count} type={widget.type} />;
			break;
		case WidgetTypes.POSNEGNEUTRAL:
			JSX = <TripleNumberCard title={widget.name} value={row.data.count} type={widget.type} />;
			break;
		case WidgetTypes.STOPWATCH:
			JSX = <TripleNumberCard title={widget.name} value={row.data} type={widget.type} />;
			break;
		case WidgetTypes.PITCHLOCATION:
			JSX = <DataPageHeatMapCard title={widget.name} data={row.data.count} />;
			order = 1;
			break;
		case WidgetTypes.NUMBERINPUT:
			JSX = <TripleNumberCard title={widget.name} value={row.data} type={widget.type} />;
			break;
		case WidgetTypes.LISTSELECT:
			JSX = <PieChartCard title={widget.name} value={row.data} />;
			order = 1;
			break;
		case WidgetTypes.NEGCOUNTER:
			JSX = <SingleNumberCard title={widget.name} value={row.data.count["-1"]} type={widget.type} />;
			break;
		case WidgetTypes.POSNEUTRAL:
			JSX = <DoubleNumberCard title={widget.name} value={row.data.count} type={widget.type} />;
			break;
		case WidgetTypes.FIELDLOCATION:
			JSX = <DataPageSprayChartCard title={widget.name} data={row.data.count} />;
			order = 1;
			break;
		case WidgetTypes.PLAYERLISTSELECT:
			break;
		default:
			JSX = <Card>{widget.type}</Card>;
			break;
	}
	return (
		<Col span={12} order={order}>
			{JSX}
		</Col>
	);
};

export const getMultiplePlayersSingleDateSliceJSX = (state, actions, data, widgetId) => {
	const widget = state.widgets.all[widgetId];
	let order = 0;
	let columns = [];
	let graphicsData = [];
	let isNumeric = false;
	let barKeys = [];
	let table = <></>;
	let barGraph = <></>;
	let JSX = <></>;
	switch (widget.type) {
		case WidgetTypes.COUNTER:
			columns = getColumnsForTable(data[0].data.count);
			graphicsData = getDataForGraphic(actions, data, isNumeric);
			JSX = <SimpleTableCard title={widget.name} data={graphicsData} columns={columns} />;
			break;
		case WidgetTypes.POSNEG:
			columns = getColumnsForTable(data[0].data.count);
			graphicsData = getDataForGraphic(actions, data, isNumeric);
			JSX = <SimpleTableCard title={widget.name} data={graphicsData} columns={columns} />;
			break;
		case WidgetTypes.POSNEGNEUTRAL:
			columns = getColumnsForTable(data[0].data.count);
			graphicsData = getDataForGraphic(actions, data, isNumeric);
			JSX = <SimpleTableCard title={widget.name} data={graphicsData} columns={columns} />;
			break;
		case WidgetTypes.STOPWATCH:
			isNumeric = true;
			columns = getColumnsForTable(data[0].data);
			graphicsData = getDataForGraphic(actions, data, isNumeric);
			barKeys = ["maximum", "avg", "minimum"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			barGraph = <BarGraphComponent data={graphicsData} dataKey={"player"} barKeys={barKeys} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={barGraph} />;
			break;
		case WidgetTypes.PITCHLOCATION:
			JSX = <DataPageMultipleHeatMap title={widget.name} data={data} />;
			order = 1;
			break;
		case WidgetTypes.NUMBERINPUT:
			isNumeric = true;
			columns = getColumnsForTable(data[0].data);
			graphicsData = getDataForGraphic(actions, data, isNumeric);
			barKeys = ["maximum", "avg", "minimum"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			barGraph = <BarGraphComponent data={graphicsData} dataKey={"player"} barKeys={barKeys} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={barGraph} />;
			break;
		case WidgetTypes.LISTSELECT:
			isNumeric = true;
			graphicsData = getDataForGraphic(actions, data, false);
			barKeys = Object.keys(data[0].data.count);
			JSX = <BarGraphCard title={widget.name} data={graphicsData} dataKey={"player"} barKeys={barKeys} />;
			order = 1;
			break;
		case WidgetTypes.NEGCOUNTER:
			columns = getColumnsForTable(data[0].data.count);
			graphicsData = getDataForGraphic(actions, data, false);
			JSX = <SimpleTableCard title={widget.name} data={graphicsData} columns={columns} />;
			break;
		case WidgetTypes.POSNEUTRAL:
			columns = getColumnsForTable(data[0].data.count);
			graphicsData = getDataForGraphic(actions, data, false);
			JSX = <SimpleTableCard title={widget.name} data={graphicsData} columns={columns} />;
			break;
		case WidgetTypes.FIELDLOCATION:
			JSX = <DataPageMultipleSprayCharts title={widget.name} data={data} />;
			order = 1;
			break;
		case WidgetTypes.PLAYERLISTSELECT:
			break;
		default:
			JSX = <Card>{widget.type}</Card>;
			break;
	}
	return (
		<Col span={isNumeric ? 24 : 12} order={order}>
			{JSX}
		</Col>
	);
};

export const getSinglePlayerMultipleDateSlicesJSX = (state, actions, data, widgetId) => {
	const widget = state.widgets.all[widgetId];
	let order = 0;
	let columns = [];
	let graphicsData = [];
	let isNumeric = false;
	let isFullWidth = false;
	let graphKey = [];
	let table = <></>;
	// let barGraph = <></>;
	let lineChart = <></>;
	let JSX = <></>;
	switch (widget.type) {
		case WidgetTypes.COUNTER:
			isFullWidth = true;
			columns = getColumnsForTable(data[0].data.count, true);
			graphicsData = getDataForGraphic(actions, data, isNumeric, true);
			graphKey = ["1"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			lineChart = <LineChartComponent data={graphicsData} dataKey={"week"} lineKeys={graphKey} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={lineChart} />;
			break;
		case WidgetTypes.POSNEG:
			isFullWidth = true;
			columns = getColumnsForTable(data[0].data.count, true);
			graphicsData = getDataForGraphic(actions, data, isNumeric, true);
			graphKey = ["1", "-1"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			lineChart = <LineChartComponent data={graphicsData} dataKey={"week"} lineKeys={graphKey} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={lineChart} />;
			break;
		case WidgetTypes.POSNEGNEUTRAL:
			isFullWidth = true;
			columns = getColumnsForTable(data[0].data.count, true);
			graphicsData = getDataForGraphic(actions, data, isNumeric, true);
			graphKey = ["1", "0", "-1"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			lineChart = <LineChartComponent data={graphicsData} dataKey={"week"} lineKeys={graphKey} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={lineChart} />;
			break;
		case WidgetTypes.STOPWATCH:
			isNumeric = true;
			isFullWidth = true;
			columns = getColumnsForTable(data[0].data, true);
			graphicsData = getDataForGraphic(actions, data, isNumeric, true);
			graphKey = ["maximum", "avg", "minimum"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			lineChart = <LineChartComponent data={graphicsData} dataKey={"week"} lineKeys={graphKey} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={lineChart} />;
			break;
		case WidgetTypes.PITCHLOCATION:
			// JSX = <DataPageMultipleHeatMap title={widget.name} data={data} />;
			order = 1;
			break;
		case WidgetTypes.NUMBERINPUT:
			isNumeric = true;
			isFullWidth = true;
			columns = getColumnsForTable(data[0].data, true);
			graphicsData = getDataForGraphic(actions, data, isNumeric, true);
			graphKey = ["maximum", "avg", "minimum"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			lineChart = <LineChartComponent data={graphicsData} dataKey={"week"} lineKeys={graphKey} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={lineChart} />;
			break;
		case WidgetTypes.LISTSELECT:
			// isNumeric = true;
			// graphicsData = getDataForGraphic(actions, data, false);
			// barKeys = Object.keys(data[0].data.count);
			// JSX = <BarGraphCard title={widget.name} data={graphicsData} dataKey={"player"} barKeys={barKeys} />;
			order = 1;
			break;
		case WidgetTypes.NEGCOUNTER:
			isFullWidth = true;
			columns = getColumnsForTable(data[0].data.count, true);
			graphicsData = getDataForGraphic(actions, data, isNumeric, true);
			graphKey = ["-1"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			lineChart = <LineChartComponent data={graphicsData} dataKey={"week"} lineKeys={graphKey} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={lineChart} />;
			break;
		case WidgetTypes.POSNEUTRAL:
			isFullWidth = true;
			columns = getColumnsForTable(data[0].data.count, true);
			graphicsData = getDataForGraphic(actions, data, isNumeric, true);
			graphKey = ["1", "0"];
			table = <Table columns={columns} dataSource={graphicsData} pagination={false} bordered />;
			lineChart = <LineChartComponent data={graphicsData} dataKey={"week"} lineKeys={graphKey} />;
			JSX = <TwoVizualizationsOneCard title={widget.name} vizualization1={table} vizualization2={lineChart} />;
			break;
		case WidgetTypes.FIELDLOCATION:
			// JSX = <DataPageMultipleSprayCharts title={widget.name} data={data} />;
			order = 1;
			break;
		case WidgetTypes.PLAYERLISTSELECT:
			break;
		default:
			JSX = <Card>{widget.type}</Card>;
			break;
	}
	return (
		<Col span={isFullWidth ? 24 : 12} order={order}>
			{JSX}
		</Col>
	);
};

const getColumnsForTable = (vals, isMultipleDateSlices) => {
	let fullColumns = {};
	if (isMultipleDateSlices) {
		fullColumns = { title: "Week start date", dataIndex: "week", fixed: true };
	} else {
		fullColumns = { title: "Player", dataIndex: "player", fixed: true };
	}
	let cols = Object.keys(vals).map((val) => {
		let title = val;
		let style = "";
		if (val === "1") {
			title = "+";
			style = "text-blue";
		} else if (val === "-1") {
			title = "-";
			style = "text-red-500";
		} else if (val === "0") {
			title = "•";
			style = "text-yellow-500";
		} else if (val === "avg") {
			style = "font-semibold";
		} else if (val === "maximum") {
			title = "max";
		} else if (val === "minimum") {
			title = "min";
		} else if (val === "tot") {
			title = "# reps";
		}
		return {
			title: title,
			dataIndex: val,
			sorter: (a, b) => a[val] - b[val],
			render(text) {
				return {
					children: <div className={text !== "-" ? style : "text-gray-400"}>{text}</div>,
				};
			},
		};
	});

	fullColumns = [fullColumns, ...cols];

	return fullColumns;
};

const getDataForGraphic = (actions, allData, isNumeric, isMultipleDateSlices) => {
	let data = [];

	allData.forEach((playerData, index) => {
		let obj = {};
		if (isMultipleDateSlices) {
			let timestamp = moment(playerData.timestamp).format("MM/DD/YYYY");
			obj = { key: index, week: timestamp };
		} else {
			let player = actions.players.getPlayerById(playerData.playerId);
			obj = { key: index, player: player.name };
		}
		if (isNumeric) {
			Object.keys(playerData.data).forEach((key) => {
				let value = playerData.data[key] ? Math.round(playerData.data[key] * 100) / 100 : "-";
				obj[key] = value;
			});
		} else {
			Object.keys(playerData.data.count).forEach((key) => {
				let value = playerData.data.count[key] !== 0 ? playerData.data.count[key] : "-";
				obj[`${key}`] = value;
			});
		}

		data.push(obj);
	});

	return data;
};
