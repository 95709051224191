import React from "react";
import { Row, Col } from "antd";

export const TwoVizualizationsOneCard = ({ title, vizualization1, vizualization2 }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<div className="h-100 w-full p-3 border rounded text-center overflow-y-auto shadow">
			<div className="mb-4 text-xl font-semibold">{title}</div>
			<div className="mb-4">{vizualization1}</div>
			<Row justify="center">
				<Col>{vizualization2}</Col>
			</Row>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default TwoVizualizationsOneCard;
