import axios from "axios";
import { Blog } from "../../constants/api";
import BlogPost from "../../models/blogPost";

export const api = {
	async getAllBlogPosts() {
		try {
			const res = await axios.post(Blog.read.all);
			const postData = res.data.blogPosts;
			const blogPosts = postData.map(
				(post) =>
					new BlogPost(
						post.dateCreated,
						post.title,
						post.description,
						post.route,
						post.imageUrl,
						post.content
					)
			);
			return blogPosts;
		} catch (error) {
			throw new Error(error);
		}
	},
};
