import WidgetModel from "../../models/widget";
import { widget } from "../../constants/apiJson";
import moment from "moment";

export const getAll = async ({ effects, state }) => {
	const widgetList = await effects.widgets.api.getAll();
	let widgetsObj = {};

	if (widgetList) {
		widgetList.forEach((widget) => {
			let widgetId = widget.widgetId;
			widgetsObj[widgetId] = widget;
		});
	}

	state.widgets.all = widgetsObj;
};

export const setEditingWidget = ({ state }, newVal) => {
	state.widgets.editingWidget = newVal;
};

export const setShowNewWidgetModal = ({ state }, newVal) => {
	state.widgets.showNewWidgetModal = newVal;
};

export const setNewWidgetLabel = ({ state }, newVal) => {
	state.widgets.new.setLabel(newVal);
};

export const setNewWidgetTool = ({ state }, newVal) => {
	state.widgets.new.setType(newVal);
};

export const setNewWidget = ({ state }, newWidget) => {
	state.widgets.new = new WidgetModel(newWidget);
};

export const clearNewWidget = async ({ state }) => {
	state.widgets.new = new WidgetModel(widget);
};

export const newWidgetSetPotentialValue = async ({ state }, newVal) => {
	state.widgets.new.setPotentialValue(newVal);
};

export const newWidgetClearPotentialValue = async ({ state }) => {
	state.widgets.new.clearPotentialValue([]);
};

export const newWidgetAddPotentialValue = async ({ state }, newVal) => {
	state.widgets.new.addPotentialValue(newVal);
};

export const deleteWidget = ({ effects }, widgetId) => {
	let payload = { widgetId: widgetId };
	effects.widgets.api.delete(payload);
};

export const setComparisonWidget1 = ({ state }, widgetId) => {
	let newWidget = state.widgets.all[widgetId];

	if (newWidget) {
		state.widgets.comparisonWidget1 = new WidgetModel(newWidget);
	} else {
		state.widgets.comparisonWidget1 = new WidgetModel(widget);
	}
};

export const setComparisonWidget2 = ({ state }, widgetId) => {
	let newWidget = state.widgets.all[widgetId];
	if (newWidget) {
		state.widgets.comparisonWidget2 = new WidgetModel(newWidget);
	} else {
		state.widgets.comparisonWidget2 = new WidgetModel(widget);
	}
};

export const setComparisonPlayerId = ({ state }, playerId) => {
	state.widgets.comparisonPlayerId = playerId;
};

export const compareWidgets = async ({ effects, state }) => {
	state.widgets.comparisonLoading = true;
	let payload = {
		playerId: state.widgets.comparisonPlayerId,
		chartId: state.charts.currentChartId,
		widgetIdX: state.widgets.comparisonWidget1.widgetId,
		widgetIdY: state.widgets.comparisonWidget2.widgetId,
		startDate: moment(state.charts.chartView.startDate).format("YYYY-MM-DD"),
		endDate: moment(state.charts.chartView.endDate).format("YYYY-MM-DD"),
	};
	try {
		let compareRes = await effects.widgets.api.compare(payload);
		state.widgets.comparisonResults = compareRes;
		state.widgets.comparisonLoading = false;
	} catch (error) {
		state.widgets.comparisonResults = {};
		state.widgets.comparisonLoading = false;

		throw new Error(error);
	}
};

export const setComparisonWidget1Words = ({ state }, newVal) => {
	state.widgets.comparisonWidget1Words = newVal;
};

export const setComparisonWidget2Words = ({ state }, newVal) => {
	state.widgets.comparisonWidget2Words = newVal;
};

export const clearComparisonStates = ({ state }) => {
	state.widgets.comparisonPlayerId = "";
	state.widgets.comparisonWidget1 = new WidgetModel(widget);
	state.widgets.comparisonWidget2 = new WidgetModel(widget);
	state.widgets.comparisonResults = {};
	state.widgets.comparisonWidget1Words = "";
	state.widgets.comparisonWidget2Words = "";
};

export const clearComparisonData = ({ state }) => {
	state.widgets.comparisonResults = {};
};

export const removeWidgetFromChart = ({ state }, widgetId) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	currentChart.widgets = currentChart.widgets.filter((chartWidgetId) => chartWidgetId !== widgetId);
};

export const createWidget = ({ effects, state }, widget) => {
	const widgetId = effects.widgets.api.create(widget);
	state.widgets.all[widgetId] = widget;
	return widgetId;
};

export const updateWidget = async ({ state, effects }) => {
	const databaseWidget = await effects.widgets.api.update(state.widgets.new.toJsonForUpdate());
	state.widgets.all[databaseWidget.widgetId].potentialValues = databaseWidget.potentialValues;
	state.widgets.all[databaseWidget.widgetId].widgetId = databaseWidget.widgetId;
	state.widgets.all[databaseWidget.widgetId].widgetTagIds = databaseWidget.widgetTagIds;
};
