import React from "react";
import { WidgetTypes } from "../../../constants/widgetTypes";
import { getRecordingTool } from "../../../util/getRecordingTool";
import { getDisabledTool } from "../../../util/getDisabledTool";
import { useAppState, useActions } from "../../../api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Menu, Dropdown, message, Modal } from "antd";
const { confirm } = Modal;

const RecordingTool = ({ widget, isGrouped }) => {
	const state = useAppState();
	const actions = useActions();

	const showConfirm = () => {
		confirm({
			title: "Are you sure you want to delete this widget? This action cannot be reversed.",
			icon: <ExclamationCircleOutlined />,
			content: "This action cannot be reversed",
			async onOk() {
				await actions.widgets.deleteWidget(widget.widgetId);
				actions.widgets.removeWidgetFromChart(widget.widgetId);
			},
			onCancel() {},
		});
	};

	const handleEditWidget = () => {
		message.info("Widget editing is not yet available! In the meantime, delete and add a new widget.");
	};

	const handleDeleteWidget = async () => {
		showConfirm();
	};

	const menu = (
		<Menu>
			<Menu.Item>
				<p onClick={handleEditWidget}>Edit Widget</p>
			</Menu.Item>
			<Menu.Item danger>
				<p onClick={handleDeleteWidget}>Delete Widget</p>
			</Menu.Item>
		</Menu>
	);
	const rowClassEditing =
		"cursor-pointer flex " +
		(widget.type === WidgetTypes.PITCHLOCATION || widget.type === WidgetTypes.FIELDLOCATION ? "h-48" : "");

	const rowClassRecording =
		"cursor-pointer flex " +
		(widget.type === WidgetTypes.PITCHLOCATION ||
		widget.type === WidgetTypes.TEXTINPUT ||
		widget.type === WidgetTypes.FIELDLOCATION
			? " h-48 "
			: widget.type === WidgetTypes.STOPWATCH
			? " h-24 "
			: widget.type === WidgetTypes.XYCOORDINATE
			? " h-20"
			: "");

	//#region JSX ----------------------------------------------------------------------------------------------------------------
	return state.charts.mobileEditing ? (
		<Dropdown overlay={menu} trigger={["click"]}>
			<div className="mb-2">
				<div className={rowClassEditing}>
					<p className="mb-0 pb-0 max-h-20 overflow-y-hidden font-montserrat w-4/12 pr-2 text-sm pt-0.5 overflow-y-hidden text-right text-wrap">
						{widget.name}
					</p>
					<div className="w-8/12 h-14">{getDisabledTool(widget.type, "edit")}</div>
				</div>
			</div>
		</Dropdown>
	) : (
		<div className="mb-2 border-b border-gray-200 rounded p-1">
			<div className={rowClassRecording}>
				<div className="w-4/12 overflow-y-hidden flex items-center">
					<div className="w-full text-right">
						<p className={"m-0 p-0 pr-3 font-montserrat text-xs text-wrap"}>{widget.name}</p>
					</div>
				</div>
				<div className="w-8/12 h-14 items-center">
					{getRecordingTool(widget.type, widget.name, widget.widgetId, isGrouped, widget.potentialValues)}
				</div>
			</div>
		</div>
	);
	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default RecordingTool;
