import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useActions } from "../../api";
import ChartOptions from "./ChartOptions";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

const DashboardChart = ({ chartName, lastUsed, chartId }) => {
	const history = useHistory();
	const actions = useActions();

	//#region HANDLERS ------------------------------------------------------------------------------------------------------

	const navigateToChart = async () => {
		await actions.charts.setCurrentChartId(chartId);
		history.push("/overview/" + chartId);
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	if (chartId) {
		return (
			<div
				key={chartId}
				className="group m-1 p-4 w-56 cursor-pointer shadow-sm border hover:shadow hover:border-coolPurple hover:border-opacity-50 transition ease-in-out duration-400"
				onClick={navigateToChart}>
				<Row>
					<Col span={16}>
						<p className="text-lg mb-0 w-full truncate font-semibold">{chartName}</p>
						<p className="text-xs text-gray-400 inline mt-0">
							{lastUsed ? moment(lastUsed).fromNow() : moment().fromNow()}
						</p>
					</Col>
					<Col span={8} className="text-right" onClick={(e) => e.stopPropagation()}>
						<ChartOptions chartId={chartId} />
					</Col>
				</Row>
				<div className="mt-4 text-right text-xs">
					<p className="inline mr-1 pt-1" onClick={navigateToChart}>
						Click to View Chart
					</p>
					<ArrowRightOutlined className="mb-1" />
				</div>
			</div>
		);
	} else {
		return "";
	}

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default DashboardChart;
