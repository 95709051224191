import React, { useEffect } from "react";
import { Row, Divider } from "antd";
import DOMPurify from "dompurify"; // prevents XSS attacks by sanitizing dangerouslySetInnerHTML
import { useAppState, useActions } from "../../../api";
import LandingPageButton from "../../../components/global/landingPageButton";
import { useHistory } from "react-router-dom";

const BlogPost = () => {
	const state = useAppState();
	const actions = useActions();
	const history = useHistory();
	const blogPosts = state.global.BlogPosts;
	actions.global.setShowLandingBackground(false);
	const Post = blogPosts.find((post) => post.route === `/${window.location.pathname.split("/").at(-1)}`);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (!Post) {
		return (
			<div>
				<Row>
					<div className="site-layout-content">
						<div className="w-full items-center justify-center sm:p-10 p-5 container bg-white">
							<Row className="w-full items-center justify-center">
								<div className=" text-4xl md:text-5xl text-black font-bold w-full text-left">
									Loading...
								</div>
							</Row>
							<Divider className="mb-10" />
							<Row className="text-xl px-2 mt-10 px-0 md:px-12 lg:px-24 xl:px-48">
								<p>Loading</p>
							</Row>
						</div>
					</div>
				</Row>
			</div>
		);
	}

	const rawHTML = Post.content;

	return (
		<div>
			<Row>
				<div className="site-layout-content">
					<div className="w-full items-center justify-center sm:p-10 p-5 container bg-white">
						<Row className="w-full items-center justify-center">
							<div className="text-gray-500 w-full text-left mb-5">{Post.dateCreated}</div>

							<div className=" text-4xl md:text-5xl text-black font-bold w-full text-left">
								{Post.title}
							</div>
						</Row>
						<Divider className="mb-10" />
						<Row className="text-xl px-2 mt-10 px-0 md:px-12 lg:px-24 xl:px-48">
							<div
								className="max-w-full overflow-hidden"
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(rawHTML),
								}}
							/>
						</Row>
						<Divider />
						<div className="site-layout-content">
							<div className="grid w-full items-center justify-center mt-20">
								<div>
									<Row className="flex items-center justify-center w-full h-fit">
										<div className="text-5xl lg:text-6xl text-center lg:w-2/3 text-gray-700">
											{" "}
											Ditch your paper chart, get tapp today{" "}
										</div>
									</Row>
								</div>
								<div className="flex items-center justify-center w-full h-fit mt-20">
									<LandingPageButton ghost onClick={() => history.push("/pricing")}>
										Sign up
									</LandingPageButton>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Row>
		</div>
	);
};

export default BlogPost;
