export default class Player {
	constructor(player) {
		this.playerId = player.playerId ?? "";
		this.name = player.name ?? "";
		this.isArchived = player.isArchived ?? false;
		this.number = player.number ?? "";
		this.height = player.height ?? "";
		this.weight = player.weight ?? "";
		this.playerTagIds = player.playerTagIds ?? [];
		this.teamIds = player.teamIds ?? [];
	}

	toJson() {
		const playerJson = JSON.parse(JSON.stringify(this));
		const keysToSave = ["name", "isArchived", "teamIds", "playerTagIds"];
		Object.entries(playerJson).forEach((key) => {
			if (!keysToSave.includes(key[0])) {
				delete playerJson[key[0]];
			}
		});
		return playerJson;
	}
}
