import Widget from "../../models/widget";
import { WidgetTypes } from "../widgetTypes";

const strikeWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Is Strike",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const strikeWidget = new Widget(strikeWidgetInput);

const pitchLocationWidgetInput = {
	type: WidgetTypes.PITCHLOCATION,
	name: "Pitch Location",
	position: null,
	attributes: [],
	potentialValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
};
export const pitchLocationWidget = new Widget(pitchLocationWidgetInput);

const pitchTypeWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Pitch Type",
	position: null,
	attributes: [],
	potentialValues: ["FB", "CV", "CH", "SL"],
};
export const pitchTypeWidget = new Widget(pitchTypeWidgetInput);

const hitResultWidgetInput = {
	type: WidgetTypes.LISTSELECT,
	name: "Hit Result",
	position: null,
	attributes: [],
	potentialValues: ["Swing & Miss", "Ground Ball", "Line Drive", "Pop Fly"],
};
export const hitResultWidget = new Widget(hitResultWidgetInput);

const fieldLocationWidgetInput = {
	type: WidgetTypes.FIELDLOCATION,
	name: "Field Location",
	position: null,
	attributes: [],
	potentialValues: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
};

export const fieldLocationWidget = new Widget(fieldLocationWidgetInput);

const veloWidgetInput = {
	type: WidgetTypes.NUMBERINPUT,
	name: "Velo",
	position: null,
	attributes: [],
	potentialValues: [],
};

export const veloWidget = new Widget(veloWidgetInput);

const pitcherWidgetInput = {
	type: WidgetTypes.PLAYERLISTSELECT,
	name: "Pitcher",
	position: null,
	attributes: [],
	potentialValues: [],
};
export const pitcherWidget = new Widget(pitcherWidgetInput);

const frontHandWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Fronthand Field",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const frontHandWidget = new Widget(frontHandWidgetInput);

const backHandWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Backhand Field",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const backHandWidget = new Widget(backHandWidgetInput);

const goodThrowWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Good Throw",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const goodThrowWidget = new Widget(goodThrowWidgetInput);

const popTimeWidgetInput = {
	type: WidgetTypes.STOPWATCH,
	name: "Pop Time",
	position: null,
	attributes: [],
	potentialValues: [],
};
export const popTimeWidget = new Widget(popTimeWidgetInput);

const goodReceiveWidgetInput = {
	type: WidgetTypes.POSNEG,
	name: "Good Receive",
	position: null,
	attributes: [],
	potentialValues: [1, -1],
};
export const goodReceiveWidget = new Widget(goodReceiveWidgetInput);

const homeToFirstWidgetInput = {
	type: WidgetTypes.STOPWATCH,
	name: "Home To First",
	position: null,
	attributes: [],
	potentialValues: [],
};
export const homeToFirstWidget = new Widget(homeToFirstWidgetInput);

const stolenBaseWidgetInput = {
	type: WidgetTypes.COUNTER,
	name: "Stolen Base",
	position: null,
	attributes: [],
	potentialValues: [1],
};
export const stolenBaseWidget = new Widget(stolenBaseWidgetInput);
