import React from "react";
import { useHistory } from "react-router-dom";
import FadeInSection from "./fadeInSection";
import LandingPageButton from "./landingPageButton";
import { Row } from "antd";

const LandingPageBanner = ({ title }) => {
	const history = useHistory();

	const handleSignupNav = () => {
		history.push("/pricing");
	};

	return (
		<FadeInSection>
			<div className="w-full bg-coolPurple my-10">
				<div className="site-layout-content">
					<Row className="w-full flex items-center justify-between justify-center h-48 py-5">
						<span className="flex text-4xl sm:text-5xl py-1 pr-3 font-bold text-white self-center">
							{title}
						</span>
						<LandingPageButton onClick={handleSignupNav}>Sign up</LandingPageButton>
					</Row>
				</div>
			</div>
		</FadeInSection>
	);
};

export default LandingPageBanner;
