import React, { useEffect } from "react";

import { useAppState, useActions } from "../../../api";
import { hasHeatMapOrSprayChartData } from "../../../engines/dataEngine";
import NoDataCard from "../../stats/NoDataCard";
import PlayerHeatMapCard from "./PlayerHeatMapCard";
import FilterHeapMaps from "./FilterHeatMaps";

import { Input, Col, Row, Dropdown, Button } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import HeatMapProperties from "./HeatMapProperties";

const HeatMapCard = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const actions = useActions();
	const state = useAppState();

	useEffect(() => {
		actions.stats.setHeatMapSearch("");
		actions.charts.setVisibleHeatMaps();
		actions.charts.setHiddenHeatMaps();
		actions.charts.clearVisibleHeatMapPlayers();
		actions.charts.clearHiddenHeatMapPlayers();
		actions.charts.setVisibleHeatMapPlayers();
		actions.charts.setHiddenHeatMapPlayers();
	}, []);

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------------
	const onSearchChange = (val) => {
		actions.stats.setHeatMapSearch(val.target.value);
	};
	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat group">
			<Row>
				<Col flex="auto">
					<p className="text-lg font-semibold mr-4">Heatmaps</p>
				</Col>
				<Col>
					<Dropdown overlay={<HeatMapProperties />} trigger={["click"]} placement="bottomCenter">
						<Button className="mr-1">
							Properties <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={<FilterHeapMaps />} trigger={["click"]} placement="bottomCenter">
						<Button className="mr-1">
							Filter <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Input
						allowClear
						style={{ width: "160px" }}
						placeholder="Search Players"
						prefix={<SearchOutlined />}
						onChange={onSearchChange}
					/>
				</Col>
			</Row>
			<div>
				{state.charts.chartDataLoading || !hasHeatMapOrSprayChartData(state).heatMap ? (
					<NoDataCard />
				) : (
					<div className="flex flex-wrap">
						<PlayerHeatMapCard />
					</div>
				)}
			</div>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default HeatMapCard;
