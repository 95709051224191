import React from "react";
import { Link } from "react-router-dom";
import FadeInSection from "./fadeInSection";
import AppStoreLogo from "../../assets/landing-page/app-store-logo.png";
import { Row, Col } from "antd";
import { ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";

const AttentionGrabberHeader = ({ header, description, mockup, valueProp }) => {
	const MakeCheckmarkRow = (text) => {
		return (
			<p className="text-md mb-4 flex items-center text-white">
				<CheckOutlined style={{ fontSize: 20, color: "#ffffff" }} />
				&nbsp;&nbsp;{text}
			</p>
		);
	};

	return (
		<Row>
			<div className="site-layout-content">
				<FadeInSection>
					<Col>
						<Row wrap={true} gutter={[25, 25]} justify="start">
							<Col span={12} xs={24} sm={24} md={13} lg={13}>
								<div className="pl-5 text-left">
									<p className="text-5xl sm:text-6xl font-semibold text-white mb-5 mt-2">{header}</p>
									<p className="text-xl font-normal text-white mb-4">{description}</p>
									<div className="text-left text-coolPurple mt-4 bg-white w-full p-4 rounded">
										<ExclamationCircleOutlined className="inline" />
										<h1 className="text-coolPurple text-xl inline ml-2">New app is here!</h1>
									</div>
									<Link
										to={{
											pathname: "https://apps.apple.com/us/app/tapp-sports/id1636570218",
										}}
										target="_blank">
										<img
											className="mt-2 w-36 md:w-40 transform hover:scale-105 transition duration-300 ease-in-out"
											src={AppStoreLogo}
											alt="App Store"
										/>
									</Link>
									<div className="mt-10 grid grid-cols-2">
										{valueProp.map((reason) => {
											return MakeCheckmarkRow(reason);
										})}
									</div>
								</div>
							</Col>
							<Col span={12} xs={24} sm={24} md={11} lg={11}>
								<div className="w-full text-center">
									<img
										className="flex-none inline w-48 md:w-72"
										src={mockup}
										alt="Logo"
										style={{ minWidth: 200 }}
									/>
								</div>
							</Col>
						</Row>
						<Row className="h-20 sm:h-24 md:h-60 lg:h-48" />
					</Col>
				</FadeInSection>
			</div>
		</Row>
	);
};

export default AttentionGrabberHeader;
