export const Chart = {
	create: "create/chart",
	read: {
		all: "/get/organization/chart",
		stats: "/get/stat",
	},
	update: "update/chart",
	delete: "delete/chart",
};

export const Organization = {
	create: "create/organization",
	read: "get/organization",
	update: "update/organization",
	subscribe: "subscribe/organization",
};

export const User = {
	create: {
		user: "create/user",
		waitlist: "create/waitlistSubmission",
		joinUser: "/get/organizationkey/player",
	},
	read: "get/user",
	update: "update/user",
	getSubscriptionLink: "create/portalSession",
};

export const Player = {
	create: "create/player",
	read: {
		all: "/get/organization/player",
		one: "get/player",
	},
	update: "update/player",
	delete: "delete/player",
};

export const Team = {
	create: "create/team",
	read: {
		all: "/get/organization/team",
	},
	update: "update/team",
	delete: "delete/team",
};

export const Tag = {
	create: "create/tag",
	read: {
		all: "/get/organization/tag",
		one: "get/tag",
	},
	update: "update/tag",
	delete: "delete/tag",
};

export const Stat = {
	create: "create/stat",
	update: "update/stat",
	delete: "delete/stat",
};

export const Dashboard = {
	read: "/get/stat",
};

export const Widget = {
	create: "create/widget",
	read: {
		all: "/get/organization/widget",
		one: "get/widget",
	},
	update: "update/widget",
	delete: "delete/widget",
	compare: "/get/widget/analytics",
};

export const Analytics = {
	crossWidgetAnalysis: "get/widget/analytics",
};

export const Import = {
	create: {
		chart: "/create/chart/import",
		stat: "/create/stat/import",
		player: "/create/player/import",
	},
};

export const Blog = {
	read: {
		all: "get/all/blogPost",
	},
};
