import React, { useEffect } from "react";
import { createStatHistoryComponent } from "../../util/takeStatUtil";
import { useAppState } from "../../api";
import NoDataCard from "./NoDataCard";

const StatHistoryContainer = () => {
	//#region JSX -------------------------------------------------------------------------------------------------------------
	const state = useAppState();

	const currentChart = state.charts.all[state.charts.currentChartId];
	//Review
	// let currentStats = state.stats.currentStats[state.charts.currentChartId];
	// const [currentStats, setCurrentStats] = useState(state.stats.currentStats[state.charts.currentChartId]);

	// useEffect(() => {
	//   setCurrentStats(state.stats.currentStats[state.charts.currentChartId]);
	// }, [state.stats.currentStats]);

	let currentStats = state.stats.currentStats[state.charts.currentChartId];

	useEffect(() => {
		currentStats = state.stats.currentStats[state.charts.currentChartId];
	}, [state.stats.currentStats]);

	return (
		<div
			className={
				"mb-2 md:max-h-96 bg-white rounded overflow-y-auto mr-1 " + (currentStats?.length > 0 ? "h-40" : "")
			}>
			<div className="flex-1 h-12/12">
				{currentStats?.length > 0 ? (
					<div className={state.charts.mobileEditing ? "hidden" : "inline"}>
						{currentStats.map((payload) => {
							return createStatHistoryComponent(payload, currentChart);
						})}
					</div>
				) : (
					<NoDataCard />
				)}
			</div>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default StatHistoryContainer;
