import moment from "moment";
import { TimeFrames } from "../constants/timeFrames";

export default class ChartView {
	constructor(chartView) {
		this.sort = chartView.sort
			? chartView.sort
			: [
					{ id: "Total_PCT", desc: false },
					{ id: "Total_POS", desc: false },
			  ];
		this.timeFrame = chartView.timeFrame ? chartView.timeFrame : TimeFrames.DAILY;
		this.hiddenColumns = chartView.hiddenColumns ? chartView.hiddenColumns : [];
		this.startDate = chartView.startDate ? moment(chartView.startDate) : moment(); // let these become populated after instantiating object
		this.endDate = chartView.endDate ? moment(chartView.endDate) : moment(); // todo, currently no support for saving custom dates
		//heat map views
		this.visibleHeatMaps = chartView.visibleHeatMaps ? chartView.visibleHeatMaps : [];
		this.hiddenHeatMaps = chartView.hiddenHeatMaps ? chartView.hiddenHeatMaps : [];
		this.visibleHeatMapPlayers = chartView.visibleHeatMapPlayers ? chartView.visibleHeatMapPlayers : [];
		this.hiddenHeatMapPlayers = chartView.hiddenHeatMapPlayers ? chartView.hiddenHeatMapPlayers : [];
		this.heatMapFraction = chartView.heatMapFraction ? chartView.heatMapFraction : false;
		//coordinate heat map views
		this.visibleCoordianteHeatMaps = [];
		this.hiddenCoordinateHeatMaps = [];
		this.visibleCoordinateHeatMapPlayers = [];
		this.hiddenCoordinateHeatMapPlayers = [];
		// spray chart views
		this.visibleSprayCharts = chartView.visibleSprayCharts ? chartView.visibleSprayCharts : [];
		this.hiddenSprayCharts = chartView.hiddenSprayCharts ? chartView.hiddenSprayCharts : [];
		this.visibleSprayChartPlayers = chartView.visibleSprayChartPlayers ? chartView.visibleSprayChartPlayers : [];
		this.hiddenSprayChartPlayers = chartView.hiddenSprayChartPlayers ? chartView.hiddenSprayChartPlayers : [];
		this.sprayChartFraction = chartView.sprayChartFraction ? chartView.sprayChartFraction : false;
	}

	async transformObjectToArray(obj) {
		return Object.values(obj);
	}
}
