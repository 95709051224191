import React from "react";

export const NegDisabled = ({ value }) => {
	let colorChangerPos = `${value === "edit" ? " text-gray-400 bg-white " : "bg-gray-400 text-white"} `;

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return <button className={"w-full h-full rounded shadow text-3xl font-bold py-2 " + colorChangerPos}>-</button>;

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default NegDisabled;
