import React, { useEffect, useState } from "react";
import moment from "moment";
import DashboardChart from "../../components/charts/DashboardChart";
import { Skeleton, Col, Row, Alert, Button } from "antd";
import { useAppState, useActions } from "../../api";
import { useHistory } from "react-router-dom";
import TeamManagementPage from "./teamManagementPage";
import { PlusCircleOutlined } from "@ant-design/icons";
import OnboardModal from "../../components/onboarding/OnboardModal";
// import Chart from "../../models/chart";

const Dashboard = () => {
	const [showArchived, setShowArchived] = useState(false);

	const state = useAppState();
	const actions = useActions();
	const history = useHistory();
	useEffect(() => {
		actions.charts.clearCurrentChart();
	}, []);

	const handleCreateNewChartNav = async () => {
		await actions.charts.createNew();
		history.push("/overview/" + state.charts.all[state.charts.currentChartId].chartId);
	};

	const handleClose = async () => {
		await actions.organization.setCreateChartBanner(false);
	};
	return (
		<div className="font-montserrat" style={{ minWidth: 500 }}>
			<OnboardModal />
			<Col flex="auto" className="mb-6">
				<span className="text-2xl lg:text-3xl text-left h-full my-auto">My Dashboard</span>
			</Col>
			<Col span={24}>
				<Row gutter={[10, 10]}>
					<Col span={24}>
						<Row gutter={[10, 10]}>
							<Col span={24}>
								<TeamManagementPage hideTitle="hide" />
							</Col>
						</Row>
						<Row gutter={[10, 10]}>
							<Col span={24}>
								{!state.global.loading ? (
									<>
										<div className="mt-8">
											<div>
												<h1 className="uppercase font-bold mb-0 pl-1 inline">Charts</h1>
												<div className="inline ml-2 hover:text-coolPurple">
													<Button
														icon={<PlusCircleOutlined />}
														loading={state.charts.create.isLoading}
														onClick={handleCreateNewChartNav}>
														Create new chart
													</Button>
													<Button type="link" onClick={() => setShowArchived(!showArchived)}>
														{showArchived ? "Hide" : "Show"} archived charts
													</Button>
													{/* 
													<PlusCircleOutlined className="inline" />
													<p className="inline cursor-pointer ml-1">Create new chart</p> */}
												</div>
												<div className="m-1">
													{state.organization.organization.organizationConfig
														.showCreateChartBanner ? (
														<Alert
															className="w-max"
															message={
																<p className="m-0">
																	Build charts to track & analyze your player data
																</p>
															}
															type="success"
															closable
															afterClose={() => handleClose()}
														/>
													) : null}
												</div>
											</div>
										</div>
										<div className="flex flex-wrap">
											{[
												Object.values(state.charts.all)
													.sort((a, b) =>
														moment(moment(b.dateLastUsed)).diff(moment(a.dateLastUsed))
													)
													.filter((a) => a.isArchived == showArchived)
													.map((chart, index) => {
														return (
															<DashboardChart
																chartName={chart.title}
																lastUsed={chart.dateLastUsed}
																chartId={chart.chartId}
																key={index}
															/>
														);
													}),
											]}
										</div>
									</>
								) : (
									<Skeleton />
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</div>
	);
};

export default Dashboard;
