import CounterMini from "../components/tools/counter/Mini";
import NegativeMini from "../components/tools/neg/Mini";
import PosNegMini from "../components/tools/posNeg/Mini";
import PosNeutral from "../components/tools/posNeutral/Mini";
import PosNegNeutralMini from "../components/tools/posNegNeutral/Mini";
import NumInputMini from "../components/tools/numberInput/Mini";
import StopwatchMini from "../components/tools/stopwatch/Mini";
import PitchLocationMini from "../components/tools/pitchLocation/Mini";
import ListSelectMini from "../components/tools/listSelect/Mini";
import PlayerListSelectMini from "../components/tools/playerListSelect/Mini";
import TextInputMini from "../components/tools/textInput/Mini";
import FieldLocationMini from "../components/tools/fieldLocation/Mini";

import { WidgetTypes } from "../constants/widgetTypes";
import XYCoordinateRecord from "../components/tools/xyCoordinate/Record";

export const miniTools = {
	posNeg: {
		tool: <PosNegMini />,
		title: "Positive & Negative",
		id: WidgetTypes.POSNEG,
	},
	numberInput: {
		tool: <NumInputMini />,
		title: "Number Input",
		id: WidgetTypes.NUMBERINPUT,
	},
	pitchLocation: {
		tool: <PitchLocationMini />,
		title: "Pitch Location",
		id: WidgetTypes.PITCHLOCATION,
	},
	fieldLocation: {
		tool: <FieldLocationMini />,
		title: "Field Location",
		id: WidgetTypes.FIELDLOCATION,
	},
	listSelect: {
		tool: <ListSelectMini />,
		title: "List Select",
		id: WidgetTypes.LISTSELECT,
	},
	stopwatch: {
		tool: <StopwatchMini />,
		title: "Stopwatch",
		id: WidgetTypes.STOPWATCH,
	},
	textInput: {
		tool: <TextInputMini />,
		title: "Text Input",
		id: WidgetTypes.TEXTINPUT,
	},
	counter: {
		tool: <CounterMini />,
		title: "Positive",
		id: WidgetTypes.COUNTER,
	},
	neg: {
		tool: <NegativeMini />,
		title: "Negative",
		id: WidgetTypes.NEGCOUNTER,
	},
	posNeutral: {
		tool: <PosNeutral />,
		title: "Positive & Neutral",
		id: WidgetTypes.POSNEUTRAL,
	},
	posNegNeutral: {
		tool: <PosNegNeutralMini />,
		title: "Pos, Neg, & Neut",
		id: WidgetTypes.POSNEGNEUTRAL,
	},
	playerListSelect: {
		tool: <PlayerListSelectMini />,
		title: "Player List Select",
		id: WidgetTypes.PLAYERLISTSELECT,
	},
	xyCoordinate: {
		tool: <XYCoordinateRecord />,
		title: "Pitch Location (Coordinates)",
		id: WidgetTypes.XYCOORDINATE,
	},
};
