import React from "react";

import { Row, Col } from "antd";

export const PitchLocationDisabled = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	const LocationButton = ({ id }) => {
		let color;

		if (value === id) {
			color = "text-white bg-gray-400";
		} else {
			color = "bg-white text-gray-400";
		}

		return (
			<button className={"w-full rounded shadow font-bold h-full " + color} key={id} id={id}>
				•
			</button>
		);
	};

	return (
		<Row className="mb-4 h-48">
			<Col span={4} className="h-48">
				<Row className="h-1/5">
					<LocationButton key={10} id={10} />
				</Row>
				<Row className="h-3/5">
					<LocationButton key={13} id={13} />
				</Row>
				<Row className="h-1/5">
					<LocationButton key={15} id={15} />
				</Row>
			</Col>
			<Col span={16} className="h-full">
				<Row className="h-1/5">
					<LocationButton key={11} id={11} />
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton key={1} id={1} />
					</Col>
					<Col span={8}>
						<LocationButton key={2} id={2} />
					</Col>
					<Col span={8}>
						<LocationButton key={3} id={3} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton key={4} id={4} />
					</Col>
					<Col span={8}>
						<LocationButton key={5} id={5} />
					</Col>
					<Col span={8}>
						<LocationButton key={6} id={6} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton key={7} id={7} />
					</Col>
					<Col span={8}>
						<LocationButton key={8} id={8} />
					</Col>
					<Col span={8}>
						<LocationButton key={9} id={9} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<LocationButton key={16} id={16} />
				</Row>
			</Col>
			<Col span={4} className="h-48">
				<Row className="h-1/5">
					<LocationButton key={12} id={12} />
				</Row>
				<Row className="h-3/5">
					<LocationButton key={14} id={14} />
				</Row>
				<Row className="h-1/5">
					<LocationButton key={17} id={17} />
				</Row>
			</Col>
		</Row>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PitchLocationDisabled;
