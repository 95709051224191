import React from "react";

import { useAppState } from "../../../api";
import { getSprayChartTotal } from "../../../engines/dataEngine";
import { heatMapAndSprayChartWidgetIds } from "../../../engines/dataEngine";

import { Col } from "antd";
import SprayChart from "./SprayChart";

const PlayerSprayChart = ({ playerData }) => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const state = useAppState();

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return heatMapAndSprayChartWidgetIds(state).sprayChart.map((sprayChartId) => {
		if (state.charts.chartView.visibleSprayCharts.includes(sprayChartId)) {
			let data = {};
			Object.keys(playerData).forEach((stat) => {
				// need to do this to get the tool's name
				let toolNameArr = stat.split("_");
				//first value of the array is the name of the tool assuming the tool name doesn't contain an _
				let toolName = toolNameArr[0];

				if (toolName === state.widgets.all[sprayChartId].name) {
					data[stat] = playerData[stat];
				}
			});
			const { total } = getSprayChartTotal(data);
			if (total !== 0) {
				return (
					<Col key={playerData.Player_Id + sprayChartId}>
						<div className="flex justify-center items-center w-full">
							<div className="pl-10 pr-9">
								<p className="text-gray-400 text-md mb-0">{state.widgets.all[sprayChartId].name}</p>
								<div className="transform -rotate-45 ml-10 mr-10 mt-12 mb-12">
									<SprayChart data={data} dataPage={true} />
								</div>
								<p>
									Total hits:
									<span className="font-semibold"> {total}</span>
								</p>
							</div>
						</div>
					</Col>
				);
			} else {
				return "";
			}
		} else {
			return "";
		}
	});

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PlayerSprayChart;
