import React from "react";

export const PosNegNeutralDisabled = ({ widgetId, value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	let colorChangerPos = `${value === 1 ? "bg-gray-400 text-white" : " text-gray-400 bg-white "} `;

	let colorChangerNeg = `${value === -1 ? "bg-gray-400 text-white" : " text-gray-400 bg-white "} `;

	let colorChangerNeutral = `${value === 0 ? "bg-gray-400 text-white" : " text-gray-400 bg-white "} `;

	return (
		<div className="h-full text-center">
			<button
				id={widgetId}
				className={" w-splitThirds h-full mr-1 rounded shadow text-3xl font-bold " + colorChangerPos}>
				+
			</button>
			<button
				id={widgetId}
				className={" w-splitThirds h-full mr-1 rounded shadow text-3xl font-bold " + colorChangerNeutral}>
				•
			</button>
			<button
				id={widgetId}
				className={"w-splitThirds h-full rounded shadow text-3xl font-bold " + colorChangerNeg}>
				-
			</button>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PosNegNeutralDisabled;
