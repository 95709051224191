import React, { useEffect, useState } from "react";
import { useAppState, useActions } from "../../api";
import { Button, message, Modal, Popconfirm, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import Player from "../../models/player";

const ImportModal = ({ isVisible, setIsVisible }) => {
	//#region HOOKS ----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const [playerImportList, setPlayerImportList] = useState([]);
	const selectedTeam = actions.teams.getTeamById(state.teams.selectedTeamId);
	const [currentStep, setCurrentStep] = useState(0);
	const antIcon = <LoadingOutlined style={{ fontSize: 72 }} spin />;
	//#endregion HOOKS ------------------------------------------------------------------------------------------------------

	//Simulated time to import players
	useEffect(async () => {
		if (currentStep === 1) {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			setCurrentStep(2);
		}
	}, [currentStep]);

	//reset all states when modal is closed
	useEffect(() => {
		setPlayerImportList([]);
		setCurrentStep(0);
	}, [isVisible]);
	//#region HANDLERS -------------------------------------------------------------------------------------------------------

	const handleOk = async () => {
		let res = await actions.players.createPlayersFromImport(playerImportList);
		if (res) {
			message.success("Roster imported successfully");
		}
		setIsVisible(false);
	};

	const handleCancel = () => {
		if (currentStep === 2) message.error("Import Cancelled. Data not saved");
		setIsVisible(false);
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	const COLUMNS = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Number",
			dataIndex: "number",
			key: "number",
		},
		{
			title: "Height",
			dataIndex: "height",
			key: "height",
		},
		{
			title: "Weight",
			dataIndex: "weight",
			key: "weight",
		},
	];

	//#region JSX -------------------------------------------------------------------------------------------------------------
	const importRoster = () => {
		return (
			<div>
				<div className="w-full mx-auto mb-5 align-text-center text-center text-3xl">Import Roster from CSV</div>
				<div className="flex justify-left w-full mb-5">
					<div className="bg-coolPurple w-6 h-6 text-center text-white rounded-full mr-2">1</div>
					<div>Drag a CSV with your player roster into the field below</div>
				</div>

				<div className="flex justify-left w-full mb-5">
					<div className="bg-coolPurple w-6 h-6 text-center text-white rounded-full mr-2">2</div>
					<div>Click and drag the columns to their corresponding column headers below</div>
				</div>

				<div className="flex justify-left w-full mb-10">
					<div className="bg-coolPurple w-6 h-6 text-center text-white rounded-full mr-2">3</div>
					<div>Review the import preview for accuracy</div>
				</div>

				<div className="mb-10">
					<Importer
						processChunk={async (rows) => {
							rows.forEach((x) => {
								let newPlayer = new Player({
									name: x.name,
									dateCreated: new Date(),
									number: x.number ? parseInt(x.number) : undefined,
									height: x.height,
									weight: x.weight,
									// omitting tags because that will be tricky
									teamIds: [selectedTeam.teamId],
								});
								delete newPlayer["playerId"];
								if (!newPlayer.number) delete newPlayer["number"];
								setPlayerImportList((playerImportList) => [...playerImportList, newPlayer]);
							});
						}}
						onComplete={() => {
							setCurrentStep(1); //move ahead to the preview table loading screen
						}}
						onClose={() => {
							setCurrentStep(1); //this should never be needed, as onComplete will execute first but just in case I'm leaving it here
						}}>
						<ImporterField name="name" label="Name" />
						<ImporterField name="number" label="Number" optional />
						<ImporterField name="height" label="Height" optional />
						<ImporterField name="weight" label="Weight" optional />
					</Importer>
				</div>
			</div>
		);
	};

	const previewTable = () => {
		return (
			<div>
				<div className="w-full mx-auto mb-5 align-text-center text-center text-3xl">Player Roster Preview</div>
				<Table columns={COLUMNS} dataSource={playerImportList} rowKey="playerId" />
			</div>
		);
	};

	const importSuccessConfirmation = () => {
		return (
			<div>
				<div className="w-full mx-auto mb-5 align-text-center text-center text-3xl">
					Roster Import Successful
				</div>
				<div className="w-full mx-auto align-text-center text-center mb-3 text-coolPurple">
					Loading preview table...
				</div>
				<div className="w-full mx-auto align-text-center text-center">
					<Spin indicator={antIcon} />
				</div>
			</div>
		);
	};

	const steps = [
		{
			content: importRoster(),
		},
		{
			content: importSuccessConfirmation(),
		},
		{
			content: previewTable(),
		},
	];

	return (
		<Modal
			visible={isVisible}
			title={"Import Players"}
			width={800}
			maskClosable={false}
			onCancel={handleCancel}
			footer={[
				<>
					{currentStep === 2 ? (
						<Popconfirm
							title="Are you sure? Data will not be saved"
							okText="Yes"
							cancelText="No"
							onConfirm={handleCancel}>
							<Button ghost type="danger" key="back">
								Cancel
							</Button>
						</Popconfirm>
					) : (
						<Button ghost type="danger" key="back" onClick={handleCancel}>
							Cancel
						</Button>
					)}
				</>,
				<Button key="submit" type="primary" onClick={handleOk} disabled={currentStep === 2 ? false : true}>
					Create Players
				</Button>,
			]}>
			<div>
				<div>{steps[currentStep].content}</div>
			</div>
		</Modal>
	);
	//#endregion JSX -----------------------------------------------------------------------------------------------------------
};

export default ImportModal;
