export const state = {
	showImportModal: false,
	importType: "",
	importReadyToComplete: false,
	existingCharts: [],
	newChartName: "",
	newChartId: "",
	currentStep: 0,
	csvString: "",
	statsChartId: "",
	newPlayers: [],
	newWidgets: [],
};
