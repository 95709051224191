import axios from "axios";
import { Organization } from "../../constants/api";

export const api = {
	async getOrganization() {
		try {
			const getOrganizationRes = await axios.post(Organization.read);
			return getOrganizationRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async createOrganization(newOrganizationPayload) {
		const createOrganizationRes = await axios.post(Organization.create, newOrganizationPayload);
		return createOrganizationRes;
	},
	async updateOrganization(updateOrganizationPayload) {
		const updateOrganizationRes = await axios.post(Organization.update, updateOrganizationPayload);
		return updateOrganizationRes;
	},
};
