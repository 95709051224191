export const Strikes = [
	"TOP_LEFT_STRIKE",
	"TOP_CENTER_STRIKE",
	"TOP_RIGHT_STRIKE",
	"MID_LEFT_STRIKE",
	"MID_CENTER_STRIKE",
	"MID_RIGHT_STRIKE",
	"BOT_LEFT_STRIKE",
	"BOT_CENTER_STRIKE",
	"BOT_RIGHT_STRIKE",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
];

export const Balls = [
	"TOP_LEFT_BALL",
	"MID_LEFT_BALL",
	"BOT_LEFT_BALL",
	"TOP_CENTER_BALL",
	"BOT_CENTER_BALL",
	"TOP_RIGHT_BALL",
	"MID_RIGHT_BALL",
	"BOT_RIGHT_BALL",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
];
