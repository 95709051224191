import StatModel from "../../models/stat";
import { stat } from "../../constants/apiJson";

export const state = {
	currentEditStatId: "",
	new: new StatModel(stat),
	recordingPlayerId: "",
	currentStats: {},
	activeGroup: {},
	rawStatsPageStats: {},
	rawStatsPageDates: [],
	rawStatsHasMoreStats: false,
	editingStatModal: false,
	playerFilter: [],
	chartFilter: "",
	flaggedFilter: false,
	heatMapSearch: "",
	coordinateHeatMapSearch: "",
	rawStatsCsvString: "",
	sprayChartSearch: "",
	nonLoadedStats: 0,
	directStatsCsvString: "",
	statToEditId: {},
	nestedStats: "",
};
