import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Checkbox, Col, Divider, Form, Input, Row, Modal } from "antd";
import { useActions } from "../../api";
import { CheckCircleFilled } from "@ant-design/icons";
import AppStoreLogo from "../../assets/landing-page/app-store-logo.png";
import { useLocation } from "react-router-dom";
import * as moment from "moment-timezone";

/**
 * This is the signup form to actually create an account (requires an existing organization)
 */
const CreateAccount = () => {
	const history = useHistory();
	const actions = useActions();
	const [loading, setLoading] = useState(false);

	// grab the query params
	const search = useLocation().search;
	const name = new URLSearchParams(search).get("name");
	const email = new URLSearchParams(search).get("email");
	const subscriptionType = new URLSearchParams(search).get("type");

	const handleSubmit = async (values) => {
		setLoading(!loading);
		const createRequest = {
			adminName: values.name,
			adminEmail: values.email,
			adminPassword: values.password,
			subscriptionType: subscriptionType,
			timezone: moment.tz.guess(),
		};

		try {
			await actions.organization.createOrganization(createRequest);
			await actions.user.loginUser({
				email: createRequest.adminEmail,
				password: createRequest.adminPassword,
			});
		} catch (err) {
			Modal.error({
				title: "Error creating account",
				content: (
					<>
						{`Reason: ${err.message}`}
						<br />
						<br />
						{"Please try again or contact team@tappsports.com for support."}
					</>
				),
			});
		}
		setLoading(false);
	};

	const agreementlinkStyle = "text-gray-500 hover:underline hover:text-coolPurple mx-auto sm:mx-0";
	const formTailLayout = {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	};

	const SubmitButton = () => {
		const baseStyles = "ml-1 text-white rounded bg-white w-full p-2 text-lg font-normal";
		const enabledStyles =
			"bg-coolPurple hover:shadow-lg hover:font-extrabold transform hover:scale-105 transition duration-300 ease-in-out";
		const disabledStyles = "bg-coolPurple cursor-default";
		return (
			<button
				disabled={loading}
				type="submit"
				className={baseStyles + " " + (loading ? disabledStyles : enabledStyles)}>
				{loading ? "Loading..." : "Create"}
			</button>
		);
	};

	const BackButton = () => {
		const baseStyles = "mr-1 rounded bg-white w-full p-2 text-lg font-normal";
		const enabledStyles =
			"border-2 border-coolPurple bg-white text-coolPurple hover:shadow-lg hover:font-extrabold transform hover:scale-105 transition duration-300 ease-in-out";
		const disabledStyles = "bg-gray-600 text-white cursor-default hidden";
		return (
			<button
				disabled={loading}
				onClick={() => history.goBack()}
				className={baseStyles + " " + (loading ? disabledStyles : enabledStyles)}>
				Go back
			</button>
		);
	};

	const makeCheckmarkRow = (text) => {
		return (
			<p className="text-md mb-4 flex items-center ">
				<CheckCircleFilled style={{ fontSize: 20, color: "#6163FF" }} />
				&nbsp;&nbsp;{text}
			</p>
		);
	};

	const whatToScore = ["Anything", "Anywhere", "Anytime"];

	const whatUserGets = ["More player buy-in", "Increased competition", "Better player development", "More wins"];

	return (
		<div className="site-layout-content-narrow">
			<div className="relative z-1 bg-white rounded shadow-md">
				<Row gutter={[10, 10]}>
					<Col span={8} xs={0} sm={0} md={8}>
						<div className="w-full h-full p-2 bg-gray-200">
							<div className="my-12 w-56 mx-auto">
								<p className="text-xl mb-6 font-semibold text-coolPurple">Win the day, every day.</p>
								<p className="text-lg mt-10 mb-6 font-semibold">With tapp, you can score</p>

								{whatToScore.map((reason) => {
									return makeCheckmarkRow(reason);
								})}

								<p className="text-lg mt-10 mb-6 font-semibold">Achieving</p>

								{whatUserGets.map((reason) => {
									return makeCheckmarkRow(reason);
								})}
							</div>
						</div>
					</Col>

					<Col span={16} xs={24} sm={24} md={16}>
						<div className="w-full p-10">
							<h1 className="mb-2 text-black text-3xl font-medium sm:text-left">Download the App</h1>
							<p>
								For the best experience, we recommend creating an account on the mobile app. Please
								download it here to get started!
							</p>
							<Row justify="center">
								<Link
									to={{
										pathname: "https://apps.apple.com/us/app/tapp-sports/id1636570218",
									}}
									target="_blank">
									<img
										className="mt-2 w-36 md:w-40 transform hover:scale-105 transition duration-300 ease-in-out"
										src={AppStoreLogo}
										alt="App Store"
									/>
								</Link>
							</Row>
							<h1 className="mb-5 mt-5 text-black text-3xl font-medium sm:text-left">
								Create an Account
							</h1>
							<Divider />

							<div>
								<Form
									name="normal_signup"
									loading={loading}
									initialValues={{ remember: true }}
									labelCol={{ span: 8 }}
									wrapperCol={{ span: 16 }}
									size="large"
									onFinish={handleSubmit}>
									<Form.Item
										name="name"
										label="Name"
										initialValue={name}
										rules={[{ required: true, message: "Please input your name. " }]}>
										<Input />
									</Form.Item>
									<Form.Item
										name="email"
										label="E-mail"
										initialValue={email}
										rules={[
											{
												type: "email",
												message: "The input is not a valid E-mail. ",
											},
											{
												required: true,
												message: "Please input your E-mail. ",
											},
										]}>
										<Input />
									</Form.Item>
									<Form.Item
										name="password"
										label="Password"
										rules={[
											{
												required: true,
												message: "Please input your password. ",
											},
											{
												min: 6,
												message: "Passwords must be at least 6 characters. ",
											},
										]}
										hasFeedback>
										<Input.Password />
									</Form.Item>
									<Form.Item
										name="agreement"
										valuePropName="checked"
										rules={[
											{
												validator: (_, value) =>
													value
														? Promise.resolve()
														: Promise.reject(
																new Error(
																	"You must accept the agreements to use this application."
																)
														  ),
											},
										]}
										wrapperCol={formTailLayout}>
										<Checkbox>
											I agree to the{" "}
											<Link className={agreementlinkStyle} to="/privacy-policy">
												Privacy Policy
											</Link>{" "}
											and{" "}
											<Link className={agreementlinkStyle} to="/terms">
												Terms of Use
											</Link>
										</Checkbox>
									</Form.Item>
									<Form.Item wrapperCol={formTailLayout}>
										<div className="flex my-2">
											<BackButton />
											<SubmitButton />
										</div>
									</Form.Item>
									<Form.Item wrapperCol={formTailLayout}>
										<p className="mt-4 mb-2 text-gray-800">
											Have an account?{" "}
											<Link to="/login">
												<span className="text-coolPurple font-semibold hover:underline cursor-pointer">
													Login
												</span>
											</Link>
										</p>
									</Form.Item>
								</Form>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default CreateAccount;
