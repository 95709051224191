import React, { useEffect, useState, useRef } from "react";
import { lacrosseCharts } from "../../../constants/templates";

// components
import FadeInSection from "../../../components/global/fadeInSection";
import Testimonials from "../../../components/global/testimonials";
import AttentionGrabberHeader from "../../../components/global/attentionGrabberHeader";
import LandingPageBanner from "../../../components/global/landingPageBanner";
import GetTappTodayFooter from "../../../components/global/getTappTodayFooter";

// mockups
import LacrosseMockup from "../../../assets/landing-page/lacrosse-mockup.png";

import LandingPageTemplates from "../../../components/global/landingPageTemplates";
import CenterGalleryCard from "../../../components/global/centerGalleryCard";

import { Row, Col } from "antd";

const Lacrosse = () => {
	const templateRef = useRef(null);
	const [activeTemplate, setActiveTemplate] = useState("Men's Lacrosse Stats");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const useCaseClicked = (title) => {
		switch (title) {
			case "Game evaluations":
				setActiveTemplate("Men's Lacrosse Stats");
				break;
			case "Tryout evaluations":
				setActiveTemplate("Men's Lacrosse Stats");
				break;
			case "Camp evaluations":
				setActiveTemplate("Men's Lacrosse Stats");
				break;
			case "Practice evaluations":
				setActiveTemplate("Men's Lacrosse Stats");
				break;
		}

		templateRef.current.scrollIntoView();
	};

	const valueProp = [
		"Customizable templates",
		"Unlimited Players",
		"Unlimited Charts",
		"Evaluate anything, anywhere, anytime",
		"Instant reports",
	];

	return (
		<div>
			<div className="mt-5 site-layout-content">
				<FadeInSection>
					<AttentionGrabberHeader
						header={<span>Lacrosse evaluations anywhere, anytime</span>}
						description="Never worry about uploading stats from sheet to computer again."
						mockup={LacrosseMockup}
						valueProp={valueProp}
					/>
					<Testimonials />
				</FadeInSection>
			</div>

			<LandingPageBanner title="Use Cases" />
			<div className="site-layout-content">
				<Row gutter={[20, 20]}>
					<Col span={24} order={1} md={{ span: 12, order: 1 }}>
						<CenterGalleryCard
							title={"Game evaluations"}
							content={
								"Know your stats year round. Every season game; every tournament game; every fall, winter, spring, and summer - all in one place."
							}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={2} md={{ span: 12, order: 2 }}>
						<CenterGalleryCard
							title={"Tryout evaluations"}
							content={
								"Evaluating every player during a tryout doesn't have to be a pain. Seamlessly record and share stats for every player."
							}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={3} md={{ span: 12, order: 3 }}>
						<CenterGalleryCard
							title={"Camp evaluations"}
							content={"Who doesn't want the hard number of how they performed during one of your camps?"}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={4} md={{ span: 12, order: 4 }}>
						<CenterGalleryCard
							title={"Practice evaluations"}
							content={
								"Practices have more reps and more opportunities for improvement. Don't miss an opportunity for increase player performance."
							}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
				</Row>
			</div>

			{/** Templates */}
			<LandingPageBanner title="Lacrosse Templates" />
			<div ref={templateRef} className="site-layout-content text-center w-full md:w-1/2">
				<p className="text-lg lg:text-xl">
					Our templates are a great way to get started. Each one is completely custimizable so you can measure
					the metrics that matter to you.
				</p>
			</div>
			<LandingPageTemplates
				charts={lacrosseCharts}
				activeIndex={activeTemplate}
				setActiveIndex={setActiveTemplate}
			/>

			{/** Landing footer (not the real footer) */}
			<GetTappTodayFooter />
		</div>
	);
};

export default Lacrosse;
