import React from "react";

export const NumberInputMini = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return value === undefined ? (
		<p className="w-full mb-0 rounded text-center font-bold bg-gray-100 flex text-center items-center justify-center">
			98
		</p>
	) : (
		<div className="h-full text-center w-full">
			<button disabled className="h-full rounded text-xl bg-gray-200 text-gray-500 w-full">
				{value}
			</button>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default NumberInputMini;
