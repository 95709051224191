import React, { useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { screenSizes } from "../../constants/screenSize";
import { analytics } from "../../firebase/firebase";
import { ErrorBoundary } from "react-error-boundary";
import GlobalErrorBoundary from "../../components/errorBoundaries/GlobalErrorBoundary";
import { useAppState, useActions } from "../../api";
import Take from "../../pages/mobile/take";
import View from "../../pages/mobile/view";
import Dashboard from "../../pages/desktop/dashboard";
import DashboardMobile from "../../pages/mobile/dashboard";
import NewChartPage from "../../pages/desktop/chartPage";
import Profile from "../../pages/desktop/profile";
import DataDashboardPage from "../../pages/desktop/dataDashboardPage";
import TakeStats from "../../pages/desktop/takeStats";
import Expired from "../../pages/global/expired";
import Snapshots from "../../pages/print/snapshots";
import WaitlistSignup from "../../pages/global/waitlistSignup";
import SignupOrganization from "../../pages/global/signupOrganization";
import Landing from "../../pages/global/landing";
import Product from "../../pages/global/product";
import UseCases from "../../pages/global/use-cases";
import Pricing from "../../pages/global/pricing";
import DocumentDisplay from "../../pages/global/document";
import CustomFooter from "../../components/global/footer";
import Login from "../../pages/global/login";
import PublicHeader from "../../components/global/publicHeader";
import { Layout } from "antd";
import MobileProfile from "../../pages/mobile/profile";
import AppHeader from "../../components/global/appHeader";
import MobileEditChart from "../../pages/mobile/editChart";
import AppLoading from "../../components/global/appLoading";
import PrintArea from "../../pages/print/printArea";
import MobileHeader from "../../components/global/mobileHeader";
import TeamManagementPage from "../../pages/desktop/teamManagementPage";
import TeamRosterPage from "../../pages/desktop/teamRosterPage";
import OperationResult from "../../pages/global/operationResult";
import Blog from "../../pages/global/blog/blog";
import BlogPost from "../../pages/global/blog/blogPost";
import Baseball from "../../pages/global/sports/baseball";
import Softball from "../../pages/global/sports/softball";
import Lacrosse from "../../pages/global/sports/lacrosse";
import Volleyball from "../../pages/global/sports/volleyball";
import CreateAccount from "../../pages/global/createAccount";
import JoinOrganization from "../../pages/global/joinOrganization";
import CCBCShowcase from "../../pages/global/teamsPublicData/ccbcShowcase";
const { Content, Footer } = Layout;

const AppRouter = () => {
	const state = useAppState();
	const actions = useActions();
	const history = useHistory();

	/**
	 * This hook fires upon loading the site
	 */
	useEffect(() => {
		actions.global.getBlogPosts();
		setContext(history.location.pathname);
		window.innerWidth <= screenSizes.collapseSidebar
			? actions.user.setSidebarCollapse(true)
			: actions.user.setSidebarCollapse(false);
	}, []);

	/**
	 * This hook fires upon any change to the history (URL navigation)
	 */
	useEffect(() => {
		const unlisten = history.listen(() => {
			setContext(history.location.pathname);

			//disable the purple backsplash if blog or blog post is loaded
			// TODO I'd rather do this some other way
			if (state.global.BlogPosts.some((post) => state.global.context === `/${post.route}`)) {
				actions.global.setShowLandingBackground(false);
			} else {
				actions.global.setShowLandingBackground(true);
			}
		});
		return () => {
			unlisten();
		};
	}, [history]);

	/**
	 * The purpose of the custom redirects is to catch any invalid paths and
	 * redirect them to an understood and specified page
	 */
	const DashboardRedirect = () => <Redirect to="/dashboard" />;
	const IndexRedirect = () => <Redirect to="/" />;

	/**
	 * Store the context in the state
	 */
	const setContext = (location) => {
		actions.global.setContext(location);
	};

	const errorHandler = (error) => {
		analytics.logEvent("ERROR", { errorMessage: error });
	};

	const authenticatedRoutes = {
		"/": DashboardRedirect,
		"/terms": DocumentDisplay,
		"/privacy-policy": DocumentDisplay,
		"/dashboard": Dashboard,
		"/profile": Profile,
		"/roster": TeamRosterPage,
		"/teams": TeamManagementPage,
		"/data": DataDashboardPage,
		"/overview": NewChartPage,
		"/overview/:id": NewChartPage,
		"/take/:id": Take,
		"/takeStats/:id": TakeStats,
		"/snapshots": Snapshots,
		"*": DashboardRedirect, // this acts as the 404 and must be at the bottom
	};
	const unauthenticatedRoutes = {
		"/": Landing,
		"/blog/:slug": BlogPost,
		"/blog": Blog,
		"/terms": DocumentDisplay,
		"/privacy-policy": DocumentDisplay,
		"/pricing": Pricing,
		"/login": Login,
		"/signup": WaitlistSignup,
		"/create-account": CreateAccount,
		"/join-organization": JoinOrganization,
		"/create-organization": SignupOrganization,
		"/expired": Expired,
		"/product": Product,
		"/use-cases": UseCases,
		"/baseball": Baseball,
		"/softball": Softball,
		"/lacrosse": Lacrosse,
		"/volleyball": Volleyball,
		"/subscription": OperationResult,
		"/showcases/ccbc/august-2022": CCBCShowcase,
		"*": IndexRedirect, // this acts as the 404 and must be at the bottom
	};

	const mobileRoutes = {
		"/": DashboardRedirect,
		"/dashboard": DashboardMobile,
		"/take": Take,
		"/new": MobileEditChart,
		"/edit": MobileEditChart,
		"/view": View,
		"/profile": MobileProfile,
		"/snapshots": Snapshots,
		"*": DashboardRedirect, // this acts as the 404 and must be at the bottom
	};
	const makeRoute = (routeMap, key) => {
		return key === "/" ? (
			<Route exact path={key} component={routeMap[key]} />
		) : (
			<Route path={key} component={routeMap[key]} />
		);
	};
	const unauthenticatedRoutesJSX = () => {
		return (
			<div
				className="w-full pt-5 sm:pt-0"
				style={{ minWidth: 300, minHeight: "100vh", backgroundColor: "#F5F5F7" }}>
				<div className={state.global.showLandingBackground ? `angle-block z-0 bg-coolPurple` : ""} />
				<PublicHeader />
				<Layout className="site-layout">
					<div className="mx-auto w-full ">
						<Switch>
							{Object.keys(unauthenticatedRoutes).map((key) => makeRoute(unauthenticatedRoutes, key))}
						</Switch>
					</div>
				</Layout>
				<Footer>
					<CustomFooter />
				</Footer>
			</div>
		);
	};
	const AuthenticatedRoutesJSX = () => {
		return (
			<div>
				<PrintArea />
				<div className="w-full absolute z-2" style={{ minWidth: 300, backgroundColor: "#f9f9f9" }}>
					<AppHeader />
					<Layout className="app-layout">
						<Content className="max-w-screen-2xl overflow-x-auto mx-auto w-full">
							<div className="app-layout-content">
								<Switch>
									{Object.keys(authenticatedRoutes).map((key) => makeRoute(authenticatedRoutes, key))}
								</Switch>
							</div>
						</Content>
					</Layout>
					<Footer>
						<CustomFooter />
					</Footer>
				</div>
			</div>
		);
	};
	const mobileRoutesJSX = () => {
		return (
			<div style={{ backgroundColor: "#f9f9f9" }}>
				<MobileHeader />
				<div className="mobile-layout-content w-full h-full overflow-x-auto overflow-y-auto mx-auto">
					<Switch>{Object.keys(mobileRoutes).map((key) => makeRoute(mobileRoutes, key))}</Switch>
				</div>
			</div>
		);
	};
	return (
		<>
			{state.global.authLoading ? (
				<AppLoading />
			) : state.user.authenticated ? (
				<ErrorBoundary FallbackComponent={GlobalErrorBoundary} onError={errorHandler}>
					{window.innerWidth < screenSizes.mobileMax ? mobileRoutesJSX() : AuthenticatedRoutesJSX()}
				</ErrorBoundary>
			) : (
				unauthenticatedRoutesJSX()
			)}
		</>
	);
};

export default AppRouter;
