export default class BlogPost {
	constructor(date, title, description, route, imageUrl, content) {
		this.dateCreated = date;
		this.title = title;
		this.description = description;
		this.route = route;
		this.imageUrl = imageUrl;
		this.content = content;
	}
}
