export const setContext = ({ state }, context) => {
	state.global.context = context;
};

export const setAuthLoading = ({ state }, loading) => {
	state.global.authLoading = loading;
};

export const setLoading = ({ state }, loading) => {
	state.global.loading = loading;
};

export const setError = ({ state }, error) => {
	state.global.error = error;
};

export const setIsPrinting = ({ state }, isPrinting) => {
	state.global.isPrinting = isPrinting;
};

export const setShowLandingBackground = ({ state }, showLandingBackground) => {
	state.global.showLandingBackground = showLandingBackground;
};

export const getBlogPosts = async ({ effects, state }) => {
	let blogPosts = [];
	try {
		blogPosts = await effects.global.api.getAllBlogPosts();
	} catch (error) {
		throw new Error(error);
	}
	state.global.BlogPosts = blogPosts;

	return state.global.BlogPosts;
};
