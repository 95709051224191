import ChartModel from "../../models/chart";

export const createChartFromCSVImport = async ({ state, effects }) => {
	let payload = {
		chartCSVString: state.imports.csvString,
	};

	let createChartFromCSVRes;

	try {
		createChartFromCSVRes = await effects.imports.api.createCSV(payload);
		state.imports.existingCharts = createChartFromCSVRes.data.response.existingCharts;
		state.imports.newChartId = createChartFromCSVRes.data.response.newChart.chartId;
		state.imports.newPlayers = createChartFromCSVRes.data.response.newPlayers;
		state.imports.newWidgets = createChartFromCSVRes.data.response.newWidgets;

		// add chart to overmind charts.all
		const chart = new ChartModel(createChartFromCSVRes.data.response.newChart);
		state.charts.all[chart.chartId] = { ...chart };
	} catch (error) {
		throw new Error(error);
	}
};

export const createStatsFromCSVImport = async ({ effects }, payload) => {
	await effects.imports.api.createStatsCSV(payload);
};

export const setCsvString = ({ state }, newVal) => {
	state.imports.csvString = newVal;
};

export const setShowImportModal = ({ state }, newVal) => {
	state.imports.showImportModal = newVal;
};

export const setImportReadyToComplete = ({ state }, newVal) => {
	state.imports.importReadyToComplete = newVal;
};

export const setImportType = ({ state }, type) => {
	state.imports.importType = type;
};

export const setNewChartName = ({ state }, newVal) => {
	state.imports.newChartName = newVal;
};

export const setCurrentStep = ({ state }, newStep) => {
	state.imports.currentStep = newStep;
};

export const clearNewName = ({ state }) => {
	state.imports.newChartName = "";
};

export const setStatsChartId = ({ state }, newVal) => {
	state.imports.statsChartId = newVal;
};

export const clearStatsChartId = ({ state }) => {
	state.imports.statsChartId = "";
};

export const clearAllFields = ({ state }) => {
	state.imports.csvString = "";
	state.imports.currentStep = 1;
	state.imports.existingCharts = [];
	state.imports.importType = "";
	state.imports.newChartId = "";
	state.imports.newChartName = "";
	state.imports.statsChartId = "";
	state.imports.newPlayers = [];
	state.imports.newWidgets = [];
};
