import moment from "moment";

export default class Dashboard {
	constructor(apiResponse) {
		this.timestamp = moment().format("YYYY/MM/DD hh:mm:ss");
		this.dataList = apiResponse.dataList ?? [];
		this.timeSlices = apiResponse.times ?? [];
		this.playerIds = apiResponse.players ?? [];
		this.widgetIds = apiResponse.widgets ?? [];
	}
}
