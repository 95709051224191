import { WidgetTypes } from "../constants/widgetTypes";

import Counter from "../components/tools/counter/Disabled";
import PosNeg from "../components/tools/posNeg/Disabled";
import PosNegNeutral from "../components/tools/posNegNeutral/Disabled";
import NumberInput from "../components/tools/numberInput/Disabled";
import Stopwatch from "../components/tools/stopwatch/Disabled";
import PitchLocation from "../components/tools/pitchLocation/Disabled";
import ListSelect from "../components/tools/listSelect/Disabled";
import Neg from "../components/tools/neg/Disabled";
import PosNeutral from "../components/tools/posNeutral/Disabled";
import PlayerListSelect from "../components/tools/playerListSelect/Disabled";
import TextInput from "../components/tools/textInput/Disabled";
import FieldLocation from "../components/tools/fieldLocation/Disabled";

export const getDisabledTool = (type, value) => {
	if (type === WidgetTypes.COUNTER) {
		return <Counter value={value} />;
	} else if (type === WidgetTypes.POSNEG) {
		return <PosNeg value={value} />;
	} else if (type === WidgetTypes.POSNEGNEUTRAL) {
		return <PosNegNeutral value={value} />;
	} else if (type === WidgetTypes.NUMBERINPUT) {
		return <NumberInput value={value} />;
	} else if (type === WidgetTypes.STOPWATCH) {
		return <Stopwatch value={value} />;
	} else if (type === WidgetTypes.PITCHLOCATION) {
		return <PitchLocation value={value} />;
	} else if (type === WidgetTypes.LISTSELECT) {
		return <ListSelect value={value} />;
	} else if (type === WidgetTypes.NEGCOUNTER) {
		return <Neg value={value} />;
	} else if (type === WidgetTypes.POSNEUTRAL) {
		return <PosNeutral value={value} />;
	} else if (type === WidgetTypes.PLAYERLISTSELECT) {
		return <PlayerListSelect value={value} />;
	} else if (type === WidgetTypes.TEXTINPUT) {
		return <TextInput value={value} />;
	} else if (type === WidgetTypes.FIELDLOCATION) {
		return <FieldLocation value={value} />;
	}
};

export default getDisabledTool;
