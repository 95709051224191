import React from "react";
import LightWordmark from "../../assets/wordmark-light.png";
import { LoadingOutlined } from "@ant-design/icons";

const AppLoading = () => {
	return (
		<div className="w-screen h-screen bg-darkGray">
			<div className="flex flex-col gap-10 h-full w-full items-center justify-center">
				<img className="w-48" src={LightWordmark} alt="tapp" />
				<LoadingOutlined style={{ fontSize: 48, color: "#f9f9f9" }} />
			</div>
		</div>
	);
};

export default AppLoading;
