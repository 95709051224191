import { Col, Row } from "antd";
import React, { useEffect } from "react";
import ipadWithWidgets from "../../assets/landing-page/ipadWithWidgets.png";
import TrackingMockup from "../../assets/landing-page/pitch-tracking-animation.GIF";
import instantAnalytics from "../../assets/landing-page/landing-instant-analytics.png";
import Mockup from "../../assets/landing-page/multi-device-mockup.png";
import LeftGalleryCard from "../../components/global/leftGalleryCard";
import FadeInSection from "../../components/global/fadeInSection";
import LandingPageButton from "../../components/global/landingPageButton";
import { useHistory } from "react-router-dom";

const Product = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const history = useHistory();

	const handleSignUpNav = () => {
		history.push("/pricing");
	};

	return (
		<div>
			<div className="site-layout-content mt-5">
				<Col>
					<Row justify="center" wrap={true} gutter={[10, 25]}>
						<Col span={24}>
							<Row justify="center" className="mb-8">
								<Col>
									<div className="text-5xl lg:text-5xl w-full text-center text-white py-1 px-16 sm:px-24 md:px-48 lg:px-3">
										<span className="text-white font-medium">T</span>
										<span className="font-normal">rack</span>
									</div>
								</Col>
								<Col>
									<div className="text-5xl lg:text-5xl w-full text-center  text-white py-1 px-16 sm:px-24 md:px-48 lg:px-3">
										<span className="text-white font-medium">A</span>
										<span className="font-normal">nalyze</span>
									</div>
								</Col>
								<Col>
									<div className="text-5xl lg:text-5xl w-full text-center  text-white py-1 px-16 sm:px-24 md:px-48 lg:px-3">
										<span className="text-white font-medium">P</span>
										<span className="font-normal">redict</span>
									</div>
								</Col>
								<Col>
									<div className="text-5xl lg:text-5xl w-full text-center  text-white py-1 px-16 sm:px-24 md:px-48 lg:px-3">
										<span className="text-white font-medium">P</span>
										<span className="font-normal">erform</span>
									</div>
								</Col>
							</Row>
							<Row justify="center">
								<Col span={24} md={8}>
									<p className="text-xl w-full text-center font-normal text-white mb-5">
										Customize your charting experience with instant analysis and reports
									</p>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="mt-24" justify="center">
						<Col span={24}>
							<img
								className="w-full h-auto mx-auto"
								src={Mockup}
								alt="Logo"
								style={{ minWidth: 200, maxWidth: 1200 }}
							/>
						</Col>
					</Row>
					<Row className="h-20 sm:h-24 md:h-60 lg:h-48" />
				</Col>
			</div>

			{/** Heading banner */}

			<FadeInSection>
				<div className="w-full bg-coolPurple my-10">
					<div className="site-layout-content">
						<Row className="w-full flex items-center justify-between justify-center h-48 py-5">
							<span className="flex text-4xl sm:text-5xl py-1 pr-3 font-bold text-white self-center">
								How does tapp work?
							</span>
							<LandingPageButton onClick={handleSignUpNav}>Sign up</LandingPageButton>
						</Row>
					</div>
				</div>
			</FadeInSection>

			{/** Step 1, create the charts */}

			<div className="site-layout-content">
				<FadeInSection>
					<div className="w-full text-4xl sm:text-5xl font-medium mb-12 mt-1 text-gray-700">
						1) Create your charts
					</div>
				</FadeInSection>
			</div>

			<FadeInSection>
				<div className="site-layout-content">
					<div className="w-full h-full rounded-sm my-5 bg-white">
						<Row align="middle" justify="space-between" className="w-full h-full">
							<Col span={24} order={1} md={{ span: 8, order: 2 }} className="px-4 pt-12 md:pt-0 my-8">
								<Row className="w-full h-fit">
									<div className="w-full mb-1 text-2xl md:text-2xl lg:text-3xl text-center font-medium text-gray-700">
										With tapp, you can
									</div>
								</Row>
								<Row className="w-full h-fit">
									<div className="w-full text-5xl md:text-4xl lg:text-5xl text-center font-semibold">
										Track Anything
									</div>
								</Row>
								<Row className="w-full mt-5">
									<div className="px-4 lg:px-6 xl:px-8 text-lg lg:text-xl text-center text-gray-700">
										Use tapp's powerful toolset to create custom charts and start tracking the
										metrics that matter.
									</div>
								</Row>
							</Col>
							<Col span={24} order={1} md={{ span: 16, order: 1 }}>
								<img className="h-auto w-full p-2 md:p-0" src={ipadWithWidgets} alt="Mockup" />
							</Col>
						</Row>
					</div>
				</div>
			</FadeInSection>

			<FadeInSection>
				<div className="site-layout-content">
					<div className="grid lg:grid-cols-2 gap-4">
						<div className="w-full bg-white rounded shadow-sm">
							<div className="p-8">
								<h2 className="text-3xl md:text-4xl font-bold text-gray-800">A Powerful Toolbox</h2>
								<p className="text-gray-600 text-lg mt-3">
									We provide a toolbox for you to use when creating your charts. This includes
									positive/negative buttons for good/bad plays, counters, stopwatches, numeric inputs,
									pitch/field locations, custom dropdown lists, and many more.
								</p>
							</div>
						</div>
						<div className="w-full bg-white rounded shadow-sm">
							<div className="p-8">
								<h2 className="text-3xl md:text-4xl font-bold text-gray-800">
									Completely Custom Charts
								</h2>
								<p className="text-gray-600 text-lg mt-3">
									Your creativity combined with tapp's toolbox results in endless charting
									possibilities. You can mix-and-match tools to create the perfect chart to track your
									drills, competitions, tryouts, games, and more.
								</p>
							</div>
						</div>
					</div>
				</div>
			</FadeInSection>

			{/** Step 2, input the data */}

			<div className="site-layout-content">
				<FadeInSection>
					<div className="w-full text-4xl sm:text-5xl font-medium mb-12 mt-24 text-gray-700">
						2) Input the data
					</div>
				</FadeInSection>
			</div>

			<FadeInSection>
				<div className="site-layout-content">
					<div className="grid lg:grid-cols-2 gap-4">
						<div className="w-full mb-4">
							<div className="p-8 h-1/2 bg-white rounded shadow-sm mb-4">
								<h2 className="text-3xl md:text-4xl font-bold text-gray-800">
									Get Rid of Paper Charts
								</h2>
								<p className="text-gray-600 text-lg mt-3">
									Recording data on paper charts or spreadsheets can be a major headache, and it's far
									too time-consuming for coaches with busy schedules. Using tapp keeps your data clean
									and organized, plus it will save you hours of time spent manually aggregating the
									data with spreadsheets.
								</p>
							</div>
							<div className="p-8 h-1/2 bg-white rounded shadow-sm mb-4">
								<h2 className="text-3xl md:text-4xl font-bold text-gray-800">
									Track Anywhere, Anytime
								</h2>
								<p className="text-gray-600 text-lg mt-3">
									Tapp is a web-application, which means you can track and access your data from any
									device at any time. Whether you are on the practice field, in your office, or
									sitting on your couch, we've got you covered.
								</p>
							</div>
						</div>
						<div className="h-full bg-white rounded shadow-sm p-4 mb-4">
							<img className="max-w-xs w-full h-auto mx-auto" src={TrackingMockup} alt="Mockup" />
						</div>
					</div>
				</div>
			</FadeInSection>

			{/** Step 3, analyze and share the data */}

			<div className="site-layout-content">
				<FadeInSection>
					<div className="w-full text-4xl sm:text-5xl font-medium mb-12 mt-24 text-gray-700">
						3) Analyze and share your data
					</div>
				</FadeInSection>
			</div>

			<FadeInSection>
				<div className="site-layout-content">
					<LeftGalleryCard
						title={"Instant analytics"}
						content={
							"Jumpstart the player development process with real time data insights and player reports."
						}
						image={instantAnalytics}
					/>
				</div>
			</FadeInSection>

			<FadeInSection>
				<div className="site-layout-content">
					<div className="grid lg:grid-cols-2 gap-4">
						<div className="w-full bg-white rounded shadow-sm">
							<div className="p-8">
								<h2 className="text-3xl md:text-4xl font-bold text-gray-800">Understand Your Data</h2>
								<p className="text-gray-600 text-lg mt-3">
									Tapp will generate player performance reports and analytics that are easy to
									understand. Insights that are too complicated are useless, so we keep the analytics
									as simple as possible for you and your players.
								</p>
							</div>
						</div>
						<div className="w-full bg-white rounded shadow-sm">
							<div className="p-8">
								<h2 className="text-3xl md:text-4xl font-bold text-gray-800">Immediate Feedback</h2>
								<p className="text-gray-600 text-lg mt-3">
									Minimizing the time between practice and feedback will create high-IQ players and
									jumpstart their development process. You can also use the data to drive competition
									by sharing leaderboards with the team.
								</p>
							</div>
						</div>
					</div>
				</div>
			</FadeInSection>

			{/** Footer with call to action */}

			<div className="site-layout-content">
				<FadeInSection>
					<div className="grid w-full items-center justify-center mt-20">
						<div>
							<Row className="flex items-center justify-center w-full h-fit">
								<div className="text-5xl lg:text-6xl text-center text-gray-700">
									Ready to try it out?
								</div>
							</Row>
						</div>
						<div className="flex items-center justify-center w-full h-fit mt-12">
							<LandingPageButton onClick={handleSignUpNav}>Sign up</LandingPageButton>
						</div>
					</div>
				</FadeInSection>
			</div>
		</div>
	);
};

export default Product;
