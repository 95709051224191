import React, { useEffect } from "react";
import { Card, Row, Divider } from "antd";
import { Link } from "react-router-dom";
import { useAppState, useActions } from "../../../api";
import moment from "moment/moment";

const Blog = () => {
	const state = useAppState();
	const actions = useActions();
	const blogPosts = [...state.global.BlogPosts].sort((a, b) => {
		const dateA = moment(a.dateCreated);
		const dateB = moment(b.dateCreated);
		return dateA.isBefore(dateB);
	});

	actions.global.setShowLandingBackground(false);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="mt-5">
			<Row>
				<div className="site-layout-content">
					<div className="w-full items-center justify-center">
						<Row className="w-full items-center justify-center">
							<div className=" text-5xl text-black font-bold mb-10 w-full">
								The latest in Baseball Analytics, Stats, and tapp
							</div>
						</Row>
						<Divider />
						<br className="mb-10" />
						<Row className="w-full max-w-2xl mx-auto flex items-center justify-center">
							{blogPosts.map((post, index) => {
								return (
									<Link to={`blog${post.route}`} className="mb-10 mt-10">
										<Card
											className="justify-center align-center w-full max-w-2xl pb-5"
											key={index}
											hoverable
											cover={<img alt="tapp" src={post.imageUrl} />}>
											<div className="text-gray-500">{post.dateCreated}</div>
											<div className="text-gray-800 text-5xl font-bold mb-5">{post.title}</div>
											<div className="text-gray-700 text-xl font-light">{post.description}</div>
										</Card>
									</Link>
								);
							})}
						</Row>
					</div>
				</div>
			</Row>
		</div>
	);
};

export default Blog;
