import React from "react";
import { Row, Col } from "antd";

export const FieldLocationMini = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	const ballColor = "white";
	const homerunColor = "white";
	const infieldColor = "darkGray";
	const outfieldColor = "lightGray";

	const LocationButton = ({ id, textColor }) => {
		let color;
		if (textColor === "darkGray") {
			color = " bg-gray-600 border border-white text-white";
		} else if (textColor === "lightGray") {
			color = " bg-gray-400 border border-white text-white";
		} else {
			color = "bg-gray-200 border border-white text-black";
		}
		color += value === id ? " shadow-inner animate-pulse" : " text-opacity-25";
		return (
			<button className={"w-full font-bold h-full " + color} key={id} id={id}>
				•
			</button>
		);
	};

	return value === undefined ? (
		<Row className="w-full" gutter={[4, 4]}>
			<Col span={8}>
				<div className="text-center rounded bg-gray-100 font-bold text-gray-600 mb-0.5">•</div>
				<div className="text-center rounded bg-gray-400 font-bold text-gray-100 mb-0.5">•</div>
				<div className="text-center rounded bg-gray-600 font-bold text-gray-100 mb-0.5">•</div>
			</Col>
			<Col span={8}>
				<div className="text-center rounded bg-gray-100 font-bold text-gray-600 mb-0.5">•</div>
				<div className="text-center rounded bg-gray-400 font-bold text-gray-100 mb-0.5">•</div>
				<div className="text-center rounded bg-gray-400 font-bold text-gray-100 mb-0.5">•</div>
			</Col>
			<Col span={8}>
				<div className="text-center rounded bg-gray-100 font-bold text-gray-600 mb-0.5">•</div>
				<div className="text-center rounded bg-gray-100 font-bold text-gray-600 mb-0.5">•</div>
				<div className="text-center rounded bg-gray-100 font-bold text-gray-600 mb-0.5">•</div>
			</Col>
		</Row>
	) : (
		<Row className="h-28 w-28">
			<Col span={4} className="h-28">
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={15} id={15} />
				</Row>
				<Row className="h-3/5">
					<LocationButton textColor={ballColor} key={16} id={16} />
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={17} id={17} />
				</Row>
			</Col>
			<Col span={16} className="h-full">
				<Row className="h-1/5">
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={14} id={14} />
					</Col>
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={13} id={13} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={9} id={9} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={8} id={8} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={7} id={7} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={4} id={4} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={3} id={3} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={6} id={6} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={1} id={1} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={2} id={2} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={5} id={5} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={18} id={18} />
				</Row>
			</Col>
			<Col span={4} className="h-28">
				<Row className="h-1/5">
					<LocationButton textColor={homerunColor} key={12} id={12} />
				</Row>
				<Row className="h-splitThirds">
					<LocationButton textColor={homerunColor} key={11} id={11} />
				</Row>
				<Row className="h-splitThirds">
					<LocationButton textColor={homerunColor} key={10} id={10} />
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={19} id={19} />
				</Row>
			</Col>
		</Row>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default FieldLocationMini;
