import React from "react";

export const NumberInputDisabled = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<>
			<input
				className={"h-full rounded shadow text-center text-xl bg-white mr-1 text-gray-400 w-full cursor-hover"}
				type="number"
				placeholder="0"
				disabled={true}
				value={value}
				readOnly
			/>
		</>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default NumberInputDisabled;
