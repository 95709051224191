import React, { useEffect, useState } from "react";
import { useAppState, useActions } from "../../api";
import { Alert, Breadcrumb, Col, message, Row, Skeleton, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import EditableTable from "../../components/dataViews/EditableTable";
import Player from "../../models/player";
import { Link } from "react-router-dom";
import ImportPlayerModal from "../../components/players/ImportPlayersModal";

const TeamRosterPage = () => {
	//#region Hooks ----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const [filteredData, setFilteredData] = useState([]);
	const [importModalVisible, setImportModalVisible] = useState(false);
	const selectedTeam = actions.teams.getTeamById(state.teams.selectedTeamId);
	useEffect(() => {
		setFilteredData(
			state.players.allPlayers.filter((player) =>
				player.teamIds ? player.teamIds.includes(state.teams.selectedTeamId) : false
			)
		);
	}, [state.players.allPlayers]);
	//#endregion Hooks ----------------------------------------------------------------------------------------------------------

	//#region Constants ----------------------------------------------------------------------------------------------------------
	const KEY_PROPERTY = "playerId";
	const COLUMNS = [
		{
			title: "Name",
			dataIndex: "name",
			dataType: "string",
			width: "20%",
			required: true,
			editable: true,
			defaultSortOrder: "ascend",
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Number",
			dataIndex: "number",
			dataType: "number",
			width: "5%",
			required: false,
			editable: true,
			sorter: (a, b) => a.number - b.number,
		},
		{
			title: "Height (in.)",
			dataIndex: "height",
			dataType: "string",
			width: "10%",
			required: false,
			editable: true,
		},
		{
			title: "Weight (lbs.)",
			dataIndex: "weight",
			dataType: "string",
			width: "10%",
			required: false,
			editable: true,
		},
		{
			title: (
				<div className="flex">
					Tags
					<Tooltip
						title="Tags are a great way to label and group teams. ex: varsity or conference"
						placement="top">
						<QuestionCircleOutlined className="mt-1 ml-auto" />
					</Tooltip>
				</div>
			),
			dataIndex: "playerTagIds",
			dataType: "tag",
			width: "30%",
			tags: state.tags.playerTags,
			required: false,
			editable: true,
		},
		{
			title: "Teams",
			dataIndex: "teamIds",
			dataType: "multiselect",
			width: "10%",
			listOptions: state.teams.allTeams.map((team) => {
				return { key: team.teamId, label: team.name };
			}),
			required: true,
			editable: true,
		},
		{
			title: "Archived",
			dataIndex: "isArchived",
			dataType: "checkbox",
			width: "5%",
			required: true,
			editable: true,
			filterMultiple: false,
			defaultFilteredValue: [false],
			filters: [
				{ text: "Show Archived", value: true },
				{ text: "Hide Archived", value: false },
			],
			onFilter: (value, record) => (value ? true : !record.isArchived),
		},
	];
	//#endregion Constants -------------------------------------------------------------------------------------------------------------

	//#region Handlers ----------------------------------------------------------------------------------------------------------------
	const showImportModal = () => {
		setImportModalVisible(true);
	};

	const rowClickAction = async (row) => {
		await actions.players.setSelectedPlayerId(row[KEY_PROPERTY]);
	};

	const archiveSelectedPlayers = async (rowKeys) => {
		await Promise.all(
			rowKeys.map(async (rowKey) => {
				const record = new Player(actions.players.getPlayerById(rowKey));
				record.isArchived = true;
				await updatePlayer(record);
			})
		);
	};

	const deleteSelectedPlayers = async (rowKeys) => {
		await Promise.all(
			rowKeys.map(async (rowKey) => {
				const record = new Player(actions.players.getPlayerById(rowKey));

				await deletePlayer(record);
			})
		);
	};

	const createPlayer = async (row) => {
		await actions.players.createPlayer(row);
	};

	const updatePlayer = async (row) => {
		await actions.players.updatePlayer(row);
	};

	const deletePlayer = async (row) => {
		const playerId = row?.playerId;
		const chartsPlayIsIn = [];
		Object.keys(state.charts.all).forEach((chartId) => {
			const exists = state.charts.all[chartId]?.players.some((chartPlayerId) => chartPlayerId === playerId);
			if (exists) chartsPlayIsIn.push(state.charts.all[chartId]?.title);
		});
		if (chartsPlayIsIn.length === 0) {
			await actions.players.deletePlayer(row[KEY_PROPERTY]);
		} else {
			message.warning(
				"Can't delete " +
					row.name +
					", please remove them from these charts first: " +
					chartsPlayIsIn.join(", ")
			);
		}
	};
	//#endregion JSX -------------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------
	return (
		<div className="font-montserrat" style={{ minWidth: 500 }}>
			{!state.global.loading ? (
				<div>
					<ImportPlayerModal isVisible={importModalVisible} setIsVisible={setImportModalVisible} />
					<Col>
						<Row className="mb-1">
							<Breadcrumb separator=">">
								<Breadcrumb.Item>
									<Link to={"/teams"}>Teams</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>
									<Link to={"/roster"}>{selectedTeam.name}</Link>
								</Breadcrumb.Item>
							</Breadcrumb>
						</Row>
						<Row>
							<EditableTable
								data={filteredData}
								keyProperty={KEY_PROPERTY}
								dataModel={Player}
								columns={COLUMNS}
								includeActions={true}
								addButtonTitle={"Create New Player"}
								title={`${selectedTeam.name} Roster`}
								rowClickAction={rowClickAction}
								importRecords={showImportModal}
								archiveSelection={archiveSelectedPlayers}
								deleteSelection={deleteSelectedPlayers}
								createRecord={createPlayer}
								updateRecord={updatePlayer}
								deleteRecord={deletePlayer}
							/>
						</Row>
					</Col>
					<div className="w-max">
						<Alert message="Add players to a chart to record data for them" closable />
					</div>
				</div>
			) : (
				<Skeleton />
			)}
		</div>
	);
	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default TeamRosterPage;
