import React, { useState } from "react";
import { Button, Col, DatePicker, Divider, Form, Input, message, Modal, Row, Select } from "antd";
import { useActions } from "../../api";
import { PrinterOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const SignupOrganization = () => {
	const [loading, setLoading] = useState(false);
	const [organizationReceipt, setOrganizationReceipt] = useState(undefined);
	const actions = useActions();

	var ianaTz = ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles", "America/Anchorage"];

	const handleSubmit = async (values) => {
		setLoading(true);
		let orgRes;
		let userRes;
		try {
			orgRes = await actions.organization.createOrganization(values);
			const orgKey = orgRes.data.orgReceipt.organizationKey;
			userRes = await actions.user.createAdminUser(orgKey);
		} catch (err) {
			Modal.error({
				title: "Error creating organization",
				content: `Reason: ${err.message}`,
			});
		}
		setLoading(false);

		const newOrganizationReceipt = {
			Title: values.title,
			"Admin Name": values.adminName,
			"Admin Email": values.adminEmail,
			ID: orgRes.data.orgReceipt.organizationId,
			Key: orgRes.data.orgReceipt.organizationKey,
			"Contract Start Date": values.contractStartDate.toString(),
			"Expiration Date": orgRes.data.orgReceipt.expirationDate,
			Timezone: values.timezone,
			"Account Owner": values.accountOwner,
			"Service Account - Email": userRes.userPayload.email,
			"Service Account - Password": userRes.userPayload.password,
		};

		setOrganizationReceipt(newOrganizationReceipt);
	};

	const handlePrint = () => {
		message.loading({ content: "Saving receipt to downloads folder", key: "printLoadingMsg", duration: 5 });
		html2canvas(document.getElementById(`receipt-print`))
			.then((canvas) => {
				const imgData = canvas.toDataURL("image/png");
				const pdf = new jsPDF({
					orientation: "portrait",
				});
				const imgProps = pdf.getImageProperties(imgData);
				const pdfWidth = pdf.internal.pageSize.getWidth();
				const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
				pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
				pdf.save(`Organization Receipt - ${organizationReceipt["Title"]}`);
			})
			.then(() => {
				message.destroy("printLoadingMsg");
				message.success("Saving complete.");
			});
	};

	const generateReceiptRow = (key) => {
		return (
			<Row gutter={[10, 10]} className="mb-1">
				<Col span={12}>
					<p className="text-lg font-semibold">{key}:</p>
				</Col>
				<Col span={12}>
					<p className="text-lg font-normal">{organizationReceipt[key]}</p>
				</Col>
			</Row>
		);
	};

	const formTailLayout = {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 5,
		},
	};

	return (
		<div className="site-layout-content-narrow">
			<div className="relative z-1 bg-white rounded shadow-md">
				<div className="w-full p-10" id="receipt-print">
					<Row>
						<Col span={22}>
							<h1 className="mb-5 text-black text-3xl font-medium">
								{organizationReceipt ? "Organization Receipt" : "Organization Details"}
							</h1>
						</Col>
						<Col span={organizationReceipt ? 2 : 0}>
							<Button id="print" icon={<PrinterOutlined />} onClick={handlePrint} />
						</Col>
					</Row>
					<p className={`mb-5 text-black text-md font-normal ${organizationReceipt ? "hidden" : "inline"}`}>
						This signup form is for internal use only
					</p>
					<Divider />
					<Col span={24}>
						{organizationReceipt
							? Object.keys(organizationReceipt).map((key) => generateReceiptRow(key))
							: ""}
					</Col>
					<Form
						name="organization_signup"
						className={organizationReceipt ? "hidden" : "inline"}
						loading={loading}
						initialValues={{ remember: true }}
						labelCol={{ span: 5 }}
						wrapperCol={{ span: 16 }}
						size="large"
						onFinish={handleSubmit}>
						<Form.Item
							name="title"
							label="Organization Title"
							rules={[{ required: true, message: "Please input the organization title. " }]}>
							<Input placeholder="What is the name of the organization?" />
						</Form.Item>
						<Form.Item
							name="adminName"
							label="Admin Name"
							rules={[{ required: true, message: "Please input an admin name. " }]}>
							<Input placeholder="Who is our primary contact? (they receive the invoice)" />
						</Form.Item>
						<Form.Item
							name="adminEmail"
							label="Admin E-mail"
							rules={[
								{
									type: "email",
									message: "The input is not a valid E-mail. ",
								},
								{
									required: true,
									message: "Please input an admin E-mail. ",
								},
							]}>
							<Input placeholder="What email should the invoice be sent to?" />
						</Form.Item>
						<Form.Item
							name="timezone"
							label="Organization Timezone"
							rules={[{ required: true, message: "Please select the organization timezone. " }]}>
							<Select placeholder="What IANA timezone does the organization primarily operate in?">
								{ianaTz.map((tz) => (
									<Select.Option value={tz}>{tz}</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							rules={[{ required: true, message: "Please select the contract start date " }]}
							name="contractStartDate"
							label="Contract Start"
							tooltip="When will this contract begin? This is when the invoice will be sent.">
							<DatePicker />
						</Form.Item>
						<Form.Item
							name="accountOwner"
							label="Account owner"
							rules={[{ required: true, message: "Please input the account owner name. " }]}>
							<Input placeholder="Which VIZN team member is responsible for this account?" />
						</Form.Item>
						<Form.Item wrapperCol={formTailLayout}>
							<button
								disabled={loading}
								type="submit"
								className="w-full p-2 rounded bg-coolPurple hover:bg-darkPurple text-white text-lg font-normal 
                hover:shadow-lg hover:font-extrabold transform hover:scale-105 disabled:bg-gray-600
                transition duration-300 ease-in-out">
								{loading ? "Loading..." : "Create Organization"}
							</button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default SignupOrganization;
