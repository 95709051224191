import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useActions, useAppState } from "../../api";
import { CheckCircleFilled } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import ChoosePlayerModal from "./choosePlayerModal";
import { Checkbox, Col, Divider, Form, Input, Row, Alert, Modal } from "antd";

/**
 * This is the signup form to actually create an account (requires an existing organization)
 */
const JoinOrganization = () => {
	const history = useHistory();
	const actions = useActions();
	const state = useAppState();
	const [loading, setLoading] = useState(false);
	const [noKey, setNoKey] = useState(false);

	// grab the query params
	const search = useLocation().search;
	const organizationKey = new URLSearchParams(search).get("organizationKey");
	const name = new URLSearchParams(search).get("name");
	const email = new URLSearchParams(search).get("email");

	if (organizationKey || name || email) {
		actions.user.setUserInputSignupInfo({ organizationKey: organizationKey, name: name, email: email });
	}

	const handleSubmit = async (values) => {
		try {
			setLoading(!loading);
			const createJoinUserModel = { organizationKey: values.organizationKey };
			await actions.user.createJoinUser(createJoinUserModel);
			await actions.user.setUserInputSignupInfo(values);
			await actions.user.setShowChoosePlayerModal(true);

			// history.push("/dashboard");
		} catch (err) {
			Modal.error({
				title: "Error creating account",
				content: (
					<>
						{`Reason: ${err.message}`}
						<br />
						<br />
						{"Please try again or contact team@tappsports.com for support."}
					</>
				),
			});
		}
		setLoading(false);
	};

	const agreementlinkStyle = "text-gray-500 hover:underline hover:text-coolPurple mx-auto sm:mx-0";
	const formTailLayout = {
		xs: {
			span: 24,
			offset: 0,
		},
		sm: {
			span: 16,
			offset: 8,
		},
	};

	const SubmitButton = () => {
		const baseStyles = "ml-1 text-white rounded bg-white w-full p-2 text-lg font-normal";
		const enabledStyles =
			"bg-coolPurple hover:shadow-lg hover:font-extrabold transform hover:scale-105 transition duration-300 ease-in-out";
		const disabledStyles = "bg-coolPurple cursor-default";
		return (
			<button
				disabled={loading}
				type="submit"
				className={baseStyles + " " + (loading ? disabledStyles : enabledStyles)}>
				{loading ? "Loading..." : "Create"}
			</button>
		);
	};

	const BackButton = () => {
		const baseStyles = "mr-1 rounded bg-white w-full p-2 text-lg font-normal";
		const enabledStyles =
			"border-2 border-coolPurple bg-white text-coolPurple hover:shadow-lg hover:font-extrabold transform hover:scale-105 transition duration-300 ease-in-out";
		const disabledStyles = "bg-gray-600 text-white cursor-default hidden";
		return (
			<button
				disabled={loading}
				onClick={() => history.goBack()}
				className={baseStyles + " " + (loading ? disabledStyles : enabledStyles)}>
				Go back
			</button>
		);
	};

	const makeCheckmarkRow = (text) => {
		return (
			<p className="text-md mb-4 flex items-center ">
				<CheckCircleFilled style={{ fontSize: 20, color: "#6163FF" }} />
				&nbsp;&nbsp;{text}
			</p>
		);
	};

	const whatToScore = ["Anywhere", "Anytime"];

	return (
		<>
			<ChoosePlayerModal />
			<div className="site-layout-content-narrow">
				<div className="relative z-1 bg-white rounded shadow-md">
					<Row gutter={[10, 10]}>
						<Col span={8} xs={0} sm={0} md={8}>
							<div className="w-full h-full p-2 bg-gray-200">
								<div className="my-12 w-56 mx-auto">
									<p className="text-xl mb-6 font-semibold text-coolPurple">
										Win the day, every day.
									</p>
									<p className="text-lg mt-10 mb-6 font-semibold">
										With tapp, you can access your stats
									</p>

									{whatToScore.map((reason) => {
										return makeCheckmarkRow(reason);
									})}
								</div>
							</div>
						</Col>

						<Col span={16} xs={24} sm={24} md={16}>
							<div className="w-full p-10">
								<h1 className="mb-5 text-black text-3xl font-medium sm:text-left">Create an account</h1>
								<Divider />
								<div>
									<Form
										name="normal_signup"
										loading={loading}
										initialValues={{ remember: true }}
										labelCol={{ span: 8 }}
										wrapperCol={{ span: 16 }}
										size="large"
										onFinish={handleSubmit}>
										<Form.Item
											name="organizationKey"
											label="Registration Key"
											initialValue={state.user.userInputSignupInfo.organizationKey}
											tooltip="If you don't know your key contact someone else in your organization who has an account."
											rules={[
												{
													required: true,
													message: "Registration key is required",
												},
												{
													len: 7,
													message: "Registration keys are 7 characters. ",
												},
											]}>
											<Input />
										</Form.Item>
										<Form.Item
											name="name"
											label="Name"
											initialValue={state.user.userInputSignupInfo.name}
											rules={[{ required: true, message: "Please input your name. " }]}>
											<Input />
										</Form.Item>
										<Form.Item
											name="email"
											label="E-mail"
											initialValue={state.user.userInputSignupInfo.email}
											rules={[
												{
													type: "email",
													message: "The input is not a valid E-mail. ",
												},
												{
													required: true,
													message: "Please input your E-mail. ",
												},
											]}>
											<Input />
										</Form.Item>
										<Form.Item
											name="password"
											label="Password"
											initialValue={state.user.userInputSignupInfo.password}
											rules={[
												{
													required: true,
													message: "Please input your password. ",
												},
												{
													min: 6,
													message: "Passwords must be at least 6 characters. ",
												},
											]}
											hasFeedback>
											<Input.Password />
										</Form.Item>
										<Form.Item
											name="agreement"
											valuePropName="checked"
											rules={[
												{
													validator: (_, value) =>
														value
															? Promise.resolve()
															: Promise.reject(
																	new Error(
																		"You must accept the agreements to use this application."
																	)
															  ),
												},
											]}
											wrapperCol={formTailLayout}>
											<Checkbox>
												I agree to the{" "}
												<Link className={agreementlinkStyle} to="/privacy-policy">
													Privacy Policy
												</Link>{" "}
												and{" "}
												<Link className={agreementlinkStyle} to="/terms">
													Terms of Use
												</Link>
											</Checkbox>
										</Form.Item>
										<Form.Item wrapperCol={formTailLayout}>
											<div className="flex my-2">
												<BackButton />
												<SubmitButton />
											</div>
										</Form.Item>
										<Form.Item wrapperCol={formTailLayout}>
											<p className="mt-4 mb-2 text-gray-800">
												Have an account?{" "}
												<Link to="/login">
													<span className="text-coolPurple font-semibold hover:underline cursor-pointer">
														Login
													</span>
												</Link>
											</p>
											<p>
												<span
													className="mt-4 mb-2 text-coolPurple font-semibold hover:underline cursor-pointer"
													onClick={() => setNoKey(true)}>
													I do not have a Registration Key
												</span>
											</p>
											{noKey ? (
												<Alert
													description="If you don't have a Registration Key, please contact your team's admin account user."
													type="info"
													closable
													onClose={() => setNoKey(false)}
												/>
											) : (
												""
											)}
										</Form.Item>
									</Form>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default JoinOrganization;
