import ChartModel from "../../models/chart";
import ChartView from "../../models/chartView";
import { chart } from "../../constants/apiJson";

export const state = {
	create: {
		isLoading: false,
		error: null,
	},
	getAll: {
		isLoading: false,
		error: null,
	},
	chartDataRecordingDate: "",
	all: {},
	currentChartId: "",
	chartView: new ChartView({}),
	new: new ChartModel(chart),
	newChartSection: 0,
	newChartLoading: false,
	chartDataLoading: false,
	mobileEditing: false,
	tableCsvString: "",
	showRecord: false,
	showGroupedExpModal: false,
	showTemplatesModal: false,
	noNewData: false,
	showDateRangePicker: true,
};
