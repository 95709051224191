import React from "react";
import { useActions } from "../../../api";
import SprayChart from "./SprayChart";
import { getSprayChartTotal } from "../../../engines/dataEngine";
import { Row, Col } from "antd";
import PlayersWithoutData from "../PlayersWithoutData";

export const DataPageMultipleSprayCharts = ({ title, data, crossWidget }) => {
	const actions = useActions();

	const sprayCharts = crossWidget
		? Object.keys(data).map((key) => {
				let title = key;

				let total = 0;
				Object.values(data[key]).forEach((val) => {
					if (val !== 0) {
						total += 1;
					}
				});
				if (total !== 0) {
					return (
						<Col>
							<div className="mb-4 text-lg font-normal">{title}</div>
							<div className="transform -rotate-45 ml-10 mr-10 mt-12 mb-12">
								<SprayChart data={data[key]} dataPage={true} />
							</div>
							{total ? (
								<p>
									Total hits:
									<span className="font-semibold"> {total}</span>
								</p>
							) : (
								""
							)}
						</Col>
					);
				} else {
					return "";
				}
		  })
		: data.map((playerData) => {
				let player = actions.players.getPlayerById(playerData.playerId);

				const { total } = getSprayChartTotal(playerData.data.count);
				if (total !== 0) {
					return (
						<Col>
							<div className="mb-4 text-lg font-normal">{player.name}</div>
							<div className="transform -rotate-45 ml-10 mr-10 mt-12 mb-12">
								<SprayChart data={playerData.data.count} dataPage={true} />
							</div>
							{total ? (
								<p>
									Total hits:
									<span className="font-semibold"> {total}</span>
								</p>
							) : (
								""
							)}
						</Col>
					);
				} else {
					return "";
				}
		  });

	//#region JSX ---------------------------------------------------------------------------------------------------------------
	return (
		<div className="w-full p-3 border rounded shadow">
			<div className="text-center">
				<div className="mb-4 text-xl font-semibold">{title}</div>
				<div className="mb-4 text-sm italic">
					Note: If this data doesn't look "complete" (i.e. missing some reps), the Field Location tool was
					likely not recorded with a Hit Result
				</div>
				<Row justify={"center"}>{sprayCharts}</Row>
			</div>
			{!crossWidget ? <PlayersWithoutData data={data} /> : ""}
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default DataPageMultipleSprayCharts;
