import React from "react";
import LightWordmark from "../../assets/wordmark-light.png";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { MenuOutlined, UserAddOutlined, LoginOutlined, DownOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import LandingPageButton from "./landingPageButton";

const PublicHeader = () => {
	const history = useHistory();
	const location = useLocation();

	const handleSignupNav = () => {
		history.push("/pricing");
	};

	const handleLoginNav = () => {
		history.push("/login");
	};

	/**
	 * Make the JSX for a navbar link (in-app redirects only)
	 */
	const MakeHeaderLink = (title, link, Icon) => {
		return (
			<Link to={link}>
				<div className="h-10 w-36 flex items-center">
					{Icon ? <Icon style={{ fontSize: 18, color: "#6163ff" }} /> : ""}
					<span
						className={`px-1 ml-2 mr-4 w-full text-lg ${
							Icon ? "text-coolPurple font-semibold" : "text-darkGray font-normal"
						} text-right font-normal`}>
						{title}
					</span>
				</div>
			</Link>
		);
	};

	const MakeSportsLink = (title, link) => {
		return (
			<Link to={link}>
				<div className="h-10 w-36 flex items-center">
					<span className={`px-1 ml-2 mr-4 w-full text-lg text-coolPurple font-normal text-right`}>
						{title}
					</span>
				</div>
			</Link>
		);
	};

	/**
	 * To add a new element to the nav-bar, add it to this list.
	 * Elements must be valid JSX. If you are adding a simple link within the app,
	 * use the MakeHeaderLink helper function to ensure consistency.
	 */
	const navElements = [
		MakeHeaderLink("Login", "/login", LoginOutlined),
		MakeHeaderLink("Sign up", "/pricing", UserAddOutlined),
		MakeHeaderLink("Blog", "/blog"),
		MakeHeaderLink("How it works", "/product"),
		MakeSportsLink("Baseball", "/baseball"),
		MakeSportsLink("Softball", "/softball"),
		MakeSportsLink("Lacrosse", "/lacrosse"),
		MakeSportsLink("Volleyball", "/volleyball"),
	];

	const navMenu = (
		<Menu theme="light" className="border">
			{navElements.map((navElement, index) => (
				<Menu.Item className="bg-white border-b" key={index}>
					{navElement}
				</Menu.Item>
			))}
		</Menu>
	);

	const SportsDropdown = (
		<Menu
			items={[
				{
					key: "1",
					label: <Link to="/baseball">Baseball</Link>,
				},
				{
					key: "2",
					label: <Link to="/softball">Softball</Link>,
				},
				{
					key: "3",
					label: <Link to="/lacrosse">Lacrosse</Link>,
				},
				{
					key: "4",
					label: <Link to="/volleyball">Volleyball</Link>,
				},
			]}
		/>
	);

	return (
		<div
			className="w-full p-5 flex flex-row flex-nowrap gap-4 z-2 absolute bg-coolPurple"
			style={{ minWidth: 375 }}>
			{/** Brand */}

			<div className="mt-3">
				<Link to="/">
					<img className="w-48 lg:w-48 float-left" src={LightWordmark} alt="Logo" />
				</Link>
			</div>

			{/** Page links */}

			<div className="hidden lg:flex items-center ml-2 mt-3">
				<Link to="/product">
					<div className="text-white font-medium text-lg float-left hover:underline truncate">
						How It Works
					</div>
				</Link>
			</div>

			<div className="hidden lg:flex items-center ml-2 mt-3">
				<Dropdown overlay={SportsDropdown}>
					<a onClick={(e) => e.preventDefault()}>
						<span className="text-white font-medium text-lg float-left hover:underline truncate">
							Sports
						</span>
						<span className="text-white font-medium text-md mt-1 ml-1 float-left hover:underline truncate">
							<DownOutlined />
						</span>
					</a>
				</Dropdown>
			</div>

			<div className="hidden lg:flex items-center ml-2 mt-3">
				<Link to="/pricing">
					<div className="text-white font-medium text-lg float-left hover:underline truncate">Pricing</div>
				</Link>
			</div>

			<div className="hidden lg:flex items-center ml-2 mt-3">
				<Link to="/blog">
					<div className="text-white font-medium text-lg float-left hover:underline truncate">Blog</div>
				</Link>
			</div>

			{/** Flex filler */}

			<div className="flex flex-auto" />

			{/** Critical action buttons */}
			<div className="hidden md:flex text-center md:text-left  mt-3">
				{location.pathname !== "/pricing" &&
				location.pathname !== "/create-account" &&
				location.pathname !== "/join-organization" ? (
					<LandingPageButton onClick={handleSignupNav}>Sign up</LandingPageButton>
				) : (
					""
				)}
				{location.pathname !== "/login" ? (
					<LandingPageButton ghost onClick={handleLoginNav}>
						Login
					</LandingPageButton>
				) : (
					""
				)}
			</div>

			{/** Nav menu for smaller screens */}

			<div className="h-full mt-2 self-center lg:hidden">
				<Dropdown overlay={navMenu} placement="bottomRight" trigger={["click"]}>
					<button className="h-8 w-8 header-menu-item flex items-center justify-center">
						<MenuOutlined style={{ fontSize: 28, color: "#ffffff" }} />
					</button>
				</Dropdown>
			</div>
		</div>
	);
};

export default PublicHeader;
