import React, { useEffect } from "react";

// components
import FadeInSection from "../../../components/global/fadeInSection";
import GetTappTodayFooter from "../../../components/global/getTappTodayFooter";
import { Divider } from "antd";

const CCBCShowcase = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="site-layout-content">
			<FadeInSection>
				<p className="text-5xl font-bold mt-2 ml-2 text-white">CCBC Essex Showcase August 2022</p>
			</FadeInSection>
			<FadeInSection>
				<p className="text-3xl font-bold mt-2 ml-2 text-white">Day 1 Results:</p>
			</FadeInSection>
			{/* Velocity */}
			<FadeInSection>
				<div className="relative w-full z-1 p-1 bg-white border border-gray-200 shadow-md text-center mb-5">
					<p className="text-xl ml-2 mt-2 font-semibold">Velocity</p>
					<Divider />
					<p className="md:hidden text-xs ml-2">* For best viewing, turn deivce sideways</p>

					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=1SMxC4DVdwG0WagB1v2hpgoR5Fuxn06YV"
							alt="image"
							style={{ minWidth: 600 }}
						/>
					</div>

					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=1jHpoFzyroHCyy4UbeJDf0g17-XUDrR7H"
							alt="image"
							style={{ minWidth: 600 }}
						/>
					</div>
				</div>
			</FadeInSection>
			{/* Running Speed */}
			<FadeInSection>
				<div className="relative w-full z-1 p-1 bg-white border border-gray-200 shadow-md text-center mb-5">
					<p className="text-xl ml-2 font-semibold mt-2">Running Speed</p>
					<Divider />
					<p className="md:hidden text-xs ml-2">* For best viewing, turn deivce sideways</p>
					<div className="overflow-x-auto mx-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=19nzQdKCZ9u4SpfibOStGDO66m1VEQLHP"
							alt="image"
							style={{ minWidth: 600 }}
						/>
					</div>
					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=1_62CEbMX-t5WeYWUeh2-ohLO_KcMQt7r"
							alt="image"
							style={{ minWidth: 600 }}
						/>
					</div>
				</div>
			</FadeInSection>
			<FadeInSection>
				<p className="text-3xl font-bold mt-2 ml-2 text-coolPurple">Day 2 Results:</p>
			</FadeInSection>
			{/* Aggregate Pitching Data */}
			<FadeInSection>
				<div className="relative w-full z-1 p-1 bg-white border border-gray-200 shadow-md text-center mb-5">
					<p className="text-xl ml-2 font-semibold mt-2">Aggregate Pitching Data</p>
					<Divider />
					<p className="lg:hidden text-xs ml-2">* For best viewing, turn deivce sideways</p>
					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=1Yrr1L7zp0xQ0fKzOonZy15eimpCfq-JG"
							alt="image"
							style={{ minWidth: 1000 }}
						/>
					</div>
				</div>
			</FadeInSection>
			{/* Velocity By Pitch Type */}
			<FadeInSection>
				<div className="relative w-full z-1 p-1 bg-white border border-gray-200 shadow-md text-center mb-5">
					<p className="text-xl ml-2 font-semibold mt-2">Velocity by Pitch Type</p>
					<Divider />
					<p className="lg:hidden text-xs ml-2">* For best viewing, turn deivce sideways</p>
					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=1ksJiZO-6vDyLtzp7f61kITkPFkWWqLpD"
							alt="image"
							style={{ minWidth: 1000 }}
						/>
					</div>
				</div>
			</FadeInSection>
			{/* Strike % By Pitch Type */}
			<FadeInSection>
				<div className="relative w-full z-1 p-1 bg-white border border-gray-200 shadow-md text-center mb-5">
					<p className="text-xl ml-2 font-semibold mt-2">Strike % by Pitch Type</p>
					<Divider />
					<p className="lg:hidden text-xs ml-2">* For best viewing, turn deivce sideways</p>
					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=15EoFCPAjN_tDg_dawhWmNpphhMjWXb7A"
							alt="image"
							style={{ minWidth: 600 }}
						/>
					</div>
				</div>
			</FadeInSection>
			{/* Quality Pitch % By Pitch Type */}
			<FadeInSection>
				<div className="relative w-full z-1 p-1 bg-white border border-gray-200 shadow-md text-center mb-5">
					<p className="text-xl ml-2 font-semibold mt-2">Quality Pitch % by Pitch Type</p>
					<Divider />
					<p className="lg:hidden text-xs ml-2">* For best viewing, turn deivce sideways</p>
					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=1_F601OCkzc8P0JUIWvLeA76dNpiM2E2f"
							alt="image"
							style={{ minWidth: 600 }}
						/>
					</div>
				</div>
			</FadeInSection>
			{/* Heatmaps */}
			<FadeInSection>
				<div className="relative w-full z-1 p-1 bg-white border border-gray-200 shadow-md text-center mb-5">
					<p className="text-xl ml-2 font-semibold mt-2">Quality Pitch % by Pitch Type</p>
					<Divider />
					<p className="lg:hidden text-xs ml-2">* For best viewing, turn deivce sideways</p>
					<div className="overflow-x-auto">
						<img
							src="https://drive.google.com/uc?export=view&id=17dr8dK6EVaQC8emIpAdI0AWAa7OTQrn_"
							alt="image"
							style={{ minWidth: 1000 }}
						/>
					</div>
				</div>
			</FadeInSection>
			<GetTappTodayFooter />
		</div>
	);
};

export default CCBCShowcase;
