import React, { useState } from "react";
import { useActions } from "../../api";

import { FaTrash } from "react-icons/fa";
import { Collapse, Divider, Skeleton, Tooltip } from "antd";
import { FlagFilled, FlagOutlined } from "@ant-design/icons";
import getMiniTool from "../../util/getMiniTool";

export const StatHistoryRowGrouped = ({ playerName, statId, dateCreated, values, isFlagged }) => {
	const { Panel } = Collapse;
	const actions = useActions();
	const [isDeleteLoading, setDeleteLoading] = useState(false);

	const handleFlagClick = () => {
		actions.stats.updateStatFlagged({
			statId: statId,
			isFlagged: !isFlagged,
		});
		actions.stats.updateCurrentStatsFlagged({ statId: statId, isFlagged: !isFlagged });
	};

	const handleDeleteClick = async () => {
		setDeleteLoading(true);
		await actions.stats.deleteStat(statId);
		await actions.stats.removeStatFromHistoryRow(statId);
		setDeleteLoading(false);
	};

	const dropdownTitle = (
		<div className="flex flex-col truncate font-montserrat">
			<p className="pr-0 m-0 text-md font-semibold truncate">{playerName}'s Group</p>
			<p className="pr-0 m-0 text-xs text-gray-600 font-medium truncate">{dateCreated}</p>
		</div>
	);

	const dropdownActions =
		statId && !isDeleteLoading ? (
			<>
				<div className="w-full flex flex-row gap-2 items-center justify-end cursor-pointer">
					<div
						className={`text-xl ${statId ? "text-red-500" : "text-gray-300"}`}
						id={statId}
						onClick={handleDeleteClick}
						disabled={!statId}>
						<FaTrash />
					</div>
					<div className="text-xl text-yellow-400 cursor-pointer" id={statId} onClick={handleFlagClick}>
						<Tooltip title="Flag the stat to go back later and review">
							{isFlagged ? <FlagFilled /> : <FlagOutlined />}
						</Tooltip>
					</div>
				</div>
			</>
		) : (
			<div className="h-12 w-full">
				<Skeleton paragraph={false} avatar={false} active />
			</div>
		);

	const stats = values
		? values.map((stat) => {
				return (
					<div className="w-full">
						<div className="flex flex-row gap-1 my-1">
							<div className="w-1/3">
								<p className="mt-2">{stat.widgetName}</p>
							</div>
							<div className="w-2/3 flex flex-col align-middle items-center">
								<div className="w-full h-full flex items-center">
									{getMiniTool(stat.widgetType, stat.value)}
								</div>
							</div>
						</div>
						<Divider style={{ margin: "5px 0px" }} />
					</div>
				);
		  })
		: "";

	//#region JSX -----------------------------------------------------------------------------------------------------------
	return (
		<Collapse accordion>
			<Panel header={dropdownTitle} key={statId} extra={dropdownActions}>
				{stats}
			</Panel>
		</Collapse>
	);
	//#endregion JSX --------------------------------------------------------------------------------------------------------
};

export default StatHistoryRowGrouped;
