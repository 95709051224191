import axios from "axios";
import { Team } from "../../constants/api";

export const api = {
	async createTeam(payload) {
		const res = await axios.post(Team.create, payload);
		return res.data.receipt;
	},
	async getAllTeams() {
		const res = await axios.post(Team.read.all, {});
		return res.data.teams;
	},
	async updateTeam(payload) {
		const res = await axios.post(Team.update, payload);
		return res.data.receipt;
	},
	async deleteTeam(payload) {
		const res = await axios.post(Team.delete, payload);
		return res.data.receipt;
	},
};
