import React from "react";

import { useAppState } from "../../../api";
import { Row, Col, Tooltip } from "antd";
import { getHeatMapTotal } from "../../../engines/dataEngine";
import { Strikes } from "../../../constants/pitchLocations";

export const HeatMap = ({ data, dataPage }) => {
	const state = useAppState();
	let { total, max, min } = getHeatMapTotal(data);

	const getDataVal = (id) => {
		let dataVal;

		Object.keys(data).forEach((key) => {
			if (dataPage) {
				var regex = /\d+/g;
				var idNumberFromKey = key.match(regex);
				if (parseInt(idNumberFromKey[0]) === id) {
					dataVal = data[key];
				}
			} else {
				if (key.includes(id)) {
					dataVal = data[key];
				}
			}
		});

		return dataVal;
	};

	const determineColor = (id, fracVal) => {
		const opacityVal = Math.ceil((fracVal * 100) / 5) * 5;
		let color;

		if (Strikes.some((val) => id.includes(val))) {
			if (fracVal === 0 || isNaN(fracVal)) {
				color = `bg-${state.user.positiveColor}-100 bg-opacity-50 text-gray-400`;
			} else {
				color = `text-black bg-${state.user.positiveColor}-500 bg-opacity-${opacityVal} `;
			}
		} else {
			if (fracVal === 0 || isNaN(fracVal)) {
				color = `bg-${state.user.negativeColor}-100 bg-opacity-50 text-gray-400`;
			} else {
				color = `text-black bg-${state.user.negativeColor}-500 bg-opacity-${opacityVal} `;
			}
		}

		return color;
	};

	//#region JSX -------------------------------------------------------------------------------------------------------------
	const LocationButton = ({ idNum, id }) => {
		let dataVal = 0;
		if (dataPage) {
			dataVal = getDataVal(idNum);
		} else {
			dataVal = getDataVal(id);
		}
		let fracVal = dataVal * (1 / (max - (min - 1)));
		let color = determineColor(id, fracVal);

		let percentage = (dataVal / total).toFixed(2);
		let fraction = dataVal + "/" + total;

		return (
			<Tooltip
				placement="top"
				title={state.charts.chartView.heatMapFraction ? percentage : fraction}
				trigger={["hover", "click"]}>
				<button
					className={"w-full h-full border border-black border-opacity-10 text-xs rounded " + color}
					key={id}
					id={id}>
					{dataVal !== 0 ? (state.charts.chartView.heatMapFraction ? fraction : percentage) : "-"}
				</button>
			</Tooltip>
		);
	};

	const heatmapHeight = " h-44 ";

	return (
		<Row className={"mb-4 w-56" + heatmapHeight}>
			<Col span={2}></Col>
			<Col span={4} className={heatmapHeight + " mr-1"}>
				<Row className="h-1/5 mb-1">
					<LocationButton key={10} idNum={10} id={"TOP_LEFT_BALL"} />
				</Row>
				<Row className="h-3/5 mb-1">
					<LocationButton key={13} idNum={13} id={"MID_LEFT_BALL"} />
				</Row>
				<Row className="h-1/5">
					<LocationButton key={15} idNum={15} id={"BOT_LEFT_BALL"} />
				</Row>
			</Col>
			<Col span={12}>
				<Row className="h-1/5 mb-1">
					<LocationButton key={11} idNum={11} id={"TOP_CENTER_BALL"} />
				</Row>
				<Row className="h-1/5" gutter={[3, 0]}>
					<Col span={8} className="mb-1">
						<LocationButton key={1} idNum={1} id={"TOP_LEFT_STRIKE"} />
					</Col>
					<Col span={8} className="mb-1">
						<LocationButton key={2} idNum={2} id={"TOP_CENTER_STRIKE"} />
					</Col>
					<Col span={8} className="mb-1">
						<LocationButton key={3} idNum={3} id={"TOP_RIGHT_STRIKE"} />
					</Col>
				</Row>
				<Row className="h-1/5" gutter={[3, 0]}>
					<Col span={8} className="mb-1">
						<LocationButton key={4} idNum={4} id={"MID_LEFT_STRIKE"} />
					</Col>
					<Col span={8} className="mb-1">
						<LocationButton key={5} idNum={5} id={"MID_CENTER_STRIKE"} />
					</Col>
					<Col span={8} className="mb-1">
						<LocationButton key={6} idNum={6} id={"MID_RIGHT_STRIKE"} />
					</Col>
				</Row>
				<Row className="h-1/5" gutter={[3, 0]}>
					<Col span={8}>
						<LocationButton key={7} idNum={7} id={"BOT_LEFT_STRIKE"} />
					</Col>
					<Col span={8}>
						<LocationButton key={8} idNum={8} id={"BOT_CENTER_STRIKE"} />
					</Col>
					<Col span={8}>
						<LocationButton key={9} idNum={9} id={"BOT_RIGHT_STRIKE"} />
					</Col>
				</Row>
				<Row className="h-1/5 mt-1">
					<LocationButton key={16} idNum={16} id={"BOT_CENTER_BALL"} />
				</Row>
			</Col>
			<Col span={4} className={heatmapHeight + " ml-1"}>
				<Row className="h-1/5 mb-1">
					<LocationButton key={12} idNum={12} id={"TOP_RIGHT_BALL"} />
				</Row>
				<Row className="h-3/5 mb-1">
					<LocationButton key={14} idNum={14} id={"MID_RIGHT_BALL"} />
				</Row>
				<Row className="h-1/5">
					<LocationButton key={17} idNum={17} id={"BOT_RIGHT_BALL"} />
				</Row>
			</Col>
			<Col span={2}></Col>
		</Row>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default HeatMap;
