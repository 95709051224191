import React, { useEffect, useState, useRef } from "react";
import { softballCharts } from "../../../constants/templates";

// components
import FadeInSection from "../../../components/global/fadeInSection";
import Testimonials from "../../../components/global/testimonials";
import AttentionGrabberHeader from "../../../components/global/attentionGrabberHeader";
import LandingPageBanner from "../../../components/global/landingPageBanner";
import GetTappTodayFooter from "../../../components/global/getTappTodayFooter";

// mockups
import SoftballMockup from "../../../assets/landing-page/pitch-tracking-animation.GIF";

import LandingPageTemplates from "../../../components/global/landingPageTemplates";

import pitchingViews from "../../../assets/landing-page/landing-pitching-views.png";
import hittingViews from "../../../assets/landing-page/landing-hitting-views.png";
import fieldingViews from "../../../assets/landing-page/landing-fielding-views.png";
import opposingViews from "../../../assets/landing-page/landing-opposing-hitter-views.png";
import CenterGalleryCard from "../../../components/global/centerGalleryCard";

import { Row, Col } from "antd";

const Softball = () => {
	const templateRef = useRef(null);
	const [activeTemplate, setActiveTemplate] = useState("Pitching");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const useCaseClicked = (title) => {
		switch (title) {
			case "Pitching chart":
				setActiveTemplate("Pitching");
				break;
			case "Hitting chart":
				setActiveTemplate("Hitting");
				break;
			case "Fielding chart":
				setActiveTemplate("Fielding");
				break;
			case "Scouting chart":
				setActiveTemplate("Hitting");
				break;
		}

		templateRef.current.scrollIntoView();
	};

	const valueProp = [
		"Customizable templates",
		"Unlimited Players",
		"Unlimited Charts",
		"Evaluate anything, anywhere, anytime",
		"Instant reports",
	];

	return (
		<div>
			<div className="mt-5 site-layout-content">
				<FadeInSection>
					<AttentionGrabberHeader
						header={<span>Softball evaluations anywhere, anytime</span>}
						description="Never worry about uploading stats from sheet to computer again."
						mockup={SoftballMockup}
						valueProp={valueProp}
					/>
					<Testimonials />
				</FadeInSection>
			</div>

			<LandingPageBanner title="Use Cases" />
			<div className="site-layout-content">
				<Row gutter={[20, 20]}>
					<Col span={24} order={1} md={{ span: 12, order: 1 }}>
						<CenterGalleryCard
							title={"Pitching chart"}
							content={"It's time to get rid of your old paper bullpen chart."}
							image={pitchingViews}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={2} md={{ span: 12, order: 2 }}>
						<CenterGalleryCard
							title={"Hitting chart"}
							content={
								"Take your offense to the next level by tracking hit efficiency based on different pitches."
							}
							image={hittingViews}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={3} md={{ span: 12, order: 3 }}>
						<CenterGalleryCard
							title={"Fielding chart"}
							content={
								"Teams that track defensive stats create more competitive practices and smarter players."
							}
							image={fieldingViews}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
					<Col span={24} order={4} md={{ span: 12, order: 4 }}>
						<CenterGalleryCard
							title={"Scouting chart"}
							content={
								"Stop allowing your opponents to surprise you every season, use data to spot their weaknesses."
							}
							image={opposingViews}
							useCaseClicked={useCaseClicked}
						/>
					</Col>
				</Row>
			</div>

			{/** Templates */}
			<LandingPageBanner title="Softball Templates" />
			<div ref={templateRef} className="site-layout-content text-center w-full md:w-1/2">
				<p className="text-lg lg:text-xl">
					Our templates are a great way to get started. Each one is completely custimizable so you can measure
					the metrics that matter to you.
				</p>
			</div>
			<LandingPageTemplates
				charts={softballCharts}
				activeIndex={activeTemplate}
				setActiveIndex={setActiveTemplate}
			/>

			{/** Landing footer (not the real footer) */}
			<GetTappTodayFooter />
		</div>
	);
};

export default Softball;
