import React from "react";
import { Button } from "antd";
import { useActions } from "../../api";

const TemplateSelect = () => {
	const actions = useActions();

	const handleViewTemplatesButton = () => {
		actions.charts.setShowTemplatesModal(true);
		actions.charts.updateChartStateValue({ property: "mobileEditing", newVal: true });
	};
	return (
		<div className="mt-4">
			<h1 className="mb-0">Or use a template:</h1>
			<Button onClick={handleViewTemplatesButton}>View Templates</Button>
		</div>
	);
};

export default TemplateSelect;
