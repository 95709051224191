import React from "react";
import { useAppState } from "../../api";
import { FileExclamationOutlined } from "@ant-design/icons";
import { Card, Col, Row } from "antd";

const NoDataCard = ({ card }) => {
	const state = useAppState();

	return (
		<Card
			className="w-full"
			loading={
				card === "cross-widget comparison" ? state.widgets.comparisonLoading : state.charts.chartDataLoading
			}>
			<Row justify="center" className="m-2">
				<Col>
					<FileExclamationOutlined style={{ fontSize: "45px" }} />
				</Col>
			</Row>
			<Row justify="center">
				<Col>
					<p className="text-lg">No Data</p>
				</Col>
			</Row>
		</Card>
	);
};

export default NoDataCard;
