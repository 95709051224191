import { analyticsEvents } from "../constants/analyticsEvents";
import { screenSizes } from "../constants/screenSize";

const sendAnalyticsEvent = (event, parameters) => {
	parameters["event"] = event;
	window.dataLayer.push(parameters);
};

export const statAddedEvent = (widgetType, chartId, organizationId, organizationName) => {
	const parameters = {
		platform: determinePlatform(window.innerWidth),
		organizationId: organizationId,
		organizationName: organizationName,
		widgetType: widgetType,
		chartId: chartId,
	};

	const event = analyticsEvents.statAdded;

	sendAnalyticsEvent(event, parameters);
};

export const statDeletedEvent = (organizationId, page, organizationName) => {
	const event = analyticsEvents.statDeleted;

	const parameters = {
		platform: determinePlatform(window.innerWidth),
		organizationId: organizationId,
		organizationName: organizationName,
		tab: page,
	};

	sendAnalyticsEvent(event, parameters);
};

export const statFlaggedEvent = (organizationId, isGrouped, organizationName) => {
	const event = analyticsEvents.statFlagged;

	const parameters = {
		platform: determinePlatform(window.innerWidth),
		organizationId: organizationId,
		organizationName: organizationName,
		isGrouped: isGrouped,
	};

	sendAnalyticsEvent(event, parameters);
};

export const chartEditedEvent = (organizationId, editAction, organizationName) => {
	const event = analyticsEvents.chartEdited;

	const parameters = {
		platform: determinePlatform(window.innerWidth),
		organizationId: organizationId,
		organizationName: organizationName,
		editAction: editAction,
	};

	sendAnalyticsEvent(event, parameters);
};

export const chartCreatedEvent = (organizationId, timeOnSteps, organizationName) => {
	const event = analyticsEvents.chartCreated;

	const parameters = {
		platform: determinePlatform(window.innerWidth),
		organizationId: organizationId,
		organizationName: organizationName,
		timeOnTitle: timeOnSteps[0],
		timeOnWidgets: timeOnSteps[1],
		timeOnPlayers: timeOnSteps[2],
	};

	sendAnalyticsEvent(event, parameters);
};

export const chartPopulatedEvent = (organizationId, widgetType, organizationName) => {
	const event = analyticsEvents.chartPopulated;

	const parameters = widgetType;
	parameters["platform"] = determinePlatform(window.innerWidth);
	parameters["organizationId"] = organizationId;
	parameters["organizationName"] = organizationName;

	sendAnalyticsEvent(event, parameters);
};

const determinePlatform = (innerWidth) => {
	return innerWidth < screenSizes.mobileMax ? "mobile" : "desktop";
};
