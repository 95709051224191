import React from "react";
import PrimaryWordmark from "../../assets/wordmark-primary.png";
import { Link } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import { typeformId } from "../../constants/typeform";
import { message } from "antd";
import { useAppState } from "../../api";
import { wikiURL } from "../../constants/wiki";

const Footer = () => {
	const state = useAppState();

	const emailClick = () => {
		window.open("mailto:team@tappsports.com?subject=Let's connect about tapp!");
	};
	const linkStyle = "text-gray-500 hover:underline hover:text-coolPurple cursor-pointer mx-auto sm:mx-0 mb-0";

	return (
		<div className="max-w-screen-lg mx-auto flex flex-col sm:flex-row flex-wrap gap-4 items-center">
			<Link to="/">
				<img
					className="flex-none mx-auto sm:mx-0"
					src={PrimaryWordmark}
					alt="Wordmark"
					style={{ height: 30, maxWidth: 100 }}
				/>
			</Link>
			<p className="w-40 h-8 mb-0 font-thin text-gray-500 text-sm border border-gray-500 text-center align-middle rounded p-1 ">
				{process.env.REACT_APP_FIREBASE_PROJECT_ID === "vizn-stats-dev"
					? "development"
					: "powered by vizn stats"}
			</p>
			<Link className={linkStyle} to="/privacy-policy">
				Privacy Policy
			</Link>
			<Link className={linkStyle} to="/terms">
				Terms of Use
			</Link>
			<Link className={linkStyle} to="/pricing">
				Pricing
			</Link>
			<a className={linkStyle} href={wikiURL.home} target="_blank" rel="noreferrer noopener">
				Help / FAQ
			</a>
			<PopupButton
				className="inline-flex items-center"
				id={typeformId.feedback}
				hidden={{
					name: state.user.name,
					email: state.user.email,
					"organization-id": state.organization.organizationId,
				}}
				onSubmit={() => message.success("Thank you, we received your feedback")}>
				<p className={linkStyle}>Feedback</p>
			</PopupButton>
			<p className={linkStyle} onClick={emailClick}>
				team@tappsports.com
			</p>
		</div>
	);
};

export default Footer;
