import React, { useEffect } from "react";
import { analytics } from "../../firebase/firebase";
import moment from "moment";
import { useAppState, useActions } from "../../api";
import { DatePicker, Alert, Col, Row } from "antd";
import Take from "../mobile/take";

const TakeStats = () => {
	const state = useAppState();
	const actions = useActions();

	const currentChart = state.charts.all[state.charts.currentChartId];
	const { chartId } = currentChart;

	useEffect(() => {
		analytics.logEvent("taking_stats");
		actions.stats.setRecordingPlayerId("");
		actions.charts.setChartDataRecordingDate("");
		actions.charts.setChartDateLastUsed(moment());
	}, [chartId, currentChart]);

	//#region HANDLERS -----------------------------------------------------------------------------------------------------------

	// const searchOnChange = (e) => {
	// 	actions.players.setRecordingPlayerSearch(e.target.value);
	// };

	// const clearOnClick = () => {
	// 	actions.players.setRecordingPlayerSearch("");
	// };

	const changeRecordingDateHandler = (value) => {
		if (value && value.isSame(moment(), "day")) {
			actions.charts.setChartDataRecordingDate("");
		} else {
			actions.charts.setChartDataRecordingDate(value);
		}
	};

	// const endGroupingHandler = () => {
	// 	actions.stats.addGroupedStats();
	// };
	//#endregion HANDLERS --------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat">
			<Col span={24}>
				<Row align="middle">
					<Col span={24}>
						<div className="w-5/6 m-auto">
							{state.charts.chartDataRecordingDate ? (
								<div className="mb-2">
									<Alert
										message="Greetings Time Traveler!"
										description={
											"You are now adding stats for " +
											state.charts.chartDataRecordingDate.format("MM/DD/YYYY")
										}
										type="warning"
									/>
								</div>
							) : null}
							<div className="text-left border-b border-gray-300 pb-4">
								<div className="w-48">
									<DatePicker
										value={
											state.charts.chartDataRecordingDate
												? state.charts.chartDataRecordingDate
												: moment()
										}
										onChange={changeRecordingDateHandler}
									/>
								</div>
							</div>
							<Take />
						</div>
					</Col>
				</Row>
			</Col>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default TakeStats;
