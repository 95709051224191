import React from "react";
import { useHistory } from "react-router-dom";
import { CheckCircleFilled } from "@ant-design/icons";
import { Divider } from "antd";
import { SubscriptionType } from "../../constants/subscriptionType";

const SubscriptionCard = ({ title, pricingDescription, features = [], buttonText, subscriptionType }) => {
	const makeCheckmarkRow = (text) => {
		return (
			<p className="text-xl mb-4 flex items-center break-words ">
				<CheckCircleFilled className="mr-3 sm:mr-4" style={{ fontSize: 24, color: "#6163FF" }} />
				{text}
			</p>
		);
	};

	const navigateToSignup = () => {
		if (subscriptionType === SubscriptionType.PlayerTier2022_1) {
			history.push("/join-organization");
		} else {
			history.push(`/create-account?type=${subscriptionType}`);
		}
	};

	const history = useHistory();

	return (
		<div className="grid min-w-fit h-full justify-items-center w-full">
			<div className="align-self-center text-center bg-gray-200 shadow-lg rounded-xl p-4 md:p-8 flex flex-col w-full">
				<div className="text-4xl font-bold text-coolPurple">{title}</div>
				<Divider />
				<div className={"mb-10 text-gray-700"}>{pricingDescription}</div>

				<div className="grid content-center mx-auto mb-5">
					{features.map((string) => makeCheckmarkRow(string))}
				</div>

				<div className="mt-auto">
					<button
						className="bg-coolPurple text-white text-xl font-bold py-4 px-28 md:px-36 rounded-xl hover:shadow-lg hover:font-extrabold transform hover:scale-105 
                transition duration-300 ease-in-out"
						onClick={navigateToSignup}>
						{buttonText}
					</button>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionCard;
