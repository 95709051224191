import React from "react";
import FadeInSection from "./fadeInSection";
import { Tabs } from "antd";
import RecordingToolsRows from "../widgets/recordingTools/RecordingToolsRows";

const { TabPane } = Tabs;

const LandingPageTemplates = ({ charts, activeIndex, setActiveIndex }) => {
	const onTabChange = (key) => {
		setActiveIndex(key);
	};
	return (
		<div className="site-layout-content">
			<FadeInSection>
				<Tabs
					onChange={onTabChange}
					activeKey={activeIndex}
					tabPosition={window.innerWidth < 500 ? "top" : "left"}
					type="card"
					size="large">
					{charts.map((templateChart) => (
						<TabPane tab={templateChart.title} key={templateChart.title}>
							<div className="h-full w-full overflow-y-auto overflow-x-hidden p-2 bg-gray-100 rounded">
								<div className="w-full md:w-3/4">
									<RecordingToolsRows chart={templateChart} isGrouped={true} />
								</div>
							</div>
						</TabPane>
					))}
				</Tabs>
			</FadeInSection>
		</div>
	);
};

export default LandingPageTemplates;
