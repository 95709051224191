import { createStateHook, createActionsHook, createEffectsHook, createReactionHook } from "overmind-react";
import { merge, namespaced } from "overmind/config";
import * as charts from "./charts";
import * as widgets from "./widgets";
import * as players from "./players";
import * as user from "./user";
import * as stats from "./stats";
import * as dashboard from "./dashboard";
import * as tags from "./tags";
import * as teams from "./teams";
import * as imports from "./imports";
import * as organization from "./organization";
import * as global from "./global";
import * as onboarding from "./onboarding";

export const config = merge(
	namespaced({
		charts,
		widgets,
		players,
		user,
		stats,
		dashboard,
		tags,
		teams,
		imports,
		organization,
		global,
		onboarding,
	})
);

export const useAppState = createStateHook();
export const useActions = createActionsHook();
export const useEffects = createEffectsHook();
export const useReaction = createReactionHook();
