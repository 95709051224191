export const SprayChartValues = [
	"FB_BEHIND",
	"FB_IN_LEFT",
	"FB_IN_RIGHT",
	"FB_OUT_LEFT",
	"FB_OUT_RIGHT",
	"HR_CENTER",
	"HR_LEFT",
	"HR_LEFT_CENTER",
	"HR_RIGHT",
	"HR_RIGHT_CENTER",
	"IN_FIRST",
	"IN_HOME",
	"IN_SECOND",
	"IN_THIRD",
	"OUT_CENTER",
	"OUT_LEFT",
	"OUT_LEFT_CENTER",
	"OUT_RIGHT",
	"OUT_RIGHT_CENTER",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
];

export const InfieldValues = ["IN_FIRST", "IN_HOME", "IN_SECOND", "IN_THIRD", "1", "2", "3", "4"];

export const FoulBalls = [
	"FB_BEHIND",
	"FB_IN_LEFT",
	"FB_IN_RIGHT",
	"FB_OUT_LEFT",
	"FB_OUT_RIGHT",
	"16",
	"17",
	"18",
	"19",
];

export const HomeRuns = [
	"HR_CENTER",
	"HR_LEFT",
	"HR_LEFT_CENTER",
	"HR_RIGHT",
	"HR_RIGHT_CENTER",
	"10",
	"11",
	"12",
	"13",
	"14",
];
