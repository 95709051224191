import React from "react";
import EditWidgetRow from "./EditWidgetRow";
import { Droppable } from "react-beautiful-dnd";

const Column = ({ column, tasks, editing }) => {
	return (
		<div>
			<Droppable droppableId={column.id}>
				{(provided) => (
					<div className="my-1 rounded overflow-y-auto" ref={provided.innerRef} {...provided.droppableProps}>
						{tasks.map((task, index) => (
							<EditWidgetRow key={task.widgetId} index={index} widget={task} editing={editing} />
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
	);
};

export default Column;
