import PitchLocation from "../../../assets/pitchLocationImage.png";

const XYCoordinateRecord = (props) => {
	const { data } = props;

	const inStrikeZone = (value) => {
		return value.x >= 0 && value.x <= 120 && value.y >= 0 && value.y <= 146;
	};
	const RenderDataPoints = () => {
		return data?.map((datum) => {
			const xVal = datum.x + 59;
			const yVal = datum.y + 38;
			const color = inStrikeZone(datum) ? "blue" : "red";
			return (
				<div
					className={`absolute  bg-${color}-400 w-5 h-5 rounded-full opacity-40`}
					style={{
						position: "absolute",
						top: yVal,
						left: xVal,
					}}
				/>
			);
		});
	};
	return (
		<div className="relative  bg-amber-500 w-500">
			<img
				src={PitchLocation}
				width={256}
				className={"rounded-md select-none"}
				onClick={() => {}}
				draggable={false}
			/>
			{RenderDataPoints()}
		</div>
	);
};

export default XYCoordinateRecord;
