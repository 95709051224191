import React from "react";

import { useAppState, useActions } from "../../api";

import { Modal, message, Button } from "antd";
import getRecordingTool from "../../util/getRecordingTool";

const EditStatModal = () => {
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];

	const statToEdit =
		state.stats.nestedStats.length > 0
			? state.stats.nestedStats.find((statInList) => statInList.statId === state.stats.statToEditId)
			: "";

	const handleOk = async () => {
		const hide = message.loading("Loading...", 0);
		await actions.stats.editStat(statToEdit);
		actions.stats.setEditingStatModal(false);
		setTimeout(hide, 500);
		message.success("Stat edited.  Refresh table to view changes");
	};

	const handleCancel = () => {
		actions.stats.setEditingStatModal(false);
		actions.stats.clearActiveGroup();
		message.warning("Stat not edited");
	};

	// only show the one widget for non-grouped charts
	const displayStats = statToEdit
		? statToEdit.statData.map((individualStat) => {
				return (
					<div className="mt-5 w-full" key={individualStat.widgetId + " non-grouped"}>
						<p className="my-auto mr-5">{individualStat.widgetName}</p>
						<div>
							{getRecordingTool(
								individualStat.widgetType,
								individualStat.widgetName,
								individualStat.widgetId,
								true,
								state.widgets.all[individualStat.widgetId].potentialValues,
								true
							)}
						</div>
					</div>
				);
		  })
		: "";

	// show all the widgets in the chart for grouped charts
	const displayStatsGrouped = currentChart
		? currentChart.widgets.map((widgetId) => {
				let widget = state.widgets.all[widgetId];
				return (
					<div className="mt-5 w-full" key={widgetId + " grouped"}>
						<p className="my-auto mr-5">{widget.name}</p>
						<div>
							{getRecordingTool(widget.type, widget.name, widgetId, true, widget.potentialValues, true)}
						</div>
					</div>
				);
		  })
		: "";

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<Modal
			title={
				state.stats.rawStatsPageStats[state.stats.editingStatId]
					? state.stats.rawStatsPageStats[state.stats.editingStatId].playerName
					: ""
			}
			visible={state.stats.editingStatModal}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={[
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleOk}>
					Save
				</Button>,
			]}>
			<p className="text-gray-400">{statToEdit ? statToEdit.statId : ""}</p>
			{currentChart.isGrouped ? displayStatsGrouped : displayStats}
		</Modal>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default EditStatModal;
