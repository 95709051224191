import React, { useState } from "react";
import { useAppState } from "../../api";
import moment from "moment";
import { Row, Col, Button, Table } from "antd";

export default function CustomQabTable() {
	const [tableData, setTableData] = useState([]);
	const state = useAppState();
	const currentChart = state.charts.all[state.charts.currentChartId];

	const huskerQualityAtBatChartId = "kO5IIKaHF3VnZbakLB5W";
	// const developmentQabChartId = "jK0evvLLxyBiIinlD7OR";

	let dataPoints;

	if (currentChart.chartId == huskerQualityAtBatChartId) {
		// use the production variables
		const huskerQab = "F08mc9CQ1Z4CkPju6xsP";
		const huskerPlateAppearance = "YiMerxCX9rXxM3D5hVru";
		const huskerAmtOfQuality = "ilhaupAgtAIv8XF08KPb";

		dataPoints = {
			huskerQAB: {
				key: huskerQab,
				title: "totalQab",
			},
			huskerPlateAppearance: {
				key: huskerPlateAppearance,
				title: "totalPa",
			},
			huskerAmtOfQuality: {
				key: huskerAmtOfQuality,
				title: "totalQuality",
			},
			huskerQabPercent: {
				title: "qabPercent",
			},
			huskerAvgQuality: {
				title: "avgQuality",
			},
		};
	} else {
		// use the development variables
		const devQab = "9CcALkrBt8kNGlVzcp8f";
		const devPlateAppearance = "sH5jnyx3MMGnbHs21L54";
		const devAmtOfQuality = "GunxkJYx2OL4S2PIAwxR";
		dataPoints = {
			huskerQAB: {
				key: devQab,
				title: "totalQab",
			},
			huskerPlateAppearance: {
				key: devPlateAppearance,
				title: "totalPa",
			},
			huskerAmtOfQuality: {
				key: devAmtOfQuality,
				title: "totalQuality",
			},
			huskerQabPercent: {
				title: "qabPercent",
			},
			huskerAvgQuality: {
				title: "avgQuality",
			},
		};
	}

	let playerIds = {};
	if (state.dashboard.directFlat[currentChart.chartId]) {
		const start = moment(state.charts.chartView.startDate);
		const end = moment(state.charts.chartView.endDate);
		let qabPercentTitle;
		let amtQualityTitle;
		let avgQualityTitle;
		let qabTitle;
		let plateAppearanceTitle;

		playerIds["team"] = {
			name: "Team",
			qabPercent: 0,
			totalQab: 0,
			totalPa: 0,
			totalQuality: 0,
			avgQuality: 0,
			numQualityReps: 0,
		};

		state.dashboard.directFlat[currentChart.chartId]
			.filter(
				(stat) =>
					moment(stat.dateCreated).isBetween(start, end) || moment(stat.dateCreated).isSame(start, "day")
			)
			.forEach((stat) => {
				let currentPlayerObject = playerIds[stat.player];

				let qabKey = dataPoints.huskerQAB.key;
				let plateAppearanceKey = dataPoints.huskerPlateAppearance.key;
				let amtQualityKey = dataPoints.huskerAmtOfQuality.key;

				qabTitle = dataPoints.huskerQAB.title;
				plateAppearanceTitle = dataPoints.huskerPlateAppearance.title;
				amtQualityTitle = dataPoints.huskerAmtOfQuality.title;
				qabPercentTitle = dataPoints.huskerQabPercent.title;
				avgQualityTitle = dataPoints.huskerAvgQuality.title;

				let qabStat = stat[qabKey];
				let plateAppearanceStat = stat[plateAppearanceKey];
				let qualityStat = stat[amtQualityKey];

				if (!Object.keys(playerIds).includes(stat.player)) {
					playerIds[stat.player] = {
						name: state.players.allPlayers.find((player) => player.playerId == stat.player).name,
						qabPercent: 0,
						totalQab: 0,
						totalPa: 0,
						totalQuality: 0,
						avgQuality: 0,
						numQualityReps: 0,
					};
					if (stat[qabKey]) {
						playerIds[stat.player][qabTitle] = qabStat;
					}
					if (stat[plateAppearanceKey]) {
						playerIds[stat.player][plateAppearanceTitle] = plateAppearanceStat;
					}
					if (stat[amtQualityKey]) {
						playerIds[stat.player][amtQualityTitle] = qualityStat;
						playerIds[stat.player].numQualityReps += 1;
					}
				} else {
					if (qabStat) {
						currentPlayerObject[qabTitle] += qabStat;
					}
					if (plateAppearanceStat) {
						currentPlayerObject[plateAppearanceTitle] += plateAppearanceStat;
					}
					if (qualityStat) {
						currentPlayerObject[amtQualityTitle] += qualityStat;
						currentPlayerObject.numQualityReps += 1;
					}
				}

				if (playerIds[stat.player][plateAppearanceTitle]) {
					let qabPercent =
						playerIds[stat.player][qabTitle] / Math.abs(playerIds[stat.player][plateAppearanceTitle]);

					playerIds[stat.player][qabPercentTitle] = qabPercent;
				}
				if (playerIds[stat.player].numQualityReps) {
					playerIds[stat.player][avgQualityTitle] =
						playerIds[stat.player][amtQualityTitle] / playerIds[stat.player].numQualityReps;
				}
			});

		let totalQabPercent = 0;
		let totalQuality = 0;
		let totalPlateAppearance = 0;
		let totalQab = 0;
		let totalAvgQuality = 0;
		let playerKeys = Object.keys(playerIds).filter((playerId) => playerId != "team");

		playerKeys.forEach((key) => {
			totalQabPercent += playerIds[key][qabPercentTitle];
			totalQuality += playerIds[key][amtQualityTitle];
			totalAvgQuality += playerIds[key][avgQualityTitle];
			totalQab += playerIds[key][qabTitle];
			totalPlateAppearance += playerIds[key][plateAppearanceTitle];
		});

		playerIds["team"][qabTitle] = totalQab;
		playerIds["team"][plateAppearanceTitle] = totalPlateAppearance;
		playerIds["team"][amtQualityTitle] = totalQuality;

		if (Object.keys(playerIds).length - 1 > 0) {
			playerIds["team"][avgQualityTitle] = totalAvgQuality / (Object.keys(playerIds).length - 1);
		}

		if (Object.keys(playerIds).length - 1 > 0) {
			playerIds["team"][qabPercentTitle] = totalQabPercent / (Object.keys(playerIds).length - 1);
		}

		if (Object.keys(playerIds).length - 1 > 0) {
			playerIds["team"][qabPercentTitle] = Math.abs(totalQab / totalPlateAppearance);
		}
	}

	const columns = [
		{
			title: "Player Name",
			dataIndex: "name",
			render: (text) => <span>{text}</span>,
		},
		{
			title: <span className="font-bold">"QAB %"</span>,
			dataIndex: "qabPercent",
			render: (text) => <span className="font-bold">{Math.round(text * 100, 4)}%</span>,
			sorter: {
				compare: (a, z) => a.qabPercent - z.qabPercent,
			},
		},
		{
			title: "Total QAB",
			dataIndex: "totalQab",
			render: (text) => <span>{text}</span>,
			sorter: {
				compare: (a, z) => a.totalQab - z.totalQab,
			},
		},
		{
			title: "PA",
			dataIndex: "totalPa",
			render: (text) => <span className="text-red-500">{text}</span>,
			sorter: {
				compare: (a, z) => a.totalPa - z.totalPa,
			},
		},
		{
			title: "Total Quality",
			dataIndex: "totalQuality",
			render: (text) => <span>{text}</span>,
			sorter: {
				compare: (a, z) => a.totalQuality - z.totalQuality,
			},
		},
		{
			title: "Average Quality",
			dataIndex: "avgQuality",
			render: (text) => <span>{text.toFixed(2)}</span>,
			sorter: {
				compare: (a, z) => a.avgQuality - z.avgQuality,
			},
		},
	];

	const handleClick = () => {
		setTableData(
			Object.values(playerIds)
				.sort((a, b) => a.qabPercent > b.qabPercent)
				.sort((a) => (a.name == "Team" ? 1 : -1))
		);
	};
	return (
		<div className="my-4">
			<Row>
				<Col flex="auto">
					<p className="text-lg font-semibold mb-0 inline p-0">Husker QAB Table (all time)</p>
				</Col>
				<Button type="primary" onClick={handleClick}>
					Click to Generate
				</Button>
			</Row>
			<Row>
				<Col flex="auto">
					<Table columns={columns} dataSource={tableData} pagination={false} />
				</Col>
			</Row>
		</div>
	);
}
