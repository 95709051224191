import React from "react";

export const CounterDisabled = ({ value }) => {
	let colorChangerPos = `${value === "edit" ? " text-gray-400 bg-white " : "bg-gray-400 text-white"} `;

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<div className="h-full w-full text-center">
			<button className={"h-full rounded shadow text-xl w-full font-bold text-3xl " + colorChangerPos}>+</button>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default CounterDisabled;
