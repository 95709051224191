export const handleError = (error, actions) => {
	try {
		if (error.request) {
			console.error(error.request);
			actions.global.setError({
				message: JSON.parse(error.request.response).error,
				status: error.request.status,
			});
		} else {
			console.error(error);
			actions.global.setError({ message: "An error has occurred. Please try again or contact support." });
		}
	} catch {
		// If we encounter an error processing the error, just re-throw
		throw error;
	}
};
