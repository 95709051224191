import React from "react";
import { useHistory } from "react-router-dom";
import FadeInSection from "./fadeInSection";
import LandingPageButton from "./landingPageButton";
import { Row } from "antd";

const GetTappTodayFooter = () => {
	const history = useHistory();

	const handleSignupNav = () => {
		history.push("/pricing");
	};

	return (
		<div className="site-layout-content">
			<FadeInSection>
				<div className="grid w-full items-center justify-center mt-20">
					<div>
						<Row className="flex items-center justify-center w-full h-fit">
							<div className="text-5xl lg:text-6xl text-center lg:w-2/3 text-gray-700">
								{" "}
								Ditch your paper chart, get tapp today{" "}
							</div>
						</Row>
					</div>
					<div className="flex items-center justify-center w-full h-fit mt-20">
						<LandingPageButton ghost onClick={handleSignupNav}>
							Sign up
						</LandingPageButton>
					</div>
				</div>
			</FadeInSection>
		</div>
	);
};

export default GetTappTodayFooter;
