import React, { useState } from "react";

import { useAppState, useActions } from "../../../api";
import NoDataCard from "../../stats/NoDataCard";
import PlayerSprayChart from "./PlayerSprayChart";
import {
	playerHasSprayChartDataForVisible,
	someVisiblePlayerHasVisibleSprayChartData,
} from "../../../engines/dataEngine";

import { Dropdown, Row, Menu } from "antd";
import { MoreOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const PlayerSprayChartCard = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];
	const [selectedPlayerId, setSelectedPlayerId] = useState("");

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	const hidePlayer = () => {
		const newVisible = state.charts.chartView.visibleSprayChartPlayers.filter(
			(playerId) => playerId !== selectedPlayerId
		);
		actions.charts.updateVisibleSprayChartPlayers(newVisible);

		const newHidden = [...state.charts.chartView.hiddenSprayChartPlayers, selectedPlayerId];
		actions.charts.updateHiddenSprayChartPlayers(newHidden);

		setSelectedPlayerId("");
	};

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	const hidePlayerDropdown = (
		<Menu>
			<Menu.Item icon={<EyeInvisibleOutlined />} onClick={hidePlayer}>
				Hide player
			</Menu.Item>
		</Menu>
	);

	return state.charts.chartView.visibleSprayCharts.length !== 0 &&
		state.charts.chartView.visibleSprayChartPlayers.length !== 0 &&
		someVisiblePlayerHasVisibleSprayChartData(state) ? (
		currentChart.chartData
			.filter(
				(playerData) =>
					playerData.Player_Name.toLowerCase().includes(state.stats.sprayChartSearch.toLowerCase()) &&
					state.charts.chartView.visibleSprayChartPlayers.includes(playerData.Player_Id)
			)
			.map((playerData) => {
				if (playerHasSprayChartDataForVisible(state, playerData)) {
					return (
						<div
							className="shadow bg-white border-gray-400 mb-2 mr-2 pr-2 py-2"
							key={"spray chart " + playerData.Player_Id}>
							<div className="flex">
								<p className="text-lg mb-0 ml-5">{playerData.Player_Name}</p>
								<Dropdown
									overlay={hidePlayerDropdown}
									trigger={["click"]}
									placement="bottomCenter"
									onClick={() => setSelectedPlayerId(playerData.Player_Id)}>
									<MoreOutlined className="ml-auto mt-auto mb-auto hover:shadow" />
								</Dropdown>
							</div>
							<Row justify={"center"}>
								<PlayerSprayChart playerData={playerData} />
							</Row>
						</div>
					);
				} else {
					return "";
				}
			})
	) : (
		<NoDataCard />
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PlayerSprayChartCard;
