import React, { useState, useEffect } from "react";

import { useAppState, useActions } from "../../../api";

import { WidgetTypes } from "../../../constants/widgetTypes";

import { Row, Col } from "antd";

export const FieldLocationRecord = ({ label, isGrouped, widgetId }) => {
	const infieldColor = "darkYellow";
	const ballColor = "white";
	const homerunColor = "white";
	const outfieldColor = "green";

	//#region HOOKS -----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const activeGroup = state.stats.activeGroup;

	const [btnValue, setBtnValue] = useState(activeGroup && activeGroup[label] ? activeGroup[label].value : "");

	useEffect(() => {
		if (!activeGroup || !activeGroup[label]) {
			setBtnValue("");
		} else {
			setBtnValue(activeGroup[label].value);
		}
	}, [activeGroup && activeGroup[label] ? activeGroup[label].value : ""]);

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------

	const handleBtnClick = (id) => {
		setBtnValue(id);
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.FIELDLOCATION,
		};

		if (isGrouped) {
			let newData = data;
			if (activeGroup[label] ? activeGroup[label].value !== id : true) {
				newData = { ...data, value: id };
			}

			actions.stats.setActiveGroup(newData);
		} else {
			let newData = { ...data, value: id };

			actions.stats.create(newData);
		}
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	const LocationButton = ({ textColor, id }) => {
		let color;

		if (btnValue === id && isGrouped) {
			if (textColor === "darkYellow") {
				color = "text-white " + "bg-yellow-900";
			} else if (textColor === "white") {
				color = "text-white " + `bg-${state.user.positiveColor}-500`;
			} else {
				color = "text-white " + (textColor === "blue" ? "bg-" + textColor : "bg-" + textColor + "-500");
			}
		} else {
			if (textColor === "darkYellow") {
				color = "bg-yellow-400" + " " + "text-yellow-900";
			} else if (textColor === "white") {
				color = `text-${state.user.positiveColor}-500 ` + "bg-white";
			} else {
				color =
					"bg-" +
					textColor +
					"-100" +
					" " +
					(textColor === "blue" ? "text-" + textColor : "text-" + textColor + "-500");
			}
		}

		return (
			<button
				className={"w-full rounded shadow font-bold h-full " + color}
				key={id}
				id={id}
				onClick={() => handleBtnClick(id)}>
				•
			</button>
		);
	};

	return (
		<Row className="mb-4 h-48">
			<Col span={4} className="h-48">
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={15} id={15} />
				</Row>
				<Row className="h-3/5">
					<LocationButton textColor={ballColor} key={16} id={16} />
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={17} id={17} />
				</Row>
			</Col>
			<Col span={16} className="h-full">
				<Row className="h-1/5">
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={14} id={14} />
					</Col>
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={13} id={13} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8} className="border-l-2 border-gray-800">
						<LocationButton textColor={outfieldColor} key={9} id={9} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={8} id={8} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={7} id={7} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8} className="border-l-2 border-gray-800">
						<LocationButton textColor={infieldColor} key={4} id={4} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={3} id={3} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={6} id={6} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8} className="border-l-2 border-b-2 border-gray-800">
						<LocationButton textColor={infieldColor} key={1} id={1} />
					</Col>
					<Col span={8} className="border-b-2 border-gray-800">
						<LocationButton textColor={infieldColor} key={2} id={2} />
					</Col>
					<Col span={8} className="border-b-2 border-gray-800">
						<LocationButton textColor={outfieldColor} key={5} id={5} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={18} id={18} />
				</Row>
			</Col>
			<Col span={4} className="h-48">
				<Row className="h-1/5">
					<LocationButton textColor={homerunColor} key={12} id={12} />
				</Row>
				<Row className="h-splitThirds">
					<LocationButton textColor={homerunColor} key={11} id={11} />
				</Row>
				<Row className="h-splitThirds">
					<LocationButton textColor={homerunColor} key={10} id={10} />
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={19} id={19} />
				</Row>
			</Col>
		</Row>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default FieldLocationRecord;
