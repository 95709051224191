import React, { useState, useEffect } from "react";
import { useAppState, useActions } from "../../../api";
import { Row, Col } from "antd";

export const NumberInputRecord = ({ label, widgetId, isGrouped }) => {
	//#region HOOKS -----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const activeGroup = state.stats.activeGroup;

	const [value, setValue] = useState(activeGroup && activeGroup[label] ? activeGroup[label].value : "");

	useEffect(() => {
		if (!activeGroup || !activeGroup[label]) {
			setValue("");
		} else {
			setValue(activeGroup[label].value);
		}
	}, [activeGroup && activeGroup[label] ? activeGroup[label].value : ""]);

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------

	const handleClick = () => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: state.widgets.all[widgetId].type,
		};
		if (isGrouped) {
			let newData = data;
			if (!isNaN(value) && value !== "") {
				newData = { ...data, value: value };
			} else {
				setValue("");
			}

			actions.stats.setActiveGroup(newData);
		} else {
			let newData = { ...data, value: value };

			actions.stats.create(newData);

			setValue("");
		}
	};

	const handleInputChange = (e) => {
		setValue(parseFloat(e.target.value));
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<div className="h-full text-center w-full">
			<Row gutter={[8, 0]} className="h-full">
				<Col span={isGrouped ? 24 : 18}>
					<input
						id={widgetId}
						className={`h-full rounded shadow text-center text-xl bg-white text-${state.user.positiveColor}-500 w-full`}
						type="number"
						onBlur={(e) => {
							if (isGrouped && state.widgets.all[widgetId]) {
								handleClick(e);
							}
						}}
						onChange={(e) => handleInputChange(e)}
						placeholder="0"
						key={"editor " + label}
						value={value}
					/>
				</Col>
				<Col span={isGrouped ? 0 : 6}>
					<button
						onClick={(e) => handleClick(e)}
						id={widgetId}
						className={
							"text-xl font-bold h-full rounded shadow w-full " +
							(value ? `text-${state.user.positiveColor}-500 bg-white` : "bg-gray-400 text-gray-600")
						}>
						+
					</button>
				</Col>
			</Row>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default NumberInputRecord;
