import React from "react";

export const HeatmapTable = ({ title, data }) => {
	const numCols = Object.keys(data).length + 1;
	const rowName = Object.keys(data[Object.keys(data)[0]]);

	const cols = (rowKey) =>
		Object.keys(data).map((key) => {
			let dataVal = data[key][rowKey];
			dataVal = Math.round(dataVal * 100);
			// const hue = dataVal < 50 ? 206 : 0; // for red and blue

			const hue = 0; // for just red

			// Really weird formula here that could probably be simplified, but it basically is just scaling the lightness.
			// The scales meet in the middle at 0, so the scale will look like:
			// dataval:   0   -> 50  -> 100
			// lightness: 50  -> 100 -> 50
			// The last confusing thing about this is that 50 is too dark on the red side, so it actually only goes down to 65
			// const lightness = dataVal < 50 ? 50 + dataVal : 50 + (115 - (65 + ((dataVal - 50) / 50) * 35)); // for red and blue

			const lightness = 110 - dataVal / 2; // for just red

			return (
				<div
					className="w-full font-semibold border border-gray"
					style={{ backgroundColor: `hsl(${hue}, 100%, ${lightness}%)` }}>
					{dataVal}%
				</div>
			);
		});

	const header = Object.keys(data).map((key) => {
		return <div>{key}</div>;
	});

	const rows = rowName.map((key) => {
		return (
			<div className={"grid grid-cols-" + numCols}>
				<div>{key}</div>
				{cols(key)}
			</div>
		);
	});
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<div className="h-50 w-full p-3 pb-5 border rounded text-center overflow-y-auto shadow">
			<div className="mb-4 text-xl font-semibold">{title}</div>
			<div className={"grid grid-cols-" + numCols}>
				{" "}
				{/** TODO add grid-cols to exception list to avoid auto-purge */}
				<div></div>
				{header}
			</div>
			{rows}
			{/* <div className="mt-4 mx-auto">
				<p className="mb-1">Key</p>
				<div className="flex w-1/2 border mx-auto">
					<div
						className="pl-4 font-semibold text-left w-full"
						style={{
							backgroundImage: "linear-gradient(to right, hsl(206, 100%, 50%) , hsl(206, 100%, 100%))",
						}}>
						0%
					</div>
					<div
						className="pr-4 font-semibold text-right w-full"
						style={{
							backgroundImage: "linear-gradient(to right, hsl(0, 100%, 100%) , hsl(0, 100%, 65%))",
						}}>
						100%
					</div>
				</div>
			</div> */}
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default HeatmapTable;
