import axios from "axios";
import { Chart } from "../../constants/api";
import ChartModel from "../../models/chart";

export const api = {
	async getAll() {
		try {
			const getAllChartsRes = await axios.post(Chart.read.all);
			const charts = getAllChartsRes.data.charts;
			const validatedCharts = charts.map((chart) => new ChartModel(chart));
			return validatedCharts;
		} catch (error) {
			//review: add error state
		}
	},
	async getData(payload) {
		try {
			const res = await axios.post(Chart.read.stats, payload);

			return res.data;
		} catch (error) {
			throw new Error(error);
		}
	},
	async create(payload) {
		try {
			const createChartRes = await axios.post(Chart.create, payload);
			return createChartRes.data.receipt.created.chartId;
		} catch (error) {
			throw new Error(error);
		}
	},
	async delete(payload) {
		try {
			const deleteRes = await axios.post(Chart.delete, { chartId: payload });
			return deleteRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async updateWidgets(payload) {
		//the two update functions could probably be merged into one function
		try {
			const updateWidgetsRes = await axios.post(Chart.update, payload);
			return updateWidgetsRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async updatePlayers(payload) {
		try {
			const updatePlayersRes = await axios.post(Chart.update, payload);
			return updatePlayersRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async updateDateLastUsed(payload) {
		try {
			const updateChartDateLastUsedRes = await axios.post(Chart.update, payload);
			return updateChartDateLastUsedRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async updateTitle(payload) {
		try {
			const updateChartNameRes = await axios.post(Chart.update, payload);
			return updateChartNameRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async updateChartView(payload) {
		try {
			const updateChartViewRes = await axios.post(Chart.update, payload);
			return updateChartViewRes;
		} catch (error) {
			throw new Error(error);
		}
	},
	async updateIsArchived(payload) {
		try {
			const updateChartViewRes = await axios.post(Chart.update, payload);
			return updateChartViewRes;
		} catch (error) {
			throw new Error(error);
		}
	},
};
