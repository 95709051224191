import React from "react";
import { useAppState, useActions } from "../../api";
import { Dropdown, Menu, Row, Col, Button } from "antd";
import { miniTools } from "../../constants/tools";
import { DownOutlined } from "@ant-design/icons";

export const ChooseTool = ({ display }) => {
	//#region Hooks -----------------------------------------------------------------------------------------------------------

	const state = useAppState();
	const actions = useActions();

	//#endregion Hooks --------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------

	const handleChoose = (widgetTypeId) => {
		if (!display) {
			actions.widgets.setNewWidgetTool(widgetTypeId);
			potentialValueHandler([]);
		}
	};

	const getMini = (widgetType) => {
		let widgetObj = Object.values(miniTools).filter((miniTool) => {
			return widgetType === miniTool.id;
		});
		return widgetObj;
	};

	const potentialValueHandler = (value) => {
		if (!display) {
			actions.widgets.newWidgetSetPotentialValue(value);
		}
	};
	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	const miniToolsMenu = (
		<Menu className="w-64 h-96 overflow-y-auto">
			{Object.values(miniTools).map((miniTool) => {
				return (
					<Menu.Item className="border-b border-gray-200 pb-0">
						<Row className="mt-4 pb-4" type={miniTool.id} onClick={() => handleChoose(miniTool.id)}>
							<Col span={10}>{miniTool.tool}</Col>
							<Col span={14}>
								<p className="ml-2">{miniTool.title}</p>
							</Col>
						</Row>
					</Menu.Item>
				);
			})}
		</Menu>
	);

	const ToolsMenu = () => {
		return (
			<Dropdown overlay={miniToolsMenu} trigger={["click"]}>
				<Button icon={<DownOutlined />} className="w-full ml-2">
					{getMini(state.widgets.new.type).length > 0 ? getMini(state.widgets.new.type)[0].title : "Tool"}
				</Button>
			</Dropdown>
		);
	};

	return <ToolsMenu />;

	//#endregion JSX -----------------------------------------------------------------------------------------------------------
};

export default ChooseTool;
