import React from "react";
import { PrivacyPolicy, TermsOfUse } from "../../constants/legalDocuments";
import Document from "../../models/document";

const DocumentDisplay = (props) => {
	const path = props.location.pathname;
	let document = new Document({}, "No document at this path", "");
	window.scrollTo(0, 0);

	switch (path) {
		case "/privacy-policy":
			document = PrivacyPolicy;
			break;
		case "/terms":
			document = TermsOfUse;
			break;
		default:
			break;
	}

	return (
		<div className="site-layout-content-narrow">
			<div className="relative w-full z-1 p-4 bg-white border border-gray-200 shadow-md" style={document.styles}>
				{document.jsx}
			</div>
		</div>
	);
};

export default DocumentDisplay;
