import React from "react";

export const TextInputMini = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return value === undefined ? (
		<p className="w-full mb-0 ml-1 rounded text-center font-bold bg-gray-100 flex text-center items-center justify-center">
			abc
		</p>
	) : (
		<input
			className={"h-full rounded text-center text-sm bg-gray-100 mr-1 text-gray-400 w-full truncate px-1"}
			type="text"
			placeholder="abc"
			disabled={true}
			value={value === "edit" ? "text" : value}
			readOnly
		/>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default TextInputMini;
