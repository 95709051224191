import axios from "axios";
import { Stat } from "../../constants/api";

export const api = {
	async create(payload) {
		try {
			const createRes = await axios.post(Stat.create, payload);

			return createRes.data.receipt.created[0];
		} catch (error) {
			throw new Error(error);
		}
	},
	async update(payload) {
		try {
			const updateRes = await axios.post(Stat.update, payload);

			return updateRes.data.statId;
		} catch (error) {
			throw new Error(error);
		}
	},
	async delete(payload) {
		try {
			const deleteRes = await axios.post(Stat.delete, payload);

			return deleteRes.data.statId;
		} catch (error) {
			throw new Error(error);
		}
	},
};
