import React, { useState, useEffect, createRef } from "react";

import { useAppState, useActions } from "../../../api";

import { WidgetTypes } from "../../../constants/widgetTypes";

import { Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

export const PlayerListSelectRecord = ({ widgetId, label, isGrouped, potentialValues }) => {
	//#region HOOKS -----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const myRef = createRef();
	const activeGroup = state.stats.activeGroup;
	const [activeGroupPayload, setActiveGroupPayload] = useState();
	const [value, setValue] = useState(
		activeGroup && activeGroup[label] && actions.players.getPlayerById(activeGroup[label].value)
			? actions.players.getPlayerById(activeGroup[label].value).name
			: "Players"
	);

	useEffect(() => {
		// We are not clearing the active group like we normally would due to user feedback
		if (activeGroupPayload && !activeGroup[label]) {
			actions.stats.setActiveGroup(activeGroupPayload);
		}
	}, [activeGroup && activeGroup[label] ? activeGroup[label].value : ""]);

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------

	const handleMenuClick = (e) => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.PLAYERLISTSELECT,
			value: e,
		};
		setActiveGroupPayload(data);
		if (isGrouped) {
			setValue(actions.players.getPlayerById(e).name);
			actions.stats.setActiveGroup(data);
		} else {
			actions.stats.create(data);
			setValue("Players");
		}
		myRef.current.blur();
	};

	const deselectValue = () => {
		setValue("Players");
		setActiveGroupPayload(undefined);
		actions.stats.deleteFromActiveGroup(label);
		myRef.current.blur();
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	const selectOptions =
		potentialValues.length === 1
			? state.players.allPlayers
					.filter((player) => {
						if (player.teamIds.includes(potentialValues[0].teamId)) {
							if (potentialValues[0].tagIds && potentialValues[0].tagIds.length) {
								return player.playerTagIds.some((tagId) => potentialValues[0].tagIds.includes(tagId));
							} else {
								return true;
							}
						}
					})
					.map((player) => <Option key={player.playerId}>{player.name}</Option>)
			: "";

	return (
		<div className="flex h-full">
			<div
				className={
					(value !== "Players" ? "w-10/12 " : "w-full") +
					" bg-white rounded shadow my-auto text-center px-2 pb-3 pt-3"
				}>
				<Select
					ref={myRef}
					onChange={handleMenuClick}
					value={value}
					bordered={false}
					className="w-full"
					showSearch
					placeholder="Select a player"
					optionFilterProp="children"
					filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					style={{ height: "100%", border: "none" }}>
					{selectOptions}
				</Select>
			</div>

			{value !== "Players" ? (
				<div
					className="text-red-400 text-lg w-2/12 h-full pb-2 rounded shadow bg-white cursor-pointer text-center pt-4 ml-1"
					onClick={deselectValue}>
					<CloseCircleOutlined />
				</div>
			) : (
				""
			)}
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PlayerListSelectRecord;
