import React, { useState } from "react";

import { useAppState, useActions } from "../../../api";
import PlayerHeatMap from "./PlayerHeatMap";
import { playerHasHeatMapDataForVisible, someVisiblePlayerHasVisibleHeatMapData } from "../../../engines/dataEngine";

import { Dropdown, Row, Menu } from "antd";
import { MoreOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import NoDataCard from "../../stats/NoDataCard";

const PlayerHeatMapCard = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];
	const [selectedPlayerId, setSelectedPlayerId] = useState("");

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	const hidePlayer = () => {
		const newVisible = state.charts.chartView.visibleHeatMapPlayers.filter(
			(playerId) => playerId !== selectedPlayerId
		);
		actions.charts.updateVisibleHeatMapPlayers(newVisible);

		const newHidden = [...state.charts.chartView.hiddenHeatMapPlayers, selectedPlayerId];
		actions.charts.updateHiddenHeatMapPlayers(newHidden);

		setSelectedPlayerId("");
	};

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	const hidePlayerDropdown = (
		<Menu>
			<Menu.Item icon={<EyeInvisibleOutlined />} onClick={hidePlayer}>
				Hide player
			</Menu.Item>
		</Menu>
	);

	return state.charts.chartView.visibleHeatMaps.length !== 0 &&
		state.charts.chartView.visibleHeatMapPlayers.length !== 0 &&
		someVisiblePlayerHasVisibleHeatMapData(state) ? (
		currentChart.chartData
			.filter(
				(playerData) =>
					playerData.Player_Name.toLowerCase().includes(state.stats.heatMapSearch.toLowerCase()) &&
					state.charts.chartView.visibleHeatMapPlayers.includes(playerData.Player_Id)
			)
			.map((playerData) => {
				if (playerHasHeatMapDataForVisible(state, playerData)) {
					return (
						<div
							className="shadow bg-white border-gray-400 mb-2 mr-2 pr-2 py-2"
							key={"heat map " + playerData.Player_Id}>
							<div className="flex">
								<p className="text-lg mb-0 ml-5">{playerData.Player_Name}</p>
								<Dropdown
									overlay={hidePlayerDropdown}
									trigger={["click"]}
									placement="bottomCenter"
									onClick={() => setSelectedPlayerId(playerData.Player_Id)}>
									<MoreOutlined className="ml-auto mt-auto mb-auto hover:shadow" />
								</Dropdown>
							</div>
							<Row justify={"center"}>
								<PlayerHeatMap playerData={playerData} />
							</Row>
						</div>
					);
				} else {
					return "";
				}
			})
	) : (
		<NoDataCard />
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PlayerHeatMapCard;
