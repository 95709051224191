import moment from "moment";
import ChartView from "./chartView";

export default class Chart {
	constructor(chart) {
		this.chartId = chart.chartId ? chart.chartId : "";
		this.creatorId = chart.creatorId ? chart.creatorId : "";
		this.dateCreated = chart.dateCreated ? chart.dateCreated : moment();
		this.dateLastUsed = chart.dateLastUsed ? chart.dateLastUsed : moment();
		this.title = chart.title ? chart.title : "";
		this.isGrouped = chart.isGrouped ? chart.isGrouped : false;
		this.includesContext = chart.includesContext ? chart.includesContext : false;
		this.subscribers = chart.subscribers ? chart.subscribers : [];
		this.players = chart.players ? chart.players.map((player) => (player.playerId ? player.playerId : player)) : [];
		this.widgets = chart.widgets ? chart.widgets.map((widget) => (widget.widgetId ? widget.widgetId : widget)) : [];
		this.chartData = chart.chartData ? chart.chartData : [];
		this.chartView = chart.chartView ? new ChartView(chart.chartView) : new ChartView({});
		this.isArchived = chart.isArchived ? chart.isArchived : false;
	}

	toJson() {
		const chartJson = JSON.parse(JSON.stringify(this));
		const keysToSave = ["title", "isGrouped", "players", "widgets", "isArchived"];
		Object.entries(chartJson).forEach((key) => {
			if (!keysToSave.includes(key[0])) {
				delete chartJson[key[0]];
			}
		});
		return chartJson;
	}

	async getWidgetsArray() {
		const valueArray = (await this.transformObjectToArray(this.widgets)) || [];
		return valueArray;
	}

	async transformObjectToArray(obj) {
		return Object.values(obj);
	}

	getPlayersObject(players) {
		let playersObj = {};

		if (players) {
			players.forEach((player) => {
				let playerId = player.playerId;
				playersObj[playerId] = player;
			});
		}

		return playersObj;
	}

	getWidgetsObject(widgets) {
		let widgetsObj = {};

		if (widgets) {
			widgets.forEach((widget) => {
				let widgetId = widget.widgetId;
				widgetsObj[widgetId] = widget;
			});
		}

		return widgetsObj;
	}

	addSubscriber(subscriber) {
		if (this.subscribers) this.subscribers.push(subscriber);
		else this.subscribers = [subscriber];
	}

	async addPlayer(playerId) {
		await this.players.push(playerId);
	}

	deletePlayer(player) {
		delete this.players[player.playerId];
	}

	async addWidget(widgetId) {
		await this.widgets.push(widgetId);
	}

	deleteWidget(widget) {
		delete this.widgets[widget.widgetId];
	}

	setTitle(title) {
		this.title = title;
	}

	setDateLastUsed(dateLastUsed) {
		this.dateLastUsed = dateLastUsed;
	}

	setIsGrouped(newVal) {
		this.isGrouped = newVal;
	}

	setChartId(newValue) {
		this.chartId = newValue;
	}
}
