import { splitColumnName } from "./tableEngine";

export const insertAndUpdateHiddenColumns = (newColumn, hiddenColumns) => {
	if (hiddenColumns.includes(newColumn)) {
		return hiddenColumns;
	}

	const splitNewColumn = splitColumnName(newColumn);

	const updatedHiddenColumns = [];
	hiddenColumns.forEach((col) => {
		const { widgetName } = splitColumnName(col);
		if (!splitNewColumn.knownSuffix && splitNewColumn.widgetName === widgetName) {
			// no-op
		} else {
			updatedHiddenColumns.push(col);
		}
	});

	updatedHiddenColumns.push(newColumn);
	updatedHiddenColumns.sort((a, b) => a > b);

	return updatedHiddenColumns;
};

export const countWidgetTypes = (widgetIds, allWidgets) => {
	const types = {
		COUNTER: 0,
		POS_NEG: 0,
		POS_NEG_NEUT: 0,
		STOPWATCH: 0,
		PITCH_LOCATION: 0,
		NUMERIC: 0,
		LIST_SELECT: 0,
		NEG_COUNTER: 0,
		POS_NEUT: 0,
		PLAYER_LIST_SELECT: 0,
	};
	widgetIds.forEach((id) => {
		const type = allWidgets[id].type;
		types[type] = types[type] + 1;
	});

	return types;
};
