import { message } from "antd";
import Dashboard from "../../models/dashboard";
import DashboardRequest from "../../models/dashboardRequest";
import { handleError } from "../../util/errorUtil";
import { getDateStandardFormat } from "../../util/dateUtil";
import moment from "moment";

export const getDashboardData = async ({ state, actions, effects }, forceRefresh) => {
	try {
		state.dashboard.loading = true;
		const currentChart = state.charts.all[state.charts.currentChartId];

		// PIUS X WIDGET IDS
		const fieldLocationId = "2QIrbWHwV24KLmTwzYyU";
		const hitResultId = "TuGJl3pVBskM36Xdn85D";
		const pitchCountId = "Dv0nv4h0Tcu9EfNH3q8h";
		const pitchTypeId = "OHrWSAmL1dEHVgxDSoWt";

		// development ids
		// const fieldLocationId = "acv5UuldLNCA4wwMb4Vk";
		// const hitResultId = "IUlAQuNdI2vvDFA9293J";
		// const pitchCountId = "R65SG8oixpZ5eEYSNRI5";
		// const pitchTypeId = "aKcYnINOO1KDzHkN0mb7";
		const hardCodedRequestQueries = [
			{
				infoRequested: {
					type: "cross",
					info: "crosstab_dd",
					params: {
						normalize: "index",
					},
				},
				widgetX: {
					widgetId: pitchCountId,
					pvs: [],
				},
				widgetY: {
					widgetId: hitResultId,
					pvs: [],
				},
			},
			{
				infoRequested: {
					type: "cross",
					info: "crosstab_dd",
					params: {
						normalize: "index",
					},
				},
				widgetX: {
					widgetId: pitchTypeId,
					pvs: [],
				},
				widgetY: {
					widgetId: hitResultId,
					pvs: [],
				},
			},
			{
				infoRequested: {
					type: "cross",
					info: "crosstab_dd",
					params: {
						normalize: "index",
					},
				},
				widgetX: {
					widgetId: hitResultId,
					pvs: [],
				},
				widgetY: {
					widgetId: fieldLocationId,
					pvs: [],
				},
			},
		];

		const request = new DashboardRequest(
			[currentChart.chartId],
			state.dashboard.requestPlayerIds,
			state.dashboard.requestWidgetIds,
			moment().subtract(state.dashboard.requestNumDeltas, state.dashboard.requestTimeDelta).format("YYYY-MM-DD"),
			moment().format("YYYY-MM-DD"),
			state.dashboard.requestTotalTimeFrame,
			state.dashboard.requestTotalPlayerRow,
			hardCodedRequestQueries,
			"infoFlat"
		);

		const requestString = JSON.stringify(request);

		const cachedDashboard = state.dashboard.dashboards[requestString];
		let dashboardToReturn;
		if (cachedDashboard && !forceRefresh) {
			dashboardToReturn = cachedDashboard;
		} else {
			const dashboardResponse = await effects.dashboard.api.getDashboard(request.getQueryRequest());
			const dashboard = new Dashboard(dashboardResponse);
			state.dashboard.dashboards["pius data"] = dashboard;
			dashboardToReturn = dashboard;
		}
		// state.dashboard.activeDashboardString = requestString;
		state.dashboard.loading = false;
		return dashboardToReturn;
	} catch (error) {
		state.dashboard.loading = false;
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

// TODO replace this with long term solution
export const getDirectFlatData = async ({ state, actions, effects }, forceRefresh) => {
	try {
		state.dashboard.loading = true;
		const currentChart = state.charts.all[state.charts.currentChartId];

		const request = new DashboardRequest(
			[currentChart.chartId],
			["*"],
			["*"],
			getDateStandardFormat(state.charts.chartView.startDate),
			getDateStandardFormat(state.charts.chartView.endDate),
			state.dashboard.requestTotalTimeFrame,
			state.dashboard.requestTotalPlayerRow,
			[],
			"directFlat"
		);
		const requestString = JSON.stringify(request);

		const cachedDashboard = state.dashboard.dashboards[requestString];
		let dashboardToReturn;
		if (cachedDashboard && !forceRefresh) {
			dashboardToReturn = cachedDashboard;
		} else {
			const dashboardResponse = await effects.dashboard.api.getDashboard(request.getQueryRequest());
			state.dashboard.directFlat = dashboardResponse;
			dashboardToReturn = dashboardResponse;
		}
		state.dashboard.activeDashboardString = requestString;
		state.dashboard.loading = false;
		return dashboardToReturn;
	} catch (error) {
		state.dashboard.loading = false;
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const getDirectNestedData = async ({ state, actions, effects }) => {
	try {
		state.dashboard.loading = true;
		const currentChart = state.charts.all[state.charts.currentChartId];

		const request = new DashboardRequest(
			[currentChart.chartId],
			["*"],
			["*"],
			moment().subtract(state.dashboard.requestNumDeltas, state.dashboard.requestTimeDelta).format("YYYY-MM-DD"),
			moment().format("YYYY-MM-DD"),
			state.dashboard.requestTotalTimeFrame,
			state.dashboard.requestTotalPlayerRow,
			[],
			"directNested"
		);

		const dashboardResponse = await effects.dashboard.api.getDashboard(request.getQueryRequest());
		state.stats.nestedStats = dashboardResponse;
		let dashboardToReturn = dashboardResponse;
		state.dashboard.loading = false;
		return dashboardToReturn;
	} catch (error) {
		state.dashboard.loading = false;
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

/**
 * Setters
 */

export const setRequestChartIds = ({ state }, requestChartIds) => {
	state.dashboard.requestChartIds = requestChartIds.length ? requestChartIds : ["*"];
};

export const setRequestPlayersIds = ({ state }, requestPlayerIds) => {
	state.dashboard.requestPlayerIds = requestPlayerIds.length ? requestPlayerIds : ["*"];
};

export const setRequestWidgetIds = ({ state }, requestWidgetIds) => {
	state.dashboard.requestWidgetIds = requestWidgetIds.length ? requestWidgetIds : ["*"];
};

export const setRequestTimeDelta = ({ state }, requestTimeDelta) => {
	state.dashboard.requestTimeDelta = requestTimeDelta;
};

export const setRequestNumDeltas = ({ state }, requestNumDeltas) => {
	state.dashboard.requestNumDeltas = requestNumDeltas;
};

export const setRequestTotalTimeFrame = ({ state }, requestTotalTimeFrame) => {
	state.dashboard.requestTotalTimeFrame = requestTotalTimeFrame;
};

export const setRequestTotalPlayerRow = ({ state }, requestTotalPlayerRow) => {
	state.dashboard.requestTotalPlayerRow = requestTotalPlayerRow;
};

export const removeStatFromFlatList = ({ state }, statToRemove) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	const updatedFlatStatList = state.dashboard.directFlat[currentChart.chartId].filter(
		(stat) => stat.statId != statToRemove.statId
	);
	state.dashboard.directFlat[currentChart.chartId] = updatedFlatStatList;
};
