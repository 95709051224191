import React from "react";
import { Droppable } from "react-beautiful-dnd";
import RecordingPlayer from "./RecordingPlayer";
import EditPlayerButton from "../edit/EditPlayerButton";
import { useAppState } from "../../../api";

const Column = ({ column, players, editing }) => {
	const state = useAppState();
	return (
		<div>
			<Droppable droppableId={column.id}>
				{(provided) => (
					<div className="my-1 rounded overflow-y-auto" ref={provided.innerRef} {...provided.droppableProps}>
						{players
							.filter((player) => {
								return player?.name
									.toLowerCase()
									.concat(player.number)
									.includes(state.players.recordingPlayerSearch.toLowerCase());
							})
							.sort((playerA, playerB) => {
								if (state.players.playerSortVal == "name") {
									return playerA[state.players.playerSortVal].localeCompare(
										playerB[state.players.playerSortVal]
									);
								} else if (state.players.playerSortVal == "number") {
									return playerA[state.players.playerSortVal] < playerB[state.players.playerSortVal]
										? -1
										: 1;
								} else {
									return 0;
								}
							})
							.map((player, index) =>
								editing ? (
									<EditPlayerButton
										key={player.playerId}
										playerId={player.playerId}
										index={index}
										name={player.name}
										editing={editing}
									/>
								) : (
									<RecordingPlayer
										player={player}
										key={player.playerId}
										index={index}
										editing={editing}
									/>
								)
							)}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</div>
	);
};

export default Column;
