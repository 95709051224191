import { WidgetTypes } from "../constants/widgetTypes";
import { Strikes, Balls } from "../constants/pitchLocations";
import moment from "moment";
import { SprayChartValues } from "../constants/fieldLocation";

/**
 * Checks if a heat map or spray chart is in the current chart
 */
export const heatMapAndSprayChartWidgetIds = (state) => {
	let currentChart = state.charts.all[state.charts.currentChartId];
	let allWidgetId = currentChart ? currentChart.widgets : [];
	let heatMapWidgetIds = [];
	let sprayChartWidgetIds = [];
	let xyPitchLocWidgetIds = [];

	allWidgetId.forEach((widgetId) => {
		if (state.widgets.all[widgetId].type === WidgetTypes.PITCHLOCATION) {
			heatMapWidgetIds = [...heatMapWidgetIds, widgetId];
		}
		if (state.widgets.all[widgetId].type === WidgetTypes.FIELDLOCATION) {
			sprayChartWidgetIds = [...sprayChartWidgetIds, widgetId];
		}
		if (state.widgets.all[widgetId].type === WidgetTypes.XYCOORDINATE) {
			xyPitchLocWidgetIds = [...xyPitchLocWidgetIds, widgetId];
		}
	});

	let returnVar = { heatMap: heatMapWidgetIds, sprayChart: sprayChartWidgetIds, xyPitchLoc: xyPitchLocWidgetIds };

	return returnVar;
};

/**
 * Checks to see if a player has any data for the visible heatmaps
 */
export const playerHasHeatMapDataForVisible = (state, playerData) => {
	let hasData = false;

	state.charts.chartView.visibleHeatMaps.forEach((heatMapId) => {
		const heatMapName = state.widgets.all[heatMapId].name;

		Object.keys(playerData).forEach((key) => {
			// need to do this to get the tool's name
			let toolNameArr = key.split("_");
			//first value of the array is the name of the tool assuming the tool name doesn't contain an _
			let toolName = toolNameArr[0];
			if (toolName === heatMapName) {
				if (playerData[key] !== 0) {
					hasData = true;
				}
			}
		});
	});

	return hasData;
};

/**
 * Checks to see if a there is any data for any visible player and visible heatmap
 */
export const someVisiblePlayerHasVisibleHeatMapData = (state) => {
	let hasData = false;
	let currentChart = state.charts.all[state.charts.currentChartId];

	state.charts.chartView.visibleHeatMapPlayers.forEach((playerId) => {
		currentChart.chartData.forEach((playerData) => {
			if (playerData.Player_Id === playerId) {
				if (playerHasHeatMapDataForVisible(state, playerData)) {
					hasData = true;
				}
			}
		});
	});

	return hasData;
};

/**
 * Checks to see if a player has any data for the visible spray chart
 */
export const playerHasSprayChartDataForVisible = (state, playerData) => {
	let hasData = false;

	state.charts.chartView.visibleSprayCharts.forEach((sprayChartId) => {
		const sprayChartName = state.widgets.all[sprayChartId].name;

		Object.keys(playerData).forEach((key) => {
			// need to do this to get the tool's name
			let toolNameArr = key.split("_");
			//first value of the array is the name of the tool assuming the tool name doesn't contain an _
			let toolName = toolNameArr[0];
			if (toolName === sprayChartName) {
				if (playerData[key] !== 0) {
					hasData = true;
				}
			}
		});
	});

	return hasData;
};

/**
 * Checks to see if a there is any data for any visible player and visible spray chart
 */
export const someVisiblePlayerHasVisibleSprayChartData = (state) => {
	let hasData = false;
	let currentChart = state.charts.all[state.charts.currentChartId];

	state.charts.chartView.visibleSprayChartPlayers.forEach((playerId) => {
		currentChart.chartData.forEach((playerData) => {
			if (playerData.Player_Id === playerId) {
				if (playerHasSprayChartDataForVisible(state, playerData)) {
					hasData = true;
				}
			}
		});
	});

	return hasData;
};

/**
 * Checks to see if the chart has data for the heatmap or spray chart
 */
export const hasHeatMapOrSprayChartData = (state) => {
	let hasHeatMapData = false;
	let hasSprayChartData = false;
	//let hasCoordinateHeatMapData = false
	let currentChart = state.charts.all[state.charts.currentChartId];
	currentChart.chartData.forEach((player) => {
		let { total: heatMapTotalForPlayer } = getHeatMapTotal(player);
		if (heatMapTotalForPlayer > 0) {
			hasHeatMapData = true;
		}

		let { total: sprayChartTotalForPlayer } = getSprayChartTotal(player);
		if (sprayChartTotalForPlayer > 0) {
			hasSprayChartData = true;
		}
	});

	let returnVal = { heatMap: hasHeatMapData, sprayChart: hasSprayChartData };
	return returnVal;
};

export const hasCoordinateHeatMapData = (currentChartFlatData, state) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	let hasData = false;
	currentChartFlatData?.forEach((datum) => {
		Object.keys(datum).forEach((key) => {
			if (currentChart.widgets.includes(key)) {
				const widget = state.widgets.all[key];

				if (widget.type === WidgetTypes.XYCOORDINATE) {
					hasData = true;
				}
			}
		});
	});

	return hasData;
};

/**
 * Gets the total number of pitches in the heat map
 */
export const getHeatMapTotal = (data) => {
	let total = 0;
	let max = 0;
	let min = 1000000;
	Object.keys(data).forEach((key) => {
		if (Strikes.some((val) => key.includes(val)) || Balls.some((val) => key.includes(val))) {
			total += data[key];
			max = data[key] > max ? data[key] : max;
			min = data[key] < min ? data[key] : min;
		}
	});
	return { total, max, min };
};

/**
 * Gets the total number of hits in the spray chart
 */
export const getSprayChartTotal = (data) => {
	let total = 0;
	let max = 0;
	let min = 1000000;
	Object.keys(data).forEach((key) => {
		if (SprayChartValues.some((val) => key.includes(val))) {
			total += data[key];
			max = data[key] > max ? data[key] : max;
			min = data[key] < min ? data[key] : min;
		}
	});
	return { total, max, min };
};

/**
 * Gets the total number of strikes in the heat map
 */
export const getHeatMapTotalStrikes = (data, dataView) => {
	let total = 0;
	Object.keys(data).forEach((key) => {
		if (dataView) {
			if (Strikes.some((val) => key === val)) {
				total += data[key];
			}
		} else {
			if (Strikes.some((val) => key.includes(val))) {
				total += data[key];
			}
		}
	});
	return total;
};

/**
 * Checks to see if the chart has data for a specific widget
 */
export const hasDataForWidget = (state, widgetId) => {
	let hasData = false;
	let currentChart = state.charts.all[state.charts.currentChartId];
	let widget = state.widgets.all[widgetId];

	currentChart.chartData.forEach((player) => {
		Object.keys(player).forEach((key) => {
			if (key.includes(widget.name)) {
				if (player[key] !== 0) {
					hasData = true;
				}
			}
		});
	});
	return hasData;
};

export const comparisonTableSign = (val) => {
	let valSign;

	if (val === -1 || val === "-1") {
		valSign = "-";
	} else if (val === 1 || val === "1") {
		valSign = "+";
	} else if (val === 0 || val === "0") {
		valSign = "•";
	} else {
		valSign = val;
	}

	return valSign;
};

// TODO delete this function when we get rid of the old "Raw Stats" block
export const rawStatsToCSV = (state, actions, obj) => {
	let newArr = [];
	const currentChart = state.charts.all[state.charts.currentChartId];
	Object.values(obj).forEach((stat) => {
		let statData = {};
		// initialize statData with all the possible widgets in the chart (for column headers)
		currentChart.widgets.forEach((widgetId) => {
			let widget = state.widgets.all[widgetId];
			let newWidgetName = widget.name.replaceAll(",", " ");
			statData[newWidgetName + "_" + widget.type] = "-";
		});
		// assign a value for every column that has a data value
		stat.statData.forEach((data) => {
			// don't include data if the widget is not currently in the chart
			if (currentChart.widgets.includes(data.widgetId)) {
				let newWidgetName = data.widgetName.replaceAll(",", " ");
				if (data.widgetType === WidgetTypes.PLAYERLISTSELECT) {
					let newPlayerName = actions.players.getPlayerById(data.value).name.replaceAll(",", " ");
					statData[newWidgetName + "_" + data.widgetType] = newPlayerName;
				} else {
					let newDataVal = data.value;
					if (typeof newDataVal === "string") newDataVal = newDataVal.replaceAll(",", ";");
					statData[newWidgetName + "_" + data.widgetType] = newDataVal;
				}
			}
		});

		// construct the new object
		let newStat = {
			Player_Name: stat.playerName.replaceAll(",", " "),
			Player_Id: stat.playerId,
			Date_Created: moment(stat.dateCreated).format("L"),
			Time_Created: moment(stat.dateCreated).format("LTS"),
			...statData,
		};
		newArr.push(newStat);
	});

	// return the array that is converted into a CSV
	return arrayToCSV(newArr);
};

export const directStatsToCSV = (state, actions, obj, chartId) => {
	let newArr = [];
	const chart = state.charts.all[chartId];

	obj.forEach((stat) => {
		let statData = {};
		// initialize statData with all the possible widgets in the chart (for column headers)
		chart.widgets.forEach((widgetId) => {
			let widget = state.widgets.all[widgetId];
			let newWidgetName = widget.name.replaceAll(",", " ");
			statData[newWidgetName + "_" + widget.type] = "-";
		});

		// assign a value for every column that has a data value
		Object.keys(stat).forEach((key) => {
			// don't include data if the widget is not currently in the chart
			if (chart.widgets.includes(key)) {
				const widget = state.widgets.all[key];
				let newWidgetName = widget.name.replaceAll(",", " ");
				if (widget.type === WidgetTypes.PLAYERLISTSELECT) {
					let newPlayerName = actions.players.getPlayerById(stat[key]).name.replaceAll(",", " ");
					statData[newWidgetName + "_" + widget.type] = newPlayerName;
				} else if (widget.type === WidgetTypes.XYCOORDINATE) {
					let newDataVal = stat[key];
					statData[newWidgetName + "_" + widget.type] = JSON.stringify(newDataVal).replaceAll(",", " ");
				} else {
					let newDataVal = stat[key];
					if (typeof newDataVal === "string") newDataVal = newDataVal.replaceAll(",", ";");
					statData[newWidgetName + "_" + widget.type] = newDataVal;
				}
			}
		});

		// construct the new object
		let newStat = {
			Player_Name: actions.players.getPlayerById(stat.player).name.replaceAll(",", " "),
			Date_Created: moment(stat.dateCreated).format("L"),
			Time_Created: moment(stat.dateCreated).format("LTS"),
			...statData,
		};
		newArr.push(newStat);
	});

	// return the array that is converted into a CSV
	return arrayToCSV(newArr);
};

export const arrayToCSV = (objArray) => {
	const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
	let str = `${Object.keys(array[0])
		.map((value) => `${value}`)
		.join(",")}\r\n`;

	return array.reduce((str, next) => {
		str += `${Object.values(next)
			.map((value) => `${value}`)
			.join(",")}\r\n`;
		return str;
	}, str);
};
