import React, { useState } from "react";

import { useAppState, useActions } from "../../../api";

import { Dropdown, Row, Menu } from "antd";
import { MoreOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import NoDataCard from "../../stats/NoDataCard";
import XYCoordinateRecord from "../../tools/xyCoordinate/Record";

import { WidgetTypes } from "../../../constants/widgetTypes";

const PlayerCoordinateHeatMapCard = (props) => {
	const { data, visiblePlayers, visibleWidgets } = props;
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	const currentChart = state.charts.all[state.charts.currentChartId];
	const [selectedPlayerId, setSelectedPlayerId] = useState("");

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	const hidePlayer = () => {
		const newVisible = state.charts.chartView.visibleHeatMapPlayers.filter(
			(playerId) => playerId !== selectedPlayerId
		);
		actions.charts.updateVisibleHeatMapPlayers(newVisible);

		const newHidden = [...state.charts.chartView.hiddenHeatMapPlayers, selectedPlayerId];
		actions.charts.updateHiddenHeatMapPlayers(newHidden);

		setSelectedPlayerId("");
	};

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	const hidePlayerDropdown = (
		<Menu>
			<Menu.Item icon={<EyeInvisibleOutlined />} onClick={hidePlayer}>
				Hide player
			</Menu.Item>
		</Menu>
	);

	const GetPlayerWidgetItterable = () => {
		const itterable = [];
		currentChart.widgets
			.filter((widgetId) => {
				return state.widgets.all[widgetId].type === WidgetTypes.XYCOORDINATE;
			})
			.forEach((widgetId) => {
				const widget = state.widgets.all[widgetId];
				currentChart.players.forEach((playerId) => {
					const player = state.players.allPlayers.filter((player) => {
						return player.playerId === playerId;
					})[0];
					const relevantData = data
						.filter((datum) => {
							return Object.keys(datum).includes(widgetId) && datum["player"] === player.playerId;
						})
						.map((datum) => {
							return { ...datum[widgetId] };
						});

					if (
						relevantData.length > 0 &&
						visiblePlayers.includes(playerId) &&
						visibleWidgets.includes(widgetId)
					) {
						itterable.push({
							player: player.name,
							widget: widget.name,
							data: relevantData,
						});
					}
				});
			});
		return itterable;
	};
	return GetPlayerWidgetItterable().length > 0 ? (
		GetPlayerWidgetItterable().map((item) => {
			return (
				<div
					className="shadow bg-white border-gray-400 mb-2 mr-2 pr-2 py-2"
					key={"heat map " + item.player + item.widget}>
					<div className="flex">
						<p className="text-lg mb-0 ml-5">{item.player}</p>
						<Dropdown
							overlay={hidePlayerDropdown}
							trigger={["click"]}
							placement="bottomCenter"
							onClick={() => setSelectedPlayerId()}>
							<MoreOutlined className="ml-auto mt-auto mb-auto hover:shadow" />
						</Dropdown>
					</div>
					<p className="text-gray-400 text-md mb-0 ml-5">{item.widget}</p>
					<Row justify={"center"}>
						<XYCoordinateRecord data={item.data} />
					</Row>
				</div>
			);
		})
	) : (
		<NoDataCard />
	);
};

export default PlayerCoordinateHeatMapCard;
