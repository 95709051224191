import { React } from "react";
import ChartData from "../../components/charts/ChartData";
import { TimeFrames, TimeFramesReadable } from "../../constants/timeFrames";
import { useAppState, useActions } from "../../api";
import { message, Select } from "antd";
const { Option } = Select;

export default function View() {
	//#region Hooks -----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();
	//#endregion Hooks --------------------------------------------------------------------------------------------------------

	const setTimeFrame = (timeFrame) => {
		actions.charts.setChartViewTimeFrame(timeFrame);
		if (timeFrame === TimeFrames.CUSTOM) {
			message.info("Use the calendar to select a custom date range");
		}
	};

	const timeFrameMenu = (
		<Select
			value={state.charts.chartView.timeFrame}
			style={{ width: 140 }}
			onChange={setTimeFrame}
			disabled={state.charts.chartDataLoading}>
			{Object.keys(TimeFramesReadable).map((timeframe) => {
				return <Option value={timeframe}>{TimeFramesReadable[timeframe]}</Option>;
			})}
		</Select>
	);

	//#region JSX -----------------------------------------------------------------------------------------------------------
	return (
		<div className="font-montserrat">
			<div className={`px-6 mb-4 flex row mt-1 mx-1`}>
				<p className="text-md flex-auto h-full my-auto">Date Range: </p>
				{timeFrameMenu}
			</div>
			<ChartData />
		</div>
	);
	//#endregion JSX -------------------------------------------------------------------------------------------------------
}
