import React from "react";

export const PosNegDisabled = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------

	let colorChangerPos = `${value === 1 ? "bg-gray-400 text-white" : " text-gray-400 bg-white "} `;

	let colorChangerNeg = `${value === -1 ? "bg-gray-400 text-white" : " text-gray-400 bg-white "} `;

	return (
		<div className="h-full text-center">
			<button
				className={"h-full w-splitHalves mr-1.5 rounded shadow text-3xl font-bold  py-2 " + colorChangerPos}>
				+
			</button>
			<button className={"h-full w-splitHalves rounded shadow text-3xl font-bold py-2 " + colorChangerNeg}>
				-
			</button>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PosNegDisabled;
