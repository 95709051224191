import React, { useState } from "react";
import moment from "moment";
import SnapTable from "./snapTable";
import { useAppState, useActions } from "../../api";
import { Input, Button, Card, message } from "antd";
const { TextArea } = Input;

export default function SingleSnapshot({ snapshot, noApi }) {
	const state = useAppState();
	const actions = useActions();
	const [textInput, setTextInput] = useState(
		snapshot.comments && snapshot.comments[0] ? snapshot.comments[0].comment : ""
	);

	const handleSaveClick = async (snapshot) => {
		await actions.snapshots.updateSnap({
			snapshotId: snapshot.snapshotId,
			comments: [{ author: "hi", date: moment().format("YYYY/MM/DD"), comment: textInput }],
		});
		message.success("Comment Saved!");
	};

	return (
		<Card>
			<SnapTable
				tableChart={snapshot.data.currentChart}
				allWidgets={noApi ? snapshot.data.widgets : state.widgets.all}
				noApi={noApi}
			/>
			<div className="mt-4 mb-8">
				<p className="mb-0 uppercase text-gray-500">Coach Notes</p>
				{!noApi ? (
					<>
						<TextArea value={textInput} onChange={(e) => setTextInput(e.target.value)} rows={8} />
						<Button
							className="mt-2 float-right"
							disabled={!textInput.trim()}
							onClick={() => handleSaveClick(snapshot)}>
							Save
						</Button>
					</>
				) : (
					<p>{textInput}</p>
				)}
			</div>
			<p className="">Data going back to {moment(snapshot.startDate).fromNow()}</p>
		</Card>
	);
}
