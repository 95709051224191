import axios from "axios";
import { Player } from "../../constants/api";

export const api = {
	async createPlayer(payload) {
		const res = await axios.post(Player.create, payload);
		return res.data.receipt;
	},
	async getAllPlayers() {
		const res = await axios.post(Player.read.all, {});
		return res.data.players;
	},
	async updatePlayer(payload) {
		const res = await axios.post(Player.update, payload);
		return res.data.receipt;
	},
	async deletePlayer(payload) {
		const res = await axios.post(Player.delete, payload);
		return res.data.receipt;
	},
};
