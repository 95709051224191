import { message } from "antd";
import { NEW_ROW_KEY } from "../../constants/editableTableConstant";
import { tagType } from "../../constants/tagType";
import { handleError } from "../../util/errorUtil";
import Player from "../../models/player";

export const createPlayer = async ({ state, actions, effects }, player) => {
	try {
		if (player.number === "") delete player["number"];
		if (player.playerId === NEW_ROW_KEY) {
			player.teamIds = [state.teams.selectedTeamId];
			state.players.allPlayers = [player, ...state.players.allPlayers];
		} else {
			player.playerTagIds = await actions.players.tryCreateNewPlayerTags(player);
			const receipt = await effects.players.api.createPlayer({ players: [player] });
			const newPlayer = new Player(receipt.created[0]);

			state.players.allPlayers = [newPlayer, ...state.players.allPlayers].filter(
				(player) => player.playerId !== NEW_ROW_KEY
			);
		}
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
		state.players.allPlayers = state.players.allPlayers.filter((player) => player.playerId !== NEW_ROW_KEY);
	}
};

export const getAllPlayers = async ({ state, actions, effects }) => {
	try {
		const playerList = await effects.players.api.getAllPlayers();
		state.players.allPlayers = playerList.map((player) => new Player(player));
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const updatePlayer = async ({ state, actions, effects }, player) => {
	try {
		player.playerTagIds = await actions.players.tryCreateNewPlayerTags(player);
		player.number = player.number === "" ? undefined : player.number;
		await effects.players.api.updatePlayer(player);
		state.players.allPlayers = state.players.allPlayers.map((playerObj) =>
			playerObj.playerId === player.playerId ? player : playerObj
		);
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const deletePlayer = async ({ state, actions, effects }, playerId) => {
	try {
		if (playerId !== NEW_ROW_KEY) {
			await effects.players.api.deletePlayer({ playerId });
		}
		state.players.allPlayers = state.players.allPlayers.filter((player) => player.playerId !== playerId);
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const tryCreateNewPlayerTags = async ({ state, actions }, player) => {
	try {
		const existingTagIds = player.playerTagIds.filter((tagId) =>
			state.tags.playerTags.some((tag) => tag.tagId === tagId)
		);
		const newTags = player.playerTagIds.filter(
			(tagId) => !state.tags.playerTags.some((tag) => tag.tagId === tagId)
		);
		const newTagIds = await Promise.all(
			newTags.map(
				async (newTag) =>
					await actions.tags.createTag({ label: newTag, tagType: tagType.player_tag, isHidden: false })
			)
		);
		return [...existingTagIds, ...newTagIds];
	} catch (error) {
		handleError(error, actions);
		message.error(state.global.error.message, 6);
	}
};

export const setSelectedPlayerId = async ({ state }, playerId) => {
	state.players.selectedPlayerId = playerId;
};

export const getPlayersInTeam = ({ state }, teamId) => {
	try {
		return state.players.allPlayers.filter((player) => player.teamIds.includes(teamId));
	} catch (error) {
		return undefined;
	}
};

export const getPlayerById = ({ state }, playerId) => {
	try {
		return state.players.allPlayers.filter((player) => player.playerId === playerId)[0];
	} catch (error) {
		return undefined;
	}
};

export const createPlayersFromImport = async ({ actions, effects, state }, playerImportList) => {
	try {
		// we might want to wait on tags for now
		//player.playerTagIds = await actions.players.tryCreateNewPlayerTags(player);

		const receipt = await effects.players.api.createPlayer({ players: playerImportList });

		const players = receipt.created.map((player) => {
			return new Player(player);
		});

		state.players.allPlayers = [...players, ...state.players.allPlayers];

		return true;
	} catch (error) {
		handleError(error, actions);
		message.error("Unable to save roster. Please try again or contact support if this issue persists.");
	}
};

export const setRecordingPlayerSearch = ({ state }, newVal) => {
	state.players.recordingPlayerSearch = newVal;
};

export const setNewPlayerName = async ({ state }, newPlayerName) => {
	state.players.newPlayer.name = newPlayerName;
};

export const setNewPlayerTeam = async ({ state }, newPlayerTeamId) => {
	state.players.newPlayer.teamIds.push(newPlayerTeamId);
};

export const setPlayerSortVal = ({ state }, newVal) => {
	state.players.playerSortVal = newVal;
};
