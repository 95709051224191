import React, { useState, useEffect } from "react";

import { useAppState, useActions } from "../../../api";

import { WidgetTypes } from "../../../constants/widgetTypes";
import { ReloadOutlined, CheckOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";

const StopwatchRecord = ({ widgetId, label, isGrouped }) => {
	const state = useAppState();
	const actions = useActions();
	const activeGroup = state.stats.activeGroup;

	//#region HOOKS -----------------------------------------------------------------------------------------------------------

	const [timer, setTimer] = useState(0);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		if (!activeGroup || !activeGroup[label]) {
			handleReset();
		}
	}, [activeGroup && activeGroup[label] ? activeGroup[label].value : ""]);

	useEffect(() => {
		let interval = null;

		var startTime = Date.now();

		if (isActive) {
			interval = setInterval(() => {
				if (timer) {
					setTimer((parseFloat(((Date.now() - startTime) / 1000).toFixed(2)) + parseFloat(timer)).toFixed(2));
				} else {
					setTimer(((Date.now() - startTime) / 1000).toFixed(2));
				}
			}, 10);
		} else if (!isActive) {
			clearInterval(interval);
		}

		return () => clearInterval(interval);
	}, [isActive]);

	// makes sure the data submitted for grouped charts is accurate based on what the stopwatch says
	useEffect(() => {
		if (isGrouped && timer !== 0 && !isActive) {
			handleSubmit();
		}
	}, [timer, isActive]);

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	//#region HANDLERS ------------------------------------------------------------------------------------------------------

	const handleReset = () => {
		setTimer(0);
		setIsActive(false);

		// delete timer from active group
		if (isGrouped) {
			let data = {
				widgetId: widgetId,
				widgetName: label,
				widgetType: WidgetTypes.STOPWATCH,
				value: undefined,
			};

			actions.stats.setActiveGroup(data);
		}
	};

	const handleSubmit = () => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.STOPWATCH,
			value: parseFloat(timer),
		};

		if (isGrouped) {
			actions.stats.setActiveGroup(data);
		} else {
			actions.stats.create(data);
			handleReset();
		}
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------
	return (
		<div className="h-24 text-center w-full">
			<Row gutter={[8, 0]} className="h-full">
				<Col span={18} className=" h-full">
					<div
						id={widgetId}
						className="w-full h-full rounded shadow text-xl py-2 text-blue text-center bg-white"
						onClick={() => setIsActive(!isActive)}>
						<p className="mb-0 mt-3">{timer === 0 ? "0.00" : timer}</p>
						{!isActive ? (
							<span className="text-sm">Start</span>
						) : (
							<span className="text-sm text-red-400">Stop</span>
						)}
					</div>
				</Col>
				<Col span={6}>
					{isGrouped ? null : (
						<Row>
							<button
								id={widgetId}
								className={
									"h-full w-full rounded shadow text-xl font-bold py-2 bg-white" +
									(isActive || timer === 0 || state.stats.recordingPlayerId === ""
										? " text-gray-500 bg-gray-300"
										: " text-blue")
								}
								onClick={(e) => handleSubmit(e)}
								disabled={isActive || timer === 0 || state.stats.recordingPlayerId === ""}>
								<CheckOutlined />
							</button>
						</Row>
					)}
					<Row>
						<button
							className={
								"rounded shadow text-xl font-bold float-left w-full " +
								(isActive || timer === 0 ? " text-gray-500 bg-gray-300" : " text-red-400 bg-white") +
								(isGrouped ? " h-24" : " h-12 mt-1")
							}
							disabled={isActive || timer === 0}
							type="primary"
							ghost
							onClick={handleReset}>
							<ReloadOutlined />
						</button>
					</Row>
				</Col>
			</Row>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default StopwatchRecord;
