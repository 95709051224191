import React from "react";

export const NegMini = ({ value }) => {
	let colorChangerNeg = value
		? `${value === "-1" ? " text-gray-400 bg-white " : "bg-gray-400 text-white"} `
		: "bg-gray-100";
	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<p
			className={
				"w-full mb-0 rounded text-center font-bold bg-gray-100 flex text-center items-center justify-center " +
				colorChangerNeg
			}>
			—
		</p>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default NegMini;
