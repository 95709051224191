import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "antd/dist/antd.css";
import App from "./App";
import { createOvermind } from "overmind";
import { Provider as OvermindProvider } from "overmind-react";
import { config } from "./api";

const overmind = createOvermind(config, { devtools: true });

ReactDOM.render(
	<OvermindProvider value={overmind}>
		<App />
	</OvermindProvider>,
	document.getElementById("root")
);
