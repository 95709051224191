import ChartModel from "../../models/chart";
import ChartView from "../../models/chartView";
import * as analyticsUtil from "../../util/analyticsUtil";
import { chartEditActions } from "../../constants/chartEditActions";
import { getCurrentDatesFromTimeFrame } from "../../engines/dateEngine";
import { heatMapAndSprayChartWidgetIds } from "../../engines/dataEngine";
import { insertAndUpdateHiddenColumns, countWidgetTypes } from "../../engines/chartEngine";
import { TimeFrames } from "../../constants/timeFrames";
import { message } from "antd";
import { getDateStandardFormat } from "../../util/dateUtil";

import DashboardRequest from "../../models/dashboardRequest";
import { WidgetTypes } from "../../constants/widgetTypes";
import { emptyChart } from "./constants";

export const createNew = async ({ effects, state }) => {
	let emptyChartCopy = JSON.parse(JSON.stringify(emptyChart));
	try {
		state.charts.create.isLoading = true;
		const createNewRes = await effects.charts.api.create(emptyChartCopy); // returns string ID
		state.charts.currentChartId = await createNewRes;
		emptyChartCopy.chartId = createNewRes;
		state.charts.all[createNewRes] = new ChartModel(emptyChartCopy);
		const types = countWidgetTypes(state.charts.all[createNewRes].widgets, state.widgets.all);
		analyticsUtil.chartPopulatedEvent(
			state.organization.organization.organizationId,
			types,
			state.organization.organization.title
		);
	} catch (error) {
		state.charts.create.error = error;
	} finally {
		state.charts.create.isLoading = false;
	}
};

export const getAll = async ({ effects, state }) => {
	try {
		state.charts.getAll.loading = true;
		const chartList = await effects.charts.api.getAll();
		let chartsObj = {};

		if (chartList) {
			await chartList.forEach((chart) => {
				let chartId = chart.chartId;
				chart.chartView = chart.chartView ? new ChartView(chart.chartView) : new ChartView({});
				// simulated chart tag ID until the backend supports this
				// chart.chartTagIds = chartId === "nwsl99dPq9DxZo0lVdnY" ? ["d7ebb7fe-eb77-44ee-a4bb-0b607cd4d14d"] : [];
				chart.chartTagIds = chartId === "2HgD2e3TkAJ1s1czjEtv" ? ["c322a1a8-9ac0-42dd-a6b0-df0d17da60bb"] : [];
				chartsObj[chartId] = chart;
			});
		}

		state.charts.all = chartsObj;
		return true;
	} catch (error) {
		state.charts.getAll.error = error;
	} finally {
		state.charts.getAll.loading = false;
	}
};

const checkArrayEquality = (arr1, arr2) => {
	return (
		Array.isArray(arr1) &&
		Array.isArray(arr2) &&
		arr1.length === arr2.length &&
		arr1.every((val, index) => val === arr2[index])
	);
};

const checkWidgetEquality = (widget1, widget2) => {
	return (
		widget1.name === widget2.name &&
		widget1.type === widget2.type &&
		checkArrayEquality(widget1.potentialValues, widget2.potentialValues)
	);
};

const checkWidgetNameEquality = (widget1, widget2) => {
	return widget1.name === widget2.name;
};

export const addWidgetToChart = async ({ effects, state }) => {
	const currentChart = state.charts.all[state.charts.currentChartId];

	let widgetInDatabase = Object.values(state.widgets.all).filter((widget) => {
		return checkWidgetEquality(widget, state.widgets.new);
	})[0];

	let widgetInChart = Object.values(currentChart.widgets).filter((widget) => {
		const widgetObject = state.widgets.all[widget];
		return checkWidgetEquality(widgetObject, state.widgets.new);
	})[0];

	let widgetNameInChart = Object.values(currentChart.widgets).filter((widget) => {
		const widgetObject = state.widgets.all[widget];
		return checkWidgetNameEquality(widgetObject, state.widgets.new);
	})[0];

	if (widgetInChart) {
		message.error("That tool is already in the chart", 6);
	} else if (widgetNameInChart) {
		message.error("You cannot have duplicate names", 6);
	} else {
		if (!widgetInDatabase) {
			const newWidgetId = await effects.widgets.api.create(state.widgets.new);
			state.widgets.new.setWidgetId(newWidgetId);
			state.widgets.all[newWidgetId] = { ...state.widgets.new };
			currentChart.addWidget(newWidgetId);
		} else {
			currentChart.addWidget(widgetInDatabase.widgetId);
		}
		analyticsUtil.chartEditedEvent(
			state.organization.organization.organizationId,
			chartEditActions.addWidget,
			state.organization.organization.title
		);

		state.widgets.new.setLabel("");
		state.widgets.new.setType("");
		state.widgets.new.setPotentialValue([]);
		state.widgets.showNewWidgetModal = false;
		return true;
	}
};

export const removeWidgetFromChart = ({ state }, widgetToRemove) => {
	const currentChart = state.charts.all[state.charts.currentChartId];

	analyticsUtil.chartEditedEvent(
		state.organization.organization.organizationId,
		chartEditActions.removeWidget,
		state.organization.organization.title
	);

	currentChart.widgets = currentChart.widgets.filter((widgetId) => {
		return String(widgetId) !== String(widgetToRemove.widgetId);
	});

	return true;
};

export const removePlayerFromChart = ({ state }, playerToRemove) => {
	const currentChart = state.charts.all[state.charts.currentChartId];

	analyticsUtil.chartEditedEvent(
		state.organization.organization.organizationId,
		chartEditActions.removePlayer,
		state.organization.organization.title
	);

	currentChart.players = currentChart.players.filter((playerId) => {
		return String(playerId) !== String(playerToRemove.playerId);
	});

	return true;
};

export const setCurrentChartId = ({ state }, newVal) => {
	state.charts.currentChartId = newVal;
	if (state.charts.all[newVal] && state.charts.all[newVal].chartView) {
		const chartViewInstance = new ChartView(state.charts.all[newVal].chartView);

		if (!chartViewInstance.startDate) {
			const currentDates = getCurrentDatesFromTimeFrame(chartViewInstance.timeFrame);
			chartViewInstance.startDate = currentDates.startDate;
			chartViewInstance.endDate = currentDates.endDate;
		}
		state.charts.chartView = chartViewInstance;
	} else {
		state.charts.chartView = new ChartView({});
	}
};

export const deleteChart = async ({ effects, state }, chartId) => {
	try {
		await effects.charts.api.delete(chartId);
		delete state.charts.all[chartId];
	} catch (error) {
		throw new Error(error);
	}
};

export const archiveChart = async ({ effects, state }, chartId) => {
	try {
		await effects.charts.api.updateIsArchived({ chartId: chartId, isArchived: true });
		state.charts.all[chartId].isArchived = true;
	} catch (error) {
		throw new Error(error);
	}
};

export const unArchiveChart = async ({ effects, state }, chartId) => {
	try {
		await effects.charts.api.updateIsArchived({ chartId: chartId, isArchived: false });
		state.charts.all[chartId].isArchived = false;
	} catch (error) {
		throw new Error(error);
	}
};

export const setWidgets = async ({ state, effects }, newVal) => {
	state.charts.all[state.charts.currentChartId].widgets = newVal;
	const currentChart = state.charts.all[state.charts.currentChartId];
	analyticsUtil.chartEditedEvent(
		state.organization.organization.organizationId,
		chartEditActions.reorderWidgets,
		state.organization.organization.title
	);
	try {
		await effects.charts.api.updateWidgets({ chartId: currentChart.chartId, widgets: newVal });
	} catch (error) {
		throw new Error(error);
	}
	return true;
};

export const updateWidgets = async ({ effects, state }) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	try {
		await effects.charts.api.updateWidgets({ chartId: currentChart.chartId, widgets: currentChart.widgets });
	} catch (error) {
		throw new Error(error);
	}
	return true;
};

export const updatePlayers = async ({ effects, state }, players) => {
	try {
		const currentChart = state.charts.all[state.charts.currentChartId];
		if (currentChart.chartId !== "") {
			await effects.charts.api.updatePlayers({ chartId: currentChart.chartId, players });
		}

		currentChart.players = [...players];
	} catch (error) {
		throw new Error(error);
	}

	analyticsUtil.chartEditedEvent(
		state.organization.organization.organizationId,
		chartEditActions.addPlayer,
		state.organization.organization.title
	);
};

export const updatePlayerOrder = async ({ state }, newVal) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	currentChart.players = newVal;
};

/**
 * Chart view methods - many of these need to include an effect update call
 */

export const setChartViewTimeFrame = async ({ state }, newValue) => {
	state.charts.chartView.timeFrame = newValue;
	const dateValues = getCurrentDatesFromTimeFrame(newValue);
	state.charts.chartView.startDate = dateValues.startDate;
	state.charts.chartView.endDate = dateValues.endDate;

	return true;
};

export const setCustomChartViewDates = async ({ state }, newValue) => {
	state.charts.chartView.startDate = newValue.startDate;
	state.charts.chartView.endDate = newValue.endDate;
	state.charts.chartView.timeFrame = TimeFrames.CUSTOM;
};

export const addChartViewHiddenColumn = ({ state }, newValue) => {
	if (state.charts.chartView.hiddenColumns) {
		const hiddenColumnsCopy = [...state.charts.chartView.hiddenColumns];
		state.charts.chartView.hiddenColumns = insertAndUpdateHiddenColumns(newValue, hiddenColumnsCopy);
	} else {
		state.charts.chartView.hiddenColumns = [newValue];
	}
};

export const removeChartViewHiddenColumn = ({ state }, newValue) => {
	if (state.charts.chartView.hiddenColumns) {
		const index = state.charts.chartView.hiddenColumns.indexOf(newValue);
		state.charts.chartView.hiddenColumns.splice(index, 1);
	}
};

export const clearChartViewHiddenColumns = ({ state }) => {
	state.charts.chartView.hiddenColumns = [];
};

export const setChartViewSort = ({ state }, newValue) => {
	// value should be an array, see ChartView object for format
	state.charts.chartView.sort = newValue;
};

export const setChartView = ({ state }, newValue) => {
	state.charts.chartView = new ChartView(newValue);
};

/**
 * Chart data methods
 */

export const setChartDataRecordingDate = ({ state }, newValue) => {
	state.charts.chartDataRecordingDate = newValue;
};

export const setChartDateLastUsed = async ({ effects, state }, newValue) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	await effects.charts.api.updateDateLastUsed({ chartId: currentChart.chartId, dateLastUsed: newValue });
};

export const getChartData = async ({ effects, actions, state }) => {
	state.charts.chartDataLoading = true;
	state.charts.noNewData = false;

	const currentChart = state.charts.all[state.charts.currentChartId];

	// using Dashboard request validator for now until we move get stats out of the chart

	const request = new DashboardRequest(
		[currentChart.chartId],
		["*"],
		["*"],
		getDateStandardFormat(state.charts.chartView.startDate),
		getDateStandardFormat(state.charts.chartView.endDate),
		state.dashboard.requestTotalTimeFrame,
		state.dashboard.requestTotalPlayerRow,
		[],
		"infoNested"
	);

	// TODO make sure pius chart works, not sure if this is how it's supposed to work
	state.charts.all[state.charts.currentChartId].chartTagIds =
		state.charts.currentChartId === "2HgD2e3TkAJ1s1czjEtv" ? ["c322a1a8-9ac0-42dd-a6b0-df0d17da60bb"] : [];
	// state.charts.currentChartId === "nwsl99dPq9DxZo0lVdnY" ? ["d7ebb7fe-eb77-44ee-a4bb-0b607cd4d14d"] : []; // development one?

	try {
		const dashboardResponse = await effects.charts.api.getData(request.getQueryRequest());

		//TODO fix, this is going to get goofy
		if (dashboardResponse["Entire Range"]) {
			let unFormattedData = dashboardResponse["Entire Range"];
			let newChartData = Object.keys(unFormattedData).map((playerId) => {
				let player = actions.players.getPlayerById(playerId);

				let playerObj = {};
				if (playerId !== "Total") {
					playerObj = {
						Player_Id: playerId,
						Player_Name: player.name,
					};
				} else {
					playerObj = {
						Player_Id: playerId,
						Player_Name: "Total",
					};
				}

				let playerData = unFormattedData[playerId];
				let posCounter = 0;
				let negCounter = 0;
				let neutCounter = 0;

				Object.keys(playerData).forEach((widgetId) => {
					let widget = state.widgets.all[widgetId];

					let newKey;

					switch (widget.type) {
						case WidgetTypes.COUNTER:
							newKey = widget.name + "_COUNTER";
							playerObj[newKey] = playerData[widgetId].count["1"];
							posCounter += playerData[widgetId].count["1"];
							break;
						case WidgetTypes.POSNEG:
							playerObj[widget.name + "_POS"] = playerData[widgetId].count["1"];
							playerObj[widget.name + "_NEG"] = playerData[widgetId].count["-1"];
							playerObj[widget.name + "_PCT"] = playerData[widgetId].pct["1"];
							posCounter += playerData[widgetId].count["1"];
							negCounter += playerData[widgetId].count["-1"];
							break;
						case WidgetTypes.POSNEGNEUTRAL:
							playerObj[widget.name + "_POS"] = playerData[widgetId].count["1"];
							playerObj[widget.name + "_NEG"] = playerData[widgetId].count["-1"];
							playerObj[widget.name + "_NEUT"] = playerData[widgetId].count["0"];
							playerObj[widget.name + "_PCT"] = playerData[widgetId].pct["1"];
							posCounter += playerData[widgetId].count["1"];
							negCounter += playerData[widgetId].count["-1"];
							neutCounter += playerData[widgetId].count["0"];
							break;
						case WidgetTypes.STOPWATCH:
							playerObj[widget.name + "_MIN"] =
								playerData[widgetId].minimum !== null && playerId !== "Total"
									? playerData[widgetId].minimum
									: 0;
							playerObj[widget.name + "_MAX"] =
								playerData[widgetId].maximum !== null && playerId !== "Total"
									? playerData[widgetId].maximum
									: 0;
							playerObj[widget.name + "_AVG"] =
								playerData[widgetId].avg !== null && playerId !== "Total"
									? playerData[widgetId].avg
									: 0;
							break;
						case WidgetTypes.PITCHLOCATION:
							Object.keys(playerData[widgetId].count).forEach((key) => {
								let newKey = widget.name + "_" + key;

								playerObj[newKey] = playerData[widgetId].count[key];
							});
							break;
						case WidgetTypes.NUMBERINPUT:
							playerObj[widget.name + "_MIN"] =
								playerData[widgetId].minimum !== null && playerId !== "Total"
									? playerData[widgetId].minimum
									: 0;
							playerObj[widget.name + "_MAX"] =
								playerData[widgetId].maximum !== null && playerId !== "Total"
									? playerData[widgetId].maximum
									: 0;
							playerObj[widget.name + "_AVG"] =
								playerData[widgetId].avg !== null && playerId !== "Total"
									? playerData[widgetId].avg
									: 0;
							break;
						case WidgetTypes.LISTSELECT:
							Object.keys(playerData[widgetId].count).forEach((key) => {
								let newKey = widget.name + "_" + key;

								// maybe pct
								playerObj[newKey] = playerData[widgetId].count[key];
							});
							break;
						case WidgetTypes.NEGCOUNTER:
							newKey = widget.name + "_NEG_COUNTER";
							playerObj[newKey] = playerData[widgetId].count["-1"];
							negCounter += playerData[widgetId].count["-1"];
							break;
						case WidgetTypes.POSNEUTRAL:
							playerObj[widget.name + "_POS"] = playerData[widgetId].count["1"];
							playerObj[widget.name + "_NEUT"] = playerData[widgetId].count["0"];
							playerObj[widget.name + "_PCT"] = playerData[widgetId].pct["1"];
							posCounter += playerData[widgetId].count["1"];
							neutCounter += playerData[widgetId].count["0"];
							break;
						case WidgetTypes.FIELDLOCATION:
							Object.keys(playerData[widgetId].count).forEach((key) => {
								let newKey = widget.name + "_" + key;

								playerObj[newKey] = playerData[widgetId].count[key];
							});
							break;
						case WidgetTypes.PLAYERLISTSELECT:
							Object.keys(playerData[widgetId].pct).forEach((key) => {
								let newKey = widget.name + "_" + key;

								// maybe count
								playerObj[newKey] = playerData[widgetId].pct[key];
							});
							break;
						default:
							break;
					}
				});

				playerObj["Total_POS"] = posCounter;
				playerObj["Total_NEG"] = negCounter;
				playerObj["Total_NEUT"] = neutCounter;
				let totPct = posCounter / (posCounter + negCounter);
				playerObj["Total_PCT"] = Math.round(totPct * 10000) / 100;
				return playerObj;
			});

			state.charts.all[state.charts.currentChartId].chartData = newChartData;
		} else {
			state.charts.all[state.charts.currentChartId].chartData = dashboardResponse;
			state.charts.noNewData = true;
		}

		state.charts.chartDataLoading = false;
	} catch (error) {
		state.charts.chartDataLoading = false;
		throw new Error(error);
	}

	return true;
};

export const updateTitle = async ({ state, effects }, newVal) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	currentChart.title = newVal;
	await effects.charts.api.updateTitle({ chartId: currentChart.chartId, title: newVal });
};

export const updateIsGrouped = async ({ state, effects }, newVal) => {
	const currentChart = state.charts.all[state.charts.currentChartId];
	currentChart.isGrouped = newVal;
	await effects.charts.api.updateTitle({ chartId: currentChart.chartId, isGrouped: newVal });
};

//#region heat map actions
export const setVisibleHeatMaps = ({ state }) => {
	// TODO when there is backend support call this function:
	// actions.charts._updateChartView(state.charts.chartView);
	// after setting the new chartView for all heatmap functions

	state.charts.chartView.visibleHeatMaps = heatMapAndSprayChartWidgetIds(state).heatMap;
};

export const setVisibleCoordinateHeatMaps = ({ state }) => {
	state.charts.chartView.visibleCoordinateHeatMaps = heatMapAndSprayChartWidgetIds(state).xyPitchLoc;
};

export const setHiddenHeatMaps = ({ state }) => {
	let hiddenHeatMaps = heatMapAndSprayChartWidgetIds(state).heatMap.filter(
		(heatMapId) => !state.charts.chartView.visibleHeatMaps.some((val) => val === heatMapId)
	);
	state.charts.chartView.hiddenHeatMaps = hiddenHeatMaps;
};

export const setHiddenCoordinateHeatMaps = ({ state }) => {
	let hiddenHeatMaps = heatMapAndSprayChartWidgetIds(state).xyPitchLoc.filter(
		(heatMapId) => !state.charts.chartView.visibleCoordinateHeatMaps.some((val) => val === heatMapId)
	);
	state.charts.chartView.hiddenHeatMaps = hiddenHeatMaps;
};

export const clearVisibleHeatMapPlayers = ({ state }) => {
	state.charts.chartView.visibleHeatMapPlayers = [];
};

export const clearVisibleCoordinateHeatMapPlayers = ({ state }) => {
	state.charts.chartView.visibleCoordinateHeatMapPlayers = [];
};

export const clearHiddenHeatMapPlayers = ({ state }) => {
	state.charts.chartView.hiddenHeatMapPlayers = [];
};

export const clearHiddenCoordinateHeatMapPlayers = ({ state }) => {
	state.charts.chartView.hiddenCoordinateHeatMapPlayers = [];
};

export const setVisibleHeatMapPlayers = ({ state }) => {
	let currentChart = state.charts.all[state.charts.currentChartId];
	let players = [];
	currentChart.players.forEach((playerId) => {
		players = [...players, playerId];
	});
	state.charts.chartView.visibleHeatMapPlayers = players;
};

export const setVisibleCoordinateHeatMapPlayers = ({ state }) => {
	let currentChart = state.charts.all[state.charts.currentChartId];
	let players = [];
	currentChart.players.forEach((playerId) => {
		players = [...players, playerId];
	});
	state.charts.chartView.visibleCoordinateHeatMapPlayers = players;
};

export const setHiddenHeatMapPlayers = ({ state }) => {
	let currentChart = state.charts.all[state.charts.currentChartId];

	let hiddenPlayers = currentChart.players.filter(
		(playerId) => !state.charts.chartView.visibleHeatMapPlayers.some((val) => val === playerId)
	);

	state.charts.chartView.hiddenHeatMapPlayers = hiddenPlayers;
};

export const setHiddenCoordinateHeatMapPlayers = ({ state }) => {
	let currentChart = state.charts.all[state.charts.currentChartId];

	let hiddenPlayers = currentChart.players.filter(
		(playerId) => !state.charts.chartView.visibleCoordinateHeatMapPlayers.some((val) => val === playerId)
	);

	state.charts.chartView.hiddenCoordinateHeatMapPlayers = hiddenPlayers;
};

//#endregion heat map actions

//#region spray chart actions
export const setVisibleSprayCharts = ({ state }) => {
	// TODO when there is backend support call this function:
	// actions.charts._updateChartView(state.charts.chartView);
	// after setting the new chartView for all spraychart functions

	state.charts.chartView.visibleSprayCharts = heatMapAndSprayChartWidgetIds(state).sprayChart;
};

export const setHiddenSprayCharts = ({ state }) => {
	let hiddenSprayCharts = heatMapAndSprayChartWidgetIds(state).sprayChart.filter(
		(sprayChartId) => !state.charts.chartView.visibleSprayCharts.some((val) => val === sprayChartId)
	);
	state.charts.chartView.hiddenSprayCharts = hiddenSprayCharts;
};

export const setVisibleSprayChartPlayers = ({ state }) => {
	let currentChart = state.charts.all[state.charts.currentChartId];
	let players = [];
	currentChart.players.forEach((playerId) => {
		players = [...players, playerId];
	});
	state.charts.chartView.visibleSprayChartPlayers = players;
};

export const setHiddenSprayChartPlayers = ({ state }) => {
	let currentChart = state.charts.all[state.charts.currentChartId];

	let hiddenPlayers = currentChart.players.filter(
		(playerId) => !state.charts.chartView.visibleSprayChartPlayers.some((val) => val === playerId)
	);

	state.charts.chartView.hiddenSprayChartPlayers = hiddenPlayers;
};

// ----------

export const updateChartStateValue = ({ state }, { property, newVal }) => {
	console.log(state, property, "working!");
	property = newVal;
};

export const updateVisibleHeatMaps = ({ state }, newVal) => {
	state.charts.chartView.visibleHeatMaps = newVal;
};

export const updateHiddenHeatMaps = ({ state }, newVal) => {
	state.charts.chartView.hiddenHeatMaps = newVal;
};

export const updateVisibleSprayChartPlayers = ({ state }, newVal) => {
	state.charts.chartView.visibleSprayChartPlayers = newVal;
};

export const updateHiddenSprayChartPlayers = ({ state }, newVal) => {
	state.charts.chartView.hiddenSprayChartPlayers = newVal;
};

export const setSprayChartFraction = ({ state }, newVal) => {
	state.charts.chartView.sprayChartFraction = newVal;
};
//#endregion spray chart actions

export const setShowRecord = ({ state }, newVal) => {
	state.charts.showRecord = newVal;
};

export const setShowGroupedExpModal = ({ state }, newVal) => {
	state.charts.showGroupedExpModal = newVal;
};

export const setShowTemplatesModal = ({ state }, newVal) => {
	state.charts.showTemplatesModal = newVal;
};

export const setShowDateRangePicker = ({ state }, newVal) => {
	state.charts.showDateRangePicker = newVal;
};

export const setNewChartSection = ({ state }, newVal) => {
	state.charts.newChartSection = newVal;
};

export const setNewChartLoading = ({ state }, newVal) => {
	state.charts.newChartLoading = newVal;
};

export const clearCurrentChart = ({ state }) => {
	state.charts.currentChartId = "";
};

export const updateVisibleSprayCharts = ({ state }, newVal) => {
	state.charts.chartView.visibleSprayCharts = newVal;
};

export const updateHiddenSprayCharts = ({ state }, newVal) => {
	state.charts.chartView.hiddenSprayCharts = newVal;
};

export const clearVisibleSprayChartPlayers = ({ state }) => {
	state.charts.chartView.visibleSprayChartPlayers = [];
};

export const clearHiddenSprayChartPlayers = ({ state }) => {
	state.charts.chartView.hiddenSprayChartPlayers = [];
};

export const updateVisibleHeatMapPlayers = ({ state }, newVal) => {
	state.charts.chartView.visibleHeatMapPlayers = newVal;
};

export const updateHiddenHeatMapPlayers = ({ state }, newVal) => {
	state.charts.chartView.hiddenHeatMapPlayers = newVal;
};

export const setHeatMapFraction = ({ state }, newVal) => {
	state.charts.chartView.heatMapFraction = newVal;
};
