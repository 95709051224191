import React, { useState } from "react";
import { useAppState, useActions } from "../../../api";
import { Modal, Table, Tag, Button, message } from "antd";
import { useHistory } from "react-router-dom";

export const EditPlayersModal = (props) => {
	//#region HOOKS -----------------------------------------------------------------------------------------------------------

	const state = useAppState();
	const actions = useActions();
	const history = useHistory();
	const currentChart = state.charts.all[state.charts.currentChartId];
	const [loading, setLoading] = useState(false);
	const [selectedRowKeys, setSelectedRowKeys] = useState(currentChart.players);
	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	const { isModalVisible, setIsModalVisible } = props;
	props.currentChart ? props.currentChart : {}; //setting these for creating new chart

	//#region HANDLERS ---------------------------------------------------------------------------------------------------------

	const handleSave = async () => {
		setLoading(true);
		await actions.charts.updatePlayers(selectedRowKeys);
		setLoading(false);
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setSelectedRowKeys(currentChart.players);
		setIsModalVisible(false);
	};

	const handleAddPlayer = () => {
		if (window.innerWidth > 500) {
			history.push("/teams");
		} else {
			message.warning("Players can only be added on desktop/iPad.");
		}
	};

	//#endregion HANDLERS ------------------------------------------------------------------------------------------------------

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			width: "30%",
			defaultSortOrder: "ascend",
			filterSearch: true,
			filters: state.players.allPlayers.map((player) => {
				return {
					text: player.name,
					value: player.name,
				};
			}),
			onFilter: (value, record) => record.name.startsWith(value),
			render: (text) => <a>{text}</a>,
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			title: "Number",
			dataIndex: "number",
			key: "number",
			width: "10%",
			sorter: (a, b) => a.number - b.number,
		},
		{
			title: "Teams",
			dataIndex: "teamIds",
			key: "teamIds",
			width: "20%",
			filters: state.teams.allTeams
				.map((team) => {
					if (!team.isArchived) {
						return {
							text: team.name,
							value: team.teamId,
						};
					} else {
						return undefined;
					}
				})
				.filter((team) => team),
			onFilter: (value, record) => record.teamIds.includes(value),
			render: (teamIds) => (
				<>
					{teamIds
						.map((teamId) => {
							const team = actions.teams.getTeamById(teamId);
							if (team) {
								return (
									<Tag color="gold" key={teamId}>
										{team.name}
									</Tag>
								);
							} else {
								// This team must have been deleted
								return undefined;
							}
						})
						.filter((team) => team)}
				</>
			),
		},
		{
			title: "Tags",
			key: "playerTagIds",
			dataIndex: "playerTagIds",
			width: "40%",
			filters: state.tags.playerTags.map((tag) => {
				return {
					text: tag.label,
					value: tag.tagId,
				};
			}),
			onFilter: (value, record) => record.playerTagIds.includes(value),
			render: (tagIds) => (
				<>
					{tagIds
						.map((tagId) => {
							const tagLabel = actions.tags.getTagLabel(tagId);
							if (tagLabel) {
								return (
									<Tag color="geekblue" key={tagId}>
										{tagLabel}
									</Tag>
								);
							} else {
								// This tag must have been deleted
								return undefined;
							}
						})
						.filter((tag) => tag)}
				</>
			),
		},
	];

	//#region JSX --------------------------------------------------------------------------------------------------------------

	return (
		<Modal
			confirmLoading={loading}
			width={1000}
			title="Edit Players in Chart"
			className="overflow-y-auto"
			visible={isModalVisible}
			maskClosable={false}
			okText="Save"
			onOk={handleSave}
			onCancel={handleCancel}>
			<div className="mb-5">
				<p className="text-lg font-semibold mb-1">Instructions:</p>
				<p className="text-md ml-3 mb-1">Select players to include/exclude from this chart.</p>
				<p className="text-md ml-3 mb-5">Use the sorts and filters to find the players you want.</p>
			</div>
			<div className="flex">
				<p className="text-lg font-semibold mt-2">Players:</p>
				<Button className="ml-auto" onClick={handleAddPlayer}>
					Add A Player
				</Button>
			</div>
			<Table
				scroll={{ x: 800, y: 300 }}
				rowSelection={rowSelection}
				rowKey={(record) => record.playerId}
				bordered
				loading={loading}
				dataSource={state.players.allPlayers.filter((player) => !player.isArchived)}
				columns={columns}
				pagination={false}
			/>
		</Modal>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default EditPlayersModal;
