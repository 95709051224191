import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Divider, Modal } from "antd";
import { Form, Input } from "antd";
import { useActions } from "../../api";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const Login = () => {
	const history = useHistory();
	const actions = useActions();
	const [loading, setLoading] = useState(false);

	const handleForgotPassword = () => {
		window.open(
			"mailto:team@tappsports.com?subject=Forgot%20password&body=I%20am%20having%20trouble%20accessing%20my%20account."
		);
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			await actions.user.loginUser(values);
			history.push("/dashboard");
		} catch (err) {
			let title = "Error processing login attempt";
			const badSigninErrors = [
				"auth/user-not-found",
				"auth/invalid-email",
				"auth/invalid-password",
				"auth/wrong-password",
			];
			if (err.code && badSigninErrors.includes(err.code)) {
				title = "Incorrect username/password";
			}
			Modal.error({
				title,
				content: `Please try again or contact team@tappsports.com for support.`,
			});
		}
		setLoading(false);
	};

	return (
		<div className="site-layout-content-narrow">
			<div className="relative z-1 p-4 bg-white border border-gray-200 rounded shadow-md">
				<h1 className="text-black text-3xl font-medium">Login</h1>
				<Divider />
				<div className="w-full md:w-3/4 mx-auto">
					<Form name="normal_login" initialValues={{ remember: true }} size="large" onFinish={handleSubmit}>
						<Form.Item name="email" rules={[{ required: true, message: "Please input your Email!" }]}>
							<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
						</Form.Item>
						<Form.Item name="password" rules={[{ required: true, message: "Please input your Password!" }]}>
							<Input
								prefix={<LockOutlined className="site-form-item-icon" />}
								type="password"
								placeholder="Password"
							/>
						</Form.Item>
						<Form.Item>
							<button
								type="submit"
								disabled={loading}
								className="w-full p-2 rounded bg-coolPurple hover:bg-darkPurple text-white text-lg font-normal 
                hover:shadow-lg hover:font-extrabold transform hover:scale-105 disabled:bg-gray-600
                transition duration-300 ease-in-out">
								{loading ? "Loading..." : "Login"}
							</button>
						</Form.Item>
					</Form>
					<p
						className="mb-3 w-36 text-gray-800 hover:text-coolPurple cursor-pointer hover:underline"
						onClick={handleForgotPassword}>
						Forgot password?
					</p>
					<p className="text-gray-800">
						Don't have an account?{" "}
						<Link to="/pricing">
							<span className="text-coolPurple font-semibold hover:underline cursor-pointer">
								Sign up now
							</span>
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
