import React from "react";

import { Row, Col } from "antd";

export const FieldLocationDisabled = ({ value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------
	const ballColor = "white";
	const homerunColor = "white";
	const infieldColor = "darkGray";
	const outfieldColor = "lightGray";

	const LocationButton = ({ id, textColor }) => {
		let color;

		if (textColor === "darkGray") {
			color = "text-white bg-gray-400";
		} else if (textColor === "lightGray") {
			color = "text-white bg-gray-200";
		} else {
			color = "bg-white text-gray-400";
		}

		if (value === id) {
			color = "text-white bg-gray-800";
		}

		return (
			<button className={"w-full rounded shadow font-bold h-full " + color} key={id} id={id}>
				•
			</button>
		);
	};

	return (
		<Row className="mb-4 h-48">
			<Col span={4} className="h-48">
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={15} id={15} />
				</Row>
				<Row className="h-3/5">
					<LocationButton textColor={ballColor} key={16} id={16} />
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={17} id={17} />
				</Row>
			</Col>
			<Col span={16} className="h-full">
				<Row className="h-1/5">
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={14} id={14} />
					</Col>
					<Col span={12}>
						<LocationButton textColor={homerunColor} key={13} id={13} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={9} id={9} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={8} id={8} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={7} id={7} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={4} id={4} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={3} id={3} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={6} id={6} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={1} id={1} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={infieldColor} key={2} id={2} />
					</Col>
					<Col span={8}>
						<LocationButton textColor={outfieldColor} key={5} id={5} />
					</Col>
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={18} id={18} />
				</Row>
			</Col>
			<Col span={4} className="h-48">
				<Row className="h-1/5">
					<LocationButton textColor={homerunColor} key={12} id={12} />
				</Row>
				<Row className="h-splitThirds">
					<LocationButton textColor={homerunColor} key={11} id={11} />
				</Row>
				<Row className="h-splitThirds">
					<LocationButton textColor={homerunColor} key={10} id={10} />
				</Row>
				<Row className="h-1/5">
					<LocationButton textColor={ballColor} key={19} id={19} />
				</Row>
			</Col>
		</Row>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default FieldLocationDisabled;
