import React, { useEffect } from "react";

import { useAppState, useActions } from "../../../api";
import { hasHeatMapOrSprayChartData } from "../../../engines/dataEngine";
import NoDataCard from "../../stats/NoDataCard";
import PlayerSprayChartCard from "./PlayerSprayChartCard";
import SprayChartProperties from "./SprayChartProperties";

import { Input, Col, Row, Dropdown, Button } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import FilterSprayCharts from "./FilterSprayCharts";

const SprayChartCard = () => {
	//#region HOOKS --------------------------------------------------------------------------------------------------------------
	const actions = useActions();
	const state = useAppState();

	useEffect(() => {
		actions.stats.setSprayChartSearch("");
		actions.charts.setVisibleSprayCharts();
		actions.charts.setHiddenSprayCharts();
		actions.charts.clearVisibleSprayChartPlayers();
		actions.charts.clearHiddenSprayChartPlayers();
		actions.charts.setVisibleSprayChartPlayers();
		actions.charts.setHiddenSprayChartPlayers();
	}, []);

	//#endregion HOOKS -----------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------------
	const onSearchChange = (val) => {
		actions.stats.setSprayChartSearch(val.target.value);
	};
	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------------

	//#region JSX ----------------------------------------------------------------------------------------------------------------

	return (
		<div className="font-montserrat group">
			<Row>
				<Col flex="auto">
					<p className="text-lg font-semibold mr-4">Spraycharts</p>
				</Col>
				<Col>
					<Dropdown overlay={<SprayChartProperties />} trigger={["click"]} placement="bottomCenter">
						<Button className="mr-1">
							Properties <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Dropdown overlay={<FilterSprayCharts />} trigger={["click"]} placement="bottomCenter">
						<Button className="mr-1">
							Filter <DownOutlined />
						</Button>
					</Dropdown>
				</Col>
				<Col>
					<Input
						allowClear
						style={{ width: "160px" }}
						placeholder="Search Players"
						prefix={<SearchOutlined />}
						onChange={onSearchChange}
					/>
				</Col>
			</Row>
			<div>
				{state.charts.chartDataLoading || !hasHeatMapOrSprayChartData(state).sprayChart ? (
					<NoDataCard />
				) : (
					<div className="flex flex-wrap">
						<PlayerSprayChartCard />
					</div>
				)}
			</div>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default SprayChartCard;
