import { widget } from "../../constants/apiJson";
import WidgetModel from "../../models/widget";

export const state = {
	new: new WidgetModel(widget),
	all: {},
	showNewWidgetModal: false,
	comparisonWidget1: new WidgetModel(widget),
	comparisonWidget2: new WidgetModel(widget),
	comparisonPlayerId: "",
	comparisonResults: {},
	comparisonWidget1Words: "",
	comparisonWidget2Words: "",
	comparisonLoading: false,
	editingWidget: false,
};
