import React from "react";
import { useActions } from "../../api";
import { getSprayChartTotal } from "../../engines/dataEngine";
import { Tooltip } from "antd";

export const PlayersWithoutData = ({ data }) => {
	const actions = useActions();

	const noDataPlayers = data.map((playerData) => {
		const { total } = getSprayChartTotal(playerData.data.count);
		if (total === 0) {
			const player = actions.players.getPlayerById(playerData.playerId);
			return <p className="my-0">{player.name}</p>;
		} else {
			return "";
		}
	});

	const numPlayers = noDataPlayers.filter((name) => name !== "").length;

	//#region JSX ---------------------------------------------------------------------------------------------------------------
	return noDataPlayers.length > 0 ? (
		<Tooltip title={noDataPlayers}>
			<span className="text-gray-400 underline cursor-pointer">
				{numPlayers} player{numPlayers === 1 ? "" : "s"} without data
			</span>
		</Tooltip>
	) : (
		""
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PlayersWithoutData;
