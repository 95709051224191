import { tagType } from "../constants/tagType";
import Tag from "../models/tag";

export const splitTagsByType = (tagDocuments) => {
	const teamTags = [];
	const playerTags = [];
	const chartTags = [];
	const widgetTags = [];
	const statTags = [];

	tagDocuments.forEach((tagDoc) => {
		switch (tagDoc.tagType) {
			case tagType.team_tag:
				tagDoc.tags.forEach((tag) => teamTags.push(new Tag({ tagType: tagType.team_tag, ...tag })));
				break;
			case tagType.player_tag:
				tagDoc.tags.forEach((tag) => playerTags.push(new Tag({ tagType: tagType.player_tag, ...tag })));
				break;
			case tagType.chart_tag:
				tagDoc.tags.forEach((tag) => chartTags.push(new Tag({ tagType: tagType.chart_tag, ...tag })));
				break;
			case tagType.widget_tag:
				tagDoc.tags.forEach((tag) => widgetTags.push(new Tag({ tagType: tagType.widget_tag, ...tag })));
				break;
			case tagType.stat_tag:
				tagDoc.tags.forEach((tag) => statTags.push(new Tag({ tagType: tagType.stat_tag, ...tag })));
				break;
		}
	});

	const allTags = [...teamTags, ...playerTags, ...chartTags, ...widgetTags, ...statTags];

	return { allTags, teamTags, playerTags, chartTags, widgetTags, statTags };
};
