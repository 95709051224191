import React from "react";
import { Col, Row } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import FadeInSection from "./fadeInSection";

const LeftGalleryCard = ({ title, content, image, linkPath, linkText = "Learn more" }) => {
	return (
		<FadeInSection>
			<div className="w-full h-full rounded-sm my-5 bg-white">
				<Row align="middle" justify="space-between" className="w-full h-full">
					<Col span={24} md={12} className="px-4 pt-12 md:pt-0 my-8">
						<Row className="w-full h-fit">
							<div className="w-full text-5xl md:text-4xl lg:text-5xl font-semibold text-center">
								{" "}
								{title}
							</div>
						</Row>
						<Row className="w-full mt-5">
							<div className="px-2 sm:px-16 md:px-5 lg:px-16 xl:px-24 text-lg lg:text-xl text-center text-gray-700">
								{content}
							</div>
						</Row>
						{linkPath ? (
							<Row className="w-full mt-5">
								<Link className="w-full text-center mt-2 text-coolPurple hover:underline" to={linkPath}>
									{linkText}
									<RightOutlined className="ml-2" />
								</Link>
							</Row>
						) : (
							""
						)}
					</Col>
					<Col span={24} md={12}>
						<img className="h-auto w-full p-2 md:p-0" src={image} alt="Mockup" />
					</Col>
				</Row>
			</div>
		</FadeInSection>
	);
};

export default LeftGalleryCard;
