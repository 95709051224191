import React, { useRef, useState } from "react";
import { useAppState, useActions } from "../../api";
import moment from "moment";
import { WidgetTypes } from "../../constants/widgetTypes";
import PitchLocationMini from "../tools/pitchLocation/Mini";
import FieldLocationMini from "../tools/fieldLocation/Mini";
import { CSVLink } from "react-csv";
import { Table, Col, Row, Button, message, Select, Tooltip, Badge, Skeleton, Space, Typography } from "antd";
import { DownloadOutlined, ReloadOutlined, LoadingOutlined } from "@ant-design/icons";
import EditStatModal from "../stats/EditStatModal";
const { Option } = Select;

export const DirectFlatTable = () => {
	const state = useAppState();
	const actions = useActions();
	const [loadingDeleteAction, setLoadingDeleteAction] = useState(false);
	const chartId = state.charts.all[state.charts.currentChartId].chartId; // TODO make this work when more than one chart is being looked at
	const csvDownloadRef = useRef(null);

	// const onFlaggedChange = () => {
	// 	actions.stats.setFlaggedFilter(!state.stats.flaggedFilter);
	// 	// getStats();
	// };

	const handleCsvDownload = async (event, done) => {
		message.loading({ content: "Saving data to downloads folder", key: "printLoadingMsg", duration: 3 });
		await actions.stats.setDirectStatsCsvString({ obj: state.dashboard.directFlat[chartId], chartId: chartId });
		done();
	};

	const handlePlayerChange = (value) => {
		actions.stats.setPlayerFilter(value);
		actions.dashboard.setRequestPlayersIds(value);
	};

	const handleRefreshDataClick = async () => {
		actions.stats.setNonLoadedStats(0);
		await actions.dashboard.getDirectFlatData(false);
		await actions.dashboard.getDirectNestedData(false);
	};

	// TODO MAKE THIS SO IT INCLUDES ALL PLAYERS WITH STATS
	const players = [
		<Option key={"*"}>All Players</Option>,
		state.charts.all[chartId].players.map((playerId) => {
			return (
				<Option key={playerId}>
					{state.players.allPlayers.filter((player) => player.playerId === playerId)[0]
						? state.players.allPlayers.filter((player) => player.playerId === playerId)[0].name
						: ""}
				</Option>
			);
		}),
	];

	const widgets = state.charts.all[chartId].widgets.map((widgetId) => {
		return {
			title: state.widgets.all[widgetId].name,
			dataIndex: widgetId,
			key: widgetId,
			render: (text) => <p className={!text ? "text-gray-400" : ""}>{text ? text : "-"}</p>,
		};
	});

	const deleteStat = async (stat) => {
		setLoadingDeleteAction(true);
		await actions.stats.deleteStat(stat);
		await actions.dashboard.removeStatFromFlatList(stat, chartId);
		setLoadingDeleteAction(false);
	};

	const handleEditStat = (stat) => {
		actions.stats.setStatToEditId(stat.statId);
		const statToEdit =
			state.stats.nestedStats.length > 0
				? state.stats.nestedStats.find((statInList) => statInList.statId === state.stats.statToEditId)
				: "";
		const statCopy = JSON.parse(JSON.stringify(statToEdit)); // need to make this for overmind
		statCopy.statData.forEach((statElement) => {
			actions.stats.setActiveGroup(statElement);
		});
		actions.stats.setEditingStatModal(true);
	};

	const columns = [
		{
			title: "Player",
			dataIndex: "player",
			key: "player",
			fixed: "left",
		},
		{
			title: "Date",
			dataIndex: "dateCreated",
			key: "dateCreated",
			width: 200,
		},
		...widgets,
		{
			title: "Action",
			key: "action",
			render: (stat) => (
				<Space size="middle">
					<Typography.Link
						className="mr-4"
						disabled={loadingDeleteAction}
						onClick={() => handleEditStat(stat)}>
						Edit
					</Typography.Link>
					<Typography.Link className="mr-4" disabled={loadingDeleteAction} onClick={() => deleteStat(stat)}>
						Delete
					</Typography.Link>
				</Space>
			),
		},
	];

	const data =
		Object.values(state.dashboard.directFlat).length > 0
			? state.dashboard.directFlat[chartId]
					.filter((stat) => {
						if (state.stats.playerFilter.length > 1) {
							return state.stats.playerFilter.includes(stat.player);
						} else {
							return true;
						}
					})
					.map((stat, index) => {
						const keys = Object.keys(stat);

						let returnObj = {
							key: index,
						};

						keys.forEach((key) => {
							if (key === "player") {
								returnObj[key] = actions.players.getPlayerById(stat[key])?.name;
							} else if (key === "dateCreated") {
								returnObj[key] = moment(stat[key]).format("MM-DD-YY hh:mm:ss a");
							} else if (key !== "chart") {
								const widget = state.widgets.all[key];
								if (widget?.type === WidgetTypes.PLAYERLISTSELECT) {
									returnObj[key] = actions.players.getPlayerById(stat[key])?.name;
								} else if (widget?.type === WidgetTypes.PITCHLOCATION) {
									returnObj[key] = <PitchLocationMini value={stat[key]} />;
								} else if (widget?.type === WidgetTypes.FIELDLOCATION) {
									returnObj[key] = (
										<div className="transform -rotate-45 mt-4 pb-6 pl-10 pr-0">
											<FieldLocationMini value={stat[key]} />
										</div>
									);
								} else if (widget?.type === WidgetTypes.XYCOORDINATE) {
									returnObj[key] = JSON.stringify(stat[key]);
								} else {
									returnObj[key] = stat[key];
								}
							}
						});
						return returnObj;
					})
			: [];

	//#region JSX -------------------------------------------------------------------------------------------------------------

	return (
		<>
			<EditStatModal />
			<div className="h-full w-full px-3 rounded">
				<Row className="pb-2" gutter={[10, 10]}>
					<Col flex="auto"></Col>
					<Col>
						<Tooltip title="Filter by player">
							<Select
								showSearch
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
								disabled={state.dashboard.loading}
								style={{ width: 200 }}
								placeholder="Filter by Player"
								defaultValue={state.dashboard.requestPlayerIds}
								onChange={handlePlayerChange}>
								{players}
							</Select>
						</Tooltip>
					</Col>
					{/* <Col> ---- save this, will probably want this functionality later
					<Tooltip title="Filter by flagged stats">
						<Button
							icon={state.stats.flaggedFilter ? <FlagFilled /> : <FlagOutlined />}
							onClick={onFlaggedChange}
							disabled={state.dashboard.loading}
						/>
					</Tooltip>
				</Col> */}
					<Col className="hidden sm:inline">
						<Tooltip title="Download list of stats">
							<CSVLink
								data={state.stats.directStatsCsvString}
								ref={csvDownloadRef}
								asyncOnClick={true}
								onClick={handleCsvDownload}
								filename={`${state.charts.all[chartId].title}_stats_${moment(
									state.charts.chartView.startDate
								).format("YYYY-MM-DD")}_${moment(state.charts.chartView.endDate).format(
									"YYYY-MM-DD"
								)}.csv`}>
								<Button id="download" icon={<DownloadOutlined />} disabled={state.dashboard.loading} />
							</CSVLink>
						</Tooltip>
					</Col>
					<Col>
						<Badge count={state.stats.nonLoadedStats} offset={[-30, 0]}>
							<Button
								id="reload"
								disabled={state.dashboard.loading}
								icon={state.dashboard.loading ? <LoadingOutlined /> : <ReloadOutlined />}
								onClick={handleRefreshDataClick}
							/>
						</Badge>
					</Col>
				</Row>

				{state.dashboard.loading ? (
					<Skeleton />
				) : (
					<Table columns={columns} dataSource={data} pagination={false} bordered />
				)}
			</div>
		</>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default DirectFlatTable;
