import { Divider } from "antd";
import moment from "moment";
import { useAppState } from "../../api";
import Table from "../../components/charts/Table";
import NoDataCard from "../../components/stats/NoDataCard";
import DarkWordmark from "../../assets/wordmark-dark.png";
import { generateTableStructure } from "../../engines/tableEngine";

/**
 * This whole component could use some more work. Eventually I'd like to see it
 * as a very simple componenet with the PrintTable component in a different file.
 * The PrintTable could be somehow responsive in it's size based on the data in the table.
 * The number of rows also might need to be considered for the number of pages.
 */
const PrintArea = () => {
	const state = useAppState();

	const currentChart = state.charts.all[state.charts.currentChartId];

	const PrintTable = () => {
		return (
			<div id={`${currentChart.chartId}_table`} className="p-5 my-0" style={{ width: 2200, height: 1700 }}>
				<div className="top-0 w-full overflow-x-auto p-2">
					{state.charts.chartDataLoading || !currentChart.chartData || !currentChart.chartData.length ? (
						<NoDataCard />
					) : (
						<div>
							<div className="flex flex-row gap-8 items-end mb-5 font-montserrat">
								<img className="h-10" src={DarkWordmark} alt="tapp" />
								<p className="text-xl font-semibold mb-0 ">{currentChart.title}</p>
								<p className="text-sm mb-0 text-gray-500 flex-auto">
									<span className="font-medium m-0">
										{moment(state.charts.chartView.startDate).format("MM/DD/YYYY")}
									</span>
									&nbsp;to&nbsp;
									<span className="font-medium ml-2">
										{moment(state.charts.chartView.endDate).format("MM/DD/YYYY")}
									</span>
								</p>
							</div>
							<Divider />
							<Table
								chartData={currentChart.chartData}
								chartView={currentChart.chartView}
								columns={generateTableStructure(
									currentChart,
									state.widgets.all,
									state.user.positiveColor,
									state.user.negativeColor
								)}
								columnActionsEnabled={true}
							/>
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<div>
			<div id="print-area" className={`z-0 ${state.global.isPrinting ? "absolute" : "hidden"}`}>
				{currentChart ? <PrintTable /> : "Loading..."}
			</div>
			{/* this is just a mask to cover the table when it is printing */}
			<div
				className={`z-1 bg-gray-100 ${state.global.isPrinting ? "absolute" : "hidden"}`}
				style={{ width: 2200, height: 1700 }}
			/>
		</div>
	);
};

export default PrintArea;
