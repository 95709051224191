import React, { useState } from "react";
import { useActions } from "../../api";
import { FaTrash } from "react-icons/fa";
import { Skeleton, Tooltip } from "antd";
import { FlagFilled, FlagOutlined } from "@ant-design/icons";
import getMiniTool from "../../util/getMiniTool";

export const StatHistoryRow = ({ widgetType, widgetName, value, playerName, statId, isFlagged }) => {
	const actions = useActions();
	const [isDeleteLoading, setDeleteLoading] = useState(false);

	const handleFlagClick = () => {
		actions.stats.updateStatFlagged({
			statId: statId,
			isFlagged: !isFlagged,
		});
		actions.stats.updateCurrentStatsFlagged({ statId: statId, isFlagged: !isFlagged });
	};

	const handleDeleteClick = async () => {
		setDeleteLoading(true);
		await actions.stats.deleteStat(statId);
		await actions.stats.removeStatFromHistoryRow(statId);
		setDeleteLoading(false);
	};

	//#region JSX -----------------------------------------------------------------------------------------------------------
	return (
		<div className="py-1 px-2 flex flex-row gap-3 items-center bg-white border">
			<div className="w-1/2 flex flex-col truncate font-montserrat">
				<p className="pr-0 m-0 text-md font-semibold truncate">{playerName}</p>
				<p className="pr-0 m-0 text-xs text-gray-600 font-medium truncate">{widgetName}</p>
			</div>
			<div className="w-1/2 flex flex-row">
				{statId && !isDeleteLoading ? (
					<>
						<div className="w-2/3 flex flex-col align-middle items-center">
							<div className="w-full h-full flex items-center">{getMiniTool(widgetType, value)}</div>
						</div>
						<div className="w-1/3 flex flex-row gap-2 items-center ml-5 justify-end cursor-pointer">
							<div
								className={`text-xl ${statId ? "text-red-500" : "text-gray-300"}`}
								id={statId}
								onClick={handleDeleteClick}
								disabled={!statId}>
								<FaTrash />
							</div>
							<div
								className="text-xl text-yellow-400 cursor-pointer"
								id={statId}
								onClick={handleFlagClick}>
								<Tooltip title="Flag the stat to go back later and review">
									{isFlagged ? <FlagFilled /> : <FlagOutlined />}
								</Tooltip>
							</div>
						</div>
					</>
				) : (
					<div className="h-12 w-full">
						<Skeleton paragraph={false} avatar={false} active />
					</div>
				)}
			</div>
		</div>
	);
	//#endregion JSX --------------------------------------------------------------------------------------------------------
};

export default StatHistoryRow;
