import React from "react";

import RecordingTool from "./RecordingTool";
import { useAppState } from "../../../api";

export const RecordingToolsRows = ({ chart, isGrouped }) => {
	const state = useAppState();

	const rows =
		chart && chart.widgets
			? chart.widgets.map((widgetId, index) => {
					let widget = widgetId;
					if (typeof widgetId === "string") {
						widget = state.widgets.all[widgetId];
					}

					return <RecordingTool widget={widget} key={index} isGrouped={isGrouped} />;
			  })
			: "";

	//#region JSX ----------------------------------------------------------------------------------------------------------------
	return <>{rows}</>;
	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default RecordingToolsRows;
