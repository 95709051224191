import React from "react";
import { Colors } from "../../../constants/colors";
import PieChartComponent from "./PieChartComponent";
import { Row, Col } from "antd";

export const PieChartCard = ({ title, value }) => {
	//#region JSX -------------------------------------------------------------------------------------------------------------
	const data = Object.keys(value.count)
		.filter((key) => value.count[key] !== 0)
		.map((key) => {
			return {
				name: key,
				value: value.count[key],
			};
		});

	const COLORS = Colors.get(Object.keys(value.count).length);

	return (
		<div className="h-full w-full p-3 shadow bg-white border rounded text-center">
			<div className="mb-4 text-xl font-semibold">{title}</div>
			<Row justify="center">
				<Col>
					<PieChartComponent data={data} COLORS={COLORS} />
				</Col>
			</Row>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default PieChartCard;
