import React, { useEffect } from "react";
import { useAppState, useActions } from "../../api";
import { Col, Row, Skeleton, Button, Tabs } from "antd";
import { getDataViewJSX } from "../../engines/widgetEngine";
import PrimaryWordmark from "../../assets/wordmark-primary.png";
import NoDataCard from "../../components/stats/NoDataCard";
import { useHistory } from "react-router-dom";
import DirectFlatTable from "../../components/dataViews/DirectFlatTable";

// import { Link } from "react-router-dom";
const { TabPane } = Tabs;

const DataDashboardPage = () => {
	const state = useAppState();
	const actions = useActions();
	const history = useHistory();

	useEffect(async () => {
		await actions.dashboard.getDashboardData(false);
		await actions.dashboard.getDirectFlatData(false);
	}, [
		state.dashboard.requestChartIds,
		state.dashboard.requestPlayerIds,
		state.dashboard.requestWidgetIds,
		state.dashboard.requestTimeDelta,
		state.dashboard.requestNumDeltas,
	]);

	// DATA FILTERS HANDLERS
	// const filterChangeHandler = (filter, value) => {
	// 	switch (filter) {
	// 		case "TIME_SLICES":
	// 			actions.dashboard.setRequestNumDeltas(value);
	// 			break;
	// 		case "TIME_DELTA":
	// 			actions.dashboard.setRequestTimeDelta(value);
	// 			break;
	// 		case "TEAM":
	// 			break;
	// 		case "PLAYER":
	// 			actions.dashboard.setRequestPlayersIds(value);
	// 			break;
	// 		case "CHART":
	// 			actions.dashboard.setRequestChartIds(value);
	// 			break;
	// 		case "WIDGET":
	// 			actions.dashboard.setRequestWidgetIds(value);
	// 			break;
	// 		default:
	// 			break;
	// 	}
	// };

	// const teamOptions = state.teams.allTeams.map((team) => {
	// 	return (
	// 		<Option key={team.teamId} value={team.teamId} label={team.name}>
	// 			{team.name}
	// 		</Option>
	// 	);
	// });

	// const playerOptions = state.players.allPlayers.map((player) => {
	// 	return (
	// 		<Option key={player.playerId} value={player.playerId} label={player.name}>
	// 			{player.name}
	// 		</Option>
	// 	);
	// });

	// const chartOptions = Object.values(state.charts.all).map((chart) => {
	// 	return (
	// 		<Option key={chart.chartId} value={chart.chartId} label={chart.title}>
	// 			{chart.title}
	// 		</Option>
	// 	);
	// });

	// const widgetOptions = Object.values(state.widgets.all).map((widget) => {
	// 	return (
	// 		<Option key={widget.widgetId} value={widget.widgetId} label={widget.name}>
	// 			{widget.name}
	// 		</Option>
	// 	);
	// });

	// const selectAfter = (
	// 	<Select
	// 		defaultValue={state.dashboard.requestTimeDelta}
	// 		style={{ width: 150 }}
	// 		onChange={(value) => filterChangeHandler("TIME_DELTA", value)}>
	// 		<Option value="day">Days</Option>
	// 		<Option value="week">Weeks</Option>
	// 		<Option value="month">Months</Option>
	// 		<Option value="quarter">Quarters</Option>
	// 	</Select>
	// );

	return (
		<div className="font-montserrat" style={{ minWidth: 500 }}>
			{!state.global.loading ? (
				<div>
					<Col>
						<Row className="mb-1">
							{/* <Breadcrumb separator=">">
								<Breadcrumb.Item>
									<Link to={"/teams"}>root</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>
									<Link to={"/roster"}>trail</Link>
								</Breadcrumb.Item>
								<Breadcrumb.Item>
									<Link to={"/player"}>here</Link>
								</Breadcrumb.Item>
							</Breadcrumb> */}
							<Button onClick={() => history.goBack()}>Back</Button>
						</Row>

						{/*DATA FILTERS*/}
						{/* <Row>
							<div className="w-full bg-white shadow p-8 mb-10">
								<Row>
									<div className="text-3xl md:text-4xl">Data Filters</div>
									<Divider />
								</Row>
								<Row gutter={[10, 10]}>
									<Col span={24}>
										<Row align="middle" gutter={[10, 10]}>
											<Col>
												<span>View data from the previous </span>
											</Col>
											<Col>
												<InputNumber
													addonAfter={selectAfter}
													defaultValue={state.dashboard.requestNumDeltas}
													style={{ width: 230 }}
													onChange={(value) => filterChangeHandler("TIME_SLICES", value)}
												/>
											</Col>
										</Row>
									</Col>

									<Col span={12}>
										<Select
											mode="multiple"
											optionFilterProp="label"
											disabled
											allowClear
											style={{ width: "100%" }}
											placeholder="Teams"
											defaultValue={[]}
											onChange={(value) => filterChangeHandler("TEAM", value)}>
											{teamOptions}
										</Select>
									</Col>

									<Col span={12}>
										<Select
											mode="multiple"
											optionFilterProp="label"
											allowClear
											style={{ width: "100%" }}
											placeholder="Charts"
											defaultValue={state.dashboard.requestChartIds}
											onChange={(value) => filterChangeHandler("CHART", value)}>
											{chartOptions}
										</Select>
									</Col>

									<Col span={12}>
										<Select
											mode="multiple"
											optionFilterProp="label"
											allowClear
											style={{ width: "100%" }}
											placeholder="Players"
											defaultValue={state.dashboard.requestPlayerIds}
											onChange={(value) => filterChangeHandler("PLAYER", value)}>
											{playerOptions}
										</Select>
									</Col>

									<Col span={12}>
										<Select
											mode="multiple"
											optionFilterProp="label"
											allowClear
											style={{ width: "100%" }}
											placeholder="Tools"
											defaultValue={state.dashboard.requestWidgetIds}
											onChange={(value) => filterChangeHandler("WIDGET", value)}>
											{widgetOptions}
										</Select>
									</Col>
								</Row>
							</div>
						</Row> */}

						<Row justify="center">
							<Col xs={24} lg={24}>
								<div className="w-full h-full bg-white shadow">
									<Col className="p-8">
										<Row gutter={[10, 10]} align="middle" justify="space-between">
											<Col>
												<span className="text-3xl md:text-4xl mb-5 text-left h-full my-auto">
													{state.players.allPlayers.filter(
														(player) =>
															player.playerId === state.dashboard.requestPlayerIds[0]
													)[0]
														? state.players.allPlayers.filter(
																(player) =>
																	player.playerId ===
																	state.dashboard.requestPlayerIds[0]
														  )[0].name
														: "Name"}
												</span>
											</Col>
											<Col>
												<img
													className="w-40 float-left"
													src={PrimaryWordmark}
													alt="Logo"
													style={{ minWidth: 130 }}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												{/* {selectedPlayer.playerTagIds.map((tagId) => {
                                                    return (
                                                        <Tag color="geekblue">{actions.tags.getTagLabel(tagId)}</Tag>
                                                    );
                                                })} */}

												{/* TODO fix this later */}
												{/* {state.dashboard.loading || !state.dashboard.activeDashboardString ? (
													<Skeleton.Button
														active={true}
														size={"small"}
														shape={"default"}
														block={true}
													/>
												) : (
													<div>
														Total Number of reps:{" "}
														<span className="font-semibold">
															{
																Object.values(
																	state.dashboard.dashboards[
																		state.dashboard.activeDashboardString
																	].dataList.filter(
																		(row) => row.timestamp === "Entire Range"
																	)
																).length
															}
														</span>
													</div>
												)} */}
											</Col>
										</Row>
										<Tabs type="card">
											<TabPane tab="Report" key="1">
												{state.dashboard.dashboards["pius data"] ? (
													state.dashboard.dashboards["pius data"].dataList ? (
														getDataViewJSX(state, actions)
													) : (
														<NoDataCard />
													)
												) : (
													""
												)}
											</TabPane>
											<TabPane tab="Play-By-Play" key="2">
												<Row className="h-full" gutter={[20, 20]}>
													{state.dashboard.directFlat[
														state.dashboard.activeDashboardString
													] ? (
														<DirectFlatTable />
													) : (
														<NoDataCard />
													)}
												</Row>
											</TabPane>
										</Tabs>
										{/* <Divider /> */}
									</Col>
								</div>
							</Col>
						</Row>
					</Col>
				</div>
			) : (
				<Skeleton />
			)}
		</div>
	);
};

export default DataDashboardPage;
