import React, { useState, useEffect } from "react";

import { useAppState, useActions } from "../../../api";

import { WidgetTypes } from "../../../constants/widgetTypes";

import { Menu, Dropdown } from "antd";
import { DownOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const ListSelectRecord = ({ widgetId, label, isGrouped, potentialValues }) => {
	//#region HOOKS -----------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const actions = useActions();

	const activeGroup = state.stats.activeGroup;
	const [value, setValue] = useState(activeGroup && activeGroup[label] ? activeGroup[label].value : "Options");

	useEffect(() => {
		if (!activeGroup || !activeGroup[label]) {
			setValue("Options");
		} else {
			setValue(activeGroup[label].value);
		}
	}, [activeGroup && activeGroup[label] ? activeGroup[label].value : ""]);

	//#endregion HOOKS --------------------------------------------------------------------------------------------------------

	//#region HANDLERS --------------------------------------------------------------------------------------------------------

	const handleMenuClick = (e) => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.LISTSELECT,
			value: e.key,
		};

		if (isGrouped) {
			setValue(e.key);

			actions.stats.setActiveGroup(data);
		} else {
			actions.stats.create(data);

			setValue("Options");
		}
	};

	const deselectValue = () => {
		setValue("Options");
		actions.stats.deleteFromActiveGroup(label);
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	const menu = (
		<Menu onClick={handleMenuClick}>
			{potentialValues.length > 0 ? potentialValues.map((val) => <Menu.Item key={val}>{val}</Menu.Item>) : ""}
		</Menu>
	);

	return (
		<div className="flex">
			<Dropdown overlay={menu} trigger={["click"]}>
				<div className="flex w-full h-14 pb-2 rounded shadow bg-white cursor-pointer text-center pt-2">
					<h1 className={`w-10/12 text-${state.user.positiveColor}-500 truncate text-center pl-1 pt-2`}>
						{value}
					</h1>
					<div className="w-4/12 pt-2">
						<DownOutlined />
					</div>
				</div>
			</Dropdown>
			{value !== "Options" ? (
				<div
					className={`text-${state.user.negativeColor}-400 text-lg w-2/12 h-14 pb-2 rounded shadow bg-white cursor-pointer text-center pt-4 ml-1`}
					onClick={deselectValue}>
					<CloseCircleOutlined />
				</div>
			) : (
				""
			)}
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default ListSelectRecord;
