import React from "react";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function Expired() {
	return (
		<div className="site-layout-content-narrow">
			<div className="relative z-1 bg-white rounded shadow-md">
				<Row gutter={[10, 10]}>
					<Col span={8} xs={0} sm={0} md={8}>
						<div className="flex items-center w-full h-full p-2 bg-gray-200">
							<div className="mx-auto my-auto">
								<ExclamationCircleOutlined
									className="text-8xl"
									type="play-circle-o"
									style={{ color: "#6163ff" }}
								/>
							</div>
						</div>
					</Col>
					<Col span={16} xs={24} sm={24} md={16}>
						<div className="w-full p-5 md:p-10">
							<h1 className="mb-5 text-black text-2xl sm:text-3xl font-medium">
								Your subscription has expired
							</h1>
							<div className="mt-8">
								<p className="mb-2 text-md">
									We want you to stay, so please use the contact information below to get in touch. We
									can only guarantee to save your data for 30 days after account expiration, so please
									contact us right away to renew your account.
								</p>
								<p className="mt-8 mb-1">
									Email:&nbsp;&nbsp;
									<a
										className="cursor-pointer"
										href="mailto: team@tappsports.com?subject=I'd like to discuss renewal">
										team@tappsports.com
									</a>
								</p>
								<p className="mb-12">
									Phone:&nbsp;&nbsp;<span className="text-coolPurple">(402) 367-2113</span>
								</p>
								<div className="flex flex-row justify-end gap-2">
									<Link to="/">
										<Button type="primary" ghost>
											Return Home
										</Button>
									</Link>
									<a href="mailto: team@tappsports.com?subject=I'd like to discuss renewal">
										<Button type="primary">Contact Us</Button>
									</a>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}
