import User from "../../models/user";
import { derived } from "overmind";

export const state = {
	user: new User({}),
	waitlistEmail: "",
	sidebarCollapse: false,
	appLoading: false,
	authenticated: false,
	joinOrg: {},
	positiveColorIsBlue: true,
	positiveColor: derived((state) => {
		return state.positiveColorIsBlue ? "blue" : "red";
	}),
	negativeColor: derived((state) => {
		return state.positiveColorIsBlue ? "red" : "blue";
	}),
	userInputSignupInfo: {
		name: "",
		email: "",
		password: "",
		organizationKey: "",
	},
	showChoosePlayerModal: false,
};
