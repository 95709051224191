import React from "react";
import moment from "moment";
import MobileDashboardChart from "../../components/charts/MobileDashboardChart";
import { useAppState } from "../../api";
import { Row, Skeleton } from "antd";

const Dashboard = () => {
	const state = useAppState();

	const charts =
		state.charts.all !== {} ? (
			Object.values(state.charts.all)
				.sort((a, b) => moment(moment(b.dateLastUsed)).diff(moment(a.dateLastUsed)))
				.map((chart) => {
					return chart.chartId ? (
						<Row className="mb-2">
							<MobileDashboardChart
								isGrouped={chart.isGrouped}
								chartName={chart.title}
								lastUsed={chart.dateLastUsed}
								chartId={chart.chartId}
								widgets={chart.widgets}
								players={chart.players}
							/>
						</Row> // review
					) : (
						""
					);
				})
		) : (
			<Skeleton />
		);

	return (
		<div className="font-montserrat mt-2">
			<div className="w-full">{state.global.loading ? <Skeleton /> : charts}</div>
		</div>
	);
};

export default Dashboard;
