export default class User {
	constructor(user) {
		this.userId = user.userId ? user.userId : "";
		this.name = user.name ? user.name : "";
		this.email = user.email ? user.email : "";
		this.phone = user.phone ? user.phone : "";
		this.dateCreated = user.dateCreated ? user.dateCreated : "";
		this.imageUrl = user.imageUrl ? user.imageUrl : "";
		this.userContext = user.userContext ? user.userContext : {};
	}
}
