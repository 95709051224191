export const setShowTeamsAlert = ({ state }, newVal) => {
	state.onboarding.showTeamsAlert = newVal;
};

export const setShowChartsAlert = ({ state }, newVal) => {
	state.onboarding.showChartsAlert = newVal;
};

export const setShowInfoModal = ({ state }, newVal) => {
	state.onboarding.showInfoModal = newVal;
};
