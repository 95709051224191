export default class Organization {
	constructor(org) {
		this.organizationId = org.organizationId ? org.organizationId : "";
		this.organizationKey = org.organizationKey ? org.organizationKey : "";
		this.adminName = org.adminName ? org.adminName : "";
		this.adminEmail = org.adminEmail ? org.adminEmail : "";
		this.dateCreated = org.dateCreated ? org.dateCreated : "";
		this.licenseExpirationDate = org.licenseExpirationDate ? org.licenseExpirationDate : "";
		this.timezone = org.timezone ? org.timezone : "";
		this.title = org.title ? org.title : "";
		this.stripeCustomerId = org.stripeCustomerId ? org.stripeCustomerId : "";
		this.organizationConfig = org.organizationConfig ? org.organizationConfig : {};
	}
}
