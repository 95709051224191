import React, { useState } from "react";
import { Table, Popconfirm, Form, Typography, Select, Button, Col, Row, message, Alert } from "antd";
import { useHistory } from "react-router-dom";
import EditableCell from "./EditableCell";
import { ImportOutlined, PlusOutlined, QuestionCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useAppState, useActions } from "../../api";
import { NEW_ROW_KEY } from "../../constants/editableTableConstant";
const { Option } = Select;

const EditableTable = ({
	data,
	keyProperty,
	dataModel,
	columns,
	includeActions,
	addButtonTitle,
	title,
	rowClickAction,
	importRecords,
	archiveSelection,
	deleteSelection,
	createRecord,
	updateRecord,
	deleteRecord,
	hideTitle,
}) => {
	const [form] = Form.useForm();
	const [editingKey, setEditingKey] = useState("");
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const state = useAppState();
	const actions = useActions();

	/**
	 * Helper functions
	 */
	const isEditing = (record) => record[keyProperty] === editingKey;
	const resetEditing = () => setEditingKey("");
	const newRecord = () => new dataModel({ [keyProperty]: NEW_ROW_KEY });
	const adjustColumns = (inputColumns) => {
		const adjustedColumns = inputColumns.map((col) => {
			if (col.dataType === "select" || col.dataType === "multiselect") {
				col.listOptionsJSX = col.listOptions.map((optn) => (
					<Option key={optn.key} label={optn.label}>
						{optn.label}
					</Option>
				));
			} else if (col.dataType === "tag") {
				col.listOptionsJSX = col.tags.map((tag) => (
					<Option key={tag.tagId} value={tag.tagId} label={tag.label}>
						{tag.label}
					</Option>
				));
			}
			return col;
		});
		return adjustedColumns;
	};

	const editRecord = (record) => {
		form.setFieldsValue({
			...newRecord(),
			...record,
		});
		setEditingKey(record[keyProperty]);
	};

	const createRow = async () => {
		setLoading(true);
		const emptyRecord = newRecord();
		await createRecord(emptyRecord, false);
		editRecord(emptyRecord);
		setLoading(false);
	};

	const cancel = () => {
		if (editingKey === NEW_ROW_KEY) {
			deleteRow({ [keyProperty]: editingKey });
		}
		resetEditing();
		message.warn("Changes not saved");
	};

	const deleteRow = async (row) => {
		setLoading(true);
		try {
			await deleteRecord(row);
		} catch (error) {
			console.error(error);
			message.error("Error encountered - the record may have failed to delete");
		}
		setSelectedRowKeys([]);
		setLoading(false);
	};

	const deleteSelectedRows = async () => {
		setLoading(true);
		try {
			await deleteSelection(selectedRowKeys);
		} catch (error) {
			console.error(error);
			message.error("Error encountered - some records may have failed to delete");
		}
		setSelectedRowKeys([]);
		setLoading(false);
	};

	const archiveSelectedRows = async () => {
		setLoading(true);
		try {
			await archiveSelection(selectedRowKeys);
			message.success("Selection Archived");
		} catch (error) {
			console.error(error);
			message.error("Error encountered - some records may have failed to archive");
		}
		setSelectedRowKeys([]);
		setLoading(false);
	};

	const save = async (key) => {
		try {
			const row = await form.validateFields();
			setLoading(true);
			if (key === NEW_ROW_KEY) {
				delete row[keyProperty];
				await createRecord(row, true);
			} else {
				await updateRecord({ [keyProperty]: key, ...row });
				message.success("Record Updated");
			}
			resetEditing();
			setLoading(false);
		} catch (errInfo) {
			setLoading(false);
		}
	};

	const onSelectChange = (selectedRowKeys) => {
		setSelectedRowKeys(selectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	const handleClose = async () => {
		await actions.organization.setCreateTeamBanner(false);
	};

	const adjustedColumns = [
		...adjustColumns(columns),
		includeActions
			? {
					title: "Actions",
					dataIndex: "action",
					width: "10%",
					render: (_, record) => {
						const editable = isEditing(record);
						return editable ? (
							<span>
								<Typography.Link className="mr-4" onClick={() => save(record[keyProperty])}>
									Save
								</Typography.Link>
								<Typography.Link className="mr-4" onClick={cancel}>
									Cancel
								</Typography.Link>
							</span>
						) : (
							<span>
								<Typography.Link
									className="mr-4"
									disabled={editingKey !== "" || selectedRowKeys.length}
									onClick={() => editRecord(record)}>
									Edit
								</Typography.Link>
								<Popconfirm
									placement="bottomRight"
									icon={<QuestionCircleOutlined style={{ color: "red" }} />}
									okType="danger"
									title="Are you sure? You cannot undo this action."
									okText="Delete"
									cancelText="Cancel"
									onConfirm={() => deleteRow(record)}>
									<Typography.Link
										disabled={editingKey !== "" || selectedRowKeys.length}
										className="mr-4">
										Delete
									</Typography.Link>
								</Popconfirm>
								{title === "Teams" ? (
									<Typography.Link
										onClick={() => {
											title === "Teams" ? history.push("/roster") : history.push("/player");
										}}
										disabled={editingKey !== "" || selectedRowKeys.length}>
										View
									</Typography.Link>
								) : (
									""
								)}
							</span>
						);
					},
			  }
			: undefined,
	];

	const mergedColumns = adjustedColumns.map((col) => {
		if (!col.editable) {
			return col;
		}

		return {
			...col,
			onCell: (record) => ({
				record,
				dataType: col.dataType,
				dataIndex: col.dataIndex,
				title: col.dataType === "tag" ? "Tags" : col.title,
				listOptionsJSX: col.listOptionsJSX,
				listOptions: col.listOptions,
				tags: col.tags,
				link: col.link,
				required: col.required,
				editing: isEditing(record),
			}),
		};
	});

	return (
		<Col span={24}>
			<Row gutter={[10, 5]}>
				{hideTitle == "hide" ? (
					<Col flex="auto">
						<div>
							<h1 className="uppercase font-bold mb-0 pl-1 inline">Teams</h1>
							<div
								onClick={
									editingKey !== "" || loading || selectedRowKeys.length ? null : () => createRow()
								}
								className="inline ml-2 hover:text-coolPurple">
								<Button icon={<PlusCircleOutlined />}>Create new team</Button>
							</div>
							<div className="my-2 w-max">
								{state.organization.organization.organizationConfig.showCreateTeamBanner ? (
									<Alert
										message="Create teams for your players - Varsity, JV, Freshman, etc."
										type="success"
										closable
										afterClose={() => handleClose()}
									/>
								) : null}
							</div>
						</div>
					</Col>
				) : (
					<Col flex="auto" className="mb-4">
						<span className="text-2xl lg:text-3xl text-left h-full my-auto">{title}</span>
					</Col>
				)}
				{hideTitle == "hide" ? (
					<Col>
						<Popconfirm
							placement="bottomRight"
							title="Are you sure? You can undo this later."
							okText="Archive"
							cancelText="Cancel"
							onConfirm={archiveSelectedRows}>
							<Button
								type="primary"
								ghost
								size="large"
								hidden={!selectedRowKeys.length}
								disabled={editingKey !== "" || loading || !selectedRowKeys.length}
								className="mb-2">
								Archive
							</Button>
						</Popconfirm>
					</Col>
				) : (
					<Col>
						<Popconfirm
							placement="bottomRight"
							title="Are you sure? You can undo this later."
							okText="Archive"
							cancelText="Cancel"
							onConfirm={archiveSelectedRows}>
							<Button
								type="primary"
								ghost
								size="large"
								hidden={!selectedRowKeys.length}
								disabled={editingKey !== "" || loading || !selectedRowKeys.length}>
								Archive
							</Button>
						</Popconfirm>
					</Col>
				)}
				{hideTitle == "hide" ? (
					<Col>
						<Popconfirm
							placement="bottomRight"
							icon={<QuestionCircleOutlined style={{ color: "red" }} />}
							okType="danger"
							title="Are you sure? You cannot undo this action."
							okText="Delete"
							cancelText="Cancel"
							onConfirm={deleteSelectedRows}>
							<Button
								type="danger"
								size="large"
								hidden={!selectedRowKeys.length}
								disabled={editingKey !== "" || loading || !selectedRowKeys.length}>
								Delete
							</Button>
						</Popconfirm>
					</Col>
				) : (
					<Col>
						<Popconfirm
							placement="bottomRight"
							icon={<QuestionCircleOutlined style={{ color: "red" }} />}
							okType="danger"
							title="Are you sure? You cannot undo this action."
							okText="Delete"
							cancelText="Cancel"
							onConfirm={deleteSelectedRows}>
							<Button
								type="danger"
								size="large"
								hidden={!selectedRowKeys.length}
								disabled={editingKey !== "" || loading || !selectedRowKeys.length}>
								Delete
							</Button>
						</Popconfirm>
					</Col>
				)}

				{hideTitle == "hide" ? null : (
					<Col>
						<Row gutter={[10, 5]}>
							<Col>
								<Button
									type="primary"
									ghost
									size="large"
									disabled={editingKey !== "" || loading || selectedRowKeys.length}
									hidden={!importRecords || selectedRowKeys.length}
									icon={<ImportOutlined />}
									onClick={() => importRecords()}>
									{"Import Data"}
								</Button>
							</Col>

							<Col>
								<Button
									type="primary"
									size="large"
									disabled={editingKey !== "" || loading || selectedRowKeys.length}
									hidden={selectedRowKeys.length}
									icon={<PlusOutlined />}
									onClick={() => createRow()}>
									{addButtonTitle}
								</Button>
							</Col>
						</Row>
					</Col>
				)}
			</Row>
			<Row justify="middle">
				<Col span={24}>
					<Form form={form} component={false}>
						<Table
							scroll={{ x: 800 }}
							onRow={(record) => {
								return {
									onClick: () => {
										rowClickAction ? rowClickAction(record) : "";
									},
								};
							}}
							components={{
								body: {
									cell: EditableCell,
								},
							}}
							rowSelection={rowSelection}
							rowKey={(record) => record[keyProperty]}
							bordered
							loading={loading}
							dataSource={data}
							columns={mergedColumns}
							pagination={hideTitle == "hide" ? { defaultPageSize: 5 } : false}
						/>
					</Form>
				</Col>
			</Row>
			{hideTitle ? null : state.players.allPlayers.length > 0 ? (
				<Row className="mt-2">
					{Object.entries(state.charts.all).length > 0 ? null : (
						<Alert
							message={
								<p className="m-0">
									<span
										className="mr-1 underline hover:text-coolPurple cursor-pointer"
										onClick={() => history.push("/dashboard")}>
										Create a chart
									</span>
									to track data for your players!
								</p>
							}
							closable
						/>
					)}
				</Row>
			) : (
				<Row className="mt-2">
					<Alert message="Add a team and some players to start tracking data!" closable />
				</Row>
			)}
		</Col>
	);
};

export default EditableTable;
