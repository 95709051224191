import React from "react";
import SprayChart from "./SprayChart";
import { Row, Col } from "antd";
import { getSprayChartTotal } from "../../../engines/dataEngine";

export const DataPageSprayChartCard = ({ title, data }) => {
	const { total } = getSprayChartTotal(data);
	//#region JSX ---------------------------------------------------------------------------------------------------------------
	return (
		<div className="w-full p-3 border rounded text-center shadow">
			<div className="mb-4 text-xl font-semibold">{title}</div>
			<Row justify={"center"}>
				<Col>
					<div className="transform -rotate-45 ml-10 mr-10 mt-12 mb-12">
						<SprayChart data={data} dataPage={true} />
					</div>
					{total ? (
						<p>
							Total hits:
							<span className="font-semibold"> {total}</span>
						</p>
					) : (
						""
					)}
				</Col>
			</Row>
		</div>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default DataPageSprayChartCard;
