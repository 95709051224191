export default class Widget {
	constructor(widget) {
		this.widgetId = widget.widgetId;
		this.type = widget.type;
		this.name = widget.name;
		this.position = widget.position;
		this.attributes = widget.attributes;
		this.potentialValues = widget.potentialValues;
	}

	toJson() {
		const widgetJson = JSON.parse(JSON.stringify(this));
		delete widgetJson.position;
		delete widgetJson.attributes;
		delete widgetJson.widgetId;
		return widgetJson;
	}

	toJsonForUpdate() {
		const widgetJson = JSON.parse(JSON.stringify(this));
		delete widgetJson.attributes;
		delete widgetJson.position;
		delete widgetJson.name;
		delete widgetJson.type;
		return widgetJson;
	}

	setLabel(newVal) {
		this.name = newVal;
	}

	setType(newVal) {
		this.type = newVal;
	}

	addAttribute(attribute) {
		this.attributes.push(attribute);
	}

	addPotentialValue(potentialValue) {
		this.potentialValues.push(potentialValue);
	}

	setPotentialValue(newValue) {
		this.potentialValues = newValue;
	}

	clearPotentialValue() {
		this.potentialValues = [];
	}

	setWidgetId(newVal) {
		this.widgetId = newVal;
	}
}
