import React, { useState, useEffect } from "react";
import { useAppState } from "../../../api";
import RecordPlayersColumn from "./RecordPlayersColumn";
import { DragDropContext } from "react-beautiful-dnd";
import { useActions } from "../../../api";
import { message } from "antd";

export const RecordingPlayerRows = ({ edit }) => {
	//#region Hooks ----------------------------------------------------------------------------------------------------------------
	const state = useAppState();
	const currentChart = state.charts.all[state.charts.currentChartId];
	const actions = useActions();
	const [column, setColumn] = useState({
		id: "column-1",
		playerIds: currentChart.players,
	});

	useEffect(() => {
		setColumn({
			id: "column-1",
			playerIds: currentChart.players,
		});
	}, [currentChart ? currentChart.players : currentChart]); //Review

	useEffect(() => {
		setColumn({
			id: "column-1",
			playerIds: currentChart.players,
		});
	}, []);

	//#endregion Hooks -------------------------------------------------------------------------------------------------------------

	//#region Handlers -------------------------------------------------------------------------------------------------------------

	const dragEndHandler = (result) => {
		if (state.players.playerSortVal == "none") {
			const { destination, source, draggableId } = result;

			if (!destination) {
				return;
			}

			if (destination.droppableId === source.droppableId && destination.index === source.index) {
				return;
			}

			const newPlayerIds = Array.from(column.playerIds);
			newPlayerIds.splice(source.index, 1);
			newPlayerIds.splice(destination.index, 0, draggableId);

			actions.charts.updatePlayerOrder(newPlayerIds);

			setColumn({
				id: "column-1",
				playerIds: newPlayerIds,
			});
		} else {
			message.error("You cannot rearrange players with a sort enabled");
		}
	};
	//#endregion Handlers ----------------------------------------------------------------------------------------------------------

	const playerRows = (
		<DragDropContext onDragEnd={dragEndHandler} isDragDisabled={true}>
			{["column-1"].map(() => {
				let players = column.playerIds.map((playerId) => actions.players.getPlayerById(playerId));
				return <RecordPlayersColumn column={column} key={column.id} editing={edit} players={players} />;
			})}
		</DragDropContext>
	);

	//#region JSX ------------------------------------------------------------------------------------------------------------------

	if (currentChart && currentChart.players && state.players.allPlayers) {
		return playerRows;
	} else {
		return <p>No Players :(</p>;
	}
	//#endregion JSX ---------------------------------------------------------------------------------------------------------------
};

export default RecordingPlayerRows;
