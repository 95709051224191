import React from "react";

import { useAppState, useActions } from "../../../api";

import { WidgetTypes } from "../../../constants/widgetTypes";

export const NegRecord = ({ label, widgetId, isGrouped }) => {
	const state = useAppState();
	const actions = useActions();

	const activeGroup = state.stats.activeGroup;

	//#region HANDLERS ------------------------------------------------------------------------------------------------------

	const onNegClick = () => {
		let data = {
			widgetId: widgetId,
			widgetName: label,
			widgetType: WidgetTypes.NEGCOUNTER,
		};

		if (isGrouped) {
			let newData = data;
			if (!activeGroup[label]) {
				newData = { ...data, value: -1 };
			}

			actions.stats.setActiveGroup(newData);
		} else {
			let newData = { ...data, value: -1 };

			actions.stats.create(newData);
		}
	};

	//#endregion HANDLERS -----------------------------------------------------------------------------------------------------

	//#region JSX -------------------------------------------------------------------------------------------------------------

	let colorChanger = `${
		activeGroup && activeGroup[label] && activeGroup[label].value === -1
			? ` text-white bg-${state.user.negativeColor}-500 `
			: ` text-${state.user.negativeColor}-500 bg-white `
	} `;

	return (
		<button
			id={widgetId}
			className={`w-full h-full rounded shadow text-3xl font-bold py-2
      ${colorChanger}`}
			onClick={() => onNegClick()}>
			-
		</button>
	);

	//#endregion JSX -------------------------------------------------------------------------------------------------------------
};

export default NegRecord;
